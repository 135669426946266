import { Box, Grid, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { ResponseResult } from 'models/api';
import { useQuery } from 'react-query';
import { FiltersProps } from 'models/filters';
import { Competency } from 'models/Competency';
import { getCompetencyList } from 'framework/competency';

type Props = {
	onChange: (name: string, value: any) => void;
	filters: FiltersProps
};

const CompetencyFilters: React.FC<Props> = ({ onChange, filters }) => {
	const { data: result, isError, isLoading } = useQuery<ResponseResult>(['competencies', { query: '' }], getCompetencyList);

	if (isError)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);
	if (isLoading) return <ActionLoader position={'absolute'} />;

	let competencies: Competency[] = result?.Data || [];
	const handleChange = (id: number) => {
		let SelectedCompetencies = filters.competencyIds ? filters.competencyIds.split(',').map((item) => competencies.find((competency) => competency.Id === parseInt(item))!) : [];
		const index = SelectedCompetencies.findIndex((item) => item.Id === id);
		if (index === -1) {
			SelectedCompetencies.push(competencies.find((item) => item.Id === id)!);
		} else {
			SelectedCompetencies.splice(index, 1);
		}

		onChange('competencyIds', SelectedCompetencies.map((item) => item.Id).join(','));
	};
 

	return (
		<Stack spacing={1} useFlexGap sx={{
			maxHeight: '20vh',
			overflowY: 'auto'		
		}}>
			<Typography variant="h6" component={'p'} fontFamily={'600'}>
				Competencies
			</Typography>
			<Stack>
				{competencies.map((competency: Competency, index: number) => (
					<Stack direction={'row'} key={index} >
						<Box component={'input'} type='checkbox'
							checked={filters?.competencyIds ? filters.competencyIds.split(',').some((item) => item === competency.Id.toString()) : false}
							onChange={() => handleChange(competency.Id)}
							sx={{
								cursor: 'pointer',
								mx: 1
							}}
						/>
						<Typography variant='body2' >{competency.Name}</Typography>
					</Stack>
				))}
			</Stack>
		</Stack>
	);
};

export default CompetencyFilters;
