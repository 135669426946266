import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';

type Props = {
	icon: React.ReactNode;
	count: number;
	title: string;
};

const ProgressCount = ({ icon, count, title }: Props) => {
	return (
		<Stack border={'thin solid'} borderColor={'rgba(5, 6, 15, 0.08)'} p={1} sx={{ borderRadius: '12px', background: 'rgba(255, 255, 255, 0.00)', boxShadow: '0px 0px 0px 1px rgba(5, 6, 15, 0.08) inset' }}>
			<Grid width={1} container justifyContent={'center'} alignItems={'center'} columnGap={1}>
				{icon}
				<Typography fontWeight={700} variant="h6" color="text.secondary">
					{count}
				</Typography>
			</Grid>
			<Typography variant="body2" color="text.secondary" textAlign={'center'}>
				{title}
			</Typography>
		</Stack>
	);
};

export default ProgressCount;
