import { Stack, TableCell, Typography } from '@mui/material';
import List from 'components/UI/List';
import { Feature } from 'models/api';
import React from 'react';
import { IReportTest, TestsCellsReports } from 'framework/reports';

interface Props {
	tests: IReportTest[];
	feature: Feature;
}

const ListTests: React.FC<Props> = ({ tests, feature }) => {
	const getBodyCells = (row: IReportTest) => {
		let cells = Object.keys(row).map((key: string, i: number) => {
			if (key === 'TestName')
				return {
					id: 'TestName',
					element: (
						<TableCell align={'left'} key={i}>
							<Stack direction={'row'} spacing={1}>
								<Typography variant="body2" fontWeight={500}>
									{row?.TestName}
								</Typography>
							</Stack>
						</TableCell>
					)
				};
			if (key === 'NumberOfparticipants')
				return {
					id: 'NumberOfparticipants',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.NumberOfparticipants}</Typography>
						</TableCell>
					)
				};
			if (key === 'PercentageOfPassedUsers')
				return {
					id: 'PercentageOfPassedUsers',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.PercentageOfPassedUsers}</Typography>
						</TableCell>
					)
				};
			if (key === 'NumberOfRetries')
				return {
					id: 'NumberOfRetries',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.NumberOfRetries}</Typography>
						</TableCell>
					)
				};
			else return <></>;
		});
		return cells;
	};

	return <List headCells={TestsCellsReports} data={tests!} getBodyCells={getBodyCells} feature={feature} canSelect={false} canEdit={false} />;
};

export default ListTests;
