import { useState } from 'react';
import { ITestEditInput } from 'models/testBank';
import { Box, Button, Grid, Stack } from '@mui/material';
import GradingTestItems from './GradingTestItems';
import GeneralTestItems from './GeneralTestItems';
import React from 'react';

type Props = {
 	editTestForm: ITestEditInput;
	onChange: (name: string, value: any) => void;
};




const ConfigureTest: React.FC<Props> = ({ editTestForm, onChange }) => {
	const [index, setIndex] = useState<number>(1);
	

   

	const tabs = [<GeneralTestItems onChange={onChange} editTestForm={editTestForm} />, <GradingTestItems onChange={onChange} editTestForm={editTestForm} />];
	const TABS = [
		{ id: 1, title: "General" },
		{ id: 2, title: 'Grading' }
	];

	return (
		<Box sx={{ overflowY: 'auto' }} px={3} >
			<Box p={3} border={'1px solid'} borderColor={'divider'} borderRadius={'8px'} >
				<Grid container height={1}>
					<Grid item xs={12} md={2} >
						<Stack spacing={1} useFlexGap my={1}
							direction={{ xs: 'row', md: 'column' }}
						>
							{TABS.map((tab) => (
								<Button
									size="large"
									color="inherit"
									key={tab.id}
									onClick={() => setIndex(tab.id)}
									sx={{
										justifyContent: 'flex-start',
										bgcolor: tab.id === index ? 'grey.200' : 'transparent',
										borderRadius: { xs: '8px 8px 0px 0px', md: '8px 0 0 8px' },
										':hover': { bgcolor: tab.id === index ? 'grey.200' : 'transparent' },
										fontWeight: tab.id === index ? 600 : 400,
										color: tab.id === index ? 'info.main' : 'text.primary'
									}}
									disableRipple>
									{tab.title}
								</Button>
							))}
						</Stack>
					</Grid>
					<Grid item xs={12} md={10} mt={1}>
						{tabs[index - 1]}
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default ConfigureTest;
