import { useEffect, useState } from 'react';
import { Box, Divider, IconButton, InputBase, Paper, Stack, Typography } from '@mui/material';
import { FilterIcon, SearchIcon } from 'components/UI/SvgIcons';
import Modal from '../../components/UI/DialogModal';
import SearchFilters from '../SearchFilters/SearchFilters';
import { Feature } from 'models/api';
import { IMediaQuery } from 'types/mediaQuery';
import useResponsive from 'hooks/useResponsive';
import { FiltersProps } from 'models/filters';
import { Resources } from 'constants/enums';
import useShowcaseStore from 'store/showcase';
import useStreamStore from 'store/stream';
import useInsightsStore from 'store/insights';
import useTestBankStore from 'store/testBank';
import useBacklogStore from 'store/backlog';
import useUserManagementStore from 'store/userManagement';
import useReportStore from 'store/reports';
import useDataCenterStore from 'store/dataCenter';
import useSupportStore from 'store/support';

interface Props {
	feature: Feature;
	isEditPage?: boolean;
}

interface IState {
	selectedShowcasesFilters: FiltersProps;
	selectedStreamsFilters: FiltersProps;
	selectedInsightsFilters: FiltersProps;
	selectedBacklogFilters: FiltersProps;
	selectedTestFilters: FiltersProps;
	selectedUserManagementFilters: FiltersProps;
	selectedReportsFilters: FiltersProps;
	selectedDataCenterFilters: FiltersProps;
	selectedSupportFilters: FiltersProps;
	isFiltersVisible: boolean;
	ShowcaseSearchTerm: string | null;
	StreamSearchTerm: string | null;
	InsightSearchTerm: string | null;
	TestSearchTerm: string | null;
	BacklogSearchTerm: string | null;
	UserManagementTerm: string | null;
	ReportsSearchTerm: string | null;
	DataCenterSearchTerm: string | null;
	SupportSearchTerm: string | null;
}

const INITIAL_STATE: IState = {
	isFiltersVisible: false,
	selectedShowcasesFilters: {} as FiltersProps,
	selectedStreamsFilters: {} as FiltersProps,
	selectedInsightsFilters: {} as FiltersProps,
	selectedTestFilters: {} as FiltersProps,
	selectedBacklogFilters: {} as FiltersProps,
	selectedUserManagementFilters: {} as FiltersProps,
	selectedReportsFilters: {} as FiltersProps,
	selectedDataCenterFilters: {} as FiltersProps,
	selectedSupportFilters: {} as FiltersProps,
	ShowcaseSearchTerm: null,
	StreamSearchTerm: null,
	InsightSearchTerm: null,
	TestSearchTerm: null,
	BacklogSearchTerm: null,
	UserManagementTerm: null,
	ReportsSearchTerm: null,
	DataCenterSearchTerm: null,
	SupportSearchTerm: null
};

const Search: React.FC<Props> = ({ feature, isEditPage }) => {
	const [state, setState] = useState(INITIAL_STATE);
	const {
		selectedShowcasesFilters,
		selectedStreamsFilters,
		selectedInsightsFilters,
		selectedTestFilters,
		selectedBacklogFilters,
		selectedUserManagementFilters,
		selectedReportsFilters,
		selectedDataCenterFilters,
		selectedSupportFilters,
		isFiltersVisible,
		ShowcaseSearchTerm,
		StreamSearchTerm,
		InsightSearchTerm,
		TestSearchTerm,
		BacklogSearchTerm,
		UserManagementTerm,
		ReportsSearchTerm,
		DataCenterSearchTerm,
		SupportSearchTerm
	} = state;

	const params: IMediaQuery = { query: 'up', key: 'md' };
	const mdUp = useResponsive(params);

	const showcasesParams = useShowcaseStore((state) => state.params);
	const streamsParams = useStreamStore((state) => state.params);
	const insightsParams = useInsightsStore((state) => state.params);
	const testParams = useTestBankStore((state) => state.params);
	const backlogParams = useBacklogStore((state) => state.params);
	const userManagementParams = useUserManagementStore((state) => state.params);
	const reportParams = useReportStore((state) => state.params);
	const dataCenterParams = useDataCenterStore((state) => state.params);
	const supportParams = useSupportStore((state) => state.params);

	const setShowcasesParams = useShowcaseStore((state) => state.setParams);
	const setStreamsParams = useStreamStore((state) => state.setParams);
	const setInsightsParams = useInsightsStore((state) => state.setParams);
	const setTestParams = useTestBankStore((state) => state.setParams);
	const setBacklogParams = useBacklogStore((state) => state.setParams);
	const setUserManagementParams = useUserManagementStore((state) => state.setParams);
	const setReportParams = useReportStore((state) => state.setParams);
	const setDataCenterParams = useDataCenterStore((state) => state.setParams);
	const setSupportParams = useSupportStore((state) => state.setParams);

	useEffect(() => {
		const timer = setTimeout(() => {
			handleSearch();
		}, 500);
		return () => clearTimeout(timer);

		// eslint-disable-next-line
	}, [ShowcaseSearchTerm, StreamSearchTerm, InsightSearchTerm, TestSearchTerm, BacklogSearchTerm, UserManagementTerm,
	ReportsSearchTerm , DataCenterSearchTerm , SupportSearchTerm]);



	const handleChangeStateData = (key: string, value: any) => setState((prev) => ({ ...prev, [key]: value }));

	const handleFiltersOpen = () => handleChangeStateData('isFiltersVisible', true);
	const handleFiltersClose = () => handleChangeStateData('isFiltersVisible', false);

	const handleSelectedFilters = (filters: FiltersProps) => {
		if (feature.slug.toLowerCase() === Resources.SHOW_CASES) handleChangeStateData('selectedShowcasesFilters', filters);
		if (feature.slug.toLowerCase() === Resources.STREAM) handleChangeStateData('selectedStreamsFilters', filters);
		if (feature.slug.toLowerCase() === Resources.INSIGHTS) handleChangeStateData('selectedInsightsFilters', filters);
		if (feature.slug.toLowerCase() === Resources.TEST_BANK) handleChangeStateData('selectedTestFilters', filters);
		if (feature.slug.toLowerCase() === Resources.BACKLOG) handleChangeStateData('selectedBacklogFilters', filters);
		if (feature.slug.toLowerCase() === Resources.USER_MANAGEMENT) handleChangeStateData('selectedUserManagementFilters', filters);
		if (feature.slug.toLowerCase() === Resources.REPORTS) handleChangeStateData('selectedReportsFilters', filters);
		if (feature.slug.toLowerCase() === Resources.DATA_CENTER) handleChangeStateData('selectedDataCenterFilters', filters);
		if (feature.slug.toLowerCase() === Resources.SUPPORT) handleChangeStateData('selectedSupportFilters', filters);
	};

	const getSelectedFilters = () => {
		if (feature.slug.toLowerCase() === Resources.SHOW_CASES) return selectedShowcasesFilters;
		if (feature.slug.toLowerCase() === Resources.STREAM) return selectedStreamsFilters;
		if (feature.slug.toLowerCase() === Resources.INSIGHTS) return selectedInsightsFilters;
		if (feature.slug.toLowerCase() === Resources.TEST_BANK) return selectedTestFilters;
		if (feature.slug.toLowerCase() === Resources.BACKLOG) return selectedBacklogFilters;
		if (feature.slug.toLowerCase() === Resources.USER_MANAGEMENT) return selectedUserManagementFilters;
		if (feature.slug.toLowerCase() === Resources.REPORTS) return selectedReportsFilters;
		if (feature.slug.toLowerCase() === Resources.DATA_CENTER) return selectedDataCenterFilters;
		if (feature.slug.toLowerCase() === Resources.SUPPORT) return selectedSupportFilters;
		else return {};
	}

	const handleSetSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		if (feature.slug.toLowerCase() === Resources.SHOW_CASES) handleChangeStateData('ShowcaseSearchTerm', value);
		if (feature.slug.toLowerCase() === Resources.STREAM) handleChangeStateData('StreamSearchTerm', value);
		if (feature.slug.toLowerCase() === Resources.INSIGHTS) handleChangeStateData('InsightSearchTerm', value);
		if (feature.slug.toLowerCase() === Resources.TEST_BANK) handleChangeStateData('TestSearchTerm', value);
		if (feature.slug.toLowerCase() === Resources.BACKLOG) handleChangeStateData('BacklogSearchTerm', value);
		if (feature.slug.toLowerCase() === Resources.USER_MANAGEMENT) handleChangeStateData('UserManagementTerm', value);
		if (feature.slug.toLowerCase() === Resources.REPORTS) handleChangeStateData('ReportsSearchTerm', value);
		if (feature.slug.toLowerCase() === Resources.DATA_CENTER) handleChangeStateData('DataCenterSearchTerm', value);
		if (feature.slug.toLowerCase() === Resources.SUPPORT) handleChangeStateData('SupportSearchTerm', value);
	}

	const getShowcaseParams = () => {
		let existParams = showcasesParams.split('?');
		if (existParams.length > 0 && showcasesParams !== '') {
			let queryParams = existParams[1].split('&');
			queryParams = queryParams.filter((item) => !item.includes('searchText'));
			queryParams.push(`searchText=${ShowcaseSearchTerm}`);
			return `?${queryParams.join('&')}`;
		} else {
			return `?searchText=${ShowcaseSearchTerm}`;
		}
	}
	const getStreamParams = () => {
		let existParams = streamsParams.split('?');
		if (existParams.length > 0 && streamsParams !== '') {
			let queryParams = existParams[1].split('&');
			queryParams = queryParams.filter((item) => !item.includes('searchText'));
			queryParams.push(`searchText=${StreamSearchTerm}`);
			return `?${queryParams.join('&')}`;
		} else {
			return `?searchText=${StreamSearchTerm}`;
		}
	}
	const getInsightsParams = () => {
		let existParams = insightsParams.split('?');
		if (existParams.length > 0 && insightsParams !== '') {
			let queryParams = existParams[1].split('&');
			queryParams = queryParams.filter((item) => !item.includes('searchText'));
			queryParams.push(`searchText=${InsightSearchTerm}`);
			return `?${queryParams.join('&')}`;
		} else {
			return `?searchText=${InsightSearchTerm}`;
		}
	}
	const getBacklogParams = () => {
		let existParams = backlogParams.split('?');
		if (existParams.length > 0 && backlogParams !== '') {
			let queryParams = existParams[1].split('&');
			queryParams = queryParams.filter((item) => !item.includes('searchText'));
			queryParams.push(`searchText=${BacklogSearchTerm}`);
			return `?${queryParams.join('&')}`;
		} else {
			return `?searchText=${BacklogSearchTerm}`;
		}
	}

	const getUserManagementParams = () => {
		let existParams = userManagementParams.split('?');
		if (existParams.length > 0 && userManagementParams !== '') {
			let queryParams = existParams[1].split('&');
			queryParams = queryParams.filter((item) => !item.includes('searchText'));
			queryParams.push(`searchText=${UserManagementTerm}`);
			return `?${queryParams.join('&')}`;
		} else {
			return `?searchText=${UserManagementTerm}`;
		}
	}

	const getReportsParams = () => {
		let existParams = reportParams.split('?');
		if (existParams.length > 0 && reportParams !== '') {
			let queryParams = existParams[1].split('&');
			queryParams = queryParams.filter((item) => !item.includes('searchText'));
			queryParams.push(`searchText=${ReportsSearchTerm}`);
			return `?${queryParams.join('&')}`;
		} else {
			return `?searchText=${ReportsSearchTerm}`;
		}
	}

	const getSelectedDataCenterParams = () => {
		let existParams = dataCenterParams.split('?');
		if (existParams.length > 0 && dataCenterParams !== '') {
			let queryParams = existParams[1].split('&');
			queryParams = queryParams.filter((item) => !item.includes('searchText'));
			queryParams.push(`searchText=${DataCenterSearchTerm}`);
			return `?${queryParams.join('&')}`;
		} else {
			return `?searchText=${DataCenterSearchTerm}`;
		}
	}

	const getSupportParams = () => {
		let existParams = supportParams.split('?');
		if (existParams.length > 0 && supportParams !== '') {
			let queryParams = existParams[1].split('&');
			queryParams = queryParams.filter((item) => !item.includes('searchText'));
			queryParams.push(`searchText=${SupportSearchTerm}`);
			return `?${queryParams.join('&')}`;
		} else {
			return `?searchText=${SupportSearchTerm}`;
		}
	}

	const getTestParams = () => {
		let newParams = testParams ? JSON.parse(testParams) : {};
		newParams.SearchText = TestSearchTerm;
		return JSON.stringify(newParams);
	}


	const handleSearch = () => {
		if ((!ShowcaseSearchTerm && ShowcaseSearchTerm !== '') && (!StreamSearchTerm && StreamSearchTerm !== '') && (!InsightSearchTerm && InsightSearchTerm !== '') && (!TestSearchTerm && TestSearchTerm !== '') &&
			(!BacklogSearchTerm && BacklogSearchTerm !== '') && (!UserManagementTerm && UserManagementTerm !== '')
			&& (!ReportsSearchTerm && ReportsSearchTerm !== '') && (!DataCenterSearchTerm && DataCenterSearchTerm !== '')
			&& (!SupportSearchTerm && SupportSearchTerm !== '')
		) return;	 // if no search term, do nothing
		if (feature.slug.toLowerCase() === Resources.SHOW_CASES) setShowcasesParams(getShowcaseParams());
		if (feature.slug.toLowerCase() === Resources.STREAM) setStreamsParams(getStreamParams());
		if (feature.slug.toLowerCase() === Resources.INSIGHTS) setInsightsParams(getInsightsParams());
		if (feature.slug.toLowerCase() === Resources.TEST_BANK) setTestParams(getTestParams());
		if (feature.slug.toLowerCase() === Resources.BACKLOG) setBacklogParams(getBacklogParams());
		if (feature.slug.toLowerCase() === Resources.USER_MANAGEMENT) setUserManagementParams(getUserManagementParams());
		if (feature.slug.toLowerCase() === Resources.REPORTS) setReportParams(getReportsParams());
		if (feature.slug.toLowerCase() === Resources.DATA_CENTER) setDataCenterParams(getSelectedDataCenterParams());
		if (feature.slug.toLowerCase() === Resources.SUPPORT) setSupportParams(getSupportParams());
	}

	const getDefaultSearchTerm = () => {
		if (feature.slug.toLowerCase() === Resources.SHOW_CASES) return ShowcaseSearchTerm;
		if (feature.slug.toLowerCase() === Resources.STREAM) return StreamSearchTerm;
		if (feature.slug.toLowerCase() === Resources.INSIGHTS) return InsightSearchTerm;
		if (feature.slug.toLowerCase() === Resources.TEST_BANK) return TestSearchTerm;
		if (feature.slug.toLowerCase() === Resources.BACKLOG) return BacklogSearchTerm;
		if (feature.slug.toLowerCase() === Resources.USER_MANAGEMENT) return UserManagementTerm;
		if (feature.slug.toLowerCase() === Resources.REPORTS) return ReportsSearchTerm;
		if (feature.slug.toLowerCase() === Resources.DATA_CENTER) return DataCenterSearchTerm;
		if (feature.slug.toLowerCase() === Resources.SUPPORT) return SupportSearchTerm;
		else return '';
	}

	return (
		<>
			{mdUp && (
				<Paper
					component="form"
					sx={{
						p: '2px 12px',
 						alignItems: 'center',
						width: 600,
						borderRadius: 10,
						position: 'relative',
						display: isEditPage ? 'none' : 'flex'
					}}>
					<SearchIcon />
					<InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search" inputProps={{ 'aria-label': 'search' }}
						value={getDefaultSearchTerm()}
						onChange={handleSetSearchTerm}
					/>
					<IconButton type="button" sx={{ p: '10px' }} aria-label="search"></IconButton>
					<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

					<Stack direction={'row'} spacing={1} display={'flex'} alignItems={'center'}>
						<IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={handleFiltersOpen}>
							<FilterIcon />
						</IconButton>
						<Typography variant="body2" color="gray">
							Filter
						</Typography>
						<Box
							sx={{
								height: 25,
								width: 25,
								bgcolor: Object.keys(getSelectedFilters()).length > 0 ? '#1B6EE4' : '#ccc',
								borderRadius: '50%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								color: '#fff'
							}}>
							<Typography variant="body2" color="white" fontWeight={400}>
								{Object.keys(getSelectedFilters()).length}
							</Typography>
						</Box>
					</Stack>
				</Paper>
			)}
			{!mdUp && (
				<>
					<Stack>
						<Paper
							component="form"
							sx={{
								p: '2px 12px',
								display: 'flex',
								alignItems: 'center',
								width: 250,
								borderRadius: 10,
								position: 'relative'
							}}>
							<IconButton sx={{ p: '10px' }} aria-label="menu">
								<SearchIcon />
							</IconButton>
							<InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search" inputProps={{ 'aria-label': 'search' }} />
							<IconButton type="button" sx={{ p: '10px' }} aria-label="search"></IconButton>
						</Paper>
						<Stack direction={'row'} spacing={1} display={'flex'} alignItems={'center'}>
							<IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={handleFiltersOpen}>
								<FilterIcon />
							</IconButton>
							<Typography variant="body2" color="gray">
								Filter
							</Typography>
							<Box
								sx={{
									height: 25,
									width: 25,
									bgcolor: Object.keys(getSelectedFilters()).length > 0 ? '#1B6EE4' : '#ccc',
									borderRadius: '50%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									color: '#fff'
								}}>
								<Typography variant="body2" color="white" fontWeight={400}>
									{Object.keys(getSelectedFilters()).length}
								</Typography>
							</Box>
						</Stack>
					</Stack>
				</>
			)}
			{isFiltersVisible && (
				<Modal
					open={isFiltersVisible}
					onClose={handleFiltersClose}
					title={'Filters'}
					children={<SearchFilters feature={feature} handleFiltersClose={handleFiltersClose} setSelectedFilters={handleSelectedFilters} selectedFilters={getSelectedFilters()} />}
					fullScreen={false}
					actions={<></>}
				/>
			)}
		</>
	);
};

export default Search;
