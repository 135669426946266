import { ADD_COMPETENCY, DELETE_COMPETENCY, EDIT_COMPETENCY, GET_COMPETENCY_BY_ID, GET_COMPETENCY_LIST, TRANSLATE_COMPETENCY } from 'constants/api';
import axios from '../helpers/axiosInstance';
import { HeadCell } from 'models/app';
import { TranslateCompetencyForm } from 'models/Competency';

export async function getCompetencyList({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_COMPETENCY_LIST + query);
	return response.data;
}

export async function getPillarList({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_COMPETENCY_LIST + query);
	return response.data;
}

export async function getCompetencyById({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_COMPETENCY_BY_ID + _params.query);
	return response.data;
}

export async function addCompetency(data: FormData) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(ADD_COMPETENCY, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
}
export async function editCompetency(data: FormData) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(EDIT_COMPETENCY, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
}
export async function deleteCompetency(id: number) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(DELETE_COMPETENCY + '?id=' + id);
	return response.data;
}

export async function translateCompetency(data: TranslateCompetencyForm) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(TRANSLATE_COMPETENCY, data);
	return response.data;
}

export const CompetencyCells: HeadCell[] = [
	{
		id: 'name',
		numeric: false,
		disablePadding: false,
		label: 'Name'
	},
	{
		id: 'pillarName',
		numeric: false,
		disablePadding: false,
		label: 'Pillar Name'
	},
	{
		id: 'indicators',
		numeric: false,
		disablePadding: false,
		label: 'Total Indicators'
	},
	{
		id: 'actions',
		numeric: false,
		disablePadding: false,
		label: '',
		isNotSortable: true
	}
];

export const PillarCells: HeadCell[] = [
	{
		id: 'name',
		numeric: false,
		disablePadding: false,
		label: 'Name'
	},
 
	{
		id: 'actions',
		numeric: false,
		disablePadding: false,
		label: '',
		isNotSortable: true
	}
];
