import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import { InputTypes } from 'constants/enums';
import { getLanguages } from 'framework/language';
import { getTags } from 'framework/tags';
import { getTopics } from 'framework/topics';
import { ShowcaseAddInput } from 'models/showcase';
import { Language, ResponseResult, Tag, Topic } from 'models/api';
import { IFormField, IOption } from 'models/app';
import React from 'react';
import { useQuery } from 'react-query';

interface Props {
  onChange: (name: string, value: any) => void;
  state: ShowcaseAddInput;
  reset?: Boolean;
  onAddTag: (tagName: string) => void;
  onAddTopic: (topicName: string) => void;
}

const AddShowcase: React.FC<Props> = ({ onChange, state, reset, onAddTag, onAddTopic }) => {
  const [openAddTopic, setOpenAddTopic] = React.useState<Boolean>(false);
  const [openAddTags, setOpenAddTags] = React.useState<Boolean>(false);
  const [tag, setTag] = React.useState<string>("");
  const [topic, setTopic] = React.useState<string>("");




  const { data: tagResult, isError: isErrorTags, isLoading: isLoadingTags } = useQuery<ResponseResult>(['tags', { query: "" }], getTags);
  const { data: topicResult, isError: isErrorTopics, isLoading: isLoadingTopics } = useQuery<ResponseResult>(['topics', { query: "" }], getTopics);
  const { data: languagesResult, isError: isErrorLanguages, isLoading: isLoadingLanguages } = useQuery<ResponseResult>(['languages', { query: "" }], getLanguages);

  if (isErrorTags || isErrorTopics || isErrorLanguages)
    return <Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}><Typography variant="body2" fontWeight={500} fontSize={15} color={"#000"}>Something Wrong</Typography></Grid>;
  if (isLoadingTags || isLoadingTopics || isLoadingLanguages)
    return <Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}><ActionLoader position={"absolute"} /></Grid>;

  let tags: Tag[] = tagResult?.Data || [] as Tag[];
  let topics: Topic[] = topicResult?.Data || [] as Topic[];
  let languages: Language[] = languagesResult?.Data || [] as Language[];

  const tagOptions: IOption[] = tags.map((item) => ({ label: item.Name, value: `${item.Id}` }));


  const handleChangeTopics = (topic: Topic, checked: boolean) => {
    if (checked) {
      onChange("TopicId", topic.Id);
    } else {
      onChange("TopicId", null);
    }

  }
  const handleChangeLanguage = (language: Language, checked: boolean) => {
    if (checked) {
      onChange("LanguageId", language.Id);
    } else {
      onChange("LanguageId", null);
    }
  }


 
  let basicFields: IFormField[] = [
    {
      name: "Subject",
      label: "Subject:",
      type: InputTypes.TEXT,
      placeholder: "Enter Subject",
    },
    {
      name: "Description",
      label: "Description:",
      type: InputTypes.EDITOR,
    },
    // {
    //   name: "Attachment",
    //   label: "Attachments:",
    //   type: InputTypes.FILE,
    //   maxSize: 5242880,
    //   showFileUrl: true,
    //   defaultImageUrl: state.AttachmentpPath,
    //   onDeleteFile: () => {
    //     onChange("AttachmentpPath", null);
    //   }
    //  },
    {
      name: "ShowCaseTags",
      label: "Tags:",
      type: InputTypes.MULTI_SELECT,
      placeholder: "Select Tags",
      options: tagOptions,
      bulkAction: !openAddTags,
      bulkActionLabel: "Add",
      bulkActionFn: () => setOpenAddTags(!openAddTags)
    },
  ];

  let topicField: IFormField[] = [
    {
      name: "Topic",
      label: "Topic:",
      type: InputTypes.TEXT,
      placeholder: "Enter Topic Name",
      hidden: !openAddTopic,
    }]
  let tagField: IFormField[] = [
    {
      name: "Tag",
      label: "Tag:",
      type: InputTypes.TEXT,
      placeholder: "Enter Tag Name",
      hidden: !openAddTags
    }]




  return (
    <Grid container justifyContent={'space-between'} spacing={2} >
      <Grid item md={12} lg={8}>
        <Stack >
          <Form
            fields={basicFields}
            onChange={onChange}
            reset={reset}
          />
          {openAddTags && <Stack>
            <Form
              fields={tagField}
              onChange={(name: string, value: any) => setTag(value)}
              reset={reset}
            />
            <Stack direction={'row'} spacing={1} >
              <MuiButton variant="text" color="primary" size="small" sx={{ textTransform: 'none' }}
                onClick={() => {
                  onAddTag(tag);
                  setOpenAddTags(false);
                }}
                disabled={!tag}
              >Add</MuiButton>
              <MuiButton variant="text" color="warning" size="small" sx={{ textTransform: 'none' }}
                onClick={() => setOpenAddTags(false)}
              >Cancel</MuiButton>
            </Stack>
          </Stack>}
        </Stack>
      </Grid>
      <Grid item md={12} lg={4}>
        <Stack spacing={2}>
          <Box>
            <Grid container justifyContent={'space-between'}>
              <Typography variant="body2" fontWeight={400} fontSize={16} color={"#000"}>
                Language</Typography>
            </Grid>
            <Divider sx={{ mb: 2 }} />
            <Stack direction={'column'} spacing={2} >
              {languages.map((language: Language, index) => (
                <Stack direction={'row'} spacing={1} key={index}>
                  <Box component={'input'} type='radio'
                    onChange={(e: any) => handleChangeLanguage(language, e?.target?.checked!)}
                    checked={state.LanguageId === language.Id}
                  />
                  <Typography variant="body2" fontWeight={300} fontSize={13}>
                    {language.Name}</Typography>
                </Stack>
              ))}
            </Stack>
          </Box>
          <Box>
            <Grid container justifyContent={'space-between'}>
              <Typography variant="body2" fontWeight={400} fontSize={16} color={"#000"}>
                Topic</Typography>
              {!openAddTopic && <MuiButton variant="text" color="primary" size="small" sx={{ textTransform: 'none' }}
                onClick={() => setOpenAddTopic(!openAddTopic)}
              >Add</MuiButton>}
            </Grid>
            <Divider sx={{ mb: 2 }} />
            <Stack direction={'column'} spacing={2} >
              <Stack direction={'column'} spacing={2} sx={{
                maxHeight: 300,
                overflowY: 'auto',
                p: 1,
              }}>
                {topics.map((topic: Topic, index) => (
                  <Stack direction={'row'} spacing={1} key={index}>
                    <Box component={'input'} type='radio'
                      onChange={(e: any) => handleChangeTopics(topic, e?.target?.checked!)}
                      checked={state.TopicId === topic.Id}
                    />
                    <Typography variant="body2" fontWeight={300} fontSize={13}>
                      {topic.Name}</Typography>
                  </Stack>
                ))}
              </Stack>
              {openAddTopic && <Stack>
                <Form
                  fields={topicField}
                  onChange={(name: string, value: any) => setTopic(value)}
                  reset={reset}
                />
                <Stack direction={'row'} spacing={1} >
                  <MuiButton variant="text" color="primary" size="small" sx={{ textTransform: 'none' }}
                    onClick={() => {
                      onAddTopic(topic);
                      setOpenAddTopic(false);
                    }}
                    disabled={!topic}
                  >Add</MuiButton>
                  <MuiButton variant="text" color="warning" size="small" sx={{ textTransform: 'none' }}
                    onClick={() => setOpenAddTopic(false)}
                  >Cancel</MuiButton>
                </Stack>
              </Stack>}
            </Stack>
          </Box>

        </Stack>
      </Grid>
    </Grid>

  );
};

export default AddShowcase;