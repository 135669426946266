import { Box, Grid, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { QuestionIcon } from 'components/UI/SvgIcons';
import { useAlert } from 'contexts/alertContext';
import {
	GetNumberOfInteractionsSetAsNone,
	getReportCompetencyIndicator,
	getReportsAssesmentsWithTests,
	getReportsAssesmentsWithoutTests,
	getReportsFirstLevelInteraction,
	getReportsSecondLevelInteraction,
	getReportsTopPerformance
} from 'framework/reports';
import { Feature, ResponseResult } from 'models/api';
import React, { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import VerticalProgressBar from './partials/VerticalProgress';
import { generateBacklogColorDarker } from 'helpers/utils';
import useReportStore from 'store/reports';

interface Props {
	feature: Feature;
}

interface ReportData {
	firstLevelInteraction: number;
	secondLevelInteraction: number;
	competencyWithTest: number;
	competencyWithoutTest: number;
	numberOfInteractionsSetAsNone?: number;
}

interface ITopPerformance {
	CompetencyId: number;
	CompetencyName: string;
	CompetencyDescription: string;
	CompetencyScore: number;
	PerformancePercentage: number;
	PerformancePercentageLabel: string;
}

interface ICompetencyIndicator {
	CompetencyId: number;
	CompetencyName: string;
	HighestIndicator: string;
	HighestScore: number;
	LowestIndicator: string;
	LowestScore: number;
}

const ReportsView: React.FC<Props> = ({ feature }) => {
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	const params = useReportStore((state) => state.params);

	const [loading, setLoading] = useState<boolean>(false);
	const [loadingPerformance, setLoadingPerformance] = useState<boolean>(false);
	const [loadingIndicators, setLoadingIndicators] = useState<boolean>(false);
	const [data, setData] = useState<ReportData>({
		firstLevelInteraction: 0,
		secondLevelInteraction: 0,
		competencyWithTest: 0,
		competencyWithoutTest: 0
	});
	const [performance, setPerformance] = useState<ITopPerformance[] | null>(null);
	const [competencyIndicator, setCompetencyIndicator] = useState<ICompetencyIndicator[] | null>(null);

	const fetchFirstLevelInteraction = useCallback(async () => {
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['ReportsFirstLevel', { query: params }], getReportsFirstLevelInteraction);
			const [result] = response.Data;
			setData((prevData) => ({ ...prevData, firstLevelInteraction: result }));

			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
		// eslint-disable-next-line
	}, [params]);

	const fetchSecondLevelInteraction = useCallback(async () => {
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['ReportsSecondLevel', { query: params }], getReportsSecondLevelInteraction);
			const [result] = response.Data;
			setData((prevData) => ({ ...prevData, secondLevelInteraction: result }));
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
		// eslint-disable-next-line
	}, [params]);

	const fetchCompetencyWithTest = useCallback(async () => {
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['ReportsAssesmentWithTests', { query: params }], getReportsAssesmentsWithTests);
			const [result] = response.Data;
			setData((prevData) => ({ ...prevData, competencyWithTest: result }));
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
		// eslint-disable-next-line
	}, [params]);
	const fetchNumberOfInteractionsSetAsNone = useCallback(async () => {
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['GetNumberOfInteractionsSetAsNone', { query: params }], GetNumberOfInteractionsSetAsNone);
			const [result] = response.Data;
			setData((prevData) => ({ ...prevData, numberOfInteractionsSetAsNone: result }));
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
		// eslint-disable-next-line
	}, [params]);

	const fetchCompetencyWithoutTest = useCallback(async () => {
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['ReportsAssesmentWithoutTests', { query: params }], getReportsAssesmentsWithoutTests);
			const [result] = response.Data;
			setData((prevData) => ({ ...prevData, competencyWithoutTest: result }));
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
		// eslint-disable-next-line
	}, [params]);

	const fetchTopPerformance = useCallback(async () => {
		setLoadingPerformance(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['ReportTopPerformance', { query: params }], getReportsTopPerformance);
			setPerformance(response.Data);
			setLoadingPerformance(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoadingPerformance(false);
		// eslint-disable-next-line
	}, [params]);

	const fetchCompetencyIndicator = useCallback(async () => {
		setLoadingIndicators(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['ReportCompetencyIndicator', { query: params }], getReportCompetencyIndicator);
			setCompetencyIndicator(response.Data);
			setLoadingIndicators(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoadingIndicators(false);
		// eslint-disable-next-line
	}, [params]);

	useEffect(() => {
		fetchFirstLevelInteraction();
		fetchSecondLevelInteraction();
		fetchCompetencyWithTest();
		fetchCompetencyWithoutTest();
		fetchTopPerformance();
		fetchCompetencyIndicator();
		fetchNumberOfInteractionsSetAsNone();
		// eslint-disable-next-line
	}, [params]);

	return (
		<Stack spacing={4} useFlexGap>
			{loading ? (
				<ActionLoader position={'sticky'} />
			) : (
				<Stack direction={'row'} spacing={4} justifyContent={'space-between'}>
					<Box>
						<Typography variant="h4" fontWeight={400}>
							{data?.competencyWithTest}
						</Typography>
						<Typography variant="caption">
							Number of Users Completed C.A with Tests <QuestionIcon />
						</Typography>
					</Box>
					<Box>
						<Typography variant="h4" fontWeight={400}>
							{data?.competencyWithoutTest}
						</Typography>
						<Typography variant="caption">
							Number of users completed C.A without tests <QuestionIcon />
						</Typography>
					</Box>
					<Box>
						<Typography variant="h4" fontWeight={400}>
							{data?.firstLevelInteraction}
						</Typography>
						<Typography variant="caption">
							1st Level of Interactions <QuestionIcon />
						</Typography>
					</Box>
					<Box>
						<Typography variant="h4" fontWeight={400}>
							{data?.secondLevelInteraction}
						</Typography>
						<Typography variant="caption">
							2nd Level of Interactions <QuestionIcon />
						</Typography>
					</Box>
					<Box>
						<Typography variant="h4" fontWeight={400}>
								{data?.numberOfInteractionsSetAsNone}
						</Typography>
						<Typography variant="caption">
							Number Of Interactions Set As None <QuestionIcon />
						</Typography>
					</Box>
				</Stack>
			)}

			<Typography mt={2} variant="body1" fontWeight={500}>
				Top Performance by competency
			</Typography>
			{loadingPerformance ? (
				<ActionLoader position={'sticky'} />
			) : (
				<Stack direction={'row'} sx={{ overflowX: 'auto' }} py={3}>
					{performance?.map((performance, index) => (
						<Stack key={index} sx={{ minWidth: 100 }} alignItems={'center'} justifyContent={'center'} spacing={1}>
							<Grid container alignItems={'center'} justifyContent={'center'} sx={{ height: '20%' }}>
								<Typography
									variant="body2"
									sx={{
										color: 'gray',
										fontWeight: 400,
										WebkitLineClamp: 2,
										textOverflow: 'ellipsis',
										maxWidth: '50%',
										minHeight: 50
									}}
									fontSize={12}>
									{performance.CompetencyName}
								</Typography>
							</Grid>
							<Box sx={{ height: '60%' }}>
								<VerticalProgressBar percent={performance?.PerformancePercentage * 100} fillColor={generateBacklogColorDarker(index)} />
							</Box>
							<Box sx={{ height: '20%' }}>
								<Typography variant="body2" sx={{ fontWeight: 400 }} fontSize={10}>
									{performance.PerformancePercentageLabel}
								</Typography>
							</Box>
						</Stack>
					))}
				</Stack>
			)}
			{loadingIndicators ? (
				<ActionLoader position={'sticky'} />
			) : (
				<Grid container rowGap={2} my={3}>
					<Grid item xs={4}>
						<Typography variant="h6">Competency</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="h6"> Top Indicator</Typography>
					</Grid>
					<Grid item xs={4}>
						<Typography variant="h6">Lowest Indicators</Typography>
					</Grid>
					{competencyIndicator?.map((competency, index) => (
						<>
							<Grid item xs={4}>
								<Typography>{competency.CompetencyName}</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography>{competency.HighestIndicator}</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography>{competency.LowestIndicator}</Typography>
							</Grid>
						</>
					))}
				</Grid>
			)}
		</Stack>
	);
};

export default ReportsView;
