import { Box, IconButton } from '@mui/material';
import { getInitial } from 'helpers/utils';

interface Props
{
	name?: string;
	size?: 'SMALL' | 'MEDIUM' | 'LARGE';
	imageWidth?: number;
	imageHeight?: number;
	ProfileImagePath?: string;
}

const ProfileAvatar: React.FC<Props> = ({ name, size = 'MEDIUM', imageWidth, imageHeight, ProfileImagePath }) =>
{
	return (
		<IconButton
			color="inherit"
			aria-label="show more"
			aria-haspopup="true"
			sx={ {
				width: size === 'SMALL' ? 32 : size === 'MEDIUM' ? 48 : 120,
				height: size === 'SMALL' ? 32 : size === 'MEDIUM' ? 48 : 120,
				bgcolor: ProfileImagePath ? 'transparent' : 'primary.main',
				color: 'common.white',
				fontSize: size === 'SMALL' ? '1.2rem' : size === 'MEDIUM' ? '1.5rem' : '4rem',
				p: 0,
				'&:hover': { bgcolor: 'primary.main', color: 'common.white' }
			} }>
			{ ProfileImagePath ? <>
				<Box component={ 'img' } src={ ProfileImagePath.replace('\\Uploads\\', 'https://devapi.scofolio.com/Uploads/') } alt={ name }
					width={ imageWidth || 50 }
					height={ imageHeight || 50 }
					sx={ {
						borderRadius: '50%',
						objectFit: 'fill',
						objectPosition: 'center',
						verticalAlign: 'middle'
					} } />
			</> : getInitial(name!) }
		</IconButton>
	);
};

export default ProfileAvatar;
