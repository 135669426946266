import { Box, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import logo from 'assets/images/Logo.jpeg';

import * as Yup from "yup";
import { IFormField } from 'models/app';
import { InputTypes } from 'constants/enums';
import Form from 'components/UI/Form';
import { Link } from 'react-router-dom';


interface IProps {
  slug: string;
  onSubmit: (data: any) => void;
}




const AuthForm: React.FC<IProps> = ({ slug, onSubmit }) => {


  const basicFields: IFormField[] = [
    {
      name: "Email",
      label: "Email",
      type: InputTypes.TEXT,
      placeholder: "Enter your email",
    },
    {
      name: "Password",
      label: "Password",
      type: InputTypes.PASSWORD,
      placeholder: "*********",
    },
    {
      name: "RememberMe",
      label: "Remember Me",
      type: InputTypes.CHECKBOX,       
      hidden: true,
    },
  ];

  let validations: any = {
    Email: Yup.string().required("Email is required").matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, "The email should be like this 'test@example.com'"),
    Password: Yup.string().required("Password is required").matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  }

  return (
    <Box width={1} height={1} justifySelf={'center'}>
      <Stack width={1} height={1} justifyContent={'center'}>
        <Box width={216} alignSelf={'center'} mb={2}>
          <Box component={'img'} src={logo} alt="scofolio" sx={{ display: 'block', maxWidth: '100%', height: 'auto' }} />
        </Box>
        <Typography textAlign={'center'} color="text.secondary" fontWeight={300}>
          Identify, map and manage your innovation potential
        </Typography>
        <Container maxWidth="sm">
          <Form
            fields={basicFields}
            cb={onSubmit}
            buttonText={"Login"}
            validations={validations}
          />
         <Link to="/forgot-password">Forgot Password?</Link>
       </Container>
      </Stack>
    </Box>
  );
};

export default AuthForm;