import { Box, Grid, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { ResponseResult, Topic } from 'models/api';
import { useQuery } from 'react-query';
import { getTopics } from 'framework/topics';
import { FiltersProps } from 'models/filters';

type Props = {
	onChange: (name: string, value: any) => void;
	filters: FiltersProps
};

const TopicFilters: React.FC<Props> = ({ onChange, filters }) => {
	const { data: result, isError, isLoading } = useQuery<ResponseResult>(['topics', { query: '' }], getTopics);

	if (isError)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);
	if (isLoading) return <ActionLoader position={'absolute'} />;

	let topics: Topic[] = result?.Data || [];
	const handleChange = (id: number) => {
		let SelectedTopics = filters.topicIds ? filters.topicIds.split(',').map((item) => topics.find((topic) => topic.Id === parseInt(item))!) : [];
		const index = SelectedTopics.findIndex((item) => item.Id === id);
		if (index === -1) {
			SelectedTopics.push(topics.find((item) => item.Id === id)!);
		} else {
			SelectedTopics.splice(index, 1);
		}

		onChange('topicIds', SelectedTopics.map((item) => item.Id).join(','));
	};
 

	return (
		<Stack spacing={1} useFlexGap sx={{
			maxHeight: '20vh',
			overflowY: 'auto'		
		}}>
			<Typography variant="h6" component={'p'} fontFamily={'600'}>
				Topics
			</Typography>
			<Stack>
				{topics.map((topic: Topic, index: number) => (
					<Stack direction={'row'} key={index} >
						<Box component={'input'} type='checkbox'
							checked={filters?.topicIds ? filters.topicIds.split(',').some((item) => item === topic.Id.toString()) : false}
							onChange={() => handleChange(topic.Id)}
							sx={{
								cursor: 'pointer',
								mx: 1
							}}
						/>
						<Typography variant='body2' >{topic.Name}</Typography>
					</Stack>
				))}
			</Stack>
		</Stack>
	);
};

export default TopicFilters;
