import { Box, Grid, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import DeleteDialog from 'Pages/ResponseForm/partials/DeleteDialog';
import React, { useState } from 'react';
import AddCard from './AddCard';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useApp from 'hooks/useApp';
import { Link } from 'react-router-dom';
interface IIndicator {
	Id: number;
	Sentence: string;
	Indicator: string;
	RefferalURL: string;
}

interface ICard {
	sentence: string;
}

type Props = {
	item: IIndicator;
	onDelete: (backlogItem: IIndicator) => void;
	onEdit: (backlogItem: IIndicator, editedSentance: string) => void;
};

const BacklogItem: React.FC<Props> = ({ item, onDelete, onEdit }) => {
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const indicatorText = item?.Indicator || 'indicator';
	const [showAddCard, setShowAddCard] = useState<boolean>(false);
	const [addCardFormData, setAddCardFormData] = useState<ICard>({ sentence: item.Sentence });
	const [anchorEl, setAnchorEl] = React.useState(null);
	const { push } = useApp();

	const handleDelete = () => {
		onDelete(item);
		setOpenDialog(false);
	};

	const handleClose = () => {
		setShowAddCard(false);
		setAddCardFormData({ sentence: item.Sentence });
	};

	const onUpdate = () => {
		onEdit(item, addCardFormData?.sentence!);
	};

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseAnchor = () => {
		setAnchorEl(null);
	};

	const handleChangeFormInputAddCard = (name: string, value: any) => setAddCardFormData({ ...addCardFormData!, [name]: value });

	const showCaseURLId = item?.RefferalURL.substring(item?.RefferalURL.lastIndexOf('/') + 1);
	return (
		<>
			<Stack bgcolor={'background.default'} minWidth={250} maxWidth={350} flexShrink={0} p={2} spacing={2} useFlexGap>
				<Grid container justifyContent={'space-between'} alignItems={'flex-start'}>
					<Box>
						<Typography fontWeight={'700'}>{indicatorText}</Typography>
						{showCaseURLId !== '0' && (
							<Typography variant="body2" color={'text.secondary'}>
								<Link to={item?.RefferalURL} target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
									showcase #{showCaseURLId}
								</Link>
							</Typography>
						)}
					</Box>

					<IconButton aria-label="more" id="long-button" aria-controls={anchorEl ? 'long-menu' : undefined} aria-expanded={anchorEl ? 'true' : undefined} aria-haspopup="true" onClick={handleClick}>
						<MoreVertIcon />
					</IconButton>
				</Grid>

				<Typography variant="body2" color={'text.secondary'}>
					{item?.Sentence}
				</Typography>
			</Stack>
			<Menu
				id="long-menu"
				MenuListProps={{
					'aria-labelledby': 'long-button'
				}}
				sx={{
					'& .MuiPaper-root': {
						boxShadow: 'none',
						border: '1px solid #E5E5E5',
						minWidth: 120
					}
				}}
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleCloseAnchor}>
				<MenuItem
					onClick={() => {
						setShowAddCard(true);
						handleCloseAnchor();
					}}>
					<Typography variant="body2" fontWeight={400} fontSize={15} color={'primary'}>
						Edit
					</Typography>
				</MenuItem>
				<MenuItem
					onClick={() => {
						setOpenDialog(true);
						handleCloseAnchor();
					}}>
					<Typography variant="body2" fontWeight={400} fontSize={15} color={'#6B6F6F'}>
						Delete
					</Typography>
				</MenuItem>
			</Menu>
			{openDialog && <DeleteDialog open={openDialog} onClose={() => setOpenDialog(false)} onSubmit={handleDelete} loading={false} title="Delete Card" description={item.Sentence} />}
			{showAddCard && <AddCard open={showAddCard} onClose={handleClose} onChange={handleChangeFormInputAddCard} onSubmit={onUpdate} loading={false} card={addCardFormData!} />}
		</>
	);
};

export default BacklogItem;
