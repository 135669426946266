import { Divider, Grid, Stack, Typography } from '@mui/material';
import CustomForm from 'components/UI/CustomForm';
import Section from 'components/UI/Section';
import { InputTypes } from 'constants/enums';
import { IFormField } from 'models/app';
import { ITestEditInput } from 'models/testBank';
import React from 'react';


interface Props {
  editTestForm: ITestEditInput;
  onChange: (name: string, value: any) => void;
 
}


const GradingTestItems: React.FC<Props> = ({ editTestForm, onChange, }) => {


  let basicPassingGradeFields: IFormField[] = [     
    {
      name: 'PassingGrade',
      type: InputTypes.NUMBER,
      placeholder: "0",
      defaultValue: editTestForm?.PassingGrade,
     },
  ];

  return (
    <Grid container height={1} spacing={2} p={3} bgcolor={'grey.200'} borderRadius={'6px'}>
      <Section sx={{ p: 2 }}>
        <Stack sx={{ mb: 3 }}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              Grade
            </Grid>
            <Grid item xs={2}>
              Minimum %
            </Grid>
            <Grid item xs={2}>
              Pass
            </Grid>
          </Grid>
          <Divider sx={{ my: 1 }} />
          <Grid container spacing={1} sx={{ px: 1 }}>
            {/* Passing Grade */}
            <Grid item lg={12}>
              <Stack direction={'row'} spacing={2} alignItems={'start'}>
                <Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'10%'}>
                  Passing Grade
                </Typography>
                <CustomForm fields={basicPassingGradeFields} onChange={onChange} width={'80%'} />
              </Stack>
            </Grid>
          </Grid>

          {/* <Form fields={basicFields} onChange={onChange} reset={reset} /> */}
        </Stack>
         

        <Typography sx={{ fontSize: '11px', fontWeight: 400, color: '#737373', my: 1 }}>The result and grade can be shown to the respondent by setting the Display Options within the Results tab</Typography>
        {/* <FormControlLabel control={<Checkbox />} label="Redirect based on grade" /> */}

        <Typography sx={{ fontSize: '11px', fontWeight: 400, color: '#737373', my: 1 }}>
          {' '}
          After completing the quiz respondents can be redirected to a specific URL or another quiz based on the grade they have achieved. Any registration details will also be automatically passed to the new quiz
        </Typography>
      </Section>
    </Grid>
  );
};

export default GradingTestItems;