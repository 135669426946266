import React from 'react';
import * as Yup from 'yup';

import useApp from 'hooks/useApp';
import { useAlert } from 'contexts/alertContext';
import { useMutation } from 'react-query';
import { resetPass } from 'framework/auth';
import { Box, Container, Grid, Typography } from '@mui/material';
import { InputTypes } from 'constants/enums';
import { IFormField } from 'models/app';
import logo from 'assets/images/Logo.jpeg';

import Form from 'components/UI/Form';
import { useNavigate, useParams } from 'react-router-dom';

const ResetPassword: React.FC = () => {
	const { push } = useApp();
	const { setAlert } = useAlert();
	const navigate = useNavigate();
	const { token } = useParams();
	const mutationResetPassword = useMutation({
		mutationFn: (createInput: any) => {
			return resetPass(createInput);
		}
	});

	const basicFields: IFormField[] = [
		{
			name: "Password",
			label: "Password",
			type: InputTypes.PASSWORD,
			placeholder: "*********",
		},
		{
			name: "ConfirmPassword",
			label: "Confirm Password",
			type: InputTypes.PASSWORD,
			placeholder: "*********",
		},
	];

	let validations: any = {

		Password: Yup.string().required("Password is required").matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
		),
		ConfirmPassword: Yup
			.string()
			.oneOf([Yup.ref('Password')], 'Passwords mismatch')
			.min(8, 'Your password should have at least 8 characters.')
	}

	const onSubmit = async (data: any) => {
		try {
			const res = await mutationResetPassword.mutateAsync({ NewPassword: data.Password!, Token: token });
			if (res.Success === 0) throw new Error(res.Message || 'Something went wrong');
			setAlert({
				open: true,
				message: `Password updated successfully`,
				type: 'success'
			});
			//redirect me to login page

			navigate('/login');
		} catch (error: any) {
			setAlert({ open: true, message: error.message, type: 'error' });
			setAlert({
				open: true,
				message: error.Message || 'Something went wrong',
				type: 'error'
			});
		}
	};

	return (
		<Container maxWidth={'xs'}>
			<Grid
				direction={{ xs: 'column', md: 'row' }}
				container
				sx={{
					position: 'relative',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					overflow: 'hidden',
					border: '1px solid #ccc',
					borderRadius: '10px',
					boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
					maxHeight: '70vh',
					marginTop: '20vh',
					padding: '20px'
				}}>
				<Box width={216} alignSelf={'center'} mb={2}>
					<Box component={'img'} src={logo} alt="scofolio" sx={{ display: 'block', maxWidth: '100%', height: 'auto' }} />
				</Box>
				<Typography variant="h4" sx={{ marginBottom: '20px' }}>
					Reset Password
				</Typography>
				<Form fields={basicFields} cb={onSubmit} buttonText={'Submit'} validations={validations} />
				{/* <Link to="/login">Back to Login page</Link> */}
			</Grid>
		</Container>
	);
};

export default ResetPassword;
