import { Grid, Stack, Typography } from '@mui/material';
import DialogModal from 'components/UI/DialogModal';
import { IErrorLog } from 'framework/dataCenter';
import React from 'react';

interface Props {
	open: boolean;
	onClose: () => void;
	currentErrorLog: IErrorLog;
}

const ErrorLogInfo: React.FC<Props> = ({ open, onClose, currentErrorLog }) => {
	return (
		<DialogModal
			open={open}
			onClose={onClose}
			maxWidth="xl"
			title=""
			children={
				<Stack>
					<Grid container sx={{ width: '100%' }} my={2} spacing={2}>
						<Grid item xs={12} sm={2}>
							<Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Message</Typography>
						</Grid>
						<Grid item xs={12} sm={10}>
							{currentErrorLog?.Message}
						</Grid>
						<Grid item xs={12} sm={2}>
							<Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Stack Trace</Typography>
						</Grid>
						<Grid item xs={12} sm={10}>
							{currentErrorLog?.StackTrace}
						</Grid>
					</Grid>
				</Stack>
			}
		/>
	);
};

export default ErrorLogInfo;
