import { GET_LANGUAGES, GET_TRANSLATION_CHARACTER, GET_TRANSLATION_COMPETENCY, GET_TRANSLATION_LANGUAGES, GET_TRANSLATION_PILLAR, GET_TRANSLATION_PURPOSE } from 'constants/api';
import axios from '../helpers/axiosInstance';

export async function getLanguages({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_LANGUAGES + _params.query);
	return response.data;
}

export async function getTranslationLanguages({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_TRANSLATION_LANGUAGES + _params.query);
	return response.data;
}

export async function getTranslationCompetency({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_TRANSLATION_COMPETENCY + _params.query);
	return response.data;
}

export async function getTranslationPillar({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_TRANSLATION_PILLAR + _params.query);
	return response.data;
}

export async function getTranslationCharacter({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_TRANSLATION_CHARACTER + _params.query);
	return response.data;
}

export async function getTranslationPurpose({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_TRANSLATION_PURPOSE + _params.query);
	return response.data;
}
