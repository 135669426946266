import { useCallback, useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import Modal from '../../../../components/UI/DialogModal';
import { IFormField } from 'models/app';
import { InputTypes } from 'constants/enums';
import MuiButton from 'components/UI/MuiButton';
import Form from 'components/UI/Form';
import { ResponseResult } from 'models/api';
import { getOrganizationsWithGroups } from 'framework/organizations';
import { useAlert } from 'contexts/alertContext';
import { useQueryClient } from 'react-query';
import { OrganizationWithGroups } from 'models/organization';
import { bulkImport } from 'framework/users';
import ActionLoader from 'components/UI/ActionLoader';
import { useCustomAlert } from 'contexts/customAlertContext';

interface Props {
	open: boolean;
	onClose: () => void;
}

const BulkUpload: React.FC<Props> = ({ open, onClose }) => {
	const [addFormData, setAddFormData] = useState<any>({});
	const { setAlert } = useAlert();
	const { setCustomAlert } = useCustomAlert();
	const queryClient = useQueryClient();
	const [organizationWithGroubs, setOrganizationsWithGroubs] = useState<OrganizationWithGroups[] | null>(null);
	const [loading, setLoading] = useState(false);

	const handleChangeFormData = (name: string, value: any) => setAddFormData({ ...addFormData, [name]: value });
	const importFile = async () => {
		let formData = new FormData();
		formData.append(`OrganizationId`, addFormData?.organization);
		formData.append(`GroupId`, `${addFormData?.group}`);
		formData.append(`File`, addFormData?.AttachmentPath);

		setLoading(true);
		try {
			const response: any = await bulkImport(formData);
			setLoading(false);

			if (response.Success === 0 && response?.ValidationResults?.length > 0)
				setCustomAlert({
					open: true,
					messages: response.ValidationResults.map((item: any) => item.Message),
					type: 'error'
				});
			else if (response.Success === 0) throw new Error(response.Message);
			else
				setCustomAlert({
					open: true,
					messages: ['Template imported successfully'],
					type: 'success'
				});
			onClose();
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
		// eslint-disable-next-line
	};

	const fetchOrganizationsWithGroups = useCallback(async () => {
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['getOrganizationsWithGroups', { query: '' }], getOrganizationsWithGroups);
			if (response.Success === 0) throw new Error(response.Message);
			setOrganizationsWithGroubs(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchOrganizationsWithGroups();
		// eslint-disable-next-line
	}, []);
	if (loading) return <ActionLoader position={'absolute'} />;
	let userFields: IFormField[] = [
		{
			name: 'AttachmentPath',
			label: 'Choose a file, Total file size may not exceed',
			type: InputTypes.FILE,
			maxSize: 5242880,
		}
	];
	let organizationFields: IFormField[] = [
		{
			name: 'organization',
			type: InputTypes.SELECT,
			placeholder: '',
			options: organizationWithGroubs?.map((item) => ({ label: item.UserOrganizationName, value: `${item.UserOrganizationId}` })) || []
		}
	];
	let groupFields: IFormField[] = [
		{
			name: 'group',
			type: InputTypes.SELECT,
			disabled: addFormData?.organization ? false : true,
			placeholder: '',
			options: organizationWithGroubs?.find((item) => item.UserOrganizationId === +addFormData?.organization)?.Groups.map((item) => ({ label: item.GroupName, value: `${item.GroupId}` })) || []
		}
	];
	return (
		<Modal
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth={'lg'}
			title="Bulk Upload"
			actions={
				<MuiButton
					onClick={importFile}
					sx={{ backgroundColor: '#1B6EE4', color: '#fff', paddingInline: 6, paddingBlock: 1, '&:hover': { color: '#1B6EE4' } }}
					disabled={!addFormData?.organization || !addFormData?.group || !addFormData?.AttachmentPath}>
					Import
				</MuiButton>
			}>
			<Box sx={{ paddingBlock: 3, paddingInline: 1 }}>
				<Typography color={'#262261'} fontWeight={300}>
					Attach File
				</Typography>
				<Form fields={userFields} onChange={handleChangeFormData} />
				<Stack sx={{ width: '100%', mt: 2 }} spacing={1}>
					<Stack sx={{ width: '100%' }} direction={'row'}>
						<Typography sx={{ width: '20%' }}>Add to organization</Typography>
						<Box sx={{ width: '50%' }}>
							<Form fields={organizationFields} onChange={handleChangeFormData} />
						</Box>
					</Stack>
					<Stack sx={{ width: '100%' }} direction={'row'}>
						<Typography sx={{ width: '20%' }}>Add to group</Typography>
						<Box sx={{ width: '50%' }}>
							<Form fields={groupFields} onChange={handleChangeFormData} />
						</Box>
					</Stack>
				</Stack>
			</Box>
		</Modal>
	);
};

export default BulkUpload;
