import { Stack } from '@mui/material';
import { Feature } from 'models/api';
import React from 'react';
import { IReportCompetency } from 'framework/reports';
import ListCompetencies from './partials/ListCompetencies';

interface Props {
	feature: Feature;
	data: IReportCompetency[];
}

const Competency: React.FC<Props> = ({ feature, data }) => {
	return (
		<Stack spacing={4} useFlexGap border={'1px solid'} borderColor={'divider'} borderRadius={1}>
			<ListCompetencies competencies={data!} feature={feature} />
		</Stack>
	);
};

export default Competency;
