import { ICategoryWithTest } from 'models/testBank';
import { create } from 'zustand';

type State = {
	categories: any;
	params: string;
	competency: any;
	testDetail: any;
	listing: ICategoryWithTest[];

};

type Actions = {
	setCategories: (payload: any) => void;
	setCompetency: (payload: any) => void;
	setTestDetail: (payload: any) => void;
	setParams: (payload: string) => void;
	setListing: (payload: ICategoryWithTest[]) => void;

};

const useTestBankStore = create<State & Actions>((set) => ({
	listing: [],
	categories: [],
	competency: [],
	testDetail: null,
	params: '',
	setCategories: (payload: any) => set((state) => ({ ...state, categories: payload })),
	setCompetency: (payload: any) => set((state) => ({ ...state, competency: payload })),
	setTestDetail: (payload: any) => set((state) => ({ ...state, testDetail: payload })),
	setParams: (payload: string) => set((state) => ({ ...state, params: payload })),
	setListing: (payload: ICategoryWithTest[]) => set((state) => ({ ...state, listing: payload })),

}));

export default useTestBankStore;
