import { Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Dashboard from "./Pages/Dashboard";
import { AlertProvider } from "./contexts/alertContext";
import { CustomAlertProvider } from "contexts/customAlertContext";
import { PrimeReactProvider } from 'primereact/api';


const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <CustomAlertProvider>
        <PrimeReactProvider>
          <AlertProvider>
            <Routes>
              <Route path="/*" element={<Dashboard />} />
            </Routes>
          </AlertProvider>
        </PrimeReactProvider>
      </CustomAlertProvider>
    </QueryClientProvider>
  );
};

export default App;
