import { Container } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { IFormField } from 'models/app';
import { InputTypes } from 'constants/enums';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import { Language, ResponseResult } from 'models/api';
import { getTranslationLanguages, getTranslationPurpose } from 'framework/language';
import { useQueryClient } from 'react-query';
import { useAlert } from 'contexts/alertContext';
import ActionLoader from 'components/UI/ActionLoader';
import { TranslatePurposeForm } from 'models/Competency';
import DialogModal from 'components/UI/DialogModal';

interface Props {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	onChange: (name: string, value: any) => void;
	translateForm: TranslatePurposeForm;
}

const TranslatePurpose: React.FC<Props> = ({ open, onChange, onClose, onSubmit, translateForm }) => {
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	const [loading, setLoading] = useState<boolean>(false);
	const [languages, setLanguages] = useState<Language[]>([]);
	const [currentTranslation, setCurrentTranslation] = useState<TranslatePurposeForm | null>(null);

	const fetchLanguages = useCallback(async () => {
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['Translation languages', { query: '' }], getTranslationLanguages);
			onChange('languageId', response.Data[0].Id);
			setLanguages(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);

	const fetchTranslation = useCallback(async () => {
		if (!translateForm?.languageId) {
			setCurrentTranslation(null);
			return;
		}
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['getTranslation', { query: `?purposeId=${translateForm?.purposeId}&languageId=${translateForm?.languageId}` }], getTranslationPurpose);
			setCurrentTranslation(response.Data[0]);
			onChange('label', response.Data[0]?.label);
			//onChange('',response.Data)
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [translateForm?.languageId]);

	useEffect(() => {
		fetchLanguages();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchTranslation();
		// eslint-disable-next-line
	}, [translateForm?.languageId]);

	if (loading) return <ActionLoader position={'absolute'} />;
	if (!languages) return null;

	let languagesOptions = languages.map((language) => ({ label: language.Name, value: `${language.Id}` }));
	const basicFields: IFormField[] = [
		{
			name: 'languageId',
			label: 'Language',
			type: InputTypes.SELECT,
			placeholder: 'Select Language',
			options: languagesOptions || [],
			defaultValue: languagesOptions[0]
		},
		{
			name: 'label',
			label: 'Label',
			type: InputTypes.TEXT,
			placeholder: 'Enter Name',
			defaultValue: currentTranslation?.label,
			disabled: !(currentTranslation?.languageId || translateForm?.languageId)
		}
	];

	return (
		<DialogModal
			open={open}
			onClose={onClose}
			title="Translate Purpose"
			children={
				<Container maxWidth={'lg'} sx={{ py: 2, pb: 4 }}>
					<Form fields={basicFields} onChange={onChange} />
				</Container>
			}
			actions={
				<>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#F0F5FD',
							color: '#8B8BAE',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onClose}>
						Cancel
					</MuiButton>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#0A94D1',
							color: '#fff',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onSubmit}
						disabled={!translateForm?.label || !translateForm?.languageId}>
						Update
					</MuiButton>
				</>
			}
		/>
	);
};

export default TranslatePurpose;
