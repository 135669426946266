import { Grid } from '@mui/material';
import DialogModal from 'components/UI/DialogModal';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import { InputTypes } from 'constants/enums';
import { IFormField } from 'models/app';
import React from 'react';

interface Props {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	onChange: (name: string, value: any) => void;
}

const AddPillar: React.FC<Props> = ({ open, onClose, onSubmit, onChange }) => {
	let addTestFields: IFormField[] = [
		{
			name: 'Name',
			label: 'Name',
			type: InputTypes.TEXT,
			placeholder: 'Enter Pillar Name'
		},
		{
			name: 'Description',
			label: 'Description',
			type: InputTypes.EDITOR,
			placeholder: 'Enter Pillar Description'
		}
	];

	return (
		<>
			<DialogModal
				open={open}
				onClose={onClose}
				maxWidth="md"
				title="Add Pillar"
				children={
					<Grid container rowGap={3} pt={3}>
						<Form fields={addTestFields} onChange={onChange} />
					</Grid>
				}
				actions={
					<>
						<MuiButton
							variant="outlined"
							size="medium"
							sx={{
								backgroundColor: '#F0F5FD',
								color: '#8B8BAE',
								fontWeight: 400,
								border: 'none',
								'&:hover': { border: 'none' }
							}}
							onClick={onClose}>
							Cancel
						</MuiButton>
						<MuiButton
							variant="outlined"
							size="medium"
							sx={{
								backgroundColor: '#0A94D1',
								color: '#fff',
								fontWeight: 400,
								border: 'none',
								'&:hover': { color: '#0A94D1', border: 'none' }
							}}
							disabled={false}
							onClick={onSubmit}>
							Add Pillar
							{/* <CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} /> */}
						</MuiButton>
					</>
				}
			/>
		</>
	);
};

export default AddPillar;
