import { Box, Grid, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { ResponseResult } from 'models/api';
import { useQuery } from 'react-query';
import { FiltersProps } from 'models/filters';
import { getCategories } from 'framework/testBank';
  
type Props = {
	onChange: (name: string, value: any) => void;
	filters: FiltersProps
};

const CategoriesFilters: React.FC<Props> = ({ onChange, filters }) => {
	const { data: result, isError, isLoading } = useQuery<ResponseResult>(['categories', { query: '' }], getCategories);

	if (isError)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);
	if (isLoading) return <ActionLoader position={'absolute'} />;

	let CategoriesFilters: any[] = result?.Data || [];
	const handleChange = (id: number) => {
		let SelectedCategoriesFilters = filters.Categories ? filters.Categories.split(',').map((item) => CategoriesFilters.find((category) => category.Id === parseInt(item))!) : [];
		const index = SelectedCategoriesFilters.findIndex((item) => item.Id === id);
		if (index === -1) {
			SelectedCategoriesFilters.push(CategoriesFilters.find((item) => item.Id === id)!);
		} else {
			SelectedCategoriesFilters.splice(index, 1);
		}

		onChange('Categories', SelectedCategoriesFilters.map((item) => item.Id).join(','));
	};
 

	return (
		<Stack spacing={1} useFlexGap sx={{
			maxHeight: '20vh',
			overflowY: 'auto'		
		}}>
			<Typography variant="h6" component={'p'} fontFamily={'600'}>
				CategoriesFilters
			</Typography>
			<Stack>
				{CategoriesFilters.map((category: any, index: number) => (
					<Stack direction={'row'} key={index} >
						<Box component={'input'} type='checkbox'
							checked={filters?.Categories ? filters.Categories.split(',').some((item) => item === category.Id.toString()) : false}
							onChange={() => handleChange(category.Id)}
							sx={{
								cursor: 'pointer',
								mx: 1
							}}
						/>
						<Typography variant='body2' >{category.Name}</Typography>
					</Stack>
				))}
			</Stack>
		</Stack>
	);
};

export default CategoriesFilters;
