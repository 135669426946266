import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { IconButton, Stack, TableCell, Typography } from '@mui/material';
import HtmlContent from 'components/UI/HtmlContent';
import List from 'components/UI/List';
import { QuestionCells } from 'framework/testBank';
import { Feature } from 'models/api';
import { Permission } from 'models/permission';
import { IQuestion } from 'models/testBank';
import React from 'react';

interface Props {
	questions: IQuestion[];
	feature: Feature;
	openEdit: (question: IQuestion) => void;
	openDelete: (question: IQuestion) => void;
}

const ListQuestions: React.FC<Props> = ({ questions, feature, openEdit, openDelete }) => {
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 8);

	const getBodyCells = (row: any) => {
		let cells = Object.keys(row).map((key: string, i: number) => {
			if (key === 'QuestionTypeName')
				return {
					id: 'QuestionTypeName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.QuestionTypeName}</Typography>
						</TableCell>
					)
				};
			if (key === 'Description')
				return {
					id: 'Description',
					element: (
						<TableCell align={'left'} key={i}>
							<HtmlContent content={row.Description} />
						</TableCell>
					)
				};
			if (key === 'QuestionsPerPage')
				return {
					id: 'total_questions',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.QuestionsPerPage}</Typography>
						</TableCell>
					)
				};

			if (key === 'IsRequired')
				return {
					id: 'IsRequired',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography
								variant="body2"
								sx={{
									color: row?.IsRequired ? '#0078B4' : '#A8A8A8',
									fontWeight: 400,
									textTransform: 'capitalize'
								}}>
								{row?.IsRequired ? 'Required' : 'Not Required'}
							</Typography>
						</TableCell>
					)
				};

			if (key === 'Id')
				return {
					id: 'actions',
					element: (
						<TableCell align={'left'} key={i}>
							<Stack direction={'row'} alignItems={'center'} spacing={1} useFlexGap>
								{!!currentPagePermissions?.HasModifyPermission && (
									<IconButton size="small" color="inherit" onClick={() => openEdit(row)}>
										<EditOutlined fontSize="small" />
									</IconButton>
								)}

								{!!currentPagePermissions?.HasDeletePermission && (
									<IconButton size="small" color="inherit" onClick={() => openDelete(row)}>
										<DeleteOutline fontSize="small" />
									</IconButton>
								)}
							</Stack>
						</TableCell>
					)
				};
			else return <></>;
		});
		return cells;
	};

	return <List headCells={QuestionCells} data={questions} getBodyCells={getBodyCells} feature={feature} canSelect={false} canEdit={false} />;
};

export default ListQuestions;
