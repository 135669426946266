import { Stack } from '@mui/material';
import { Feature } from 'models/api';
import React from 'react';
import { ReportUser, User } from 'models/user';
import ListUsers from './partials/ListAcoountUsers';
import UserProgressModal from './partials/UserProgressModal';

interface Props {
	feature: Feature;
	data: ReportUser[];
}

const Users: React.FC<Props> = ({ feature, data }) => {
	const [openProgress, setOpenProgress] = React.useState(false);
	const [user, setUser] = React.useState<ReportUser | null>(null);

	const handleOpenProgress = (user: ReportUser) => {
		setOpenProgress(true);
		setUser(user);
	}

	const handleClose = () => {
		setOpenProgress(false);
		setUser(null);
	}

	return (
		<>
			<Stack spacing={4} useFlexGap border={'1px solid'} borderColor={'divider'} borderRadius={1}>
				<ListUsers users={data!} feature={feature} onSelect={handleOpenProgress} />
			</Stack>
			{openProgress && user && <UserProgressModal user={user!} open={openProgress} onClose={handleClose} feature={feature} />}
		</>
	);
};

export default Users;
