import React from 'react';
import { Dialog } from 'primereact/dialog';
import Section from '../Section';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import MuiOutlineButton from '../MuiOutlineButton';
import { CloseOutlined } from '@mui/icons-material';

interface Props {
	open: boolean;
	onClose: () => void;
	title: string;
	children: React.ReactNode;
	actions?: React.ReactNode;
	maxWidth?: string | number;
	minWidth?: string | number;
}

const PrimeReactModal: React.FC<Props> = ({ open, onClose, title, children, actions, maxWidth, minWidth }) => {
	return (
		<Dialog
			// hide the close icon
			showHeader={false}
			visible={open} onHide={onClose} style={{
				maxWidth: maxWidth || '50vw',
				minWidth: minWidth || '50vw',
 			}} >
			<Section
				sx={{
					backgroundColor: '#F8F7F7',
					padding: '1rem 1.5rem',
					borderBottom: '1px solid #E0E0E0',
					'& .MuiTypography-h6': {
						color: '#333333'
					},
				}} >
				<Grid container justifyContent="space-between" alignItems="center">
					<Typography variant="h6">{title}</Typography>
					<MuiOutlineButton sx={{ color: 'gray', minWidth: 40 }} size="small" onClick={onClose}>
						<CloseOutlined fontSize="small" />
					</MuiOutlineButton>
				</Grid>
			</Section>
			<Box sx={{ overflowX: 'hidden', p: 2, width: '100%' }}>{children}</Box>
			{actions && <Container sx={{ overflowX: 'hidden', py: 3 }}> <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
				{actions}
			</Stack></Container>}
		</Dialog>
	);
};

export default PrimeReactModal;
