import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { Feature } from 'models/api';
import React, { useEffect } from 'react';
import CompetenciesPage from './CompetenciesPage';
import PillarsPage from './PillarsPage';
import { Permission } from 'models/permission';
import useApp from 'hooks/useApp';

interface Props {
	feature: Feature;
	onHideHeader?: (status: boolean) => void;
}

const Competencies: React.FC<Props> = ({ feature, onHideHeader }) => {
	React.useEffect(() => {
		if (onHideHeader) onHideHeader(true);
		return () => {
			if (onHideHeader) onHideHeader(false);
		};
	}, [feature, onHideHeader]);

	const [selectedTab, setSelectedTab] = React.useState<number>(0);
	const { push } = useApp();


	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 13);

	useEffect(() => {
		if (!currentPagePermissions?.HasReadPermission) {
			push('/404');
		}
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [currentPagePermissions]);

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`
		};
	}

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		event.preventDefault();
		setSelectedTab(newValue);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Grid container justifyContent={'space-between'} alignItems={'center'} sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
					<Tab
						label={
							<Typography variant="body2" fontWeight={400} fontSize={12} color={'TextGray'}>
								Competencies
							</Typography>
						}
						{...a11yProps(0)}
					/>
					<Tab
						label={
							<Typography variant="body2" fontWeight={400} fontSize={12} color={'TextGray'}>
								Pillars
							</Typography>
						}
						{...a11yProps(1)}
					/>
				</Tabs>
			</Grid>
			<Box sx={{ overflowY: 'auto', m: 5 }}>
				{selectedTab === 0 && <CompetenciesPage feature={feature} />}
				{selectedTab === 1 && <PillarsPage feature={feature} />}
			</Box>
		</Box>
	);
};

export default Competencies;
