import { User } from 'models/user';
import { create } from 'zustand';

type State = {
	listing: User[];
	params: string;
 };

type Actions = {
	setListing: (payload: User[]) => void;
	setParams: (payload: string) => void;
 };

const useUserManagementStore = create<State & Actions>((set) => ({
	listing: [],
	params: '',	 
 	setListing: (payload: User[]) => set((state) => ({ ...state, listing: payload })),
	setParams: (payload: string) => set((state) => ({ ...state, params: payload }))
}));

export default useUserManagementStore;
