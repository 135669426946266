import { Box, Skeleton, Stack } from '@mui/material';
import { Feature, ResponseResult } from 'models/api';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useAlert } from 'contexts/alertContext';
import MuiButton from 'components/UI/MuiButton';
import React from 'react';
import { addPillar, deletePillar, getPillar, translatePillar, updatePillar } from 'framework/pillar';
import { IPillar, IPillarInput } from 'models/pillar';
import ListPillars from './partials/ListPillars/ListPillars';
import DeletePillar from './partials/DeletePillar/DeletePillar';
import AddPillar from './partials/AddPillar/PillarForm';
import EditPillar from './partials/EditPillar/EditPillar';
import TranslatePillar from './partials/TranslatePillar/TranslatePillar';
import { TranslatePillarForm } from 'models/Competency';
import { Permission } from 'models/permission';
interface Props {
	feature: Feature;
}

const PillarsPage: React.FC<Props> = ({ feature }) => {
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 13);

	const queryClient = useQueryClient();

	const [openAddPillar, setOpenAddPillar] = useState<boolean>(false);
	const [openEditPillar, setOpenEditPillar] = useState<boolean>(false);
	const [openDeleteCompetency, setOpenDeleteCompetency] = useState<boolean>(false);
	const [pillarForm, setPillarForm] = React.useState<IPillarInput | null>(null);
	const [pillars, setPillars] = useState<IPillar[] | null>(null);
	const [currentPillar, setCurrentPillar] = useState<IPillar | null>(null);
	const [openTranslatePillar, setOpenTranslatePillar] = useState<boolean>(false);
	const [translatePillarForm, setTranslatePillarForm] = useState<TranslatePillarForm | null>(null);

	const [loading, setLoading] = useState<boolean>(false);

	const { setAlert } = useAlert();

	const addPillarMutation = useMutation({
		mutationFn: (data: IPillarInput) => {
			return addPillar(data);
		}
	});

	const translatePillarMutation = useMutation({
		mutationFn: (translatePillarForm: TranslatePillarForm) => {
			return translatePillar(translatePillarForm!);
		},
		onSuccess: () => {
			fetchPillars();
		}
	});

	const handleClose = () => {
		setOpenDeleteCompetency(false);
		setOpenAddPillar(false);
		setOpenEditPillar(false);
		setOpenTranslatePillar(false);
		setCurrentPillar(null);
		setTranslatePillarForm(null);
	};

	const handleOpenAddPillar = () => setOpenAddPillar(true);

	const handleOpenEditPillar = (pillar: IPillar) => {
		setOpenEditPillar(true);
		setPillarForm({ Name: pillar?.Name, Description: pillar?.Description, PillarId: pillar?.Id });
		setCurrentPillar(pillar);
	};

	const handleOpenDeletePillar = (pillar: IPillar) => {
		setOpenDeleteCompetency(true);
		setCurrentPillar(pillar);
	};

	const handleOpenTranslatePillar = (pillar: IPillar) => {
		setOpenTranslatePillar(true);
		setTranslatePillarForm({ Name: '', Description: '', PillarId: pillar?.Id, LanguageId: 0 });
		setCurrentPillar(pillar);
	};

	const handleChangeAddPillarForm = (name: string, value: any) => setPillarForm({ ...pillarForm!, [name]: value });
	const handleChangeTranslatePillarForm = (name: string, value: any) => setTranslatePillarForm({ ...translatePillarForm!, [name]: value });
	const handleChangeTranslatePillarMultipleForm = (data: { name: string, value: any }[]) => {
		let temp: any = { ...translatePillarForm! };
		data.forEach((item) => {
			temp[item.name] = item.value;
		});
		setTranslatePillarForm(temp);
	}

	const fetchPillars = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['pillar', { query: '' }], getPillar);
			setPillars(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchPillars();
		// eslint-disable-next-line
	}, []);

	if (loading) return <Skeleton variant="rectangular" height={400} animation="wave" />;
	if (!pillars) return null;

	const handleEditPillar = async () => {
		setLoading(true);

		try {
			const res = await updatePillar(pillarForm!);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Pillar Updated successfully',
				type: 'success'
			});
			setLoading(false);
			fetchPillars();
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleDeletePillar = async () => {
		setLoading(true);
		try {
			const res = await deletePillar(currentPillar?.Id!);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Pillar deleted successfully',
				type: 'success'
			});
			setLoading(false);
			fetchPillars();
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			handleClose();
		}
		setLoading(false);
	};
	const handleAddPillar = async () => {
		setLoading(true);
		try {
			const res = await addPillarMutation.mutateAsync(pillarForm!);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Pillar Added successfully',
				type: 'success'
			});
			setLoading(false);
			fetchPillars();
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleTranslatePillar = async () => {
		setLoading(true);
		const createInput: TranslatePillarForm = {
			Name: translatePillarForm?.Name!,
			PillarId: currentPillar?.Id!,
			Description: translatePillarForm?.Description!,
			LanguageId: +translatePillarForm?.LanguageId!
		};
		try {
			const res = await translatePillarMutation.mutateAsync(createInput!);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Pillar Translated successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	return (
		<>
			<Stack spacing={4} useFlexGap>
				<Box sx={{ ml: 'auto' }}>
					{!!currentPagePermissions?.HasModifyPermission && (
						<MuiButton variant="outlined" color="primary" onClick={handleOpenAddPillar} size="large" sx={{ borderRadius: 0, borderColor: 'white', '&:hover': {} }}>
							Add Pillars
						</MuiButton>
					)}
				</Box>

				<ListPillars pillars={pillars!} feature={feature} onOpenTranslatePillar={handleOpenTranslatePillar} onOpenEditPillar={handleOpenEditPillar} onOpenDeletePillar={handleOpenDeletePillar} />
				{openDeleteCompetency && <DeletePillar onClose={handleClose} open={openDeleteCompetency} onSubmit={handleDeletePillar} currentPillar={currentPillar!} />}
				{openTranslatePillar && <TranslatePillar open={openTranslatePillar} onClose={handleClose} translateForm={translatePillarForm!} onChange={handleChangeTranslatePillarForm} onSubmit={handleTranslatePillar} onChangeMultiple={handleChangeTranslatePillarMultipleForm}/>}
			</Stack>

			{/* Create Pillar */}
			{openAddPillar && <AddPillar open={openAddPillar} onClose={handleClose} onChange={handleChangeAddPillarForm} onSubmit={handleAddPillar} />}
			{/* Edit Competency */}
			{openEditPillar && <EditPillar open={openEditPillar} currentPillar={pillarForm!} onClose={handleClose} onChange={handleChangeAddPillarForm} onSubmit={handleEditPillar} loading={false} />}
		</>
	);
};

export default PillarsPage;
