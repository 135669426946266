import { CircularProgress, Grid, Typography } from '@mui/material';
import DeleteDialog from 'Pages/ResponseForm/partials/DeleteDialog';
import ActionLoader from 'components/UI/ActionLoader';
import DialogModal from 'components/UI/DialogModal';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import { InputTypes } from 'constants/enums';
import { AdminGroup, ResponseResult } from 'models/api';
import { IFormField, IOption } from 'models/app';
import { IEditUser } from 'models/settings';

import React, { useState } from 'react';
import { useQuery } from 'react-query';
import NewPassword from './NewPassword';
import { GetAdminGroupsListByAdminId } from 'framework/groups';

interface Props {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	onDelete: () => void;
	onChange: (name: string, value: string) => void;
	loading: boolean;
	currentUserInput: IEditUser;
}

const Edituser: React.FC<Props> = ({ open, loading, currentUserInput, onChange, onClose, onSubmit, onDelete }) => {
	const { data, isError, isLoading } = useQuery<ResponseResult>(['adminGroupsList', { query: `?SelectedAdminId=${currentUserInput?.UserId!}` }], GetAdminGroupsListByAdminId);
	const [showDelete, setShowDelete] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean>(false);

	if (isError)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);

	if (isLoading) return <ActionLoader position={'absolute'} />;

	const groupsOptions: IOption[] = data?.Data ? data?.Data.map((item: AdminGroup) => ({ label: item.Name, value: `${item.Id}` })) : [];
	let firstNameField: IFormField[] = [
		{
			name: 'FirstName',
			type: InputTypes.TEXT,
			placeholder: 'Enter User First Name',
			defaultValue: currentUserInput?.FirstName
		}
	];

	let lastNameField: IFormField[] = [
		{
			name: 'LastName',
			type: InputTypes.TEXT,
			placeholder: 'Enter User Last Name',
			defaultValue: currentUserInput?.LastName
		}
	];

	let groupField: IFormField[] = [
		{
			name: 'GroupIds',
			type: InputTypes.MULTI_SELECT,
			multiple: true,
			placeholder: 'Select Group',
			options: groupsOptions,
			defaultValue: data?.Data ? data?.Data.filter((a: AdminGroup) => a.IsSeleted).map((item: AdminGroup) => ({ label: item.Name, value: `${item.Id}` })) : []
		}
	];

	let emailField: IFormField[] = [
		{
			name: 'EmailAddress',
			type: InputTypes.TEXT,
			placeholder: 'Enter Email Adrress',
			defaultValue: currentUserInput?.EmailAddress
		}
	];

	let notificationEmailField: IFormField[] = [
		{
			name: 'NotificationEmailAddress',
			type: InputTypes.TEXT,
			placeholder: 'Enter Notification Email Adrress',
			defaultValue: currentUserInput?.NotificationEmailAddress
		}
	];

	const handleShowDelete = () => {
		setShowDelete(true);
	};

	const handleCloseDelete = () => {
		setShowDelete(false);
	};

	const handleShowAddPassword = () => {
		setShowPassword(true);
	};

	const handleCloseAddPassword = () => {
		setShowPassword(false);
	};
	const handleDeleteUser = () => {
		handleCloseDelete();
		onClose();
		onDelete();
	};
	return (
		<DialogModal
			open={open}
			onClose={onClose}
			maxWidth="md"
			title="Account Management"
			children={
				<Grid container sx={{ width: '100%' }} my={2} spacing={2}>
					<Grid item xs={12} sm={3}>
						<Typography sx={{ fontSize: '16px', fontWeight: 500 }}>First Name</Typography>
					</Grid>
					<Grid item xs={12} sm={7}>
						<Form fields={firstNameField} onChange={onChange} />
					</Grid>
					<Grid item xs={12} sm={3}>
						<Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Last Name</Typography>
					</Grid>
					<Grid item xs={12} sm={7}>
						<Form fields={lastNameField} onChange={onChange} />
					</Grid>

					<Grid item xs={12} sm={3}>
						<Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Email Address</Typography>
					</Grid>
					<Grid item xs={12} sm={7}>
						<Form fields={emailField} onChange={onChange} />
					</Grid>

					<Grid item xs={12} sm={3}>
						<Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Notification Email Address</Typography>
					</Grid>
					<Grid item xs={12} sm={7}>
						<Form fields={notificationEmailField} onChange={onChange} />
					</Grid>
					<Grid item xs={12} sm={3}>
						<Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Add To Group</Typography>
					</Grid>
					<Grid item xs={12} sm={7}>
						<Form fields={groupField} onChange={onChange} />
					</Grid>
					<Grid item xs={12} sm={3}>
						<MuiButton
							variant="outlined"
							size="medium"
							sx={{
								px: 1,
								color: '#0A94D1',
								fontWeight: 400,
								border: 'none',
								borderRadius: 0,
								borderBottom: '1px solid #0A94D1',
								'&:hover': { color: '#0A94D1', border: 'none', borderBottom: '1px solid #0A94D1' }
							}}
							onClick={handleShowAddPassword}>
							Update Password
							<CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
						</MuiButton>
					</Grid>
					<Grid item xs={12}>
						<MuiButton
							variant="outlined"
							size="medium"
							sx={{
								px: 1,
								color: '#FF2020',
								fontWeight: 400,
								border: 'none',
								borderRadius: 0,
								'&:hover': { color: '#FF2020', border: 'none' }
							}}
							onClick={handleShowDelete}>
							Delete User
							<CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
						</MuiButton>
					</Grid>

					{showDelete && (
						<DeleteDialog open={showDelete} title="Delete User" description={currentUserInput?.FirstName + ' ' + currentUserInput?.LastName} onSubmit={handleDeleteUser} loading={false} onClose={handleCloseDelete} />
					)}
					{showPassword && <NewPassword onChange={onChange} onClose={handleCloseAddPassword} open={showPassword} />}
				</Grid>
			}
			actions={
				<>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#F0F5FD',
							color: '#8B8BAE',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onClose}>
						Cancel
					</MuiButton>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#0A94D1',
							color: '#fff',
							fontWeight: 400,
							border: 'none',
							'&:hover': { color: '#0A94D1', border: 'none' }
						}}
						disabled={loading || !currentUserInput?.EmailAddress || !currentUserInput?.GroupIds || !currentUserInput?.FirstName || !currentUserInput?.LastName}
						onClick={onSubmit}>
						Save
						<CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
					</MuiButton>
				</>
			}
		/>
	);
};

export default Edituser;
