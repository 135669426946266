import { Box, Grid, Pagination, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { getShowcase, updateShowcaseStatus } from 'framework/showcase';
import { ResponseResult } from 'models/api';
import { ShowcaseListItem } from 'models/showcase';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useAlert } from 'contexts/alertContext';
import ShowcaseCard from './partials/ShowcaseCard';
import useShowcaseStore from 'store/showcase';
import useLoading from 'hooks/loading';

interface Props {
	openEdit: (showcase: ShowcaseListItem) => void;
	onDelete: (id: number) => void;
}

const ShowcaseList: React.FC<Props> = ({ openEdit, onDelete }) => {
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	const { loading, setLoading } = useLoading();
	const [error, setError] = useState<boolean>(false);
	const setListing = useShowcaseStore((state) => state.setListing);
	const listing = useShowcaseStore((state) => state.listing);
	const params = useShowcaseStore((state) => state.params);
	const setParams = useShowcaseStore((state) => state.setParams);
	const [listingToShow, setListingToShow] = useState<ShowcaseListItem[]>([]);
	const [page, setPage] = useState(1);
	const [numPages, setNumPages] = useState(0);

 
	const itemsPerPage = 12;

	const updateShowcaseStatusMutation = useMutation({
		mutationFn: (createInputs: any) => {
			return updateShowcaseStatus(createInputs);
		},
		onSuccess: () => {
			setParams(`?${params}`);
		}
	});

	const fetchShowcases = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['showcases', { query: params }], getShowcase);
			setListing(response.Data && response.Data.length > 0 ? response.Data.sort((a: ShowcaseListItem, b: ShowcaseListItem) => b.Id - a.Id) : []);
			setLoading(false);
		} catch (err: Error | any) {
			setError(true);
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [params]);

	useEffect(() => {
		fetchShowcases();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params]);
	useEffect(() => {
		if (listing.length === 0) return setListingToShow([]);
		setNumPages(Math.ceil(listing.length / itemsPerPage));
		const startIndex = (page - 1) * itemsPerPage;
		const newListingToShow = listing.slice(startIndex, startIndex + itemsPerPage);
		setListingToShow(newListingToShow);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listing, itemsPerPage, page, params]);

	if (error)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);
	if (loading) return <ActionLoader position={'absolute'} />;

	const handleStatusChange = async (showcaseId: number, statusId: number) => {
		let createInput = { showcaseId, statusId };
		try {
			let res = await updateShowcaseStatusMutation.mutateAsync(createInput);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Showcase edited successfully',
				type: 'success'
			});
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};

	const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
	};

	return (
		<Stack spacing={1}>
			<Grid container spacing={2} mx={2}>
				{listingToShow.length > 0 &&
					listingToShow.map((showcase: ShowcaseListItem, index) => (
						<Grid item lg={4} md={6} sm={12} xs={12} key={index} mb={5}>
							<ShowcaseCard openEdit={openEdit} showcase={showcase} onChangeStatus={handleStatusChange} />
						</Grid>
					))}
			</Grid>
			<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
				<Pagination count={numPages} page={page} onChange={handleChangePage} variant="outlined" color="primary" />
			</Box>
		</Stack>
	);
};

export default ShowcaseList;
