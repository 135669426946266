import { KeyboardArrowDown } from '@mui/icons-material';
import { Button, ListItemText, Menu, MenuItem, Stack } from '@mui/material';
import { useState } from 'react';
 
import { IProgressScoreForChart } from 'models/progress';
 

type Props = {
	scores: IProgressScoreForChart[];
	selectedScore: string;
	setSelectedScore: (score: string) => void;
	group: string[];
};


const enum scoresGroupLabelsForView {
	SCORE_WORLD_WIDE = 'Worldwide',
	SCORE_COUNTRY = 'National',
	SCORE_BY_ORGANIZATION = 'My Organization'
}
const enum scoresGroup {
	SCORE_WORLD_WIDE = 'ScoreWorldWide',
	SCORE_COUNTRY = 'ScoreCountry',
	SCORE_BY_ORGANIZATION = 'ScoreByOrganisation'
}

const getLabel = (score: string): string => {
	switch (score) {
		case scoresGroup.SCORE_WORLD_WIDE: return  scoresGroupLabelsForView.SCORE_WORLD_WIDE;
		case scoresGroup.SCORE_COUNTRY: return scoresGroupLabelsForView.SCORE_COUNTRY;
		case scoresGroup.SCORE_BY_ORGANIZATION: return scoresGroupLabelsForView.SCORE_BY_ORGANIZATION;
		default: return '';
	}
}

const ProgressToolbar = ({
	selectedScore,
	setSelectedScore,
	group
}: Props) => {
 	const [anchorElMenuGroup, setAnchorElMenuGroup] = useState<null | (EventTarget & HTMLElement)>(null);
 	const isMenuOpenMenuGroup = Boolean(anchorElMenuGroup);
   
	const handleOpenMenuGroup = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElMenuGroup(event.currentTarget);
	}
	const handleMenuCloseMenuGroup = () => setAnchorElMenuGroup(null);
 
	  
 
	return (
		<>
			<Stack direction={'row'} alignItems={'center'} spacing={2} mb={3} flexWrap={'wrap'} useFlexGap>
				<Button color="inherit" size="small" variant="outlined" endIcon={<KeyboardArrowDown />} onClick={handleOpenMenuGroup} sx={{ borderRadius: '32px', borderColor: '#05060F52', color: 'text.primary' }}>
					{getLabel(selectedScore)}
				</Button>
			 
			</Stack>
			<Menu
				id={'account-menu1'}
				anchorEl={anchorElMenuGroup}
				open={isMenuOpenMenuGroup}
				onClose={handleMenuCloseMenuGroup}
				transitionDuration={250}
				elevation={0}
				sx={{
					mt: 1,
					'& .MuiPaper-root': {
						backgroundColor: 'white',
						borderRadius: '16px',
						boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
						'.Mui-selected': {
							backgroundColor: '#00B5A2 !important',
							color: 'white !important',
							borderRadius: '8px'
						}
					},
				}}
				keepMounted>
				{group.map((score, i: number) => (
					<MenuItem key={i}
						sx={{ color: 'text.secondary' }}
						onClick={() => {
							setSelectedScore(score);
							handleMenuCloseMenuGroup();
						}}>
						<ListItemText>{getLabel(score)}</ListItemText>
					</MenuItem>
				))}

			</Menu>
	 

		 



		</>
	);
};

export default ProgressToolbar;
