import { Divider, IconButton, Stack, TableCell, Typography } from '@mui/material';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import React from 'react';
import List from 'components/UI/List';
import { Feature } from 'models/api';
import { Category, CategoryCells } from 'framework/support';
import { Permission } from 'models/permission';
interface Props {
	categories: Category[];
	feature: Feature;
	selectedCategoryToSort: Category | null;
	onOpenEditCategory: (category: Category) => void;
	onOpenDeleteCategory: (category: Category) => void;
	selectCategoryToSort: (category: Category) => void;
}

const ListCategories: React.FC<Props> = ({ categories, feature, selectedCategoryToSort,onOpenEditCategory, selectCategoryToSort, onOpenDeleteCategory }) => {
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 12);

	const getBodyCells = (row: any) => {
		let cells = Object.keys(row)?.map((key: string, i: number) => {
			if (key === 'Name')
				return {
					id: 'name',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography sx={{ color: 'rgba(95, 193, 206, 1)', fontWeight: 600 }} variant="body2">
								{row?.Name}
							</Typography>
						</TableCell>
					)
				};

			if (key === 'Id')
				return {
					id: 'actions',
					element: (
						<TableCell align={'left'} key={i}>
							<Stack direction={'row'} alignItems={'center'} spacing={1} useFlexGap>
								{!!currentPagePermissions?.HasModifyPermission && (
									<IconButton size="small" color="inherit" onClick={() => onOpenEditCategory(row)}>
										<EditOutlined fontSize="small" />
									</IconButton>
								)}

								{!!currentPagePermissions?.HasDeletePermission && (
									<IconButton size="small" color="inherit" onClick={() => onOpenDeleteCategory(row)}>
										<DeleteOutline fontSize="small" />
									</IconButton>
								)}
							</Stack>
						</TableCell>
					)
				};
			 
			else return <></>;
		});
		return cells;
	};

	return (
		<Stack divider={<Divider />}>
			<Stack p={0} spacing={1} useFlexGap>
				<List removeHeader={true} headCells={CategoryCells} data={categories!} getBodyCells={getBodyCells} feature={feature} canEdit={false} canSelect={true} selectCategoryToSort={selectCategoryToSort} selectedCategoryToSort={selectedCategoryToSort} />
			</Stack>
		</Stack>
	);
};

export default ListCategories;
