import { Box, CircularProgress, Stack } from '@mui/material';
import DialogModal from 'components/UI/DialogModal';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import { InputTypes } from 'constants/enums';
import { NewAddOptionForm } from 'framework/settings';
import { IFormField } from 'models/app';

import React from 'react';

interface Props {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	onChange: (name: string, value: string) => void;
	loading: boolean;
	NewFormField: NewAddOptionForm;
}

const AddProfileBuilderOption: React.FC<Props> = ({ open, loading, onChange, onClose, onSubmit, NewFormField }) => {
	let NameField: IFormField[] = [{ name: 'value', type: InputTypes.TEXT, label: 'Value', isHorizontal: false }];

	return (
		<DialogModal
			open={open}
			onClose={onClose}
			maxWidth="xl"
			title="Form Builder Option"
			children={
				<Box sx={{ backgroundColor: 'rgba(235, 237, 238, 1)', p: 4, width: '30vw' }}>
					<Stack direction={'column'} spacing={2}>
						<Form fields={NameField} onChange={onChange} />
					</Stack>
				</Box>
			}
			actions={
				<>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#F0F5FD',
							color: '#8B8BAE',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onClose}>
						Cancel
					</MuiButton>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#0A94D1',
							color: '#fff',
							fontWeight: 400,
							border: 'none',
							'&:hover': { color: '#0A94D1', border: 'none' }
						}}
						disabled={loading || !NewFormField?.value}
						onClick={onSubmit}>
						Save
						<CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
					</MuiButton>
				</>
			}
		/>
	);
};

export default AddProfileBuilderOption;
