import { CircularProgress, Stack } from '@mui/material';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import PrimeReactModal from 'components/UI/PrimeReactModal';
import { InputTypes } from 'constants/enums';
import { Competency } from 'models/Competency';
import { IFormField } from 'models/app';
import { ICategoryWithTest, ITestInput } from 'models/testBank';
import React from 'react';


interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onChange: (name: string, value: string) => void;
   loading: boolean;
  categories: ICategoryWithTest[];
  competencies: Competency[];
  indicators: string[];
  addTestFormData: ITestInput;
 };


const AddTest: React.FC<Props> = ({ open, loading, categories, competencies, indicators, addTestFormData,   onChange, onClose, onSubmit }) => {

 

  let categoryOptions = categories?.map((category: ICategoryWithTest) => ({
    value: `${category.CategoryId}`,
    label: category.CategoryName
  })) || [];


  let competencyOptions = competencies?.map((comp: Competency) => ({
    value: `${comp.Id}`,
    label: comp.Name
  })) || [];


  let indicatorOptions = indicators?.map((indicator: string) => ({
    value: indicator,
    label: indicator
  })) || [];




  let addTestFields: IFormField[] = [
    {
      name: 'Name',
      label: 'Test Name:',
      type: InputTypes.TEXT,
      placeholder: 'Enter Category Name',
    },
    {
      name: 'Description',
      label: 'Description:',
      type: InputTypes.EDITOR,
    },
    {
      name: 'CategoryId',
      label: 'Category:',
      type: InputTypes.SELECT,
      options: categoryOptions ? categoryOptions : [],
      placeholder: 'Select Category'
    },
    {
      name: 'CompetencyId',
      label: 'Competency:',
      type: InputTypes.SELECT,
      options: competencyOptions ? competencyOptions : [],
      placeholder: 'Select Competency'
    },
    {
      name: 'Indicator',
      label: 'Indicator:',
      type: InputTypes.SELECT,
      options: indicatorOptions ? indicatorOptions : [],
      placeholder: 'Select Indicator',
      disabled: !addTestFormData?.CompetencyId,
      errorMessage: !addTestFormData?.CompetencyId ? 'Please select competency first' : undefined,
    }
  ];

  return (
    <>
      <PrimeReactModal
        open={open}
        onClose={onClose}

        title="New Test"
        children={
          <Stack sx={{ pt: 3 }}>
            <Form fields={addTestFields} onChange={onChange} />
          </Stack>
        }
        actions={
          <>
            <MuiButton
              variant="outlined"
              size="medium"
              sx={{
                backgroundColor: '#F0F5FD',
                color: '#8B8BAE',
                fontWeight: 400,
                border: 'none',
                '&:hover': { border: 'none' }
              }}
              onClick={onClose}>
              Cancel
            </MuiButton>
            <MuiButton
              variant="outlined"
              size="medium"
              sx={{
                backgroundColor: '#0A94D1',
                color: '#fff',
                fontWeight: 400,
                border: 'none',
                '&:hover': { color: '#0A94D1', border: 'none' }
              }}
              disabled={loading || !addTestFormData?.Name || !addTestFormData?.CategoryId || !addTestFormData?.CompetencyId}
              onClick={onSubmit}>
              Add
              <CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
            </MuiButton>
          </>
        }
      />
    </>
  );
};

export default AddTest;