import { Stack, Grid, Tooltip, IconButton, Box, Typography } from '@mui/material';
import MuiButton from 'components/UI/MuiButton';
import { Country, Group, Organization, OrganizationInput, OrganizationWithGroups } from 'models/organization';
import { IFormField } from 'models/app';
import { InputTypes } from 'constants/enums';
import CustomForm from 'components/UI/CustomForm';
import { ColorPicker } from 'primereact/colorpicker';
import React, { useCallback, useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Feature, ResponseResult } from 'models/api';
import { AddGroupForm, EditGroupForm, addGroup, deleteGroup, editGroup, getOrganizationsWithGroups } from 'framework/organizations';
import { useAlert } from 'contexts/alertContext';
import { useQueryClient } from 'react-query';
import ActionLoader from 'components/UI/ActionLoader';
import ListGroups from './partials/ListGroups';
import AddGroup from './partials/AddGroup';
import { Permission } from 'models/permission';

interface Props {
	feature: Feature;
	open: boolean;
	onClose: () => void;
	countries: Country[];
	organization: Organization;
	editOrganizationFormData: OrganizationInput;
	onChange: (name: string, value: string | number | boolean) => void;
	onSubmit: () => void;
}

const EditOrganization: React.FC<Props> = ({ feature, open, countries, organization, editOrganizationFormData, onChange, onSubmit, onClose }) => {
	const [color, setColor] = React.useState(organization.ColorHEXCode || '#1B6EE4');
	const [useDefaultLogo, setUseDefaultLogo] = React.useState<boolean>(!organization.UseOwnLogo!);
	const [loading, setLoading] = useState<boolean>(false);
	const [showAddGroup, setShowAddGroup] = useState<boolean>(false);

	const [organizationsWithGroups, setOrganizationsWithGroups] = useState<OrganizationWithGroups[]>([]);
	const [addFormData, setAddFormData] = useState<AddGroupForm>({} as AddGroupForm);

	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 5);

	const queryClient = useQueryClient();
	const { setAlert } = useAlert();

	const fetchOrganizationsWithGroups = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['organizationsWithGroups', { query: '' }], getOrganizationsWithGroups);
			setOrganizationsWithGroups(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchOrganizationsWithGroups();
		// eslint-disable-next-line
	}, []);

	if (loading) return <ActionLoader position={'absolute'} />;
	const handleChange = (name: string, value: string | number | boolean) => {
		onChange(name, value);
		if (name === 'UseOwnLogo') {
			setUseDefaultLogo(!value);
		}
		if (name === 'ColorHEXCode') {
			onChange(name, value);
			setColor(value as string);
		}
	};

	const handleChangeFormData = (name: string, value: any) => {
		setAddFormData({ ...addFormData, [name]: value });
	};

	const handleClose = () => {
		setShowAddGroup(false);
		setAddFormData({} as AddGroupForm);
	};

	const handleAddGroup = async () => {
		setLoading(true);

		const createInput: AddGroupForm = {
			OrganizationId: organization?.Id,
			GroupDescription: addFormData?.GroupDescription,
			GroupName: addFormData?.GroupName
		};

		try {
			await addGroup(createInput);

			setLoading(false);

			fetchOrganizationsWithGroups();
			handleClose();
			setAlert({
				open: true,
				message: 'Group Added successfully',
				type: 'success'
			});
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleEditGroup = async (form: EditGroupForm) => {
		setLoading(true);

		try {
			 await editGroup(form);

			setLoading(false);

			fetchOrganizationsWithGroups();
			handleClose();
			setAlert({
				open: true,
				message: 'Group Edited successfully',
				type: 'success'
			});
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleOpenAddGroup = () => {
		setShowAddGroup(true);
	};

	const handleDeleteGroup = async (group: Group) => {
		setLoading(true);

		try {
			await deleteGroup(group?.GroupId);

			setLoading(false);

			fetchOrganizationsWithGroups();
			setAlert({
				open: true,
				message: 'Group deleted successfully',
				type: 'success'
			});
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	let basicFields: IFormField[] = [
		{
			name: 'Name',
			label: 'Organization Name',
			type: InputTypes.TEXT,
			placeholder: 'Enter email or name',
			defaultValue: organization.Name
		},
		{
			name: 'CountryId',
			label: 'Country',
			type: InputTypes.SELECT,
			placeholder: 'Select Country',
			options: countries.map((country) => ({ label: country.Name, value: `${country.Id}` })),
			defaultValue: {
				label: countries.find((country) => country.Id === organization.CountryId)?.Name,
				value: `${countries.find((country) => country.Id === organization.CountryId)?.Id}`
			}
		},
		{
			name: 'ClaimedDomain',
			label: 'Claimed Domain',
			type: InputTypes.TEXT,
			placeholder: 'http://',
			helperText: '(Link to original ticket, tweet, etc.)',
			defaultValue: organization.ClaimedDomain
		}
	];

	let LogoFields: IFormField[] = [
		{
			name: 'UseOwnLogo',
			label: 'Use Own Logo',
			type: InputTypes.SWITCH,
			isHorizontal: true,
			defaultValue: organization.UseOwnLogo!,
			helperText: organization.UseOwnLogo
				? 'For best results, logo should be transparent, with no padding around the outside of the image. Image file size must be less than 1 MB.'
				: 'You are using default logo from scofolio'
		},
		{
			name: 'LogoPath',
			label: 'Logo',
			type: InputTypes.MULTIPLE_FILES,
			limitFiles: 1,
			hidden: useDefaultLogo,
			defaultImageUrl: organization.LogoPath!
		}
	];

	let basicColorFields: IFormField[] = [
		{
			name: 'UseCustomColor',
			label: 'Use Custom Color',
			type: InputTypes.CHECKBOX,
			checkBoxDirection: 'start',
			defaultValue: organization.UseCustomColor!
		}
	];

	const groubs: Group[] = organizationsWithGroups?.find((item) => item?.UserOrganizationId === editOrganizationFormData?.Id)?.Groups || [];
	return (
		<Sidebar visible={open} position="right" onHide={onClose} style={{ width: '50%' }} title="Edit Organization">
			<Grid container height={1} spacing={2} p={3}>
				<Grid item lg={12}>
					<Stack spacing={2} alignItems={'start'}>
						<CustomForm fields={basicFields} onChange={handleChange} width={'80%'} />
					</Stack>
				</Grid>
				<Grid item lg={12}>
					<Stack direction={'row'} spacing={2} alignItems={'start'}>
						<CustomForm fields={basicColorFields} onChange={onChange} width={'30%'} />
						{editOrganizationFormData.UseCustomColor && (
							<Tooltip title="Select Color" arrow>
								<IconButton
									sx={{
										color: '#000',
										'&:hover': {
											color: '#000'
										}
									}}>
									<ColorPicker
										value={color}
										onChange={(e) => {
											handleChange('ColorHEXCode', '#' + e.value);
										}}
									/>
								</IconButton>
							</Tooltip>
						)}
					</Stack>
				</Grid>
				<Grid item lg={12}>
					<Stack spacing={2} alignItems={'start'}>
						<Box width={'fit-content'} minHeight={'auto'}>
							<CustomForm fields={LogoFields} onChange={handleChange} />
						</Box>
					</Stack>
				</Grid>
				<Box m={3} width={'100%'}>
					<Stack direction={'row'} justifyContent={'space-between'}>
						<Typography variant="h6">Groups Inside Organization</Typography>

						{!!currentPagePermissions?.HasModifyPermission && (
							<MuiButton onClick={handleOpenAddGroup} sx={{ backgroundColor: '#1B6EE4', color: '#fff', paddingInline: 6, paddingBlock: 1, '&:hover': { color: '#1B6EE4' } }}>
								Add Group
							</MuiButton>
						)}
					</Stack>
					<ListGroups feature={feature} Groups={groubs} onDelete={handleDeleteGroup} onEdit={handleEditGroup} />
				</Box>
				<Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ p: 2, width: '100%' }} justifyContent={'end'}>
					<MuiButton onClick={onClose} sx={{ color: '#5C5F66', paddingInline: 6, paddingBlock: 1, border: '1px solid #5C5F66' }}>
						Cancel
					</MuiButton>
					{!!currentPagePermissions?.HasModifyPermission && (
						<MuiButton
							onClick={onSubmit}
							sx={{ backgroundColor: '#1B6EE4', color: '#fff', paddingInline: 6, paddingBlock: 1, '&:hover': { color: '#1B6EE4' } }}
							disabled={!editOrganizationFormData?.Name || !editOrganizationFormData?.CountryId || !editOrganizationFormData?.ClaimedDomain}>
							Save
						</MuiButton>
					)}
				</Stack>
			</Grid>

			<AddGroup open={showAddGroup} onClose={handleClose} onChange={handleChangeFormData} onSubmit={handleAddGroup} currentAddForm={addFormData} />
		</Sidebar>
	);
};

export default EditOrganization;
