import { Box, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { deleteShowcase, editShowcase, getShowcaseInteractions, getSingleShowcase } from 'framework/showcase';
import { Feature, ResponseResult } from 'models/api';
import { ShowcaseDetails, ShowcaseEditInput, ShowcaseInteractionSummary } from 'models/showcase';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import DetailsAndActivity from './partials/DetailsAndActivity';
import ShowcaseReports from './partials/ShowcaseReports';
import { useParams } from 'react-router-dom';
import { useAlert } from 'contexts/alertContext';
import useApp from 'hooks/useApp';
import useShowcaseStore from 'store/showcase';
import { addTopic } from 'framework/topics';
import { addTag } from 'framework/tags';
import { IOption } from 'models/app';
import MuiOutlineButton from 'components/UI/MuiOutlineButton';
import { CloseOutlined } from '@mui/icons-material';
import { ITopicInput } from 'models/topic';
import { Resources } from 'constants/enums';
import { BackOfficeHeader } from 'helpers/utils';
import { OrganizationWithGroups } from 'models/organization';
import { getOrganizationsWithGroups } from 'framework/organizations';
import { Permission } from 'models/permission';

interface Props {
	feature: Feature;
	onHideHeader?: (status: boolean) => void;
}

const EditShowcase: React.FC<Props> = ({ feature, onHideHeader }) => {
	const [selectedTab, setSelectedTab] = React.useState<number>(0);
	const [showcase, setShowcase] = React.useState<ShowcaseDetails | null>(null);
	const [showcaseInteractions, setShowcaseInteractions] = React.useState<ShowcaseInteractionSummary[] | null>(null);
	const [organizationsWithGroups, setOrganizationsWithGroups] = useState<OrganizationWithGroups[]>([]);

	const urlParams = useParams();
	let id: string = urlParams[`${feature.singleName}Id`] as string;
	const queryClient = useQueryClient();
	const params = useShowcaseStore((state) => state.params);
	const setParams = useShowcaseStore((state) => state.setParams);
	const { push } = useApp();
	const { setAlert } = useAlert();

	const [loading, setLoading] = React.useState<boolean>(false);

 

	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 4);

	useEffect(() => {
		if (!currentPagePermissions?.HasReadPermission) {
			push('/404');
		}
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [currentPagePermissions]);

	const editShowcaseMutation = useMutation({
		mutationFn: (createInput: ShowcaseEditInput) => {
			return editShowcase(createInput);
		},
		onSuccess: () => {
			queryClient.invalidateQueries('showcase');
			setParams(`?${params}`);
		}
	});

	const deleteShowcaseMutation = useMutation({
		mutationFn: (id: number) => {
			return deleteShowcase(id);
		},
		onSuccess: () => {
			setParams(`?${params}`);
		}
	});

	const addTagMutation = useMutation({
		mutationFn: (tagName: string) => {
			return addTag(tagName);
		},
		onSuccess: () => {
			queryClient.invalidateQueries('tags');
		}
	});
	const addTopicMutation = useMutation({
		mutationFn: (data: ITopicInput) => {
			return addTopic(data);
		},
		onSuccess: () => {
			queryClient.invalidateQueries('topics');
		}
	});

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		event.preventDefault();
		setSelectedTab(newValue);
	};

	const fetchShowcaseDetails = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['showcase', { query: '?showcaseID=' + id }], getSingleShowcase);
			setShowcase(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [id]);
	const fetchShowcaseInteractions = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['showcaseInteractions', { query: '?showcaseId=' + id }], getShowcaseInteractions);
			setShowcaseInteractions(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [id]);
	const fetchOrganizationsWithGroups = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['organizationsWithGroups', { query: '' }], getOrganizationsWithGroups);
			setOrganizationsWithGroups(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			})
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);


	useEffect(() => {
		fetchShowcaseDetails();
		fetchShowcaseInteractions();
		fetchOrganizationsWithGroups();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	React.useEffect(() => {
		if (onHideHeader) onHideHeader(true);
		return () => {
			if (onHideHeader) onHideHeader(false);
		};
	}, [feature, onHideHeader]);

	if (loading) return <ActionLoader position={'absolute'} />;
	if (!showcase || !showcaseInteractions) return null;

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`
		};
	}

	const handleAddTag = async (tagName: string) => {
		if (!tagName) return;
		try {
			setLoading(true);
			let res = await addTagMutation.mutateAsync(tagName);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Tag added successfully',
				type: 'success'
			});
			setLoading(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleAddTopic = async (topicName: string) => {
		if (!topicName) return;
		try {
			setLoading(true);
			let res = await addTopicMutation.mutateAsync({ Name: topicName });
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Topic added successfully',
				type: 'success'
			});
			setLoading(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const onDelete = async () => {
		try {
			setLoading(true);
			let res = await deleteShowcaseMutation.mutateAsync(parseFloat(id!));
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Showcase deleted successfully',
				type: 'success'
			});
			setLoading(false);
			push(`/${feature.slug}`);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleEditShowcase = async (data: ShowcaseEditInput) => {
		data.ShowcaseTags = data.ShowcaseTags.length ? data.ShowcaseTags.map((item: IOption) => item.value).join(',') : null;
		data.GroupIds = data.GroupIds.length ? data.GroupIds.map((item: IOption) => item.value).join(',') : null;
		try {
			setLoading(true);
			let res = await editShowcaseMutation.mutateAsync(data);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Showcase edited successfully',
				type: 'success'
			});
			setLoading(false);
			push(`/${feature.slug}`);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleBack = () => push(`/${feature.slug}`);

	return (
		<Box m={5}>
			<BackOfficeHeader title={['Showcases', `Showcase Details #${id || ''}`]} resorseToNavigate={Resources.SHOW_CASES} />
			<Grid container justifyContent={'space-between'} alignItems={'center'} sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
					<Tab
						label={
							<Typography variant="body2" fontWeight={400} fontSize={12} color={'TextGray'}>
								Details & Activity
							</Typography>
						}
						{...a11yProps(0)}
					/>
					<Tab
						label={
							<Stack direction={'row'} spacing={1}>
								<Typography variant="body2" fontWeight={400} fontSize={12} color={'TextGray'}>
									Report
								</Typography>
								<Typography variant="body2" fontWeight={400} fontSize={12} color={'TextGray'} sx={{ backgroundColor: '#F5F5F5', borderRadius: 2, padding: '0px 5px' }}>
									{showcase?.reports!.length}
								</Typography>
							</Stack>
						}
						{...a11yProps(1)}
					/>
				</Tabs>
				<MuiOutlineButton sx={{ color: 'gray', minWidth: 40 }} size="small" onClick={handleBack}>
					<CloseOutlined fontSize="small" />
				</MuiOutlineButton>
			</Grid>
			{selectedTab === 0 && (
				<DetailsAndActivity showcase={showcase} onSubmit={handleEditShowcase} onDelete={onDelete} onAddTag={handleAddTag} onAddTopic={handleAddTopic} loading={loading} showcaseInteractions={showcaseInteractions!} organizationsWithGroups={organizationsWithGroups} />
			)}
			{selectedTab === 1 && <ShowcaseReports showcase={showcase} />}
		</Box>
	);
};

export default EditShowcase;
