import { Container } from '@mui/material';
import React from 'react';
import { IFormField } from 'models/app';
import { InputTypes } from 'constants/enums';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import PrimeReactModal from 'components/UI/PrimeReactModal';
import { Category, ITicket } from 'framework/support';

interface Props {
	open: boolean;
	categories: Category[];
	selectedCategoryId: string | null;
	currentTicket: ITicket;
	onClose: () => void;
	onSubmit: () => void;
	onChange: (name: string, value: any) => void;
}

const AddToCategory: React.FC<Props> = ({ open, categories, selectedCategoryId, currentTicket, onChange, onClose, onSubmit }) => {

	const categoriesOptions = categories?.map((category: Category) => ({
		label: category.Name,
		value: `${category.Id}`
	}));


	const basicFields: IFormField[] = [
		{
			name: 'categoryId',
			label: 'Category:',
			type: InputTypes.SELECT,
			placeholder: 'Select Category',
			options: categoriesOptions,
			defaultValue: categoriesOptions?.find((category) => category.value === `${currentTicket?.SupportCategoryId}`!)
		}
	];

	return (
		<PrimeReactModal
			open={open}
			onClose={onClose}
			title="New Category"
			children={
				<Container maxWidth={'md'} sx={{ py: 2, pb: 4 }}>
					<Form fields={basicFields} onChange={onChange} />
				</Container>
			}
			actions={
				<>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#F0F5FD',
							color: '#8B8BAE',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onClose}>
						Cancel
					</MuiButton>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#0A94D1',
							color: '#fff',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						disabled={!selectedCategoryId}
						onClick={onSubmit}>
						Assign
					</MuiButton>
				</>
			}
		/>
	);
};

export default AddToCategory;
