
import { GET_HELP, UPDATE_HELP } from "constants/api";
import axios from "../helpers/axiosInstance";
import { HelpInput } from "models/help";


export async function getHelp({ queryKey }: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, _params] = queryKey;
  const response = await axios.get(GET_HELP + _params.query);
  return response.data;
}


export async function updateHelp(data: HelpInput) {
  const response = await axios.post(UPDATE_HELP, data);
  return response.data;
}