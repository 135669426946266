import { Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import MuiButton from 'components/UI/MuiButton';
import { IFormField } from 'models/app';
import { InputTypes } from 'constants/enums';
import Form from 'components/UI/Form';
import { useMutation, useQueryClient } from 'react-query';
import { getLabels, updateLabels } from 'framework/responseForm';
import { Language, ResponseResult } from 'models/api';
import ActionLoader from 'components/UI/ActionLoader';
import { useAlert } from 'contexts/alertContext';
import { getLanguages } from 'framework/language';
import { ILabels } from 'models/ResponseForm';
type Props = {};

const LabelsAndDescriptions: React.FC<Props> = () => {
	const queryClient = useQueryClient();
	const [formLabels, setFormLabels] = useState<ILabels | null>(null);
	const [languages, setLanguages] = useState<Language[] | null>(null);
	const [loadingLabels, setLoadingLabels] = useState<boolean>(false);
	const [loadingLanguages, setLoadingLanguages] = useState<boolean>(false);

	const { setAlert } = useAlert();

	const updateLabelsMutation = useMutation({
		mutationFn: (data: ILabels) => {
			return updateLabels(data);
		},
		onSuccess: () => {
			fetchLabels();
		}
	});

	const fetchLabels = useCallback(async () => {
		setLoadingLabels(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['labels', { query: `?languageId=${formLabels?.LanguageId || 1}` }], getLabels);
			const [labelsObj] = response.Data;
			setFormLabels({ ...labelsObj, LanguageId: formLabels?.LanguageId || 1 });
			setLoadingLabels(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoadingLabels(false);
		}

		// eslint-disable-next-line
	}, [formLabels?.LanguageId]);

	const fetchLanguages = useCallback(async () => {
		setLoadingLanguages(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['languages', { query: '' }], getLanguages);

			setLanguages(response.Data);
			setLoadingLanguages(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoadingLanguages(false);
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchLanguages();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchLabels();
		// eslint-disable-next-line
	}, [formLabels?.LanguageId]);

	if (loadingLabels || loadingLanguages) return <ActionLoader position={'absolute'} />;

	let languageOptions = languages
		? languages.map((language: Language) => ({
				value: `${language.Id}`,
				label: language.Name
		  }))
		: [];

	const formFields: IFormField[] = [
		{
			name: 'LanguageId',
			type: InputTypes.SELECT,
			options: languageOptions ? languageOptions : [],
			defaultValue: languageOptions?.find((item) => +item.value === formLabels?.LanguageId),
			label: 'Language',
			placeholder: 'Select Language'
		},
		{
			name: 'FeelingLabel',
			type: InputTypes.TEXT,
			placeholder: 'Enter Feeling Label',
			defaultValue: formLabels?.FeelingLabel,
			label: 'Feeling Label'
		},
		{
			name: 'FeelingDescription',
			type: InputTypes.TEXT,
			placeholder: 'Enter Feeling Description',
			defaultValue: formLabels?.FeelingDescription,
			label: 'Feeling Description'
		},
		{
			name: 'CharacterLabel',
			type: InputTypes.TEXT,
			placeholder: 'Enter character Label',
			defaultValue: formLabels?.CharacterLabel,
			label: 'Character Label'
		},
		{
			name: 'CharacterDescription',
			type: InputTypes.TEXT,
			placeholder: 'Enter Character Description',
			defaultValue: formLabels?.CharacterDescription,
			label: 'Character Description'
		},
		{
			name: 'PurposeLabel',
			type: InputTypes.TEXT,
			placeholder: 'Enter purpose Label',
			defaultValue: formLabels?.PurposeLabel,
			label: 'purpose Label'
		},
		{
			name: 'PurposeDescription',
			type: InputTypes.TEXT,
			placeholder: 'Enter purpose Description',
			defaultValue: formLabels?.PurposeDescription,
			label: 'purpose Description'
		},
		{
			name: 'ResponseLabel',
			type: InputTypes.TEXT,
			placeholder: 'Enter Response Label',
			defaultValue: formLabels?.ResponseLabel,
			label: 'Response Label'
		},
		// {
		// 	name: 'ResponseDescription',
		// 	type: InputTypes.TEXT,
		// 	placeholder: 'Enter Response Label',
		// 	defaultValue: formLabels?.ResponseDescription,
		// 	label: 'Response Description'
		// }
	];

	const handleChangeFormLabels = (name: string, value: any) => setFormLabels({ ...formLabels!, [name]: value });
	const handleAddLabels = async () => {
		const createInput: ILabels = {
			...formLabels,
			ResponseId: formLabels?.Id!,
			LanguageId: +formLabels?.LanguageId!,
			CharacterLabel: formLabels?.CharacterLabel!,
			CharacterDescription: formLabels?.CharacterDescription!,
			FeelingDescription: formLabels?.FeelingDescription!,
			FeelingLabel: formLabels?.FeelingLabel!,
			PurposeDescription: formLabels?.PurposeDescription!,
			PurposeLabel: formLabels?.PurposeLabel!,
			ResponseDescription: formLabels?.ResponseDescription!,
			ResponseLabel: formLabels?.ResponseLabel!
		};
		setLoadingLabels(true);

		try {
			const res = await updateLabelsMutation.mutateAsync(createInput);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully updated',
				type: 'success'
			});
			setLoadingLabels(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoadingLabels(false);
		}
	};
	return (
		<Stack direction={'column'} sx={{ backgroundColor: 'rgba(241, 241, 241, 1)' }} p={4}>
			<Typography variant="h5">Labels & Descriptions</Typography>
			<MuiButton
				variant="contained"
				color="primary"
				size="large"
				disabled={!formLabels?.LanguageId}
				sx={{ width: 'fit-content', borderRadius: 0, borderColor: 'white', p: 2, px: 3, backgroundColor: 'rgba(30, 169, 234, 1)', ml: 'auto' }}
				onClick={handleAddLabels}>
				Save
			</MuiButton>

			<Form fields={formFields} onChange={handleChangeFormLabels} />
		</Stack>
	);
};

export default LabelsAndDescriptions;
