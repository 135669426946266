import { Box, Grid, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { ResponseResult, TicketStatus } from 'models/api';
import { useQuery } from 'react-query';
import React from 'react';
import { FiltersProps } from 'models/filters';
import { getTicketStatuses } from 'framework/ticketStatus';

type Props = {
	onChange: (name: string, value: any) => void;
	filters: FiltersProps;
};

const TicketStatusesFilters: React.FC<Props> = ({ onChange, filters }) => {
	const { data: result, isError, isLoading } = useQuery<ResponseResult>(['ticketStatuses', { query: '' }], getTicketStatuses);

	if (isError)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);
	if (isLoading) return <ActionLoader position={'absolute'} />;

	let tickets: TicketStatus[] = result?.Data || [];
	const handleChange = (id: number) => {
		let SelectedTickets = filters.ticketStatuses ? filters.ticketStatuses.split(',').map((item) => tickets.find((ticket) => ticket.Id === parseInt(item))!) : [];
		const index = SelectedTickets.findIndex((item) => item.Id === id);
		if (index === -1) {
			SelectedTickets.push(tickets.find((item) => item.Id === id)!);
		} else {
			SelectedTickets.splice(index, 1);
		}
		onChange('ticketStatuses', SelectedTickets.map((item) => item.Id).join(','));
	};


	return (
		<Stack spacing={1} useFlexGap sx={{
			maxHeight: '20vh',
			overflowY: 'auto'
		}}>
			<Typography variant="h6" component={'p'} fontFamily={'600'}>
				Ticket Statuses
			</Typography>
			<Stack>
				{tickets.map((ticket: any, index: number) => (
					<Stack direction={'row'} key={index} >
						<Box component={'input'} type='checkbox'
							checked={filters?.ticketStatuses ? filters.ticketStatuses.split(',').some((item) => item === ticket.Id.toString()) : false}
							onChange={() => handleChange(ticket.Id)}
							sx={{
								cursor: 'pointer',
								mx: 1
							}}
						/>
						<Typography variant='body2' >{ticket.Name}</Typography>
					</Stack>
				))}
			</Stack>
		</Stack>
	);
};

export default TicketStatusesFilters;
