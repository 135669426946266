import React, { Fragment } from 'react';

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useApp from 'hooks/useApp';
import { useForm } from 'react-hook-form';
import { IFormField } from 'models/app';
import { InputTypes } from 'constants/enums';
import { Box, CircularProgress, Container, Grid, Typography } from '@mui/material';
import CustomPaper from 'components/UI/CustomPaper/CustomPaper';
import FormFields from 'components/UI/FormFields';
import MuiButton from 'components/UI/MuiButton';
import { useMutation } from 'react-query';
import { signUp } from 'framework/auth';
import { useAlert } from 'contexts/alertContext';
import { handleErrors } from 'helpers/utils';



const validation = () => {

    return Yup.object().shape({
        fullName: Yup.string(),
        email: Yup.string().email().matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, "The email should be like this test@example.com"),
        username: Yup.string(),
        password1: Yup.string().matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
        password2: Yup.string().matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
    });
}


const Register: React.FC = () => {
    const { push } = useApp();
    const { setAlert } = useAlert();
    const [loading, setLoading] = React.useState(false);

    const mutationRegister = useMutation({
        mutationFn: (createInput: any) => {
            return signUp({ data: createInput });
        },
    });

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        register,
        formState: { errors, isSubmitting },
    } = useForm<any>({ mode: "onChange", resolver: yupResolver(validation() as any) });


    const basicFields: IFormField[] = [

        {
            name: "fullName",
            label: "Full Name",
            type: InputTypes.TEXT,
            placeholder: "Enter your full name",
        },
        {
            name: "email",
            label: "Email",
            type: InputTypes.TEXT,
            placeholder: "Enter your email",
        },
        {
            name: "password1",
            label: "Password",
            type: InputTypes.PASSWORD,
            placeholder: "*********",
        },

        {
            name: "password2",
            label: "Confirm Password",
            type: InputTypes.PASSWORD,
            placeholder: "*********",
        },
    ];


    const onsubmit = async (data: any) => {
        setLoading(true);
        try {
            const createInput = {
                name: data.fullName,
                email: data.email,
                password: data.password1,
            };
            const response = await mutationRegister.mutateAsync(createInput);
            if (response) {
                setAlert({ open: true, message: "You have successfully registered", type: "success" });
                push("/login");
            }
        } catch (error: any) {
            setAlert({ open: true, message: handleErrors(error), type: "error" });
        }
        setLoading(false);
    };

    return (
        <>
            <Grid container alignItems="center" justifyContent={'center'} pt={5} >
                <Box component={"img"} src={require(`assets/images/Logo.jpeg`)} sx={{ mb: 2, width: 300, height: 150 }} />
            </Grid>
            <Box component="form" onSubmit={handleSubmit(onsubmit)} py={5} >
                <Container maxWidth="md">
                    <CustomPaper sx={{ p: 2 }}>
                        {/* Form Header */}
                        <Grid container justifyContent="center" py={2} >
                            <Typography variant="h2" paragraph>
                                Register
                            </Typography>
                        </Grid>
                        {/* Basic Info Form */}
                        {basicFields.map((field: IFormField, index: number) => (
                            <Fragment key={index}>
                                <FormFields
                                    {...field}
                                    control={control}
                                    errors={errors}
                                    watch={watch}
                                    setValue={setValue}
                                    register={register}
                                    defaultValue={field?.defaultValue || null}
                                />
                            </Fragment>
                        ))}
                        {/* Submit Button */}
                        <Grid container justifyContent="center" sx={{ py: 4 }}>
                            <MuiButton
                                size="large"
                                variant="contained"
                                type="submit"
                                sx={{ minWidth: 184 }}
                                disabled={isSubmitting}
                            >
                                Register
                                <CircularProgress
                                    size={10}
                                    sx={{ mx: 2, display: loading ? "" : "none" }}
                                />
                            </MuiButton>
                        </Grid>
                        <Grid container justifyContent="center" alignItems={'center'} flexDirection={'column'} sx={{ py: 2 }}>
                            <Typography variant="body1" paragraph>
                                <span style={{ color: "#3f51b5", cursor: "pointer" }} onClick={() => push("/forget-password")}>Forget Password?</span>
                            </Typography>
                            <Typography variant="body1" >
                                Already have an account? <span style={{ color: "#3f51b5", cursor: "pointer" }} onClick={() => push("/login")}>Login</span>
                            </Typography>
                        </Grid>
                    </CustomPaper>
                </Container>
            </Box>
        </>
    )
}

export default Register;