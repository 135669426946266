import { GET_ACTIVE_USERS, GET_ERROR_LOG, GET_GROUP, GET_TOTAL_ORGANIZATIONS, GET_TOTAL_REPORTS, GET_TOTAL_USERS } from 'constants/api';
import axios from '../helpers/axiosInstance';
import { HeadCell } from 'models/app';

export async function getGroups({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_GROUP + _params.query);
	return response.data;
}

export async function getToTalUsers({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_TOTAL_USERS + query);
	return response.data;
}

export async function getErrorLog({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_ERROR_LOG + query);
	return response.data;
}

export async function getTotalOrganizations({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_TOTAL_ORGANIZATIONS + query);
	return response.data;
}

export async function getTotalReports({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_TOTAL_REPORTS + query);
	return response.data;
}

export async function getActiveUsers({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_ACTIVE_USERS + query);
	return response.data;
}

export interface IErrorLog {
	Id: number;
	LogDate: string;
	FirstName: string;
	LastName: string;
	ApplicationName: string | null;
	Source: string;
	Message: string;
	StackTrace: string;
	AdditionalInfo: string | null;
}

export const ErrorLogCells: HeadCell[] = [
	{
		id: 'FirstName',
		numeric: false,
		disablePadding: false,
		label: 'Name'
	},
	{
		id: 'ApplicationName',
		numeric: false,
		disablePadding: false,
		label: 'Application Name'
	},

	{
		id: 'Message',
		numeric: false,
		disablePadding: false,
		label: 'Message'
	},
	{
		id: 'StackTrace',
		numeric: false,
		disablePadding: false,
		label: 'StackTrace'
	},
	{
		id: 'LogDate',
		numeric: false,
		disablePadding: false,
		label: 'Log Date',
		isDate: true
	},
	{
		id: 'actions',
		numeric: false,
		disablePadding: false,
		label: '',
		isNotSortable: true
	}
];
