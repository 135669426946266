import { Box, Button, Grid, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useAlert } from 'contexts/alertContext';
import React from 'react';
import Restrictions from './partials/Restrictions';
import PostSubmission from './partials/PostSubmission';
import { Language, ResponseResult } from 'models/api';
import { useMutation, useQueryClient } from 'react-query';
import { getLanguages } from 'framework/language';
import { getAdvancedSettings, updateAdvancedSettings } from 'framework/responseForm';
import Form from 'components/UI/Form';
import { InputTypes } from 'constants/enums';
import ActionLoader from 'components/UI/ActionLoader';
import MuiButton from 'components/UI/MuiButton';
import { IFormField } from 'models/app';
import { IAdvancedSettings } from 'models/ResponseForm';
type Props = {};

const AdvancedEditStanderedForm: React.FC<Props> = () => {
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	const [loadingSettings, setLoadingSettings] = useState<boolean>(false);
	const [loadingLanguages, setLoadingLanguages] = useState<boolean>(false);
	const [index, setIndex] = useState<number>(1);

	const [advancedSettings, setAdvancedSettings] = useState<IAdvancedSettings | null>(null);
	const [languages, setLanguages] = useState<Language[] | null>(null);

	const updateSettingsMutation = useMutation({
		mutationFn: (data: IAdvancedSettings) => {
			return updateAdvancedSettings(data);
		},
		onSuccess: () => {
			fetchAdvancedSettings();
		}
	});

	const fetchAdvancedSettings = useCallback(async () => {
		//if (!advancedSettings?.LanguageId) return;

		setLoadingSettings(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['labels', { query: `?languageId=${advancedSettings?.LanguageId || 1}` }], getAdvancedSettings);
			let [settingsObj] = response.Data;
			settingsObj = { ...settingsObj, LanguageId: advancedSettings?.LanguageId || 1 };
			setAdvancedSettings(settingsObj);
			setLoadingSettings(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoadingSettings(false);
		}

		// eslint-disable-next-line
	}, [advancedSettings?.LanguageId]);

	const fetchLanguages = useCallback(async () => {
		setLoadingLanguages(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['languages', { query: '' }], getLanguages);

			setLanguages(response.Data);
			setLoadingLanguages(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoadingLanguages(false);
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchLanguages();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchAdvancedSettings();

		// eslint-disable-next-line
	}, [advancedSettings?.LanguageId]);

	let languageOptions = languages
		? languages.map((language: Language) => ({
				value: `${language.Id}`,
				label: language.Name
		  }))
		: [];

	if (loadingLanguages || loadingSettings) return <ActionLoader position={'absolute'} />;

	const handleChangeAdvancedSettingsForm = (name: string, value: any) => setAdvancedSettings({ ...advancedSettings!, [name]: value });

	let tabs = [
		<Restrictions onChange={handleChangeAdvancedSettingsForm} advancedSettings={advancedSettings!} />,
		<PostSubmission onChange={handleChangeAdvancedSettingsForm} PostSubmissionMessage={advancedSettings?.PostSubmissionMessage!} />
	];
	const TABS = [
		{ id: 1, title: 'Restrictions' },
		{ id: 2, title: 'Post Submissions' }
	];

	const handleUpdate = async () => {
		const createInput: IAdvancedSettings = {
			ResponseId: advancedSettings?.Id!,
			LanguageId: +advancedSettings?.LanguageId!,
			EnableReferenceSentenceEditing: advancedSettings?.EnableReferenceSentenceEditing || false,
			EnableInsufficientContent: advancedSettings?.EnableInsufficientContent || false,
			InsufficientContentLength: advancedSettings?.InsufficientContentLength!,
			InsufficientContentErrorMessage: advancedSettings?.InsufficientContentErrorMessage!,
			EnableMaxNumberOfReferenceSentences: advancedSettings?.EnableMaxNumberOfReferenceSentences || false,
			MaxNumberOfReferenceSentences: advancedSettings?.MaxNumberOfReferenceSentences!,
			SubmissionWordsLimit: advancedSettings?.SubmissionWordsLimit!,
			SubmissionWordsLimitReachedErrorMessage: advancedSettings?.SubmissionWordsLimitReachedErrorMessage!,
			PostSubmissionMessage: advancedSettings?.PostSubmissionMessage!
		};
		setLoadingSettings(true);

		try {
			const res = await updateSettingsMutation.mutateAsync(createInput);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully updated',
				type: 'success'
			});
			setLoadingSettings(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoadingSettings(false);
		}
	};

	const languageField: IFormField[] = [
		{
			name: 'LanguageId',
			type: InputTypes.SELECT,
			options: languageOptions || [],
			label: 'Language',
			defaultValue: languageOptions?.find((x) => x.value === advancedSettings?.LanguageId!?.toString()),
			isHorizontal: true,
			clearButton: true
		}
	];
	return (
		<Stack p={4} spacing={3} useFlexGap>
			<Grid container spacing={2}>
				<Grid item xs={12} md={5}>
					<Stack spacing={4} useFlexGap my={1} direction={'row'} justifyContent={{ md: 'flex-start', xs: 'center' }}>
						{TABS.map((tab) => (
							<Button
								size="large"
								color="inherit"
								key={tab.id}
								onClick={() => setIndex(tab.id)}
								sx={{
									justifyContent: 'flex-start',
									boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
									p: 4,
									border: tab.id === index ? '2px solid rgba(132, 204, 30, 1)' : 'none',
									borderRadius: 0,
									':hover': { bgcolor: tab.id === index ? 'grey.200' : 'transparent' },
									fontWeight: tab.id === index ? 600 : 400
									//color: tab.id === index ? 'info.main' : 'text.primary'
								}}
								disableRipple>
								{tab.title}
							</Button>
						))}
					</Stack>
				</Grid>
				<Grid item xs={12} md={7}>
					<Box sx={{ overflowY: 'auto' }}>
						<Grid container height={1}>
							<Grid item xs={12} md={10} sx={{ backgroundColor: 'rgba(241, 241, 241, 1)' }} p={4}>
								<Stack direction={'row'} spacing={3} pb={3}>
									<Form fields={languageField} onChange={handleChangeAdvancedSettingsForm} />
									<MuiButton
										variant="contained"
										color="primary"
										size="large"
										sx={{ width: 'fit-content', borderRadius: 0, borderColor: 'white', backgroundColor: 'rgba(30, 169, 234, 1)', ml: 'auto' }}
										disabled={!advancedSettings?.LanguageId}
										onClick={handleUpdate}>
										Save
									</MuiButton>
								</Stack>
								{advancedSettings?.LanguageId && tabs[index - 1]}
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</Stack>
	);
};

export default AdvancedEditStanderedForm;
