import { Box, Container, Grid, IconButton, Skeleton, Stack, Tab, Tabs, Typography } from '@mui/material';
import DialogModal from 'components/UI/DialogModal';
import MuiButton from 'components/UI/MuiButton';
import { useAlert } from 'contexts/alertContext';
import { getUserProgress } from 'framework/progress';
import { Feature, ResponseResult } from 'models/api';
import { IProgress } from 'models/progress';
import React, { useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import ProgressOverview from './ProgressSections/Overview/Overview';
import ProfileAvatar from 'components/UI/profile-avatar';
import { InfoOutlined, LanguageOutlined } from '@mui/icons-material';
import { ReportUser, UserActivities, UserTests } from 'models/user';
import ProgressActivity from './ProgressSections/Activity/UserActivity';
import { getReportsUserActivities, getReportsUserTests } from 'framework/reports';
import UserTestsList from './ProgressSections/UserTests/UserTests';
import UserProfileView from './ProgressSections/UserProfile/UserProfile';
 

interface Props {
  user: ReportUser;
  open: boolean;
  feature: Feature;

  onClose: () => void;

};

const UserProgressModal: React.FC<Props> = ({ user, open, feature, onClose }) => {
  const [loading, setLoading] = React.useState(false);
  const [progress, setProgress] = React.useState<IProgress | null>(null);
  const [userTests, setUserTests] = React.useState<UserTests[] | null>(null);  
  const [userActivities, setUserActivities] = React.useState<UserActivities[] | null>(null);
  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  const queryClient = useQueryClient();
  const { setAlert } = useAlert();

  const fetchUserProgress = useCallback(async () => {
    setLoading(true);

    try {
      const response: ResponseResult = await queryClient.fetchQuery(['progress', { query: '?userId=' + user.Id }], getUserProgress);
      setProgress(response.Data);
      setLoading(false);
    } catch (err: Error | any) {
      setAlert({
        open: true,
        message: err?.response?.data?.Message || err.message || 'Something went wrong',
        type: 'error'
      });
      setLoading(false);
    }

    // eslint-disable-next-line
  }, []);
  const fetchUserTests = useCallback(async () => {
    setLoading(true);

    try {
      const response: ResponseResult = await queryClient.fetchQuery(['user_tests', { query: '?userId=' + user.Id }], getReportsUserTests);
      setUserTests(response.Data);
      setLoading(false);
    } catch (err: Error | any) {
      setAlert({
        open: true,
        message: err?.response?.data?.Message || err.message || 'Something went wrong',
        type: 'error'
      });
      setLoading(false);
    }

    // eslint-disable-next-line
  }, []);
  const fetchUserActivities = useCallback(async () => {
    setLoading(true);

    try {
      const response: ResponseResult = await queryClient.fetchQuery(['user_activities', { query: '?userId=' + user.Id }], getReportsUserActivities);
      setUserActivities(response.Data);
      setLoading(false);
    } catch (err: Error | any) {
      setAlert({
        open: true,
        message: err?.response?.data?.Message || err.message || 'Something went wrong',
        type: 'error'
      });
      setLoading(false);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchUserProgress();
    fetchUserTests();
    fetchUserActivities();
    // eslint-disable-next-line
  }, []);



  if (loading) return <>
    <Container>
      <Box sx={{ width: '100%' }}>
        <Skeleton animation="wave" height={600} />
      </Box>
    </Container>

  </>


  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    setSelectedTab(newValue);
  }

  if (!progress || !userActivities || !userTests) return <></>;


  return (
    <>
      <DialogModal
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        title="User Details"
        children={
          <>
          
            <Stack alignItems={'center'} spacing={2} sx={{ width: '100%' }}>
              <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Stack spacing={1} alignItems={'center'} justifyContent={'center'}>
                  <Stack direction={'row'} spacing={1} >
                    <ProfileAvatar size="LARGE" imageWidth={100} imageHeight={100} name={user.FirstName
                      + ' ' + user.LastName}  />
                    <Stack spacing={1} alignItems={'center'} justifyContent={'center'}>
                      <Typography variant='h5' fontWeight={400}>{user.OrganizationName}</Typography>
                      <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'center'}>
                        <Typography variant='h5' fontWeight={700}>{user.FirstName + " " + user.LastName}</Typography>
                        <LanguageOutlined sx={{
                          fontSize: '2rem',
                          color: 'rgb(175,175,178)',
                        }} />
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack direction={'row'} spacing={1} >
                    <Typography color={'rgb(175,175,178)'} variant="body2" fontSize={12} fontWeight={400} >
                      {userActivities.length || 0} Interactions
                    </Typography>
                  </Stack>
               </Stack>
                <Stack alignItems={'center'} py={2} >
                  <Typography color={'rgb(175,175,178)'} variant="h2" fontWeight={700} >
                    {progress.UserScore.ScoreWorldWide || 0}
                  </Typography>
                  <Stack direction={'row'} alignItems={'center'} spacing={1} useFlexGap>
                    <Typography variant="body1" color={'gray'}>
                     SII Score
                    </Typography>
                    <IconButton size="small" sx={{ bgcolor: 'rgba(0, 0, 0, 0.04)' }} onClick={() => { }}>
                      <InfoOutlined />
                    </IconButton>
                  </Stack>                 
                </Stack>
              </Grid>
              <Box sx={{ width: '100%' }} px={1}>
                <Grid container justifyContent={'space-between'} alignItems={'center'}
                  sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
                    <Tab
                      label={
                        <Typography variant="body2" fontWeight={400} fontSize={12} color={'TextGray'}>
                          Overview
                        </Typography>
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={
                        <Typography variant="body2" fontWeight={400} fontSize={12} color={'TextGray'}>
                          Activity
                        </Typography>
                      }
                      {...a11yProps(1)}
                    />
                    <Tab
                      label={
                        <Typography variant="body2" fontWeight={400} fontSize={12} color={'TextGray'}>
                          Tests
                        </Typography>
                      }
                      {...a11yProps(2)}
                    />
                    <Tab
                      label={
                        <Typography variant="body2" fontWeight={400} fontSize={12} color={'TextGray'}>
                          Profile
                        </Typography>
                      }
                      {...a11yProps(3)}
                    />

                  </Tabs>

                </Grid>
                {selectedTab === 0 && <ProgressOverview progress={progress} user={user} />}
                {selectedTab === 1 && <><ProgressActivity userActivities={userActivities} /></>}
                {selectedTab === 2 && <UserTestsList userTests={userTests} feature={feature} />}
                {selectedTab === 3 && <UserProfileView user={user} />}
              </Box>
            </Stack>
          </>
        }
        actions={
          <>
            <MuiButton
              variant="outlined"
              size="medium"
              sx={{
                backgroundColor: '#F0F5FD',
                color: '#8B8BAE',
                fontWeight: 400,
                border: 'none',
                '&:hover': { border: 'none' }
              }}
              onClick={onClose}>
              Close
            </MuiButton>

          </>
        }
      />
    </>
  );
};

export default UserProgressModal;
