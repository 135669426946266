import React, { useEffect, useState } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TextField from '@mui/material/TextField';
import { Button, Divider, IconButton, Stack } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DeleteDialog from './DeleteDialog';
import { DeleteIcon, UploadIcon } from 'components/UI/SvgIcons';
import { InputTypes } from 'constants/enums';
import { IFormField } from 'models/app';
import MuiButton from 'components/UI/MuiButton';
import DialogModal from 'components/UI/DialogModal';
import Form from 'components/UI/Form';
import TranslateIcon from '@mui/icons-material/Translate';

interface DropZoneModalProps {
	open: boolean;
	onClose: () => void;
	onDrop: (files: File) => void;
}

const DropZoneModal: React.FC<DropZoneModalProps> = ({ open, onClose, onDrop }) => {
	const [file, setFiles] = useState<File | null>(null);

	let addCategoryFields: IFormField[] = [
		{
			name: 'iconPath',
			type: InputTypes.MULTIPLE_FILES,
			limitFiles: 1,
			onDeleteFile: () => {},
			placeholder: 'Enter Label Description'
		}
	];

	const handleDrop = (name: string, value: string) => {
		setFiles(value as unknown as File);
	};
	return (
		<DialogModal
			open={open}
			onClose={onClose}
			maxWidth="xl"
			title="New Character"
			children={
				<Stack sx={{ width: '30vw', pt: 3 }}>
					<Form fields={addCategoryFields} onChange={handleDrop} />
				</Stack>
			}
			actions={
				<>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#F0F5FD',
							color: '#8B8BAE',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onClose}>
						Cancel
					</MuiButton>
					<MuiButton
						variant="outlined"
						size="medium"
						color="primary"
						sx={{
							fontWeight: 400,
							borderColor: '#fff',
							'&:hover': { color: '#0A94D1' }
						}}
						disabled={!file}
						onClick={() => onDrop(file!)}>
						Add
						{/* <CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} /> */}
					</MuiButton>
				</>
			}
		/>
	);
};

interface FormField {
	Id: number;
	FeelingId: number;
	IconPath?: File;
	SortOrder: number;
	Label: string;
	IsDeleted: boolean;
	ResponseId: number;
}
interface DraggableTextFieldProps {
	field: FormField;
	index: number;
	onDelete: () => void;
	onFieldSubmit: (field: { Id: number; Label: string; IconPath: File }) => void;
	onMarkClick: (characterId: number | null) => void;
	onSortUpdate: (drag: number, drop: number) => void;
	currentCharacter: number | null;
	onSelectioTranslation: (item: FormField) => void;
}

interface DraggableFormProps {
	fields: FormField[];
	onDelete: (id: number) => void;
	onFieldSubmit: (field: { Id: number; Label: string; IconPath: File }) => void;
	onMarkClick: (characterId: number | null) => void;
	currentCharacter: number | null;
	onSortUpdate: (drag: number, drop: number) => void;
	onSelectioTranslation: (item: FormField) => void;
}

const DraggableTextField: React.FC<DraggableTextFieldProps> = ({ field, index, onDelete, onFieldSubmit, onMarkClick, currentCharacter, onSortUpdate, onSelectioTranslation }) => {
	const [, ref] = useDrop({
		accept: 'FIELD',
		drop: (item: { index: number }) => {
			if (item.index !== index) {
				// onFieldSubmit({ Id: field.Id, Label: field.Label, SortOrder: item.index, IconPath: field.IconPath! });
				// item.index = index;

				onSortUpdate(item.index, index);
			}
		}
	});

	const [{ isDragging }, drag] = useDrag({
		type: 'FIELD',
		item: { index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging()
		})
	});

	const [openDialog, setOpenDialog] = useState(false);
	const [editedReference, setEditedReference] = useState(field.Label);
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [openDropZone, setOpenDropZone] = useState(false);

	const handleDeleteClick = () => {
		setOpenDialog(true);
	};

	const handleDeleteConfirm = () => {
		setOpenDialog(false);
		onDelete();
	};

	const handleDeleteCancel = () => {
		setOpenDialog(false);
	};

	const handleFieldSubmit = () => {
		onFieldSubmit({
			Id: field.Id,
			Label: editedReference,
			IconPath: field.IconPath!
		});
	};

	const handleUploadClick = () => {
		setOpenDropZone(true);
	};

	const handleOpenTranslation = (item: FormField) => {
		onSelectioTranslation(item);
	};

	const handleMarkClick = () => {
		if (field.Id === currentCharacter) return onMarkClick(null);
		onMarkClick(field.Id);
	};

	const onDrop = (acceptedFiles: File) => {
		setOpenDropZone(false);
		onFieldSubmit({
			Id: field.Id,
			Label: editedReference,
			IconPath: acceptedFiles!
		});
	};

	const handleCloseDropZone = () => {
		setOpenDropZone(false);
		setSelectedFile(null);
	};

	useEffect(() => {
		setEditedReference(field.Label);
	}, [field.Label]);

	return (
		<div
			ref={(node) => ref(node)}
			style={{
				opacity: isDragging ? 0.5 : 1,
				cursor: 'move',
				marginBottom: 8
			}}>
			<Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={{ xs: 4, sm: 2 }}>
				<IconButton onClick={handleMarkClick} sx={{ mb: { xs: 1, sm: 0 }, mr: { xs: 0, sm: 1 } }}>
					<ArrowBackIosNewIcon
						sx={{
							opacity: currentCharacter === field.Id ? 1 : 0.4,
							fontWeight: currentCharacter === field.Id ? 700 : 300,
							transform: currentCharacter === field.Id ? 'rotate(270deg)' : 'rotate(90deg)',
							transition: 'transform 0.3s ease-in-out'
						}}
					/>
				</IconButton>
				<TextField fullWidth variant="outlined" value={editedReference} inputRef={(node) => drag(node)} style={{ marginBottom: '8px', marginRight: '8px' }} onChange={(e) => setEditedReference(e.target.value)} />
				<Stack direction="row" spacing={2}>
					<IconButton onClick={() => handleOpenTranslation(field)}>
						<TranslateIcon />
					</IconButton>
					<Divider orientation="vertical" flexItem sx={{ mb: { xs: 1, sm: 0 }, mx: { xs: 1, sm: 0 } }} />
					<IconButton onClick={handleUploadClick}>
						<UploadIcon />
					</IconButton>
					<Divider orientation="vertical" flexItem sx={{ mb: { xs: 1, sm: 0 }, mx: { xs: 1, sm: 0 } }} />
					<IconButton onClick={handleDeleteClick}>
						<DeleteIcon />
					</IconButton>
					<Button variant="outlined" color="primary" onClick={handleFieldSubmit}>
						Submit
					</Button>
				</Stack>
			</Stack>

			<DeleteDialog open={openDialog} onClose={handleDeleteCancel} onSubmit={handleDeleteConfirm} loading={false} title="Delete Character" description={field.Label} />
			<DropZoneModal open={openDropZone} onClose={handleCloseDropZone} onDrop={onDrop} />
		</div>
	);
};

const DraggableFormCharacters: React.FC<DraggableFormProps> = ({ fields, onDelete, onFieldSubmit, onMarkClick, currentCharacter, onSortUpdate, onSelectioTranslation }) => {
	return (
		<DndProvider backend={HTML5Backend}>
			<Stack spacing={2}>
				{fields?.map((field, index) => (
					<DraggableTextField
						onSelectioTranslation={onSelectioTranslation}
						key={field.Id}
						field={field}
						index={index}
						onDelete={() => onDelete(field.Id)}
						onFieldSubmit={onFieldSubmit}
						onSortUpdate={onSortUpdate}
						onMarkClick={onMarkClick}
						currentCharacter={currentCharacter}
					/>
				))}
			</Stack>
		</DndProvider>
	);
};

export default DraggableFormCharacters;
