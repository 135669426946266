import { IconButton, Stack, TableCell, Typography } from '@mui/material';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import React from 'react';
import { Organization } from 'models/organization';
import List from 'components/UI/List';
import { OrganizationCells } from 'framework/organizations';
import { Feature } from 'models/api';
import MuiButton from 'components/UI/MuiButton';
import Image from 'components/UI/Image';
import { Permission } from 'models/permission';

interface Props {
	organizations: Organization[];
	feature: Feature;
	onEdit: (row: Organization) => void;
	onDelete: (row: Organization) => void;
}

const ListOrganization: React.FC<Props> = ({ organizations, feature, onDelete, onEdit }) => {
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 5);

	const handleEdit = (e: any, row: Organization) => {
		e.stopPropagation();
		onEdit(row);
	};

	const handleDelete = (e: any, row: Organization) => {
		e.stopPropagation();
		onDelete(row);
	};

	const getBodyCells = (row: any) => {
		let cells = Object.keys(row).map((key: string, i: number) => {
			if (key === 'Name')
				return {
					id: 'Name',
					element: (
						<TableCell align={'left'} key={i}>
							<MuiButton
								onClick={(e) => handleEdit(e, row)}
								variant={'text'}
								sx={{
									flexDirection: 'row',
									justifyContent: 'flex-start',
									alignItems: 'center',
									maxHeight: '40px'
								}}>
								<Image sx={{ width: '40px', objectFit: 'contain' }} url={require('assets/icons/organization.png')} alt={'organization'} />
								<Stack spacing={1} alignItems={'flex-start'} sx={{ ml: 1 }}>
									<Typography color={'#0A8CC6'} fontWeight={600}>
										{row?.Name}
									</Typography>
									<Typography color={'#8A8F99'}>{`ID: ${row.Id}`}</Typography>
								</Stack>
							</MuiButton>
						</TableCell>
					)
				};
			if (key === 'GroupsCount')
				return {
					id: 'GroupsCount',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.GroupsCount}</Typography>
						</TableCell>
					)
				};
			if (key === 'UsersCount')
				return {
					id: 'UsersCount',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.UsersCount}</Typography>
						</TableCell>
					)
				};

			if (key === 'Id')
				return {
					id: 'actions',
					element: (
						<TableCell align={'left'} key={i}>
							<Stack direction={'row'} alignItems={'center'} spacing={1} useFlexGap>
								{!!currentPagePermissions?.HasModifyPermission && (
									<IconButton size="small" color="inherit" onClick={(e) => handleEdit(e, row)}>
										<EditOutlined fontSize="small" />
									</IconButton>
								)}

								{!!currentPagePermissions?.HasDeletePermission && (
									<IconButton size="small" color="inherit" onClick={(e) => handleDelete(e, row)}>
										<DeleteOutline fontSize="small" />
									</IconButton>
								)}
							</Stack>
						</TableCell>
					)
				};
			else return <></>;
		});
		return cells;
	};

	return <List headCells={OrganizationCells} data={organizations} getBodyCells={getBodyCells} feature={feature} canEdit={false} />;
};

export default ListOrganization;
