import { useEffect, useState } from 'react';
import { Box, Menu, Pagination, Stack, Typography } from '@mui/material';

import { INotification } from 'models/app';
import NotificationCard from './NotificationCard';
import { notificationMarkAsReadMultiple } from 'framework/services';

type Props = {
	notifications?: INotification[];
	element: EventTarget & HTMLElement;
	open: boolean;
	handleClose: () => void;
	refetch: () => void;
};

const NotificationsMenu = ({ notifications, element, open, handleClose, refetch }: Props) => {
	const [currentNotifications, setCurrentNotifications] = useState<INotification[]>(notifications || []);
	const [page, setPage] = useState(1);
	const pageSize = 5;
 
	useEffect(() => {
		setCurrentNotifications(notifications || []);
	}, [notifications]);

	const renderContent = () => {
		if (!currentNotifications || currentNotifications.length === 0) {
			return (
				<Box p={4}>
					<Typography>No Notifications</Typography>
				</Box>
			);
		} else {
			const startIndex = (page - 1) * pageSize;
			const endIndex = startIndex + pageSize;
			const paginatedNotifications = currentNotifications?.slice(startIndex, endIndex);

			return (
				<Stack p={3} spacing={3} useFlexGap>
					<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
						<Typography fontWeight={700}>Notifications</Typography>
						{/* <Button size="small">{'header.viewAll'}</Button> */}
					</Stack>
					<Stack spacing={2} useFlexGap>
						{paginatedNotifications.map((notification: INotification) => (
							<NotificationCard refetch={refetch} key={notification.Id} notification={notification} />
						))}
					</Stack>
					<Pagination count={Math.ceil(currentNotifications.length / pageSize)} page={page} onChange={(event, value) => setPage(value)} />
				</Stack>
			);
		}
	};

	const markReadMultiple = async (Ids: number[]) => {
		try {
			let notificationsIds = Ids.map((Id) => Id.toString()).join(',');
			await notificationMarkAsReadMultiple(notificationsIds);
			// refetch();
		} catch (err: Error | any) {
			console.error(err);
		}
	};

	useEffect(() => {
		if (notifications && notifications.length > 0) {
			let filteredNotificationsByPage = notifications.slice((page - 1) * pageSize, page * pageSize);
			markReadMultiple(filteredNotificationsByPage?.filter((notification) => !notification.IsRead).map((notification) => notification.Id) || []);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);


	return (
		<Menu
			id={'notifications-menu'}
			anchorEl={element}
			open={open}
			onClose={handleClose}
			sx={{
				mt: 1,
				ml: 11,

				'& .MuiPaper-root': {
					backgroundColor: 'white',
					borderRadius: '16px',
					boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
					'.Mui-selected': {
						backgroundColor: '#00B5A2 !important',
						color: 'white !important',
						borderRadius: '8px'
					}
				}
			}}
			keepMounted>
			{renderContent()}
		</Menu>
	);
};

export default NotificationsMenu;
