import { Stack } from '@mui/material';
import { Resources } from 'constants/enums';
import { Feature } from 'models/api';
import ShowcaseFilters from './partials/ShowcaseFilters';
import StreamFilters from './partials/StreamFilters';
import InsightFilters from './partials/InsightFilters';
import { FiltersProps } from 'models/filters';
import TestFilters from './partials/TestFilters';
import BacklogFilter from './partials/BacklogFilter';
import UserManagementFilter from './partials/UserManagementFilter';
import ReportsFilters from './partials/ReportsFilters';
import DataCenterFilters from './partials/DataCenterFilters';
import SupportFilters from './partials/SupportFilters';

type Props = {
	feature: Feature;
	handleFiltersClose: () => void;
	setSelectedFilters?: (data: FiltersProps) => void;
	selectedFilters?: any;
};

const SearchFilters = ({ feature, handleFiltersClose, setSelectedFilters, selectedFilters }: Props) => {
	const getPageFilters = () => {
		if (!feature) return null;
		if (feature.slug.toLowerCase() === Resources.SHOW_CASES) return <ShowcaseFilters handleFiltersClose={handleFiltersClose} setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} />;
		if (feature.slug.toLowerCase() === Resources.STREAM) return <StreamFilters handleFiltersClose={handleFiltersClose} setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} />;
		if (feature.slug.toLowerCase() === Resources.INSIGHTS) return <InsightFilters handleFiltersClose={handleFiltersClose} setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} />;
		if (feature.slug.toLowerCase() === Resources.TEST_BANK) return <TestFilters handleFiltersClose={handleFiltersClose} setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} />;
		if (feature.slug.toLowerCase() === Resources.BACKLOG) return <BacklogFilter handleFiltersClose={handleFiltersClose} setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} />;
		if (feature.slug.toLowerCase() === Resources.USER_MANAGEMENT) return <UserManagementFilter handleFiltersClose={handleFiltersClose} setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} />;
		if (feature.slug.toLowerCase() === Resources.REPORTS) return <ReportsFilters handleFiltersClose={handleFiltersClose} setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} />;
		if (feature.slug.toLowerCase() === Resources.DATA_CENTER) return <DataCenterFilters handleFiltersClose={handleFiltersClose} setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} />;
		if (feature.slug.toLowerCase() === Resources.SUPPORT) return <SupportFilters handleFiltersClose={handleFiltersClose} setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} />;

		else return <></>;
	};
  
	return <Stack minWidth={'30dvw'} >{getPageFilters()}</Stack>;
};

export default SearchFilters;
