import { Stack } from '@mui/material';
import Form from 'components/UI/Form';
import { InputTypes } from 'constants/enums';
import { IFormField } from 'models/app';
import { ReportUser } from 'models/user';
import React from 'react';

interface Props {
  user: ReportUser;
}


const UserProfileView: React.FC<Props> = ({ user }) => {
  let basicFields: IFormField[] = [
    {
      name: "FirstName",
      label: "First Name:",
      type: InputTypes.TEXT,
      placeholder: "Enter First Name",
      disabled: true,
      defaultValue: user.FirstName,
      isHorizontal: true
    },
    {
      name: "LastName",
      label: "Last Name:",
      type: InputTypes.TEXT,
      placeholder: "Enter First Name",
      disabled: true,
      defaultValue: user.LastName,
      isHorizontal: true
    },
    {
      name: "Email",
      label: "Email:",
      type: InputTypes.TEXT,
      placeholder: "test@example.com",
      disabled: true,
      defaultValue: user.EmailAddress,
      isHorizontal: true
    },
    {
      name: "Organization",
      label: "Affiliated Organization:",
      type: InputTypes.TEXT,
      placeholder: "Enter Organization",
      disabled: true,
      defaultValue: user.OrganizationName,
      isHorizontal: true
    },
     
    
    {
      name: "UpdatedOn",
      label: "Updated On:",
      type: InputTypes.TEXT,
      disabled: true,
      defaultValue: new Date(user.UpdatedOn).toLocaleString('en-US', { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true }),
      isHorizontal: true
    },


  ];
  return <>
    <Stack py={2} >
      <Form
        fields={basicFields}
      />
    </Stack>
  </>
};

export default UserProfileView;