import React, { useState } from 'react';
import { TableCell, Typography, Stack, Tooltip, IconButton } from '@mui/material';
import List from 'components/UI/List';
import { Feature } from 'models/api';
import { ReferenceSentenceSetsCells, deleteRefrenceSentenceSet, updateRefrenceSentenceSet } from 'framework/responseForm';
import RefrenceSentenceSetForm from './RefrenceSentenceSetForm';
import { FormMode } from 'constants/enums';
import { useAlert } from 'contexts/alertContext';
import ActionLoader from 'components/UI/ActionLoader';
import useApp from 'hooks/useApp';
import EditIcon from '@mui/icons-material/Edit';
import { useQueryClient } from 'react-query';
import { DeleteIcon } from 'components/UI/SvgIcons';
import DeleteDialog from 'Pages/ResponseForm/partials/DeleteDialog';
import { IRSSFormData, IReferenceSentenceSet } from 'models/ResponseForm';
import { Permission } from 'models/permission';

interface Props {
	refrences: IReferenceSentenceSet[];
	feature: Feature;
}

const ListRefrenceSets: React.FC<Props> = ({ refrences, feature }) => {
	const [showEditRSS, setShowEditRSS] = useState<boolean>(false);

	const [showDeleteRSS, setShowDeleteRSS] = useState<boolean>(false);
	const [currentRSS, setCurrentRSS] = useState<IRSSFormData | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	const { push } = useApp();

	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 11);

	if (loading) return <ActionLoader position={'absolute'} />;

	const handleClose = () => {
		setShowEditRSS(false);

		setShowDeleteRSS(false);
		setCurrentRSS(null);
	};

	const handleOpenEdit = (row: IRSSFormData) => {
		handleClose();
		setShowEditRSS(true);
		setCurrentRSS(row);
	};

	const handleOpenDelete = (row: IRSSFormData) => {
		handleClose();
		setShowDeleteRSS(true);
		setCurrentRSS(row);
	};

	const handleChangeFormEditRSS = (name: string, value: any) => setCurrentRSS({ ...currentRSS!, [name]: value });

	const handleEditRSS = async () => {
		setLoading(true);
		try {
			const res = await updateRefrenceSentenceSet({ queryKey: `?Id=${currentRSS?.Id!}&Name=${currentRSS?.Name!}&CountryId=${currentRSS?.CountryId!}` });
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Response Sentence Set Updated successfully',
				type: 'success'
			});

			// Invalidate and refetch the query to get the updated data
			queryClient.invalidateQueries('refrenceSets');

			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleDeleteRSS = async () => {
		setLoading(true);
		try {
			const res = await deleteRefrenceSentenceSet(currentRSS?.Id!);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Response Sentence Set Deleted successfully',
				type: 'success'
			});

			// Invalidate and refetch the query to get the updated data
			queryClient.invalidateQueries('refrenceSets');
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const getBodyCells = (row: IReferenceSentenceSet) => {
		return Object.keys(row).map((key: string, i: number) => {
			if (key === 'Name')
				return {
					id: 'Name',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2" onClick={() => push(`/${feature.slug}/${row.Id}`)} sx={{ cursor: 'pointer', color: 'rgba(30, 169, 234, 1)', fontWeight: 500 }}>
								{row?.Name}
							</Typography>
						</TableCell>
					)
				};
			if (key === 'countryName')
				return {
					id: 'countryName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.countryName}</Typography>
						</TableCell>
					)
				};
			if (key === 'UpdatedOn')
				return {
					id: 'UpdatedOn',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">
								{new Date(row?.UpdatedOn).toLocaleString('en-US', {
									year: '2-digit',
									month: '2-digit',
									day: '2-digit',
									hour: '2-digit',
									minute: '2-digit',
									hour12: true
								})}
							</Typography>
						</TableCell>
					)
				};
			if (key === 'Id') {
				return {
					id: 'Id',
					element: (
						<TableCell align={'left'} key={i}>
							<Stack direction={'row'}>
								{row?.IsEditable && !!currentPagePermissions?.HasModifyPermission && (
									<Tooltip title="Edit">
										<IconButton onClick={() => handleOpenEdit(row)}>
											<EditIcon />
										</IconButton>
									</Tooltip>
								)}
								{!row?.IsDefault && !!currentPagePermissions?.HasDeletePermission && (
									<Tooltip title="Delete">
										<IconButton onClick={() => handleOpenDelete(row)}>
											<DeleteIcon />
										</IconButton>
									</Tooltip>
								)}
							</Stack>
						</TableCell>
					)
				};
			} else return <></>;
		});
	};

	return (
		<>
			<List headCells={ReferenceSentenceSetsCells} data={refrences} getBodyCells={getBodyCells} feature={feature} canSelect={false} canEdit={false} />

			{/* Edit RSS */}
			{showEditRSS && <RefrenceSentenceSetForm mode={FormMode.EDIT} open={showEditRSS} onClose={handleClose} onChange={handleChangeFormEditRSS} onSubmit={handleEditRSS} loading={false} formData={currentRSS!} />}

			{/* Delete RSS */}
			{showDeleteRSS && <DeleteDialog open={showDeleteRSS} onClose={handleClose} onSubmit={handleDeleteRSS} loading={false} description={currentRSS?.Name} title="Delete Refrence Sentence Set" />}
		</>
	);
};

export default ListRefrenceSets;
