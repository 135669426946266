import { CircularProgress, Grid, Typography } from '@mui/material';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import PrimeReactModal from 'components/UI/PrimeReactModal';
import { InputTypes } from 'constants/enums';
import { IFormField } from 'models/app';
import { IEmailTemplate } from 'models/settings';

import React from 'react';

interface Props {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	onChange: (name: string, value: any) => void;
	loading: boolean;
	currentEmailTemplate: Partial<IEmailTemplate>;
}

const EditEmailTemplate: React.FC<Props> = ({ open, loading, currentEmailTemplate, onChange, onClose, onSubmit }) => {
	let SubjectField: IFormField[] = [
		{
			name: 'Subject',
			type: InputTypes.TEXT,
			placeholder: 'Enter Email Subject',
			defaultValue: currentEmailTemplate?.Subject
		}
	];

	let BodyField: IFormField[] = [
		{
			name: 'Body',
			type: InputTypes.EDITOR,
			placeholder: 'Enter Email Body',
			defaultValue: currentEmailTemplate?.Body
		},
		// {
		// 	name: "Attachment",
		// 	label: "Attachments:",
		// 	type: InputTypes.FILE,
		// 	maxSize: 5242880,
		// 	showFileUrl: true,
		// 	defaultImageUrl: currentEmailTemplate.AttachmentpPath,
		// 	onDeleteFile: () => {
		// 		onChange("AttachmentpPath", null);
		// 	}
		// },
	];

	return (
		<PrimeReactModal
			open={open}
			onClose={onClose}
			title="Edit Email Template"
			children={
				<Grid container sx={{ width: '100%' }} my={2} spacing={2}>
					<Grid item xs={12} sm={2}>
						<Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Subject</Typography>
					</Grid>
					<Grid item xs={12} sm={10}>
						<Form fields={SubjectField} onChange={onChange} />
					</Grid>
					<Grid item xs={12} sm={2}>
						<Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Body</Typography>
					</Grid>
					<Grid item xs={12} sm={10}>
						<Form fields={BodyField} onChange={onChange} />
					</Grid>
				</Grid>
			}
			actions={
				<>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#F0F5FD',
							color: '#8B8BAE',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onClose}>
						Cancel
					</MuiButton>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#0A94D1',
							color: '#fff',
							fontWeight: 400,
							border: 'none',
							'&:hover': { color: '#0A94D1', border: 'none' }
						}}
						disabled={loading || !currentEmailTemplate?.Body || !currentEmailTemplate?.Subject}
						onClick={onSubmit}>
						Save
						<CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
					</MuiButton>
				</>
			}
		/>
	);
};

export default EditEmailTemplate;
