import { Upload } from '@mui/icons-material';
import { Grid } from '@mui/material';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import MuiOutlineButton from 'components/UI/MuiOutlineButton';
import PrimeReactModal from 'components/UI/PrimeReactModal';
import { BASE_URL } from 'constants/api';
import { InputTypes } from 'constants/enums';
import { useAlert } from 'contexts/alertContext';
import { UploadShowCaseImage } from 'framework/showcase';
import { IFormField } from 'models/app';

import React from 'react';
import { useMutation } from 'react-query';

interface Props
{

}

const UploadImage: React.FC<Props> = () =>
{
	const [AttachmentpPath, setAttachmentpPath] = React.useState<string | null>(null);
	const { setAlert } = useAlert();
	const [openUploadImage, setOpenUploadImage] = React.useState<boolean>(false);

	const UploadShowMutation = useMutation({
		mutationFn: (data: FormData) =>
		{
			return UploadShowCaseImage(data);
		}
	});

	const onChange = (name: string, value: any) =>
	{
		if (name === 'Attachment')
		{
			return handleUploadShowCaseImage(value);
		} else return setAttachmentpPath(value);

	};

	const handleOpenUploadImage = () => setOpenUploadImage(true);
	const handleCloseUploadImage = () => setOpenUploadImage(false);



	let BodyField: IFormField[] = [

		{
			name: "Attachment",
			label: "Attachments:",
			type: InputTypes.FILE,
			maxSize: 5242880,
			showFileUrl: true,
			defaultImageUrl: AttachmentpPath,
			onDeleteFile: () =>
			{
				onChange("AttachmentpPath", null);
			}
		},
	];
	const handleUploadShowCaseImage = async (file: File) =>
	{
		const formData = new FormData();
		formData.append('AttachmentPath', file);
		try
		{
			let res = await UploadShowMutation.mutateAsync(formData);
			if (res.Success === 0) throw new Error(res.Message);
			onChange('AttachmentpPath', res.Data.replace('http://scofolio.tridmark.com', BASE_URL).replace('https://devapi.scofolio.com', BASE_URL));
		} catch (err: any)
		{
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};

	return (
		<>
			{ !openUploadImage &&
				<MuiOutlineButton variant="outlined" size="medium" sx={ { backgroundColor: '#F0F5FD', color: '#8B8BAE', fontWeight: 400, border: 'none', '&:hover': { border: 'none' } } }
					onClick={ handleOpenUploadImage }>
					<Upload /> Upload Image
				</MuiOutlineButton>
			}
			{ openUploadImage && <PrimeReactModal
				open={ openUploadImage }
				onClose={ handleCloseUploadImage }
				title="Upload Image"
				children={
					<Grid container sx={ { width: '100%' } } p={ 5 } spacing={ 2 }>
						<Form fields={ BodyField } onChange={ onChange } />
					</Grid>
				}
				actions={
					<>
						<MuiButton
							variant="outlined"
							size="medium"
							sx={ {
								backgroundColor: '#F0F5FD',
								color: '#8B8BAE',
								fontWeight: 400,
								border: 'none',
								'&:hover': { border: 'none' }
							} }
							onClick={ handleCloseUploadImage }>
							Cancel
						</MuiButton>

					</>
				}
			/> }
		</>
	);
};

export default UploadImage;
