import React, { useState } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { Controller } from 'react-hook-form';
import { IFormField } from 'models/app';
import UploadImage from 'components/UploadImage/UploadImageModal';

interface Props extends IFormField
{
	value: string;
	dir?: 'rtl' | 'ltr' | undefined;
	name: string;
	control: any;
	onChange?: (data: any) => void;
}

const RHEditor: React.FC<Props> = ({ name, control, dir, onChange, disabled, value, hidden, label, isHorizontal, editorHeight }) =>
{
	const [initialized, setInitialized] = useState(false);

	return (
		<>
			<Box
				sx={ {
					overflowY: 'auto',
					position: 'relative',
				} }>
				<Stack direction={ isHorizontal ? 'row' : 'column' } hidden={ hidden }>
					{ !isHorizontal && <Grid container alignItems={ 'center' } justifyContent={ 'space-between' }>
						{ label && <Typography variant="body2" fontWeight={ 600 } textAlign={ 'start' }>
							{ label }
						</Typography> }
						<Grid container mb={ 1 } justifyContent={ 'flex-end' }>
							<UploadImage />
						</Grid>
					</Grid> }

					<Controller
						name={ name }
						control={ control }
						defaultValue={ value || '' }
						render={ ({ field }) => (
							<Stack minWidth={ '100%' } direction={ 'column' }>
								{ isHorizontal && <Grid container mb={ 1 } justifyContent={ 'flex-end' }>
									<UploadImage />
								</Grid> }
								<Box sx={ { position: 'relative', maxHeight: editorHeight || 200, width: '100%' } }>
									<Editor
										disabled={ disabled }
										apiKey="vxb1oewq5rfv8o427snn64xyyl39qrhd7evgjooizloi3daa"
										initialValue={ field.value || '' }
										onInit={ () => setInitialized(true) }
										// onChange={(evt, editor) => {
										// 	field.onChange(editor.getContent());
										// }}
										onKeyUp={ (evt, editor) =>
										{
											if (onChange)
											{
												onChange(editor.getContent());
											}
											if (evt.ctrlKey && evt.key === 'z')
											{
												editor.undoManager.undo();
											}
										} }
										onNodeChange={ (evt, editor) =>
										{
											if (onChange)
											{
												onChange(editor.getContent());
											}
										} }
										init={ {
											language: dir === 'rtl' ? 'ar' : 'en',
											directionality: dir === 'rtl' ? 'rtl' : 'ltr',
											height: editorHeight || 200,
											menubar: false,
											inline: false,
											plugins: 'advlist autolink lists link image charmap preview anchor searchreplace visualblocks fullscreen insertdatetime media table  help wordcount directionality emoticons',
											toolbar:
												'undo redo | ltr rtl | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image media lists link | table  wordcount | help emoticons | customButton',
											content_style:
												dir === 'rtl' ? 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; direction: rtl; text-align: right; }' : 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }'
										} }
									/>
									<Box
										sx={ {
											position: 'absolute',
											inset: 0,
											backgroundColor: 'common.white',
											border: '1px solid',
											borderColor: 'divider',
											zIndex: initialized ? -1 : (theme) => theme.zIndex.appBar - 1,
											opacity: initialized ? 0 : 1
										} }
									/>
								</Box>
							</Stack>

						) }
					/>
				</Stack>
			</Box>
		</>
	);
};

export default RHEditor;
