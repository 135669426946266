import { Box, Skeleton, Stack } from '@mui/material';
import { Feature, ResponseResult } from 'models/api';
import { Competency, CompetencyInput, TranslateCompetencyForm } from 'models/Competency';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { addCompetency, deleteCompetency, editCompetency, getCompetencyList, translateCompetency } from 'framework/competency';
import { useAlert } from 'contexts/alertContext';
import MuiButton from 'components/UI/MuiButton';
import ListCompetencies from './partials/ListComptencies/ListCompetencies';
import DeleteCompetency from './partials/DeleteCompetency/DeleteCompitency';
import React from 'react';
import AddCompetency from './partials/AddCopetency/AddCopetency';
import { addPillar, getPillar } from 'framework/pillar';
import { IPillar, IPillarInput } from 'models/pillar';
import EditCompetency from './partials/EditCopetency/EditCompetency';
import TranslateCompetency from './partials/TranslateCompetency/TranslateCompetency';
import { Permission } from 'models/permission';
interface Props {
	feature: Feature;
}

const CompetenciesPage: React.FC<Props> = ({ feature }) => {
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 13);

	const queryClient = useQueryClient();
	const [competencies, setCompetencies] = useState<Competency[] | null>(null);
	const [currentCompetency, setCurrentCompetency] = useState<Competency | null>(null);
	const [openAddCompetency, setOpenAddCompetency] = useState<boolean>(false);
	const [openEditCompetency, setOpenEditCompetency] = useState<boolean>(false);
	const [openDeleteCompetency, setOpenDeleteCompetency] = useState<boolean>(false);
	const [openTranslateCompetency, setOpenTranslateCompetency] = useState<boolean>(false);
	const [addCompetencyForm, setAddCompetencyForm] = useState<CompetencyInput | null>(null);
	const [editCompetencyForm, setEditCompetencyForm] = useState<CompetencyInput | null>(null);
	const [translateCompetencyForm, setTranslateCompetencyForm] = useState<TranslateCompetencyForm | null>(null);
	const [pillarForm, setPillarForm] = React.useState<IPillarInput | null>(null);
	const [pillars, setPillars] = useState<IPillar[] | null>(null);

	const [loading, setLoading] = useState<boolean>(false);

	const { setAlert } = useAlert();

	const addCompetencyMutation = useMutation({
		mutationFn: (data: FormData) => {
			return addCompetency(data);
		},
		onSuccess: () => {
			fetchCompetencies();
		}
	});
	const editCompetencyMutation = useMutation({
		mutationFn: (data: FormData) => {
			return editCompetency(data);
		},
		onSuccess: () => {
			fetchCompetencies();
		}
	});
	const deleteCompetencyMutation = useMutation({
		mutationFn: (id: number) => {
			return deleteCompetency(id);
		},
		onSuccess: () => {
			fetchCompetencies();
		}
	});

	const translateCompetencyMutation = useMutation({
		mutationFn: (translateCompetencyForm: TranslateCompetencyForm) => {
			return translateCompetency(translateCompetencyForm!);
		},
		onSuccess: () => {
			fetchCompetencies();
		}
	});

	const addPillarMutation = useMutation({
		mutationFn: (data: IPillarInput) => {
			return addPillar(data);
		}
	});

	const handleClose = () => {
		setOpenDeleteCompetency(false);
		setOpenAddCompetency(false);
		setOpenEditCompetency(false);
		setOpenTranslateCompetency(false);
		setCurrentCompetency(null);
		setTranslateCompetencyForm(null);
	};

	const handleOpenAddCompetency = () => setOpenAddCompetency(true);
	const handleOpenEditCompetency = (competency: Competency) => {
		setOpenEditCompetency(true);
		setCurrentCompetency(competency);
	};
	const handleOpenDeleteCompetency = (competency: Competency) => {
		setOpenDeleteCompetency(true);
		setCurrentCompetency(competency);
	};

	const handleOpenTranslateCompetency = (competency: Competency) => {
		setOpenTranslateCompetency(true);
		setCurrentCompetency(competency);
		setTranslateCompetencyForm({ CompetencyId: competency?.Id, Description: '', LanguageId: 0, Name: '' });
	};

	const handleChangeAddCompetencyForm = (name: string, value: any) => setAddCompetencyForm({ ...addCompetencyForm!, [name]: value });
	const handleChangeEditCompetencyForm = (name: string, value: any) => setEditCompetencyForm({ ...editCompetencyForm!, [name]: value });
	const handleChangeTranslateCompetencyForm = (name: string, value: any) => setTranslateCompetencyForm({ ...translateCompetencyForm!, [name]: value });
	const handleChangeTranslateCompetencyMultipleForm = (data: { name: string, value: any }[]) => {
		let temp: any = { ...translateCompetencyForm! };
		data.forEach((item) => {
			temp[item.name] = item.value;
		});
		setTranslateCompetencyForm(temp);
	}
	const handleChangeAddPillarForm = (name: string, value: any) => setPillarForm({ ...pillarForm!, [name]: value });

	const fetchCompetencies = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['competencies', { query: '' }], getCompetencyList);
			setCompetencies(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);
	const fetchPillars = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['pillar', { query: '' }], getPillar);
			setPillars(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchCompetencies();
		fetchPillars();
		// eslint-disable-next-line
	}, []);

	if (loading) return <Skeleton variant="rectangular" height={400} animation="wave" />;
	if (!competencies) return null;

	const handleAddCompetency = async () => {
		setLoading(true);
		let formData = new FormData();
		formData.append('Name', addCompetencyForm?.Name!);
		formData.append('Slug', addCompetencyForm?.Slug!);
		formData.append('Description', addCompetencyForm?.Description!);
		formData.append('numIndicators', `${addCompetencyForm?.numIndicators!}`);
		formData.append('ParentPillarId', `${addCompetencyForm?.ParentPillarId!}`);
		if (addCompetencyForm?.IncompleteBadgeIconPath) formData.append('IncompleteBadgeIconPath', addCompetencyForm?.IncompleteBadgeIconPath!);
		if (addCompetencyForm?.EmergingPotentialBadgeIconPath) formData.append('EmergingPotentialBadgeIconPath', addCompetencyForm?.EmergingPotentialBadgeIconPath!);
		if (addCompetencyForm?.EstablishedPotentialBadgeIconPath) formData.append('EstablishedPotentialBadgeIconPath', addCompetencyForm?.EstablishedPotentialBadgeIconPath!);
		try {
			const res = await addCompetencyMutation.mutateAsync(formData);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Competency Added successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleEditCompetency = async () => {
		setLoading(true);
		let formData = new FormData();
		formData.append('Id', `${currentCompetency!.Id!}`);
		formData.append('Name', editCompetencyForm?.Name! || currentCompetency?.Name!);
		formData.append('Slug', editCompetencyForm?.Slug! || currentCompetency?.Slug!);
		formData.append('Description', editCompetencyForm?.Description! || currentCompetency?.Description!);
		formData.append('numIndicators', `${editCompetencyForm?.numIndicators || currentCompetency?.NumIndicators!}`);
		formData.append('ParentPillarId', `${editCompetencyForm?.ParentPillarId || currentCompetency?.PillarId!}`);
		if (editCompetencyForm?.IncompleteBadgeIconPath) formData.append('IncompleteBadgeIconPath', editCompetencyForm?.IncompleteBadgeIconPath!);
		if (editCompetencyForm?.EmergingPotentialBadgeIconPath) formData.append('EmergingPotentialBadgeIconPath', editCompetencyForm?.EmergingPotentialBadgeIconPath!);
		if (editCompetencyForm?.EstablishedPotentialBadgeIconPath) formData.append('EstablishedPotentialBadgeIconPath', editCompetencyForm?.EstablishedPotentialBadgeIconPath!);
		try {
			const res = await editCompetencyMutation.mutateAsync(formData);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Competency Updated successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleTranslateCompetency = async () => {
		setLoading(true);
		const createInput: TranslateCompetencyForm = {
			Name: translateCompetencyForm?.Name!,
			CompetencyId: currentCompetency?.Id!,
			Description: translateCompetencyForm?.Description!,
			LanguageId: translateCompetencyForm?.LanguageId!
		};
		try {
			const res = await translateCompetencyMutation.mutateAsync(createInput!);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Competency Translated successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleDeleteCompetency = async () => {
		setLoading(true);
		try {
			const res = await deleteCompetencyMutation.mutateAsync(currentCompetency?.Id!);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Competency deleted successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleAddPillar = async () => {
		setLoading(true);
		try {
			const res = await addPillarMutation.mutateAsync(pillarForm!);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Competency deleted successfully',
				type: 'success'
			});
			setLoading(false);
			fetchPillars();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};
	return (
		<>
			<Stack spacing={4} useFlexGap>
				<Box sx={{ ml: 'auto' }}>
					{!!currentPagePermissions?.HasModifyPermission && (
						<MuiButton variant="outlined" color="primary" onClick={handleOpenAddCompetency} size="large" sx={{ borderRadius: 0, borderColor: 'white', '&:hover': {} }}>
							Add Competency
						</MuiButton>
					)}
				</Box>

				<ListCompetencies
					competencies={competencies!}
					onOpenTranslateCompetency={handleOpenTranslateCompetency}
					feature={feature}
					onOpenEditCompetency={handleOpenEditCompetency}
					onOpenDeleteCompetency={handleOpenDeleteCompetency}
				/>

				{openDeleteCompetency && <DeleteCompetency onClose={handleClose} open={openDeleteCompetency} onSubmit={handleDeleteCompetency} currentCompetency={currentCompetency!} />}
				{openTranslateCompetency && (
					<TranslateCompetency open={openTranslateCompetency} onClose={handleClose} translateForm={translateCompetencyForm!} onChange={handleChangeTranslateCompetencyForm} onSubmit={handleTranslateCompetency} onChangeMultiple={handleChangeTranslateCompetencyMultipleForm} />
				)}
			</Stack>
			{/* Create Competency */}
			{openAddCompetency && (
				<AddCompetency
					open={openAddCompetency}
					addCompetencyForm={addCompetencyForm!}
					onClose={handleClose}
					onChange={handleChangeAddCompetencyForm}
					onSubmit={handleAddCompetency}
					onAddPillar={handleAddPillar}
					onChangeAddPillarForm={handleChangeAddPillarForm}
					pillars={pillars!}
					loading={loading}
				/>
			)}
			{/* Edit Competency */}
			{openEditCompetency && (
				<EditCompetency
					open={openEditCompetency}
					editCompetencyForm={editCompetencyForm!}
					onClose={handleClose}
					onChange={handleChangeEditCompetencyForm}
					onSubmit={handleEditCompetency}
					onAddPillar={handleAddPillar}
					onChangeAddPillarForm={handleChangeAddPillarForm}
					pillars={pillars!}
					loading={loading}
					currentCompetency={currentCompetency!}
				/>
			)}
		</>
	);
};

export default CompetenciesPage;
