import { Box, Grid, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { Language, ResponseResult } from 'models/api';
import { useQuery } from 'react-query';
import { getLanguages } from 'framework/language';
import React from 'react';
import { FiltersProps } from 'models/filters';

type Props = {
	onChange: (name: string, value: any) => void;
	filters: FiltersProps;
};

const LanguageFilters2: React.FC<Props> = ({ onChange, filters }) => {
 	const { data: result, isError, isLoading } = useQuery<ResponseResult>(['languages', { query: '' }], getLanguages);

	if (isError)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);
	if (isLoading) return <ActionLoader position={'absolute'} />;

	let languages: Language[] = result?.Data || [];

	const handleChange = (id: number) => {
		if (filters.LanguageId === id) {
			onChange('LanguageId', null);
			return;
		}
		onChange('LanguageId', id);
 		 
 	};

	return (
		<Stack spacing={1} useFlexGap sx={{
			maxHeight: '20vh',
			overflowY: 'auto'
		}}>
			<Typography variant="h6" component={'p'} fontFamily={'600'}>
				Languages
			</Typography>
			<Stack>
				{languages.map((language: Language, index: number) => (
					<Stack direction={'row'} key={index} >
						<Box component={'input'} type='checkbox'
							checked={filters?.LanguageId ? filters.LanguageId === language.Id : false}
 							onChange={() => handleChange(language.Id)}
							sx={{
								cursor: 'pointer',
								mx: 1
							}}
						/>
						<Typography variant='body2' >{language.Name}</Typography>
					</Stack>
				))}
			</Stack>
		</Stack>
	);
};

export default LanguageFilters2;
