import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { Feature } from 'models/api';
import React from 'react';
import AccountUsers from './AccountUsers';
import AccountGroups from './AccountGroups';

interface Props {
	feature: Feature;
	onHideHeader?: (status: boolean) => void;
}

const AccessPermissions: React.FC<Props> = ({ feature, onHideHeader }) => {
	React.useEffect(() => {
		if (onHideHeader) onHideHeader(true);
		return () => {
			if (onHideHeader) onHideHeader(false);
		};
	}, [feature, onHideHeader]);

	const [selectedTab, setSelectedTab] = React.useState<number>(0);

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`
		};
	}

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		event.preventDefault();
		setSelectedTab(newValue);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Grid container justifyContent={'space-between'} alignItems={'center'} sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
					<Tab
						label={
							<Typography variant="body2" fontWeight={400} fontSize={12} color={'TextGray'}>
								Account Users Permissions
							</Typography>
						}
						{...a11yProps(0)}
					/>
					<Tab
						label={
							<Typography variant="body2" fontWeight={400} fontSize={12} color={'TextGray'}>
								Groups Permissions
							</Typography>
						}
						{...a11yProps(1)}
					/>
				</Tabs>
			</Grid>
			<Box sx={{ overflowY: 'auto' }} m={5}>
				{selectedTab === 0 && <AccountUsers feature={feature} />}
				{selectedTab === 1 && <AccountGroups feature={feature} />}
			</Box>
		</Box>
	);
};

export default AccessPermissions;
