import { Alert, Stack } from '@mui/material';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { CustomAlertObj, useCustomAlert } from 'contexts/customAlertContext';
import React from 'react';

export interface State extends SnackbarOrigin {
  _open: boolean;
}

interface Props {
  CustomAlert: CustomAlertObj;
}

const MuiSnackbarTable: React.FC<Props> = ({ CustomAlert }) => {
  const { setCustomAlert } = useCustomAlert();
  const handleClose = () => {
    setCustomAlert({ open: false, messages: [], type: "success" });
  };



  return (

    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={CustomAlert.open}
      onClose={handleClose}
      autoHideDuration={6000}
      key={'bottom right'}
    >
      <Alert onClose={handleClose} severity={CustomAlert.type} variant={'filled'}>
        {CustomAlert.messages.map((message, index) => (
          <Stack direction={'column'} key={index} spacing={1}>
            * {message}
          </Stack>
        ))}
      </Alert>
    </Snackbar >
  )
};

export default MuiSnackbarTable;
