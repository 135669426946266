import { FC } from "react";

import { IFormField, IOption } from "models/app";
import { InputTypes } from "constants/enums";
import RHTextField from "./RHTextField";
import RHPasswordField from "./RHPasswordField";
import RHCheckbox from "./RHCheckbox";
import RHAutoComplete from "./RHAutoComplete";
import RHEditorField from "./RHEditor";
import RHFiles from "./RHFiles";
import RHDropzone from "./RHDropzone";
import RHSwitch from "./RHSwitch";

interface Props extends IFormField {
  errors: any;
  control: any;
  watch: any;
  setValue: any;
  register: any;
  options?: IOption[];
  required?: boolean;
  defaultValue?: any;
}

const FormFields: FC<Props> = (props) => {
  const { type, hidden, setValue } = props;

  const renderField = (): React.ReactNode => {
    if (hidden) return null;
    if (type === InputTypes.TEXT || type === InputTypes.HIDDEN || type === InputTypes.NUMBER || type === InputTypes.TEXTAREA) {
      return <RHTextField {...props} />;
    }

    if (type === InputTypes.EMAIL) {
      return <RHTextField {...props} />;
    }
     
    if (type === InputTypes.PASSWORD) {
      return <RHPasswordField {...props} />;
    }

    if (type === InputTypes.CHECKBOX) {
      return <RHCheckbox {...props} />;
    }

    if (type === InputTypes.MULTI_SELECT) {
      return <RHAutoComplete {...props} multiple={true} setValue={setValue}  defaultValue={props.defaultValue || []} />;
    }

    if (type === InputTypes.SELECT) {
      return <RHAutoComplete {...props} setValue={setValue} />;
    }
    if (type === InputTypes.SWITCH) {
      return <RHSwitch {...props} setValue={setValue} />;
    }
    if (type === InputTypes.FILE) {
      return <RHFiles {...props} />;
    }
    if (type === InputTypes.MULTIPLE_FILES) {
      return <RHDropzone {...props} />;
    }
    if (type === InputTypes.EDITOR) {
      return <RHEditorField {...props} value={props.defaultValue} />;
    }


    return null;
  };

  return <>{renderField()}</>;
};

export default FormFields;
