
import { GET_REVIEWER } from "constants/api";
import axios from "../helpers/axiosInstance";


export async function getReviewers({ queryKey }: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, _params] = queryKey;
  const response = await axios.get(GET_REVIEWER + _params.query);
  return response.data;
}
