
import { GET_TOPICS, ADD_TOPIC } from "constants/api";
import axios from "../helpers/axiosInstance";
import { ITopicInput } from "models/topic";


export async function getTopics({ queryKey }: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, _params] = queryKey;
  const response = await axios.get(GET_TOPICS + _params.query);
  return response.data;
}


export async function addTopic(data: ITopicInput) {
  const _params = {
    Name: data.Name,
    HexColor: data.HexColor,
  };
  const response = await axios.post(ADD_TOPIC + "?" + new URLSearchParams(_params));
  return response.data;
}