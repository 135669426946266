import { Stack, TableCell, Typography, Divider } from '@mui/material';
import List from 'components/UI/List';
import { GroupCellsPermission, UpdateGroupPermission, getGroupPermissions } from 'framework/users';
import { Feature, ResponseResult } from 'models/api';
import { Group } from 'models/user';
import React, { useCallback, useEffect, useState } from 'react';
import { IPermissions, GroupForm, IPermissionsGroupForm } from 'models/settings';
import PersonIcon from '@mui/icons-material/Person';
import { useQueryClient } from 'react-query';
import { useAlert } from 'contexts/alertContext';
import ActionLoader from 'components/UI/ActionLoader';
import EditGroup from './EditGroup';
import { IOption } from 'models/app';
import EditGroupPermissions from './EditGroupPermissions';
import { Permission } from 'models/permission';

interface Props {
	groups: Group[];
	feature: Feature;
	onEditGroup: (data: GroupForm) => {};
	handleOnDeleteGroup: (id: number) => void;
}

function getPermissionById(id: number, permissions: IPermissions[]) {
	if (!id) return;
	return permissions.find((permission) => permission.PermissionId === id) || null;
}

function handleValues(obj: any) {
	const handledObj = { ...obj };

	for (const key in handledObj) {
		if (handledObj[key] === null) {
			handledObj[key] = 0;
		} else if (handledObj[key] === true) {
			handledObj[key] = 1;
		}
	}

	return handledObj;
}

const ListGroups: React.FC<Props> = ({ groups, feature, onEditGroup, handleOnDeleteGroup }) => {
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	const [showEditGroup, setShowEditGroup] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingPermissions, setLoadingPermissions] = useState<boolean>(false);
	const [showEditPermissions, setShowEditPermissions] = useState<boolean>(false);
	const [permissions, setPermissions] = useState<IPermissions[] | null>(null);
	const [currentGroup, setCurrentGroup] = useState<Group | null>(null);
	const [editGroupFormData, setEditGroupFormData] = useState<GroupForm | null>(null);
	const [editPermissionsFormData, setEditPermissionsFormData] = useState<IPermissions | null>(null);

	let permissionsLocalStorage: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissionsLocalStorage?.find((item) => item.PermissionId === 14);

	const getBodyCells = (row: Group) => {
		let cells = Object.keys(row).map((key: string, i: number) => {
			if (key === 'Name')
				return {
					id: 'Name',
					element: (
						<TableCell align={'left'} key={i}>
							<Stack direction={'row'} spacing={1}>
								<PersonIcon />
								<Stack direction={'column'}>
									<Typography variant="body2" fontWeight={500}>
										{row?.Name}
									</Typography>

									{!!currentPagePermissions?.HasModifyPermission && (
										<Stack direction={'row'} spacing={1}>
											<Typography color="primary" component="span" sx={{ cursor: 'pointer' }} onClick={() => handleOpenEdit(row)}>
												Update
											</Typography>
											<Divider orientation="vertical" flexItem />
											<Typography color="primary" component="span" sx={{ cursor: 'pointer' }} onClick={() => handleOpenPermissions(row)}>
												Permissions
											</Typography>
										</Stack>
									)}
								</Stack>
							</Stack>
						</TableCell>
					)
				};
			if (key === 'Description')
				return {
					id: 'Description',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.Description}</Typography>
						</TableCell>
					)
				};
			else return <></>;
		});
		return cells;
	};

	const fetchPermissions = useCallback(async () => {
		setLoadingPermissions(true);

		try {
			const res: ResponseResult = await queryClient.fetchQuery(['AdminGroupPermissions', { query: `?groupId=${currentGroup?.Id}` }], getGroupPermissions);

			if (res.Success === 0) throw new Error(res.Message);

			setPermissions(res.Data);

			setLoadingPermissions(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoadingPermissions(false);
	}, [currentGroup]);

	useEffect(() => {
		if (!currentGroup) return;
		fetchPermissions();
	}, [currentGroup]);

	const handleClose = () => {
		setShowEditGroup(false);
		setShowEditPermissions(false);
		setEditGroupFormData(null);
		setEditPermissionsFormData(null);
		setCurrentGroup(null);
	};

	const handleOpenEdit = (group: Group) => {
		setEditGroupFormData({ Description: group.Description!, Name: group.Name!, GroupId: group.Id!, Users: '' });
		setShowEditGroup(true);
	};
	const handleOpenPermissions = (group: Group) => {
		setCurrentGroup(group!);
		setShowEditPermissions(true);
	};

	const handleChangeFormEditUser = (name: string, value: any) => {
		if (name === 'Users') {
			value = value?.map((item: IOption) => item.value).join(',') || '';
		}
		setEditGroupFormData({ ...editGroupFormData!, [name]: value });
	};
	const handleChangeFormEditPermissionsGroup = (name: string, value: any) => setEditPermissionsFormData({ ...editPermissionsFormData!, [name]: value });

	const handleEditUser = async () => {
		let CreateInput: GroupForm = {
			GroupId: editGroupFormData!.GroupId,
			Description: editGroupFormData!.Description,
			Name: editGroupFormData!.Name,
			Users: editGroupFormData!.Users
		};
		onEditGroup(CreateInput);
		handleClose();
	};
	const handleEditPermissions = async (data: IPermissionsGroupForm[]) => {
		setLoadingPermissions(true);

		try {
			const res: ResponseResult = await UpdateGroupPermission(data);

			if (res.Success === 0) throw new Error(res.Message);

			setAlert({
				open: true,
				message: 'Permission Updated',
				type: 'success'
			});
			fetchPermissions();

			setLoadingPermissions(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoadingPermissions(false);
	};

	if (loading || loadingPermissions) return <ActionLoader position={'absolute'} />;
	return (
		<>
			<List headCells={GroupCellsPermission} data={groups} getBodyCells={getBodyCells} feature={feature} canSelect={false} canEdit={false} />

			{/* Edit Group */}
			{showEditGroup && (
				<EditGroup open={showEditGroup} onDelete={handleOnDeleteGroup} onClose={handleClose} onChange={handleChangeFormEditUser} onSubmit={handleEditUser} loading={false} currentGroup={editGroupFormData!} />
			)}

			{/* Edit Permissions */}
			{showEditPermissions && (
				<EditGroupPermissions
					open={showEditPermissions}
					currentUserName={`${currentGroup?.Name}`}
					onClose={handleClose}
					onChange={handleChangeFormEditPermissionsGroup}
					onSubmit={handleEditPermissions}
					loading={loadingPermissions}
					permissions={permissions!}
				/>
			)}
		</>
	);
};

export default ListGroups;
