import { IBacklog } from 'models/backlog';
import { create } from 'zustand';

type State = {
	listing: IBacklog[];
	params: string;
};

type Actions = {
	setListing: (payload: IBacklog[]) => void;
	setParams: (payload: string) => void;
};

const useBacklogStore = create<State & Actions>((set) => ({
	listing: [],
	params: '',
	setListing: (payload: IBacklog[]) => set((state) => ({ ...state, listing: payload })),
	setParams: (payload: string) => set((state) => ({ ...state, params: payload }))
}));

export default useBacklogStore;
