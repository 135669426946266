import { Alert, Box, Typography } from '@mui/material';
import React from 'react';

const Error404: React.FC = () => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            backgroundColor: '#fff',
            position: 'absolute',
            top: "15%",
            left: "50%",
            transform: "translate(-50%, -50%)"
        }}>
            <Alert severity="error">
                <Typography variant="h4" component="div">
                    404 This page not found or you don't have permission to access it!
                </Typography>
            </Alert>
        </Box>
    )
};

export default Error404;