import { useState } from 'react';
import MuiButton from '../MuiButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Chip, Stack, Typography, Box, InputBase, Button } from '@mui/material';

interface Props {
	tags: string[];
	addTags: (items: string) => void;
	onDelete: (index: number) => void;
	placeholder?: string;
	clearAll: () => void;
}

const TagsInput: React.FC<Props> = ({ tags, placeholder, onDelete, addTags, clearAll }) => {
	const [items, setItems] = useState('');
	const addUsers = (value: string) => {
		addTags(value);
		setItems('');
	};
	const handleInput = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			const inputElement = e.target as HTMLInputElement;
			addUsers(inputElement.value);
		}
	};

	return (
		<>
			<Stack
				sx={{
					border: '1px solid #C9C9C9',
					borderRadius: '4px',
					p: 0,
					mb: 1
				}}>
				<Box
					component={'div'}
					sx={{
						m: 0,
						p: 1,
						maxHeight: '100px',
						borderBottom: '1px solid #c9c9c9'
					}}>
					{tags && tags.length > 0 ? (
						tags.map((tag: any, index: number) => tag && <Chip key={index} label={tag} onDelete={() => onDelete(index)} deleteIcon={<CloseIcon />} sx={{ backgroundColor: '#EDEDED', color: '#0f0f0f' }} />)
					) : (
						<Typography variant="body1" color="text.secondary">
							{'No emails added yet'}
						</Typography>
					)}
				</Box>
				<Stack direction={'row'} sx={{ p: 1 }}>
					<InputBase sx={{ border: 'none', width: '100%' }} value={items} placeholder={placeholder || ''} onChange={(e) => setItems(e.target.value)} onKeyDown={(e: React.KeyboardEvent) => handleInput(e)} />
					<MuiButton sx={{ color: '#1B6EE4', m: 0, alignItems: 'flex-start' }} onClick={() => addUsers(items)}>
						<AddCircleOutlineIcon />
					</MuiButton>
				</Stack>
			</Stack>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
				<Typography>{tags.length}/25 Items</Typography>
				<Button sx={{ color: '#262626' }} onClick={clearAll}>
					<Stack direction={'row'} spacing={1}>
						<DeleteOutlineIcon />
						<Typography>Clear All</Typography>
					</Stack>
				</Button>
			</Box>
		</>
	);
};
export default TagsInput;
