import { Box, Grid, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { ResponseResult } from 'models/api';
import { useQuery } from 'react-query';
import { FiltersProps } from 'models/filters';
import { getFeelings } from 'framework/responseForm';

type Props = {
	onChange: (name: string, value: any) => void;
	filters: FiltersProps
};

const FeelingsFilters: React.FC<Props> = ({ onChange, filters }) => {
	const { data: result, isError, isLoading } = useQuery<ResponseResult>(['feelings', { query: '' }], getFeelings);

	if (isError)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);
	if (isLoading) return <ActionLoader position={'absolute'} />;

	let feelings: any[] = result?.Data || [];
	const handleChange = (id: number) => {
		let SelectedFeelings = filters.feelingIds ? filters.feelingIds.split(',').map((item) => feelings.find((feeling) => feeling.Id === parseInt(item))!) : [];
		const index = SelectedFeelings.findIndex((item) => item.Id === id);
		if (index === -1) {
			SelectedFeelings.push(feelings.find((item) => item.Id === id)!);
		} else {
			SelectedFeelings.splice(index, 1);
		}

		onChange('feelingIds', SelectedFeelings.map((item) => item.Id).join(','));
	};
 

	return (
		<Stack spacing={1} useFlexGap sx={{
			maxHeight: '20vh',
			overflowY: 'auto'		
		}}>
			<Typography variant="h6" component={'p'} fontFamily={'600'}>
				feelings
			</Typography>
			<Stack>
				{feelings.map((feeling: any, index: number) => (
					<Stack direction={'row'} key={index} >
						<Box component={'input'} type='checkbox'
							checked={filters?.feelingIds ? filters.feelingIds.split(',').some((item) => item === feeling.Id.toString()) : false}
							onChange={() => handleChange(feeling.Id)}
							sx={{
								cursor: 'pointer',
								mx: 1
							}}
						/>
						<Typography variant='body2' >{feeling.Name}</Typography>
					</Stack>
				))}
			</Stack>
		</Stack>
	);
};

export default FeelingsFilters;
