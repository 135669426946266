import { Box, Stack, Typography } from '@mui/material';
import { getOrganizationsWithGroups } from 'framework/organizations';
import { ResponseResult } from 'models/api';
import { useQueryClient } from 'react-query';
import { OrganizationWithGroups } from 'models/organization';
import { FiltersProps } from 'models/filters';
import { useCallback, useEffect, useState } from 'react';
import { useAlert } from 'contexts/alertContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeView } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ActionLoader from 'components/UI/ActionLoader';

type Props = {
	handleChangeMultiple: (data: { name: string, value: any }[]) => void;
	onChange: (name: string, value: any) => void;
	filters: FiltersProps
};

const OrganizationFilters2: React.FC<Props> = ({ handleChangeMultiple, onChange, filters }) => {
	const [organizationsWithGroups, setOrganizationsWithGroups] = useState<OrganizationWithGroups[]>([]);
	const [loading, setLoading] = useState(false);
	const { setAlert } = useAlert();

	let queryClient = useQueryClient();
	const fetchOrganizationsWithGroups = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['organizationsWithGroups', { query: '' }], getOrganizationsWithGroups);
			setOrganizationsWithGroups(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			})
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchOrganizationsWithGroups();

		// eslint-disable-next-line
	}, []);

	const handleChange = (id: number) => {
		let SelectedOrganizations: number[] = filters.organisations ? filters.organisations.split(',').map((item) => parseInt(item)) : [];
		const index = SelectedOrganizations.findIndex((item) => item === id);
		if (index === -1) {
			SelectedOrganizations.push(id);
			// check all groups of this organization
			let organization = organizationsWithGroups.find((item) => item.UserOrganizationId === id)!;
			let groups = organization.Groups.map((group) => group.GroupId);
			let SelectedGroups: number[] = filters.groups ? filters.groups.split(',').map((item) => parseInt(item)) : [];
			// add groups of this organization to selected groups
			let newGroups = [...SelectedGroups, ...groups];
			handleChangeMultiple([
				{ name: 'organisations', value: SelectedOrganizations.join(',') },
				{ name: 'groups', value: newGroups.join(',') }]);
		} else {
			SelectedOrganizations.splice(index, 1);
			// remove all groups of this organization from selected groups
			let organization = organizationsWithGroups.find((item) => item.UserOrganizationId === id)!;
			let groups = organization.Groups.map((group) => group.GroupId);
			let SelectedGroups: number[] = filters.groups ? filters.groups.split(',').map((item) => parseInt(item)) : [];
			let newGroups = SelectedGroups.filter((item) => !groups.includes(item));
			handleChangeMultiple([
				{ name: 'organisations', value: SelectedOrganizations.join(',') },
				{ name: 'groups', value: newGroups.join(',') }]);

		}
	};

	const handleChangeGroup = (id: number) => {
		let SelectedGroups: number[] = filters.groups ? filters.groups.split(',').map((item) => parseInt(item)) : [];
		const index = SelectedGroups.findIndex((item) => item === id);
		if (index === -1) {
			let group = organizationsWithGroups.find((item) => item.Groups.some((group) => group.GroupId === id))!.Groups.find((group) => group.GroupId === id) as any;
			SelectedGroups.push(group.GroupId);
		} else {
			SelectedGroups.splice(index, 1);
		}
		onChange('groups', SelectedGroups.join(','));
	}

	if (loading) return <ActionLoader position="fixed" />;


	return (
		<Stack spacing={1} useFlexGap sx={{
			maxHeight: '20vh',
			overflowY: 'auto'
		}}>
			<Typography variant="h6" component={'p'} fontFamily={'600'}>
				Organizations
			</Typography>
			<Box sx={{ minHeight: 180, flexGrow: 1, maxWidth: 300 }}>
				<TreeView
					aria-label="file system navigator"
					defaultCollapseIcon={<ExpandMoreIcon />}
					defaultExpandIcon={<ChevronRightIcon />}
				>
					{organizationsWithGroups.map((org: OrganizationWithGroups, index: number) => (
						<TreeItem
							key={index}
							nodeId={org.UserOrganizationId.toString + index.toString()} // Add the nodeId property with a unique identifier
							label={
								<Stack direction="row" alignItems="center" spacing={1}>
									<Box
										component={'input'}
										type="checkbox"
										checked={
											filters?.organisations
												? filters.organisations.split(',').some((item) => item === org.UserOrganizationId.toString())
												: false
										}
										onChange={() => handleChange(org.UserOrganizationId)}
										sx={{
											cursor: 'pointer',
											mx: 1
										}}
									/>
									<Typography variant="body1" component={'p'} fontFamily={'600'}>
										{org.UserOrganizationName}
									</Typography>
								</Stack>
							}
						>
							{org.Groups.map((group, i) => (
								<TreeItem
									key={i}
									nodeId={group.GroupId.toString() + i.toString()} // Add the nodeId property with a unique identifier
									label={
										<Stack direction="row" alignItems="center" spacing={1}>
											<Box
												component={'input'}
												type="checkbox"
												checked={
													filters?.groups
														? filters.groups.split(',').some((item) => item === group.GroupId.toString())
														: false
												}
												onChange={() => handleChangeGroup(group.GroupId)}
												sx={{
													cursor: 'pointer',
													mx: 1
												}}
											/>
											<Typography variant="body1" component={'p'} fontFamily={'600'}>
												{group.GroupName}
											</Typography>
										</Stack>
									}
								/>
							))}
						</TreeItem>
					))}
				</TreeView>
			</Box>
		</Stack>
	);
};

export default OrganizationFilters2;
