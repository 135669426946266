import { create } from 'zustand';

type State = {
	totalUsers: number;
	totalOrganizations: number;
	totalReports: number;
	activeUsers: any;
	params: string;
};

type Actions = {
	setUsersNumber: (payload: any) => void;
	setTotalOrganizations: (payload: any) => void;
	setTotalReports: (payload: any) => void;
	setActiveUsers: (payload: any) => void;
	setParams: (payload: string) => void;
};

const useDataCenterStore = create<State & Actions>((set) => ({
	totalUsers: 0,
	totalOrganizations: 0,
	totalReports: 0,
	params: '',
	activeUsers: null,

	setUsersNumber: (payload) => set((state) => ({ ...state, totalUsers: payload })),
	setTotalOrganizations: (payload) => set((state) => ({ ...state, totalOrganizations: payload })),
	setTotalReports: (payload) => set((state) => ({ ...state, totalReports: payload })),
	setActiveUsers: (payload) => set((state) => ({ ...state, activeUsers: payload })),
	setParams: (payload: string) => set((state) => ({ ...state, params: payload }))
}));

export default useDataCenterStore;
