import { CircularProgress, Container } from '@mui/material';
import React from 'react';
import { IFormField } from 'models/app';
import { InputTypes } from 'constants/enums';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import PrimeReactModal from 'components/UI/PrimeReactModal';
import { IPillarInput } from 'models/pillar';

interface Props {
	open: boolean;

	currentPillar: IPillarInput | null;
	loading: boolean;
	onClose: () => void;
	onSubmit: () => void;
	onChange: (name: string, value: any) => void;
}

const EditPillar: React.FC<Props> = ({ open, currentPillar, loading, onChange, onClose, onSubmit }) => {
	const basicFields: IFormField[] = [
		{
			name: 'Name',
			label: 'Name',
			type: InputTypes.TEXT,
			placeholder: 'Enter Pillar Name',
			defaultValue: currentPillar?.Name
		},

		{
			name: 'Description',
			label: 'Description',
			type: InputTypes.EDITOR,
			placeholder: 'Enter Pillar Description',
			defaultValue: currentPillar?.Description
		}
	];

	return (
		<PrimeReactModal
			open={open}
			onClose={onClose}
			title="Edit Pillar"
			children={
				<Container maxWidth={'md'} sx={{ py: 2, pb: 4 }}>
					<Form fields={basicFields} onChange={onChange} />
				</Container>
			}
			actions={
				<>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#F0F5FD',
							color: '#8B8BAE',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onClose}>
						Cancel
					</MuiButton>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#0A94D1',
							color: '#fff',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						disabled={loading}
						onClick={onSubmit}>
						Update
						<CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
					</MuiButton>
				</>
			}
		/>
	);
};

export default EditPillar;
