import { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAlert } from '../../contexts/alertContext';

import Overview from 'Pages/Overview';
import MuiSnackbar from 'components/UI/MuiSnackbar';
import Login from 'Pages/Auth/Login';
import Register from 'Pages/Auth/Register';
import MuiSnackbarTable from 'components/UI/MuiSnackbarTable';
import { useCustomAlert } from 'contexts/customAlertContext';
import ForgetPassword from 'Pages/Auth/ForgetPassword';
import ResetPassword from 'Pages/Auth/ResetPassword/ResetPassword';
import ActivateAccount from 'Pages/Auth/ActivateAccount/ActivateAccount';

const Dashboard: React.FC = () => {
	const { alert } = useAlert();
	const { CustomAlert } = useCustomAlert();

	return (
		<>
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/forgot-password" element={<ForgetPassword />} />
				<Route path="/reset-password/:token?" element={<ResetPassword />} />
				<Route path="/register" element={<Register />} />
				<Route path="/activate-account/:token" element={<ActivateAccount />} />
				<Route path="/*" element={<Overview />} />
			</Routes>

			<MuiSnackbar alert={alert!} />
			<MuiSnackbarTable CustomAlert={CustomAlert!} />
		</>
	);
};

export default memo(Dashboard);
