import { Alert, Badge, Box, Grid, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import React from 'react';
import { useQuery } from 'react-query';
import CustomMenuItem from '../MenuItem';
import { Feature, ResponseResult, User } from 'models/api';
import { fetchServices, getNotifications } from 'framework/services';
import useApp from 'hooks/useApp';
import { NotificationIcon } from 'components/UI/SvgIcons';
import { Permission } from 'models/permission';
import NotificationsMenu from './partials/NotificationMenu';
import { INotification } from 'models/app';
import UpdateUserInformation from './partials/UpdateUserInformation';
import { Resources } from 'constants/enums';

interface Props { 
 }

const SideMenu: React.FC<Props> = () => {
	const { push } = useApp();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
	const [showNotification, setShowNotification] = React.useState<boolean>(false);
	const [showProfile, setShowProfile] = React.useState<boolean>(false);
	let slug = window.location.pathname.split('/')[1];
	let showNotificationIcon = slug === Resources.SHOW_CASES ? true : false;

	const { data, isError, isLoading } = useQuery<Feature[]>('features', fetchServices);
	const { data: notifications, refetch } = useQuery<ResponseResult>('notifications', getNotifications);
	let features: Feature[] | undefined = data;
	const [selectedFeature, setSelectedFeature] = React.useState<Feature | null>(null);

	if (isError) return <Alert severity="error">An error occurred while fetching features.</Alert>;
	if (isLoading) return <ActionLoader position="fixed" />;

	let user: User | null = JSON.parse(localStorage.getItem('user')!) || null;
	if (!user) push('/login');
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	if (features && features.length > 0) {
		features = features.filter(
			(feature: Feature) => permissions.some((permission: Permission) => feature.PermissionId === `${permission.PermissionId}` && permission.HasReadPermission === 1) || feature.PermissionId === '-1'
		);

		features.forEach((feature: Feature) => {
			feature.children = feature.children.filter(
				(child: Feature) => permissions.some((permission: Permission) => child.PermissionId === `${permission.PermissionId}` && permission.HasReadPermission === 1) || child.PermissionId === '-1'
			);
		});
	}

	features = features?.filter((feature: Feature) => feature.PermissionId === '-1' ? feature.children.length > 0 : feature);

	const handleSelectUnLinkFeature = (feature: Feature) => setSelectedFeature(feature);
	const handleShowNotification = (event: any) => {
		setNotificationAnchorEl(event.currentTarget);
		setShowNotification(true);
	};

	const getHashName = () => {
		let hash: string = '';
		user?.Name!.split(' ').forEach((name: string) => (hash += name[0] || ''));
		return hash;
	};

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseAnchor = () => {
		setAnchorEl(null);
		setShowNotification(false);
	};

	const handleCloseShowProfile = () => {
		setShowProfile(false);
	};

	
	const handleLogout = () => {
		localStorage.clear();
		push('/login');
	};

	const handleShowProfile = () => {
		setShowProfile(true);
		handleCloseAnchor();
	};

	const numberOfUnreadNotifications = notifications?.Data?.reduce((count: number, notification: INotification) => {
		return count + (notification.IsRead ? 0 : 1);
	}, 0);

	return (
		<>
			<Stack
				component={'aside'}
				maxWidth={100}
				zIndex={1200}
				sx={{
					backgroundColor: '#464653',
					color: '#fff',
					pt: 8,
					pb: 4,
					flexShrink: 0,
					position: 'sticky',
					top: 0,
					display: { xs: 'none', lg: 'flex' },
					overflowY: 'auto'
				}}
				spacing={20}>
				<Stack direction={'column'} spacing={2} height={'100%'}>
					{features &&
						features.length > 0 &&
						features.map((feature, index) => (
							<CustomMenuItem
								key={index}
								feature={feature}
								color={'white'}
								onSelectUnLinkFeature={handleSelectUnLinkFeature}
								hasChildren={feature.children.length > 0 ? true : false}
								selectedFeature={selectedFeature!}
							/>
						))}
				</Stack>
				<Stack direction={'column'} spacing={5} px={3} pt={2} mt={'0 !important'} mb={'100% !important'}>

					{showNotificationIcon && <IconButton onClick={handleShowNotification}>
						<Badge badgeContent={numberOfUnreadNotifications} color="primary">
							<NotificationIcon />
						</Badge>
					</IconButton>}
					<Box
						sx={{
							mx: 4,
							width: 45,
							height: 45,
							borderRadius: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							backgroundColor: '#FFFFFF',
							border: '3px solid #AAAAB4',
							cursor: 'pointer',
							'&:hover': {
								border: '3px solid #FFFFFF'
							}
						}}
						aria-label="more"
						id="long-button"
						aria-controls={anchorEl ? 'long-menu' : undefined}
						aria-expanded={anchorEl ? 'true' : undefined}
						aria-haspopup="true"
						onClick={handleClick}>
						<Typography variant="body2" fontWeight={500} fontSize={15} color={'#93989D'}>
							{getHashName()}
						</Typography>
					</Box>
				</Stack>
			</Stack>
			{selectedFeature && (
				<>
					{/* Overlay */}
					<Box
						onClick={() => setSelectedFeature(null)}
						sx={{
							position: 'fixed',
							top: 0,
							left: 0,
							width: '100vw',
							height: '100vh',
							zIndex: 1100,
							backgroundColor: 'rgba(0, 0, 0, 0.3)' // Adjust the alpha value for transparency
						}}
					/>

					<Stack
						component={'aside'}
						zIndex={1200}
						height={'100vh'}
						sx={{
							bgcolor: (theme) => theme.palette.background.paper,
							color: (theme) => theme.palette.text.primary,
							pt: 8,
							pb: 4,
							position: 'absolute',
							top: 0,
							left: 92
						}}
						justifyContent={'start'}
						alignItems={'start'}
						alignContent={'start'}
						textAlign={'start'}>
						<Stack direction={'column'} spacing={2}>
							<Grid container>
								<Typography mx={5} variant="body2" fontWeight={400} fontSize={12} color={'gary'}>
									{selectedFeature.name}
								</Typography>
							</Grid>
							{selectedFeature.children.length > 0 &&
								selectedFeature.children.map((feature, index) => (
									<CustomMenuItem key={index} feature={feature} color={'dark'} onSelectUnLinkFeature={handleSelectUnLinkFeature} direction={'row'} spacing={1} borderBottom={'1px solid #E5E5E5'} pr={5} />
								))}
						</Stack>
					</Stack>
				</>
			)}
			<Menu
				id="long-menu"
				MenuListProps={{
					'aria-labelledby': 'long-button'
				}}
				sx={{
					'& .MuiPaper-root': {
						boxShadow: 'none',
						border: '1px solid #E5E5E5',
						minWidth: 120,
						position: 'absolute',
						top: 0, // Adjust the top position relative to the sidebar section
						left: 100 // Adjust the left position relative to the sidebar section
					}
				}}
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleCloseAnchor}>
				<MenuItem onClick={handleShowProfile}>
					<Typography variant="body2" fontWeight={400} fontSize={15} color={'#6B6F6F'}>
						Profile
					</Typography>
				</MenuItem>
				<MenuItem onClick={handleLogout}>
					<Typography variant="body2" fontWeight={400} fontSize={15} color={'#6B6F6F'}>
						Logout
					</Typography>
				</MenuItem>
			</Menu>
			{showNotification && <NotificationsMenu refetch={refetch} element={notificationAnchorEl!} open={showNotification} handleClose={handleCloseAnchor} notifications={notifications?.Data || []} />}
			{showProfile && <UpdateUserInformation open={showProfile} onClose={handleCloseShowProfile} />}

		</>
	);
};

export default SideMenu;
