import { Skeleton, Stack, Typography } from '@mui/material';
import BacklogItem from './partials/BacklogItem';
import { useCallback, useEffect, useState } from 'react';
import { ResponseResult } from 'models/api';
import { useAlert } from 'contexts/alertContext';
import { useQueryClient } from 'react-query';
import { deleteBacklog, getBackLog, updateBacklog } from 'framework/backlog';
import { IBacklogIndicator } from 'models/backlog';
import React from 'react';
import { generateBacklogColor, generateBacklogColorDarker } from 'helpers/utils';
import useBacklogStore from 'store/backlog';
import { Permission } from 'models/permission';
import useApp from 'hooks/useApp';

type Props = {
	onHideHeader?: (status: boolean) => void;
};

const Backlog: React.FC<Props> = ({ onHideHeader }) => {
	const queryClient = useQueryClient();
	const [loading, setLoading] = useState<boolean>(false);
	const setListing = useBacklogStore((state) => state.setListing);
	const listing = useBacklogStore((state) => state.listing);
	const params = useBacklogStore((state) => state.params);
	const { setAlert } = useAlert();
	const { push } = useApp();

	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 7);

	useEffect(() => {
		if (!currentPagePermissions?.HasReadPermission) {
			push('/404');
		}
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [currentPagePermissions]);

	const fetchBacklog = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['backlogs', { query: params }], getBackLog);
			setListing(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [params]);

	useEffect(() => {
		fetchBacklog();
		// eslint-disable-next-line
	}, [params]);

	if (loading) return <Skeleton variant="rectangular" height={'100vh'} animation="wave" />;

	const onDelete = async (item: IBacklogIndicator) => {
		setLoading(true);

		try {
			const res = await queryClient.fetchQuery(['DeleteBacklog', { query: `?id=${item?.Id}` }], deleteBacklog);

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Deleted',
				type: 'success'
			});
			setLoading(false);

			fetchBacklog();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};
	const onUpdate = async (item: IBacklogIndicator, edited: string) => {
		setLoading(true);

		try {
			const res = await queryClient.fetchQuery(['updateBacklog', { query: `?backLogId=${item?.Id}&sentence=${edited}` }], updateBacklog);

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Updated',
				type: 'success'
			});
			setLoading(false);

			fetchBacklog();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	return (
		<Stack
			direction={'row'}
			spacing={2}
			m={5}
			sx={{
				overflowY: 'hidden'
			}}>
			{listing!?.map((item, index: number) => {
				const randomBgColor = generateBacklogColor(index);
				return (
					<Stack
						key={index}
						bgcolor={`rgba(${randomBgColor})`}
						height={'80vh'}
						minHeight={250}
						borderTop={`15px solid rgb(${generateBacklogColorDarker(index)})`}
						borderRadius={3}
						width={350}
						flexShrink={0}
						p={2}
						pb={12}
						spacing={2}
						useFlexGap
						sx={{
							overflowY: 'auto'
						}}>
						<Typography variant="h6" width={'fit-content'} textAlign="center">
							{item?.CompetencyName}
						</Typography>
						{item?.Indicators?.map((indicator) => (
							<BacklogItem key={indicator.Id} item={indicator} onDelete={onDelete} onEdit={onUpdate} />
						))}
					</Stack>
				);
			})}
		</Stack>
	);
};

export default Backlog;
