import { Box, Stack, Typography } from '@mui/material';
import Modal from '../../../../../components/UI/DialogModal';
import { IFormField } from 'models/app';
import { InputTypes } from 'constants/enums';
import MuiButton from 'components/UI/MuiButton';
import Form from 'components/UI/Form';
import { AddGroupForm } from 'framework/organizations';

interface Props {
	open: boolean;
	onClose: () => void;
	onChange: (name: string, value: string) => void;
	onSubmit: () => void;
	currentAddForm: AddGroupForm;
}

let nameFields: IFormField[] = [
	{
		name: 'GroupName',
		type: InputTypes.TEXT,
		placeholder: 'Group Name'
	}
];

let descriptionFields: IFormField[] = [
	{
		name: 'GroupDescription',
		type: InputTypes.TEXT,
		placeholder: 'Group Description'
	}
];

const AddGroup: React.FC<Props> = ({ open, onClose, onChange, onSubmit, currentAddForm }) => {
	const save = () => {
		onSubmit();
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth={'md'}
			title="Add Group"
			actions={
				<Stack direction={'row'} spacing={2}>
					<MuiButton onClick={onClose} sx={{ color: '#5C5F66', paddingInline: 6, paddingBlock: 1, border: '1px solid #5C5F66' }}>
						Cancel
					</MuiButton>
					<MuiButton
						disabled={!currentAddForm?.GroupDescription || !currentAddForm?.GroupName}
						onClick={save}
						sx={{ backgroundColor: '#1B6EE4', color: '#fff', paddingInline: 6, paddingBlock: 1, '&:hover': { color: '#1B6EE4' } }}>
						Save
					</MuiButton>
				</Stack>
			}>
			<Box sx={{ paddingBlock: 3, paddingInline: 1 }}>
				<Stack spacing={2}>
					<Stack sx={{ width: '100%' }} direction={'row'}>
						<Typography sx={{ width: '25%' }}>Group Name</Typography>
						<Box sx={{ width: '50%' }}>
							<Form fields={nameFields} onChange={onChange} />
						</Box>
					</Stack>
					<Stack sx={{ width: '100%' }} direction={'row'}>
						<Typography sx={{ width: '25%' }}>Group Description</Typography>
						<Box sx={{ width: '50%' }}>
							<Form fields={descriptionFields} onChange={onChange} />
						</Box>
					</Stack>
				</Stack>
			</Box>
		</Modal>
	);
};

export default AddGroup;
