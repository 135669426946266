import React from 'react';

import useApp from 'hooks/useApp';
import { useAlert } from 'contexts/alertContext';
import { useMutation } from 'react-query';
import { signIn } from 'framework/auth';
import { IMediaQuery } from 'types/mediaQuery';
import useResponsive from 'hooks/useResponsive';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { AccountsBLIconSvg, AccountsBLSvg, AccountsTRIconSvg, AccountsTRImageSvg } from '../../../components/UI/SvgIcons';
import accountsBg from 'assets/images/accounts-bg.png';
import AuthForm from '../AuthForm';

const Login: React.FC = () => {
	const { push } = useApp();
	const { setAlert } = useAlert();

	const params: IMediaQuery = { query: 'up', key: 'md' };
	const mdUp = useResponsive(params);

	const mutationLogin = useMutation({
		mutationFn: (createInput: any) => {
			return signIn(createInput);
		}
	});

	const onsubmit = async (data: any) => {
		try {
			const res = await mutationLogin.mutateAsync({ ...data, AdminLogin: true });
			if (res.Error) throw new Error(res.Message || 'Something went wrong');
			let user = JSON.parse(res.Data) || {};
			setAlert({ open: true, message: `Welcome ${user.Name}`, type: 'success' });
			localStorage.setItem('access-token', res.accessToken);
			localStorage.setItem('refresh-token', res.refreshToken);
			localStorage.setItem('user', res.Data);
			let redirect = sessionStorage.getItem('redirect');
			if (redirect && redirect !== '/' && redirect !== '/forgot-password' && redirect !== '/404') {
				sessionStorage.removeItem('redirect');
				push(redirect);
			} else	push('/');
		} catch (error: any) {
			setAlert({ open: true, message: error.message, type: 'error' });
		}
	};

	return (
		<>
			<Grid container>
				<Grid item xs={12} md={6}>
					{mdUp && (
						<Box
							sx={{
								position: 'relative',
								height: '100vh',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'column',
								minHeight: '100%',
								overflow: 'hidden',
								backgroundColor: 'primary.main'
							}}>
							{/* Top right background image */}
							<Box
								sx={{
									position: 'absolute',
									right: 0,
									top: 0,
									svg: { display: 'block' }
								}}>
								<AccountsTRImageSvg />
							</Box>
							<Box
								sx={{
									position: 'absolute',
									right: 0,
									top: 0,
									svg: { display: 'block' }
								}}>
								<AccountsTRIconSvg />
							</Box>
							{/* background image */}
							<Box
								sx={{
									position: 'absolute',
									top: '50%',
									left: '50%',
									transform: 'translate(-50%,-50%)'
								}}>
								<Box component={'img'} src={accountsBg} alt="scofolio-bg" sx={{ display: 'block' }} />
							</Box>
							{/* Left bottom background image */}
							<Box
								sx={{
									position: 'absolute',
									bottom: -3,
									left: 0,
									svg: { display: 'block' }
								}}>
								<AccountsBLSvg />
							</Box>
							<Box
								sx={{
									position: 'absolute',
									bottom: -3,
									left: 0,
									svg: { display: 'block' }
								}}>
								<AccountsBLIconSvg />
							</Box>
							<Stack height={1} width={1} justifyContent={'flex-end'} pb={4}>
								<Typography textTransform={'capitalize'} component={'p'} variant="h5" textAlign={'center'} color={'common.white'}>
									Login
								</Typography>
								<Typography component={'p'} variant="h5" textAlign={'center'} color={'common.white'} paragraph>
									and come on in
								</Typography>
								<Typography fontWeight={300} textAlign={'center'} color={'common.white'}>
									&copy; Scofolio
								</Typography>
							</Stack>
						</Box>
					)}
				</Grid>
				<Grid item xs={12} md={6}>
					<Stack width={1} height={1} justifyContent={'center'} spacing={2} px={{ xs: 3, lg: 6 }} sx={{ overflowY: 'auto' }}>
						<AuthForm slug={'Login'} onSubmit={onsubmit} />
					</Stack>
				</Grid>
			</Grid>
		</>
	);
};

export default Login;
