import { Stack, TableCell, Typography, Divider } from '@mui/material';
import List from 'components/UI/List';
import { UpdateUserPermission, UserCellsPermission, deleteAdminUser, getUsersPermissions, updateAdminUser } from 'framework/users';
import { Feature, ResponseResult } from 'models/api';
import { User } from 'models/user';
import React, { useCallback, useEffect, useState } from 'react';
import { IPermissions, IEditUser, IPermissionsUserForm } from 'models/settings';
import PersonIcon from '@mui/icons-material/Person';
import Edituser from './EditUser';
import { useQueryClient } from 'react-query';
import { useAlert } from 'contexts/alertContext';
import ActionLoader from 'components/UI/ActionLoader';
import EditUserPermissions from './EditUserPermissions';
import { Permission } from 'models/permission';

interface Props {
	users: User[];
	feature: Feature;
}

 

const ListUsers: React.FC<Props> = ({ users, feature }) => {
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	const [showEditUser, setShowEditUser] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingPermissions, setLoadingPermissions] = useState<boolean>(false);
	const [showEditPermissions, setShowEditPermissions] = useState<boolean>(false);
	const [permissions, setPermissions] = useState<IPermissions[] | null>(null);
	const [currentUser, setCurrentUser] = useState<User | null>(null);
	const [editUserFormData, setEditUserFormData] = useState<IEditUser | null>(null);
	const [editPermissionsFormData, setEditPermissionsFormData] = useState<IPermissions | null>(null);

	let permissionsLocalStorage: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissionsLocalStorage?.find((item) => item.PermissionId === 14);

	const getBodyCells = (row: User) => {
		let cells = Object.keys(row).map((key: string, i: number) => {
			if (key === 'FirstName')
				return {
					id: 'FirstName',
					element: (
						<TableCell align={'left'} key={i}>
							<Stack direction={'row'} spacing={1}>
								<PersonIcon />
								<Stack direction={'column'}>
									<Typography variant="body2" fontWeight={500}>
										{row?.FirstName + ' ' + row.LastName}
									</Typography>
									{!!currentPagePermissions?.HasModifyPermission && (
										<Stack direction={'row'} spacing={1}>
											<Typography color="primary" component="span" sx={{ cursor: 'pointer' }} onClick={() => handleOpenEdit(row)}>
												Update
											</Typography>
											<Divider orientation="vertical" flexItem />
											<Typography color="primary" component="span" sx={{ cursor: 'pointer' }} onClick={() => handleOpenPermissions(row)}>
												Permissions
											</Typography>
										</Stack>
									)}
								</Stack>
							</Stack>
						</TableCell>
					)
				};
			if (key === 'OrganizationName')
				return {
					id: 'OrganizationName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.OrganizationName}</Typography>
						</TableCell>
					)
				};
			if (key === 'GroupName')
				return {
					id: 'GroupName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.GroupName}</Typography>
						</TableCell>
					)
				};
			if (key === 'LastLogin')
				return {
					id: 'LastLogin',
					element: (
						<TableCell align={'left'} key={i}>
								{!row?.LastLogin && <Typography variant="caption">Never</Typography>}
							{row?.LastLogin && <Typography variant="body2">
								{new Date(row?.LastLogin).toLocaleString('en-US', {
									year: '2-digit',
									month: '2-digit',
									day: '2-digit',
									hour: '2-digit',
									minute: '2-digit',
									hour12: true
								})}
							</Typography>}
						</TableCell>
					)
				};

			if (key === 'EmailAddress')
				return {
					id: 'EmailAddress',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.EmailAddress}</Typography>
						</TableCell>
					)
				};

			if (key === 'UserStatusName')
				return {
					id: 'UserStatusName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.UserStatusName}</Typography>
						</TableCell>
					)
				};
			else return <></>;
		});
		return cells;
	};

	const fetchPermissions = useCallback(async () => {
		setLoadingPermissions(true);

		try {
			const res: ResponseResult = await queryClient.fetchQuery(['AdminUsersPermissions', { query: `?userId=${currentUser?.Id}` }], getUsersPermissions);

			if (res.Success === 0) throw new Error(res.Message);

			setPermissions(res.Data);

			setLoadingPermissions(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoadingPermissions(false);

		// eslint-disable-next-line
	}, [currentUser]);

	useEffect(() => {
		if (!currentUser) return;
		fetchPermissions();
		// eslint-disable-next-line
	}, [currentUser]);

	const handleClose = () => {
		setShowEditUser(false);
		setShowEditPermissions(false);
		setEditUserFormData(null);
		setEditPermissionsFormData(null);
		setCurrentUser(null);
	};

	const handleOpenEdit = (user: User) => {
		setCurrentUser(user!);
		setEditUserFormData({ FirstName: user?.FirstName, LastName: user?.LastName, EmailAddress: user?.EmailAddress, Password: '', GroupIds: user?.GroupId!, NotificationEmailAddress: user?.NotificationEmailAddress, UserId:user.Id });
		setShowEditUser(true);
	};
	const handleOpenPermissions = (user: User) => {
		setCurrentUser(user!);
		setShowEditPermissions(true);
	};

	const handleChangeFormEditUser = (name: string, value: any) => setEditUserFormData({ ...editUserFormData!, [name]: value });
	const handleChangeFormEditPermissionsUser = (name: string, value: any) => setEditPermissionsFormData({ ...editPermissionsFormData!, [name]: value });

	const handleEditUser = async () => {
		setLoading(true);

		let CreateInput: IEditUser = {
			UserId: currentUser?.Id,
			EmailAddress: editUserFormData?.EmailAddress!,
			FirstName: editUserFormData?.FirstName!,
			LastName: editUserFormData?.LastName!,
			NotificationEmailAddress: editUserFormData?.NotificationEmailAddress!,
			GroupIds: editUserFormData?.GroupIds.map((item: any) => item.value).join(',') || null,
			Password: editUserFormData?.Password!
		};

		try {
			const res: ResponseResult = await updateAdminUser(CreateInput);

			if (res.Success === 0) throw new Error(res.Message);

			setAlert({
				open: true,
				message: 'User Updated',
				type: 'success'
			});
			queryClient.refetchQueries(['users', { query: '' }]);
			handleClose();

			setLoading(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
	};
	const handleEditPermissions = async (data: IPermissionsUserForm[]) => {
		setLoadingPermissions(true);
		setEditPermissionsFormData(null);

		try {
			const res: ResponseResult = await UpdateUserPermission(data);

			if (res.Success === 0) throw new Error(res.Message);

			setAlert({
				open: true,
				message: 'Permission Updated',
				type: 'success'
			});
			fetchPermissions();

			setLoadingPermissions(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoadingPermissions(false);
	};

	const handleOnDeleteUser = async () => {
		setLoading(true);

		try {
			const res: ResponseResult = await queryClient.fetchQuery(['DeleteAdminUser', { query: `?userId=${currentUser?.Id}` }], deleteAdminUser);

			if (res.Success === 0) throw new Error(res.Message);

			//setPermissions(res.Data);
			setAlert({
				open: true,
				message: 'User Deleted Successfully',
				type: 'success'
			});
			queryClient.refetchQueries(['users', { query: '' }]);
			setLoading(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	if (loading || loadingPermissions) return <ActionLoader position={'absolute'} />;
	return (
		<>
			<List headCells={UserCellsPermission} data={users} getBodyCells={getBodyCells} feature={feature} canSelect={false} canEdit={false} />

			{/* Edit User */}
			{showEditUser && (
				<Edituser open={showEditUser} onClose={handleClose} onChange={handleChangeFormEditUser} onSubmit={handleEditUser} onDelete={handleOnDeleteUser} loading={false} currentUserInput={editUserFormData!} />
			)}

			{/* Edit Permissions */}
			{showEditPermissions && permissions && (
				<EditUserPermissions
					open={showEditPermissions}
					currentUserName={`${currentUser?.FirstName} ${currentUser?.LastName}`}
					onClose={handleClose}
					onChange={handleChangeFormEditPermissionsUser}
					onSubmit={handleEditPermissions}
					loading={loadingPermissions}
					permissions={permissions!}
				/>
			)}
		</>
	);
};

export default ListUsers;
