import { CircularProgress, Container, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { InputTypes } from 'constants/enums';
import { IFormField } from 'models/app';
import React, { useCallback, useEffect, useState } from 'react';
import { IOption } from 'models/app';
import { Language, ResponseResult } from 'models/api';
import ActionLoader from 'components/UI/ActionLoader';
import { useQueryClient } from 'react-query';
import { getQuestionTypes } from 'framework/testBank';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { IQuestionAnswer, IQuestionInputItem, ITestQuestionType } from 'models/testBank';
import MuiButton from 'components/UI/MuiButton';
import InfoIcon from '@mui/icons-material/Info';
import CustomForm from 'components/UI/CustomForm';
import { RemoveIcon } from 'components/UI/SvgIcons';
import PrimeReactModal from 'components/UI/PrimeReactModal';
interface Props {
  open: boolean;
  addQuestionForm: IQuestionInputItem;
  onClose: () => void;
  onChange: (name: string, value: any) => void;
  onSubmit: (answers: IQuestionAnswer[]) => void;
}


let defaultAnswer: IQuestionAnswer = {
  QuestionId: 0,
  Name: '',
  IsCorrectOption: false,
  Points: 0,
  imagePath: null,
}

const AddQuestion: React.FC<Props> = ({ open, addQuestionForm, onClose, onChange, onSubmit }) => {
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [questionsTypes, setQuestionsTypes] = useState<ITestQuestionType[] | null>(null);
  const [answers, setAnswers] = useState<IQuestionAnswer[]>([defaultAnswer]);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const generateIcon = (iconName: String) => {
    switch (iconName) {
      case 'Single Choice':
        return <RadioButtonCheckedIcon />;
      case 'Multiple Choice':
        return <DomainVerificationIcon />;
      case 'Picture Choice':
        return <PhotoLibraryIcon />;
      default:
        return null;
    }
  };

  const questionTypeOptions: IOption[] = questionsTypes ? questionsTypes?.map((item: Language) => ({ label: item.Name, value: `${item.Id}`, icon: generateIcon(item.Name) })) : [];

  const fetchQuestionTypes = useCallback(async () => {
    setLoading(true);

    try {
      const response: ResponseResult = await queryClient.fetchQuery(['questionTypes'], getQuestionTypes);
      setQuestionsTypes(response.Data);
      setLoading(false);
    } catch (err: Error | any) {
      setError(true);
      setLoading(false);
    }

    // eslint-disable-next-line
  }, []);


  const handleChangeAnswers = (index: number, name: string, value: any) => {
    let _answers = [...answers];
    let _answer = { ..._answers[index] };
    _answer[name] = value;
    setAnswers((answers) => {
      answers[index] = _answer;
      return answers;
    });
  }

  const handleAddNewAnswer = (answer: IQuestionAnswer) => {
    setAnswers([...answers, answer]);
  }

  const handleRemoveAnswer = (index: number) => {
    // check answer is not the last one
    if (answers.length === 1) {
      return;
    }
    let _answers = [...answers];
    _answers.splice(index, 1);
    setAnswers(_answers);
  }

  let basicQuestionTypeFields: IFormField[] = [
    {
      name: 'QuestionTypeId',
      type: InputTypes.SELECT,
      options: questionTypeOptions,
      isHorizontal: true,
      placeholder: 'Select Question Type'
    }
  ];
  let pointField: IFormField[] = [
    {
      name: 'Points',
      type: InputTypes.NUMBER,
      isHorizontal: true,
      defaultValue: 0,
    }
  ];
  let basicDescriptionFields: IFormField[] = [
    {
      name: 'Description',
      type: InputTypes.EDITOR,
      isHorizontal: true,
      editorHeight: 300,
    }
  ];
  let basicIsRequiredFields: IFormField[] = [
    {
      name: 'IsRequired',
      label: 'Is Required',
      type: InputTypes.CHECKBOX,
      labelColor: "blue",
      checkBoxDirection: 'end'
    }
  ];
  let basicIsRandomizeOptionsFields: IFormField[] = [
    {
      name: 'RandomizeOptions',
      label: 'Randomize Options',
      type: InputTypes.CHECKBOX,
      labelColor: "blue",
      checkBoxDirection: "end"
    }
  ];
  let basicHasOptionPointsFields: IFormField[] = [
    {
      name: 'hasOptionPoints',
      type: InputTypes.SWITCH,
      defaultValue: false,
    }
  ];

  const getAnswersFields = (answer: IQuestionAnswer, index: number) => {
    let Name: IFormField[] = [{
      name: `Name`,
      type: InputTypes.TEXT,
      placeholder: 'Enter Answer',
      isHorizontal: true,

      hidden: addQuestionForm?.QuestionTypeId! === '3'
    }];
    let imagePath: IFormField[] = [{
      name: `imagePath`,
      type: InputTypes.MULTIPLE_FILES,
      limitFiles: 1,
      isHorizontal: true,
      hidden: !addQuestionForm?.QuestionTypeId || addQuestionForm?.QuestionTypeId! === '1' || addQuestionForm?.QuestionTypeId! === '2'
    }];
    let Points: IFormField[] = [{
      name: `Points`,
      type: InputTypes.TEXT,
      isHorizontal: false,
      placeholder: 'Enter Points',
      hidden: !addQuestionForm?.hasOptionPoints,
      customItem: <IconButton onClick={() => handleRemoveAnswer(index)}>
        <RemoveIcon />
      </IconButton>
    }];
    let IsCorrectOption: IFormField[] = [{
      name: `IsCorrectOption`,
      label: 'Is Correct',
      type: InputTypes.CHECKBOX,
      isHorizontal: true,
      hidden: addQuestionForm?.hasOptionPoints,

      customItem: <IconButton onClick={() => handleRemoveAnswer(index)}>
        <RemoveIcon />
      </IconButton>
    }];


    return {
      Name,
      imagePath,
      Points,
      IsCorrectOption
    };
  }



  useEffect(() => {
    fetchQuestionTypes();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!addQuestionForm?.QuestionTypeId || !addQuestionForm?.Description || !answers || answers.length === 0 || !answers[0].Name) setCanSubmit(false);
    else setCanSubmit(true);

    // eslint-disable-next-line
  }, [addQuestionForm, answers, answers[0].Name]);

  if (error)
    return (
      <Grid container justifyContent={'center'} alignItems={'center'} height={'9.50vh'}>
        <Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
          Something Wrong
        </Typography>
      </Grid>
    );

  if (loading) return <ActionLoader position={'absolute'} />;

  return (
    <PrimeReactModal
      open={open}
      onClose={onClose}
      title="New Question"
      children={
        <Container >

          <Grid container alignItems={'center'} spacing={2}>
            <Grid item lg={8} md={12}>
              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'20%'}>
                  Question Type:
                </Typography>
                <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ width: '80%' }}>
                  <CustomForm fields={basicQuestionTypeFields} onChange={onChange} />
                  <IconButton sx={{ width: '10px', height: '10px' }}>
                    <InfoIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </Grid>


            <Grid item lg={4} md={12}>
              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
                  Points:
                </Typography>
                <CustomForm fields={pointField} onChange={onChange} />
              </Stack>
            </Grid>

            <Grid item lg={12}>
              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'13%'}>
                  Question:
                </Typography>
                <CustomForm fields={basicDescriptionFields} onChange={onChange} width={'87%'} />
              </Stack>
            </Grid>

            <Grid item lg={12}>
              <Grid container direction={'row'} spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
                <CustomForm fields={basicIsRequiredFields} onChange={onChange} />
              </Grid>
            </Grid>


            <Grid item lg={12}>
              <Stack direction={'row'} spacing={2} alignItems={'start'}>
                <Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'15%'}>
                  Has Points:
                </Typography>
                <CustomForm isHorizontal={true} fields={basicHasOptionPointsFields} onChange={onChange} />
              </Stack>
              <Stack direction={'row'} spacing={2} alignItems={'start'}>
                <Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'13%'}>
                  Options:
                </Typography>

                <Stack spacing={2} alignItems={'center'} width={'90%'}>
                  {answers.map((answer, i) => (
                    <Grid container key={i} spacing={1}>
                      <Grid item xs={12} md={6}>
                        <CustomForm isHorizontal={true} fields={getAnswersFields(answer, i).Name}
                          onChange={(name: string, value: any) => handleChangeAnswers(i, name, value)}
                        />
                        <CustomForm isHorizontal={true} fields={getAnswersFields(answer, i).imagePath}
                          onChange={(name: string, value: any) => handleChangeAnswers(i, name, value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <CustomForm fields={getAnswersFields(answer, i).Points}
                          onChange={(name: string, value: any) => handleChangeAnswers(i, name, value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <CustomForm isHorizontal={true} fields={getAnswersFields(answer, i).IsCorrectOption}
                          onChange={(name: string, value: any) => handleChangeAnswers(i, name, value)}
                        />
                      </Grid>
                      <Divider sx={{ width: '100%' }} />
                    </Grid>
                  ))}
                  <Grid container alignItems={'center'} >
                    <Grid item lg={6} my={2}>
                      <Grid container direction={'row'} spacing={2} alignItems={'center'} justifyContent={'flex-start'}>
                        <MuiButton variant="contained" size="large"
                          sx={{
                            borderRadius: 1,
                            mx: 2
                          }}
                          onClick={() => handleAddNewAnswer(defaultAnswer)}>
                          Add Option
                        </MuiButton>
                      </Grid>
                    </Grid>

                    <Grid item lg={6}>
                      <Grid container direction={'row'} spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
                        <CustomForm fields={basicIsRandomizeOptionsFields} onChange={onChange} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Grid>

          </Grid>
        </Container>


      }
      actions={<>
        <MuiButton
          variant="outlined"
          size="medium"
          sx={{
            backgroundColor: '#F0F5FD',
            color: '#8B8BAE',
            fontWeight: 400,
            border: 'none',
            '&:hover': { border: 'none' }
          }}
          onClick={onClose}>
          Cancel
        </MuiButton>
        <MuiButton
          variant="outlined"
          size="medium"
          sx={{
            backgroundColor: '#0A94D1',
            color: '#fff',
            fontWeight: 400,
            border: 'none',
            '&:hover': { color: '#0A94D1', border: 'none' }
          }}
          disabled={loading || !canSubmit}
          onClick={() => onSubmit(answers)}>
          Add
          <CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
        </MuiButton>
      </>}
    />
  );
};

export default AddQuestion;
