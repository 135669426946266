import { Insight } from 'models/insight';
import { create } from 'zustand';

type State = {
	listing: Insight[];
	params: string;
};

type Actions = {
	setListing: (payload: Insight[]) => void;
	setParams: (payload: string) => void;
};

const useInsightsStore = create<State & Actions>((set) => ({
	listing: [],
	params: '',
	setListing: (payload: Insight[]) => set((state) => ({ ...state, listing: payload })),
	setParams: (payload: string) => set((state) => ({ ...state, params: payload }))
}));

export default useInsightsStore;
