import { useCallback, useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import Modal from '../../../../components/UI/DialogModal';
import { IFormField } from 'models/app';
import { InputTypes } from 'constants/enums';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MuiButton from 'components/UI/MuiButton';
import Form from 'components/UI/Form';
import TagsInput from 'components/UI/TagsInput';
import { useQueryClient } from 'react-query';
import { useAlert } from 'contexts/alertContext';
import { ResponseResult } from 'models/api';
import { OrganizationWithGroups } from 'models/organization';
import { getOrganizationsWithGroups } from 'framework/organizations';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
	open: boolean;
	onClose: () => void;
	onSubmit: (users: any) => void;
}

const AddUsers: React.FC<Props> = ({ open, onClose, onSubmit }) => {
	const [users, setUsers] = useState<any>([{}]);

	// eslint-disable-next-line
	const [method, setMethod] = useState('names-emails');
	const [addFormData, setAddFormData] = useState<any>({});
	const [selectedEmails, setSelectedEmails] = useState<string[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [organizationWithGroups, setOrganizationsWithGroups] = useState<OrganizationWithGroups[] | null>(null);
	const { setAlert } = useAlert();
	const queryClient = useQueryClient();



	const fetchOrganizationsWithGroups = useCallback(async () => {
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['getOrganizationsWithGroups', { query: '' }], getOrganizationsWithGroups);
			setOrganizationsWithGroups(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchOrganizationsWithGroups();
		// eslint-disable-next-line
	}, []);

	let userFields: IFormField[] = [
		{
			name: 'FirstName',
			type: InputTypes.TEXT,
			placeholder: 'First name'
		},
		{
			name: 'LastName',
			type: InputTypes.TEXT,
			placeholder: 'Last name'
		},
		{
			name: 'Email',
			type: InputTypes.EMAIL,
			placeholder: 'Email address',
			errorMessage: "Email is required!"
		}
	];
	let organizationFields: IFormField[] = [
		{
			name: 'OrgId',
			type: InputTypes.SELECT,
			placeholder: 'Select organization',
			options: organizationWithGroups?.map((item) => ({ label: item.UserOrganizationName, value: `${item.UserOrganizationId}` })) || [],
		}
	];
	let groupFields: IFormField[] = [
		{
			name: 'GroupId',
			type: InputTypes.SELECT,
			placeholder: 'Select group',
			disabled: addFormData?.OrgId ? false : true,
			options: organizationWithGroups?.find((item) => item.UserOrganizationId === +addFormData?.OrgId)?.Groups.map((item) => ({ label: item.GroupName, value: `${item.GroupId}` })) || []
		}
	];

	const handleChangeFormData = (name: string, value: any) => setAddFormData({ ...addFormData, [name]: value });
	const changeUserData = (name: string, value: any, index: number) => {
		users[index] = { ...users[index], [name]: value };
		setUsers([...users]);
	};
	const addUsers = () => {
		setUsers([...users, {}]);
	};



	const deleteEmail = (index: number) => {
		selectedEmails.splice(index, 1);
		setSelectedEmails([...selectedEmails]);
	};
	const addEmails = (emails: string) => {
		let emailsArray = emails.split(',');
		setSelectedEmails((prevSelectedEmails) => [...prevSelectedEmails, ...emailsArray]);
	};
	const addUser = async () => {
		let createInput: any = [];

		if (method === 'names-emails') {
			users.forEach((user: any) => {
				createInput.push({
					FirstName: user.FirstName,
					LastName: user.LastName,
					Email: user.Email,
					OrgId: addFormData.OrgId,
					GroupId: addFormData.GroupId
				});
			});
		}

		onSubmit(createInput);
		onClose();
	};

	const deleteUsers = (index: number) => {
		users.splice(index, 1);
		setUsers([...users]);
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth={'lg'}
			title="Add Users"
			actions={
				<MuiButton
					onClick={addUser}
					sx={{
						backgroundColor: '#1B6EE4', color: '#fff', paddingInline: 6, paddingBlock: 1, '&:hover': {
							color: '#1B6EE4',
						},
						"&:disabled": {
							color: '#bebfc1',
						}
					}}
					disabled={loading || (method === 'names-emails' && users.some((user: any) => !user.FirstName || !user.LastName || !user.Email)) || (method === 'emails-only' && selectedEmails.length === 0)}>
					Add Users
				</MuiButton>
			}>
			<Box sx={{ paddingBlock: 3, paddingInline: 1 }}>
				<Typography color={'#000'} mb={1}>
					Names and Email address :
				</Typography>
				{method === 'names-emails' &&
					users.map((user: any, index: number) => (
						<Stack direction={'row'} spacing={1} key={index} mb={3}>
							<Form isHorizontal={true} fields={userFields} onChange={(name, value) => changeUserData(name, value, index)} />
							<MuiButton sx={{ minHeight: '64px', color: '#1B6EE4', m: 0, alignItems: 'flex-start' }} onClick={addUsers}>
								<AddCircleOutlineIcon />
							</MuiButton>
							{index > 0 && (
								<MuiButton sx={{ minHeight: '64px', m: 0, alignItems: 'flex-start' }} onClick={() => deleteUsers(index)}>
									<CloseIcon />

								</MuiButton>
							)}
						</Stack>
					))}

				{ <TagsInput tags={selectedEmails} addTags={addEmails} placeholder="Enter user emails separated by commas" onDelete={deleteEmail} clearAll={() => setSelectedEmails([])} />}

				<Stack sx={{ width: '100%' }} spacing={2}>
					<Stack sx={{ width: '100%' }} direction={'row'} spacing={2}>
						<Typography sx={{ width: '15%' }}>Add to organization</Typography>
						<Box sx={{ width: '50%' }}>
							<Form fields={organizationFields} onChange={handleChangeFormData} />
						</Box>
					</Stack>
					<Stack sx={{ width: '100%' }} direction={'row'} spacing={2}>
						<Typography sx={{ width: '15%' }}>Add to group</Typography>
						<Box sx={{ width: '50%' }}>
							<Form fields={groupFields} onChange={handleChangeFormData} />
						</Box>
					</Stack>
				</Stack>
			</Box>
		</Modal>
	);
};

export default AddUsers;
