import { Stack, Typography } from '@mui/material';
import DialogModal from 'components/UI/DialogModal';
import MuiButton from 'components/UI/MuiButton';

import React from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  message: string;
  title: string;
};


const ConfirmDialog: React.FC<Props> = ({ open, message, title, onClose, onSubmit }) => {


  return (
    <DialogModal
      open={open}
      onClose={onClose}
      maxWidth="xl"
      title={title}
      children={
        <Stack sx={{ width: '30vw', pt: 3 }}>
          <Typography sx={{ textAlign: 'center' }}>
            {message}
          </Typography>
        </Stack>
      }
      actions={
        <>
          <MuiButton
            variant="outlined"
            size="medium"
            sx={{
              backgroundColor: '#F0F5FD',
              color: '#8B8BAE',
              fontWeight: 400,
              border: 'none',
              '&:hover': { border: 'none' }
            }}
            onClick={onClose}>
            Cancel
          </MuiButton>
          <MuiButton
            variant="outlined"
            size="medium"
            sx={{
              backgroundColor: '#F44335',
              color: '#fff',
              fontWeight: 400,
              border: 'none',
              '&:hover': { color: '#F44335', border: 'none' }
            }}
            onClick={onSubmit}>
            Confirm
          </MuiButton>
        </>
      }
    />
  );
};

export default ConfirmDialog;