import { IconButton, Stack, TableCell, Tooltip, Typography } from '@mui/material';
import List from 'components/UI/List';
import { Feature } from 'models/api';
import React, { useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { ErrorLogCells, IErrorLog } from 'framework/dataCenter';
import InfoIcon from '@mui/icons-material/Info';
import ErrorLogInfo from './ShowErrorLogInfo';

interface Props {
	errorLogs: IErrorLog[];
	feature: Feature;
}

interface TruncateProps {
	text: string;
	limit: number;
}

const truncateString = ({ text, limit }: TruncateProps): string => {
	if (text.length > limit) {
		return `${text.slice(0, limit - 3)}...`;
	} else {
		return text;
	}
};

const ListErrors: React.FC<Props> = ({ errorLogs, feature }) => {
	const [showOpenInfo, setShowOpenInfo] = useState<boolean>(false);
	const [currentErrorLog, setCurrentErrorLog] = useState<IErrorLog | null>(null);

	const getBodyCells = (row: IErrorLog) => {
		let cells = Object.keys(row).map((key: string, i: number) => {
			if (key === 'FirstName')
				return {
					id: 'FirstName',
					element: (
						<TableCell align={'left'} key={i}>
							<Stack direction={'row'} spacing={1}>
								<PersonIcon />
								<Typography variant="body2" fontWeight={500}>
									{row?.FirstName + ' ' + row.LastName}
								</Typography>
							</Stack>
						</TableCell>
					)
				};
			if (key === 'ApplicationName')
				return {
					id: 'ApplicationName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.ApplicationName}</Typography>
						</TableCell>
					)
				};
			if (key === 'Message')
				return {
					id: 'Message',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{truncateString({ text: row?.Message, limit: 50 })}</Typography>
						</TableCell>
					)
				};
			if (key === 'LogDate')
				return {
					id: 'LogDate',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">
								{new Date(row?.LogDate).toLocaleString('en-US', {
									year: '2-digit',
									month: '2-digit',
									day: '2-digit',
									hour: '2-digit',
									minute: '2-digit',
									hour12: true
								})}
							</Typography>
						</TableCell>
					)
				};

			if (key === 'StackTrace')
				return {
					id: 'StackTrace',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{truncateString({ text: row?.StackTrace, limit: 50 })}</Typography>
						</TableCell>
					)
				};
			if (key === 'Id')
				return {
					id: 'actions',
					element: (
						<TableCell align={'left'} key={i}>
							<Tooltip title={'More Info'} placement="top">
								<IconButton size="small" color="inherit" onClick={() => handleOpenInfo(row)}>
									<InfoIcon fontSize="small" />
								</IconButton>
							</Tooltip>
						</TableCell>
					)
				};
			else return <></>;
		});
		return cells;
	};

	const handleOpenInfo = (errorLog: IErrorLog) => {
		setShowOpenInfo(true);
		setCurrentErrorLog(errorLog);
	};
	const handleCloseInfo = () => {
		setShowOpenInfo(false);
		setCurrentErrorLog(null);
	};

	return (
		<>
			<List headCells={ErrorLogCells} data={errorLogs} getBodyCells={getBodyCells} feature={feature} canSelect={false} canEdit={false} />
			{showOpenInfo && <ErrorLogInfo open={showOpenInfo} onClose={handleCloseInfo} currentErrorLog={currentErrorLog!} />}
		</>
	);
};

export default ListErrors;
