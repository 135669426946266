import { Box, Grid, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { ResponseResult } from 'models/api';
import { useQuery } from 'react-query';
import React from 'react';
import { FiltersProps } from 'models/filters';
import { getCountries } from 'framework/organizations';
import { Country } from 'models/organization';

type Props = {
	onChange: (name: string, value: any) => void;
	filters: FiltersProps;
};

const CountriesFilter: React.FC<Props> = ({ onChange, filters }) => {
	const { data: result, isError, isLoading } = useQuery<ResponseResult>(['countries', { query: '' }], getCountries);

	if (isError)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);
	if (isLoading) return <ActionLoader position={'absolute'} />;

	let countries: Country[] = result?.Data || [];

	const handleChange = (id: number) => {
		let SelectedCountries = filters.countries ? filters.countries.split(',').map((item) => countries.find((country) => country.Id === parseInt(item))!) : [];
		const index = SelectedCountries.findIndex((item) => item.Id === id);
		if (index === -1) {
			SelectedCountries.push(countries.find((item) => item.Id === id)!);
		} else {
			SelectedCountries.splice(index, 1);
		}

		onChange('countries', SelectedCountries.map((item) => item.Id).join(','));
 	};

	return (
		<Stack spacing={1} useFlexGap sx={{
			maxHeight: '20vh',
			overflowY: 'auto'
		}}>
			<Typography variant="h6" component={'p'} fontFamily={'600'}>
				countries
			</Typography>
			<Stack>
				{countries.map((country: Country, index: number) => (
					<Stack direction={'row'} key={index} >
						<Box component={'input'} type='checkbox'
							checked={filters?.countries ?  filters.countries.split(',').some((item) => item === country.Id.toString()) : false}
 							onChange={() => handleChange(country.Id)}
							sx={{
								cursor: 'pointer',
								mx: 1
							}}
						/>
						<Typography variant='body2' >{country.Name}</Typography>
					</Stack>
				))}
			</Stack>
		</Stack>
	);
};

export default CountriesFilter;
