import { Settings } from "@mui/icons-material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Alert, Avatar, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import MenuItem from "components/MenuItem";
import ActionLoader from "components/UI/ActionLoader";
import { fetchServices } from "framework/services";
import { Feature } from "models/api";
import React from "react";
import { useQuery } from "react-query";


interface Props {
	setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

const DrawerMenu: React.FC<Props> = ({ setOpenDrawer }) => {
	const { data, isError, isLoading } = useQuery<Feature[]>("features", fetchServices);
	const [selectedFeature, setSelectedFeature] = React.useState<Feature | null>(null);

	let features: Feature[] | undefined = data;

	if (isError) return <Alert severity="error">An error occurred while fetching features.</Alert>;
	if (isLoading) return <ActionLoader position="fixed" />;

	const handleSelectUnLinkFeature = (feature: Feature) => setSelectedFeature(feature);

	return (
		<List>
			<ListItem disablePadding>
				<ListItemButton>
					<ListItemIcon>
						<Avatar alt="User Image" src={"https://images.unsplash.com/photo-1576558656222-ba66febe3dec?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"} sx={{ width: "24px", height: "24px" }} />
					</ListItemIcon>
					<ListItemText primary="Profile" />
				</ListItemButton>
			</ListItem>
			<ListItem disablePadding>
				<ListItemButton>
					<ListItemIcon>
						<NotificationsIcon sx={{ fill: "#757575" }} />
					</ListItemIcon>
					<ListItemText primary="Notifications" />
				</ListItemButton>
			</ListItem>
			<ListItem disablePadding>
				<ListItemButton>
					<ListItemIcon>
						<Settings sx={{ fill: "#757575" }} />
					</ListItemIcon>
					<ListItemText primary="Settings" />
				</ListItemButton>
			</ListItem>
			<Divider sx={{ mb: 1 }} />
			<Stack direction={'column'} spacing={2} >
				{(features && features.length > 0) && features.map((feature, index) =>
					<MenuItem
						key={index}
						feature={feature}
						color={"dark"}
						onSelectUnLinkFeature={handleSelectUnLinkFeature}
						hasChildren={feature.children.length > 0 ? true : false}
						selectedFeature={selectedFeature!}
						setOpenDrawer={setOpenDrawer}
					/>)}
			</Stack>
			{selectedFeature && <Stack direction={'column'} spacing={2} >
				{(selectedFeature.children.length > 0) && selectedFeature.children.map((feature, index) =>
					<MenuItem
						key={index}
						feature={feature}
						color={"dark"}
						onSelectUnLinkFeature={handleSelectUnLinkFeature}
						direction={'row'}
						spacing={1}
						borderBottom={'1px solid #E5E5E5'}
						pr={5}
						setOpenDrawer={setOpenDrawer}
					/>)}
			</Stack>}
 
		</List >
	);
}

export default DrawerMenu;