import { Stack } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Listing from 'Pages/Listing';
import SideMenu from 'components/SideMenu';
import DashboardView from 'Pages/Dashboard/DashboardView';

const Overview: React.FC = () => {

	

	return (
		<>
 			<Stack component="main" direction={'row'} bgcolor="background.default" width={'100dvw'} height={'100dvh'} useFlexGap>
				<Suspense fallback={<ActionLoader position="fixed" />}>
					<SideMenu />
					<Stack flexGrow={1} sx={{ overflowY: 'auto' }} useFlexGap>
						<Suspense fallback={<ActionLoader position="fixed" />}>
							<Routes>
								<Route path="/*" element={<DashboardView />} />
								<Route path=":slug/*" element={<Listing />} />
							</Routes>
						</Suspense>
					</Stack>
				</Suspense>
			</Stack>
		</>
	);
};

export default Overview;
