
import { GET_ADMIN_PROFILE, UPDATE_ADMIN_PROFILE } from "constants/api";
import axios from "../helpers/axiosInstance";
import { AdminProfileInput } from "models/user";


export async function getAdminProfile() {
  const response = await axios.get(GET_ADMIN_PROFILE);
  return response.data;
}


export async function updateAdminProfile(data: AdminProfileInput) {
  const response = await axios.post(UPDATE_ADMIN_PROFILE, data);
  return response.data;
}