import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import MuiOutlineButton from 'components/UI/MuiOutlineButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MuiButton from 'components/UI/MuiButton';
import { useMutation, useQueryClient } from 'react-query';
import { Feature } from 'models/api';
import ActionLoader from 'components/UI/ActionLoader';
import AddUsers from './partials/AddUsers';
import BulkUpload from 'Pages/UserManagement/partials/BulkUpload';
import OrganizationModal from './partials/OrganizationModal';
import ListUsers from './partials/ListUsers/ListUsers';
import { useAlert } from 'contexts/alertContext';
import { Organization } from 'models/organization';
import { DownloadUsersTemplate, addUsersAndGroups } from 'framework/users';
import { BASE_URL } from 'constants/api';
import { Download } from '@mui/icons-material';
import { BackOfficeHeader } from 'helpers/utils';
import { Permission } from 'models/permission';
import useApp from 'hooks/useApp';

type Props = {
	feature: Feature;
};

const UserManagement: React.FC<Props> = ({ feature }) => {
	const [openAddModal, setOpenAddModal] = useState(false);
	const [openUploadModal, setOpenUploadModal] = useState(false);
	const [openOrganizationModal, setOpenOrganizationModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedOrganization, setSelectedOrganization] = useState<Organization>({} as Organization);
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 5);

	const queryClient = useQueryClient();
	const { setAlert } = useAlert();

	const { push } = useApp();


 
	useEffect(() => {
		if (!currentPagePermissions?.HasReadPermission) {
			push('/404');
		}
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [currentPagePermissions]);
	const addUsersAndGroupsMutation = useMutation({
		mutationFn: (createInput: any) => addUsersAndGroups(createInput),
		onSuccess: () => {
			queryClient.invalidateQueries('users');
		}
	});

	const importUserTemplateMutation = useMutation({
		mutationFn: () => {
			return DownloadUsersTemplate();
		}
	});

	const handleAddUsers = async (users: any) => {
		setLoading(true);
		try {
			let res: any = await addUsersAndGroupsMutation.mutateAsync(users);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Users added successfully',
				type: 'success'
			});
			setLoading(false);
			queryClient.refetchQueries(['users', { query: '' }]);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleGenerateUserTemplate = async () => {
		try {
			let res = await importUserTemplateMutation.mutateAsync();
			if (!res.Data || res.Success === 0) throw new Error('Failed to generate template');
			else {
				window.open(res.Data.replace('http://localhost:3000', BASE_URL).replace('http://scofolio.tridmark.com', BASE_URL));
			}
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};

	return (
		<Box m={5}>
			{loading && <ActionLoader position={'absolute'} />}
			<BackOfficeHeader title="Users, Organizations and Groups" />

			<Grid container justifyContent={'flex-end'} sx={{ p: 2, columnGap: 2 }}>
				<MuiButton variant="contained" size="large" onClick={() => setOpenOrganizationModal(true)}>
					Organizations & Groups
				</MuiButton>
				{!!currentPagePermissions?.HasModifyPermission && (
					<>
						<MuiButton
							sx={{
								color: '#fff',
								backgroundImage: 'linear-gradient(to right, #F95419, #E9230B 50%)'
							}}
							variant="contained"
							size="large"
							onClick={() => setOpenAddModal(true)}
							startIcon={
								<AddCircleIcon
									sx={{
										width: 30,
										height: 30
									}}
								/>
							}>
							Manually Add Users
						</MuiButton>
						<MuiButton
							variant="contained"
							size="large"
							sx={{
								backgroundColor: '#0A94D1',
								color: '#fff',
								fontWeight: 500,
								border: 'none',
								maxWidth: 200,
								fontSize: 12
							}}
							startIcon={<Download />}
							onClick={handleGenerateUserTemplate}>
							Download Template
						</MuiButton>
						<MuiOutlineButton
							sx={{
								color: '#fff',
								backgroundImage: 'linear-gradient(to right, #F95419, #E9230B 50%)'
							}}
							variant="contained"
							size="large"
							onClick={() => setOpenUploadModal(true)}
							startIcon={
								<AddCircleIcon
									sx={{
										width: 30,
										height: 30
									}}
								/>
							}>
							Bulk Upload
						</MuiOutlineButton>
					</>
				)}
			</Grid>
			<ListUsers feature={feature} />
			{openAddModal && <AddUsers onSubmit={handleAddUsers} open={openAddModal} onClose={() => setOpenAddModal(false)} />}
			{openUploadModal && <BulkUpload open={openUploadModal} onClose={() => setOpenUploadModal(false)} />}
			{openOrganizationModal && (
				<OrganizationModal
					open={openOrganizationModal}
					onClose={() => setOpenOrganizationModal(false)}
					feature={feature}
					onSelectedOrganization={setSelectedOrganization}
					selectedOrganization={selectedOrganization}
				/>
			)}
			{/* {openEditModal && <EditUserAccount onChange={handleChangeEditUserFormData} CurrentUser={currentUser!} open={openEditModal} onClose={() => setOpenEditModal(false)} />} */}
		</Box>
	);
};

export default UserManagement;
