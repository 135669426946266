import { DELETE_BACKLOG, GET_BACKLOG, UPDATE_BACKLOG } from 'constants/api';
import axios from '../helpers/axiosInstance';

export async function getBackLog({ queryKey }: any) {
	const { query } = queryKey[1];

	const response = await axios.get(GET_BACKLOG + query);
	return response.data;
}

export async function deleteBacklog({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.post(DELETE_BACKLOG + query);
	return response.data;
}

export async function updateBacklog({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.post(UPDATE_BACKLOG + query);
	return response.data;
}
