import { CircularProgress, Container } from '@mui/material';
import React, { useState } from 'react';
import { IFormField } from 'models/app';
import { InputTypes } from 'constants/enums';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import AddPillar from '../AddPillar/PillarForm';
import PrimeReactModal from 'components/UI/PrimeReactModal';
import { CompetencyInput } from 'models/Competency';
import { IPillar } from 'models/pillar';

interface Props {
	open: boolean;
	addCompetencyForm: CompetencyInput | null;
	pillars: IPillar[] | null;
	loading: boolean;
	onClose: () => void;
	onSubmit: () => void;
	onChange: (name: string, value: any) => void;
	onAddPillar: () => void;
	onChangeAddPillarForm: (name: string, value: any) => void;
}


const AddCompetency: React.FC<Props> = ({ open, addCompetencyForm, pillars, loading, onChange, onClose, onSubmit, onAddPillar, onChangeAddPillarForm }) => {

	const [openAddPillar, setOpenAddPillar] = useState<boolean>(false);


	const handleOpenAddPillar = () => setOpenAddPillar(true);
	const handleCloseAddPillar = () => setOpenAddPillar(false);


	const basicFields: IFormField[] = [
		{
			name: 'Name',
			label: 'Name',
			type: InputTypes.TEXT,
			placeholder: 'Enter Name',
			helperText: 'The name how it appears in your site.',
		},
		{
			name: 'Slug',
			label: 'Slug',
			type: InputTypes.TEXT,
			placeholder: 'Enter Slug',
			helperText: 'The “slug” is the URL-friendly version of the name. It is usually all lowercase and contains only letters, numbers, and hyphens.',
		},
		{
			name: 'numIndicators',
			type: InputTypes.NUMBER,
			label: 'Indicators Count',
			placeholder: '0',
			helperText: 'Specify the number of indicators for each competency. The system will automatically order them alphabetically, for example, A, B, C.',
		},
		{
			name: 'ParentPillarId',
			label: 'Parent Pillar',
			type: InputTypes.SELECT,
			placeholder: 'Select Pillar',
			options: pillars?.map((pillar) => ({ label: pillar.Name, value: `${pillar.Id}` })) || [],
			bulkAction: true,
			bulkActionLabel: 'Add',
			bulkActionFn: () => handleOpenAddPillar(),
		},
		{
			name: 'Description',
			type: InputTypes.EDITOR,
			label: 'Competencies Description',
		},
		{
			name: 'IncompleteBadgeIconPath',
			label: 'Incomplete',
			type: InputTypes.MULTIPLE_FILES,
			limitFiles: 1,
		},
		{
			name: 'EmergingPotentialBadgeIconPath',
			label: 'Emerging Potential',
			type: InputTypes.MULTIPLE_FILES,
			limitFiles: 1,
		},
		{
			name: 'EstablishedPotentialBadgeIconPath',
			label: 'Established Potential',
			type: InputTypes.MULTIPLE_FILES,
			limitFiles: 1,
		}
	];


	return (
		<PrimeReactModal
			open={open}
			onClose={onClose}
			title="New Competency"
			children={
				<Container maxWidth={'md'} sx={{ py: 2, pb: 4 }} >
					<Form fields={basicFields} onChange={onChange} />
					<AddPillar open={openAddPillar} onClose={handleCloseAddPillar} onSubmit={onAddPillar} onChange={onChangeAddPillarForm} />
				</Container>
			}
			actions={
				<>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#F0F5FD',
							color: '#8B8BAE',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onClose}>
						Cancel
					</MuiButton>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#0A94D1',
							color: '#fff',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						disabled={loading || !addCompetencyForm?.Name}
						onClick={onSubmit}>
						Add
						<CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
					</MuiButton>
				</>
			}
		/>

	);
};

export default AddCompetency;
