import { Box, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import MuiButton from 'components/UI/MuiButton';
import { FiltersProps } from 'models/filters';
import useUserManagementStore from 'store/userManagement';
import OrganizationFilters2 from '../GlobalFilters/OrganizationFilters2';
import LanguageFilters3 from '../GlobalFilters/LanguageFilters3';
import CountriesFilter from '../GlobalFilters/CountriesFilter';

type Props = {
	handleFiltersClose: () => void;
	setSelectedFilters?: (data: FiltersProps) => void;
	selectedFilters?: FiltersProps;
};

const UserManagementFilters: React.FC<Props> = ({ handleFiltersClose, setSelectedFilters, selectedFilters }) => {
 	const setParams = useUserManagementStore((state) => state.setParams);
 	const selectedParams = useUserManagementStore((state) => state.params);

	const [filters, setFilters] = useState<FiltersProps>({		 
		groups: selectedFilters?.userGroupIds || null,
		organisations: selectedFilters?.organisations || null, 
		languages: selectedFilters?.languages || null, 
		countries: selectedFilters?.countries || null, 
		searchText: selectedFilters?.searchText || null
	});

	const getQueryParams = () => {
		const params = [];

		for (const filter in filters) {
			if (filters.hasOwnProperty(filter)) {
				const value = filters[filter as keyof FiltersProps];
				if(!value) continue;
				const encodedValue = value ? encodeURIComponent(value) : 'null';
				params.push(`${filter}=${encodedValue}`);
			}
		}

		return params.join('&');
	};

	const handleChange = (name: string, value: any) => {
		setFilters((prev) => ({ ...prev, [name]: value }));
		// remove null values and update selected filters
		const _selectedFilters: FiltersProps = Object.fromEntries(Object.entries({ ...filters, [name]: value }).filter(([_, v]) => v));
 		setSelectedFilters && setSelectedFilters(_selectedFilters);
	};
	const handleChangeMultiple = (data:{ name: string, value: any }[]) => {
		setFilters((prev) => ({ ...prev, ...Object.fromEntries(data.map((item) => [item.name, item.value])) }));
		// remove null values and update selected filters
		const _selectedFilters: FiltersProps = Object.fromEntries(Object.entries({ ...filters, ...Object.fromEntries(data.map((item) => [item.name, item.value])) }).filter(([_, v]) => v));
		setSelectedFilters && setSelectedFilters(_selectedFilters);
	};

	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		let query: string = "";
		let existParams = selectedParams.split('?');
		if (existParams.length > 0 && selectedParams !== '') {
			let queryParams = existParams[1].split('&');
			queryParams = queryParams.filter((item) => item.includes('searchText') || item.includes('fromDate') || item.includes('toDate'));
			query = `?${queryParams.join('&') + '&' + getQueryParams()}`;
		}
		else query = `?${getQueryParams()}`;
		// setSearchParams({ query });
		setParams(query);
 		// queryClient.invalidateQueries('showcases');
		handleFiltersClose();
	};

	return (
		<Box component={'form'} onSubmit={onSubmit} p={2} minWidth={'30dvw'}>
			<Stack spacing={4} useFlexGap>			 
				<LanguageFilters3 onChange={handleChange} filters={filters} />
				<CountriesFilter onChange={handleChange} filters={filters} />
				<OrganizationFilters2 handleChangeMultiple={handleChangeMultiple} onChange={handleChange} filters={filters} />
				<Grid container justifyContent={'center'}>
					<MuiButton type="submit" variant="contained" size="large">
						Search
					</MuiButton>
				</Grid>
			</Stack>
		</Box>
	);
};

export default UserManagementFilters;
