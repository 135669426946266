import { GET_INSIGHTS } from 'constants/api';
import axios from '../helpers/axiosInstance';
import { HeadCell } from 'models/app';

export async function getInsights({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_INSIGHTS + _params.query);
	return response.data;
}

export const InsightsCells: HeadCell[] = [
	{
		id: 'EnteredSentence',
		numeric: false,
		disablePadding: false,
		label: 'Interaction Level'
	},
	{
		id: 'FirstName',
		numeric: false,
		disablePadding: false,
		label: 'Username'
	},
	{
		id: 'TopicName',
		numeric: false,
		disablePadding: false,
		label: 'Topic'
	},
	{
		id: 'OrganisationName',
		numeric: false,
		disablePadding: false,
		label: 'Organization'
	},
	{
		id: 'AutomatedCompetencyName',
		numeric: false,
		disablePadding: false,
		label: 'Automated Score'
	},
	{
		id: 'InteractionStatusName',
		numeric: false,
		disablePadding: false,
		label: 'Review Status'
	},
	{
		id: 'UpdatedCompetencyName',
		numeric: false,
		disablePadding: false,
		label: 'Review Matching'
	},
	{
		id: 'ReviewerFirstName',
		numeric: false,
		disablePadding: false,
		label: 'Reviewer'
	},
	{
		id: 'RelevanceVoteName',
		numeric: false,
		disablePadding: false,
		label: 'RelevanceVotes'
	},
	{
		id: 'ShowCaseId',
		numeric: true,
		disablePadding: false,
		label: 'ShowCase Id'
	},
	{
		id: 'UpdatedOn',
		numeric: true,
		isDate: true,
		disablePadding: false,
		label: 'Last Updated'
	}
];
