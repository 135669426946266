import { Box, Grid, Skeleton, Stack } from '@mui/material';
import CustomForm from 'components/UI/CustomForm';
import MuiButton from 'components/UI/MuiButton';
import { InputTypes } from 'constants/enums';
import { useAlert } from 'contexts/alertContext';
import { getTestStatuses } from 'framework/testBank';
import { ResponseResult } from 'models/api';
import { IFormField } from 'models/app';
import { ITestFullInfo } from 'models/testBank';
import React, { useCallback } from 'react';
import { useQueryClient } from 'react-query';


interface Props {
  testFullInfo: ITestFullInfo;
  onSubmit: () => void;
  onChange: (name: string, value: string) => void;
}


const SaveTestChanges: React.FC<Props> = ({ testFullInfo, onSubmit, onChange }) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = React.useState(false);
  const [Statuses, setCompetencies] = React.useState<any[]>([]);
  const { setAlert } = useAlert();
 
  const fetchStatuses = useCallback(async () => {
    setLoading(true);

    try {
      const response: ResponseResult = await queryClient.fetchQuery(['TestStatuses', { query: '' }], getTestStatuses);
      setCompetencies(response.Data);
      setLoading(false);
    } catch (err: Error | any) {
      setAlert({
        open: true,
        message: err?.response?.data?.Message || err.message || 'Something went wrong',
        type: 'error'
      })
      setLoading(false);
    }

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    fetchStatuses();
    // eslint-disable-next-line
  }, []);

  if(loading) return <Skeleton variant="rectangular" width={'100%'} height={'100%'}  animation="wave" />

  let basicStatusFields: IFormField[] = [     
    {
      name: 'StatusId',
      type: InputTypes.SELECT,
      placeholder:  'Select Passing Grade',
      options: Statuses.map((status: any) => ({
        value: status.Id,
        label: status.Name
      })),
      defaultValue: {
        value: `${testFullInfo.StatusId}`,
        label: Statuses.find((status: any) => status.Id === testFullInfo.StatusId)?.Name
      }
     },
  ];

  return (
    <Stack direction={'column'} sx={{ width: '70%', margin: '0 auto' }} justifyContent={'center'}>
      <Box
        sx={{
          bgcolor: 'inherit'
        }}>
        <Grid container sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '40px' }}>
          <Grid container sx={{ fontWeight: 700 }}>
            Test Status
          </Grid>
          <Grid container gap={2}>
              <CustomForm fields={basicStatusFields} onChange={onChange} width={'80%'} />            
            <Grid container>
              <MuiButton
                variant="contained"
                sx={{ borderRadius: 1, backgroundColor: '#0078B4', '&:hover': { color: '#0078B4', backgroundColor: 'inherit' } }}
                onClick={onSubmit}>
                Save
              </MuiButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default SaveTestChanges;