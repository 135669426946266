import { ITicket } from 'framework/support';
import { create } from 'zustand';

type State = {
 
	params: string;
	listing: ITicket[];
};

type Actions = {
 
	setListing: (payload: ITicket[]) => void;
	setParams: (payload: string) => void;

};

const useSupportStore = create<State & Actions>((set) => ({
 
	params: '',
	listing: [],
 
	setListing: (payload) => set(() => ({ listing: payload })),
	setParams: (payload) => set(() => ({ params: payload })),
}));
 
export default useSupportStore;
