import { CircularProgress, Stack, Typography } from '@mui/material';
import DialogModal from 'components/UI/DialogModal';
import MuiButton from 'components/UI/MuiButton';

import { ICategoryWithTest } from 'models/testBank';
import React from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  loading: boolean;
  currentCategory: ICategoryWithTest
};


const DeleteCategory: React.FC<Props> = ({ open, loading, currentCategory, onClose, onSubmit }) => {


  return (
    <DialogModal
      open={open}
      onClose={onClose}
      maxWidth="xl"
      title="Delete Category"
      children={
        <Stack sx={{ width: '30vw', pt: 3 }}>
          <Typography sx={{ textAlign: 'center' }}>
            Are You Sure You Want To Delete <span style={{ fontWeight: 700 }}>{`${currentCategory?.CategoryName}`}</span> ?
          </Typography>
        </Stack>
      }
      actions={
        <>
          <MuiButton
            variant="outlined"
            size="medium"
            sx={{
              backgroundColor: '#F0F5FD',
              color: '#8B8BAE',
              fontWeight: 400,
              border: 'none',
              '&:hover': { border: 'none' }
            }}
            onClick={onClose}>
            Cancel
          </MuiButton>
          <MuiButton
            variant="outlined"
            size="medium"
            sx={{
              backgroundColor: '#F44335',
              color: '#fff',
              fontWeight: 400,
              border: 'none',
              '&:hover': { color: '#F44335', border: 'none' }
            }}
            disabled={loading}
            onClick={onSubmit}>
            Delete
            <CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
          </MuiButton>
        </>
      }
    />
  );
};

export default DeleteCategory;