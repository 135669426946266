import { Box, Stack, Typography } from '@mui/material';
import { getOrganizationsWithGroups } from 'framework/organizations';
import { ResponseResult } from 'models/api';
import { useQueryClient } from 'react-query';
import { OrganizationWithGroups } from 'models/organization';
import { FiltersProps } from 'models/filters';
import { useCallback, useEffect, useState } from 'react';
import { useAlert } from 'contexts/alertContext';

type Props = {
	handleChangeMultiple: (data: { name: string, value: any }[]) => void;
	onChange: (name: string, value: any) => void;
	filters: FiltersProps
};

const OrganizationFilters3: React.FC<Props> = ({ handleChangeMultiple, onChange, filters }) => {
	const [organizationsWithGroups, setOrganizationsWithGroups] = useState<OrganizationWithGroups[]>([]);
 	const { setAlert } = useAlert();

	let queryClient = useQueryClient();
	const fetchOrganizationsWithGroups = useCallback(async () => {
 
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['organizationsWithGroups', { query: '' }], getOrganizationsWithGroups);
			setOrganizationsWithGroups(response.Data);
 		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			})
 		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchOrganizationsWithGroups();

		// eslint-disable-next-line
	}, []);

	const handleChange = (id: number) => {
		let SelectedOrganizations: number[] = filters.organisations ? filters.organisations.split(',').map((item) => parseInt(item)) : [];
		const index = SelectedOrganizations.findIndex((item) => item === id);
		if (index === -1) {
			SelectedOrganizations.push(id);

		} else {
			SelectedOrganizations.splice(index, 1);
		}
		onChange('organisations', SelectedOrganizations.join(','));
	};



	return (
		<Stack spacing={1} useFlexGap sx={{
			maxHeight: '20vh',
			overflowY: 'auto'
		}}>
			<Typography variant="h6" component={'p'} fontFamily={'600'}>
				Organizations
			</Typography>
			<Box sx={{ minHeight: 180, flexGrow: 1, maxWidth: 300 }}>

				{organizationsWithGroups.map((org: OrganizationWithGroups, index: number) => (
					<Box
						key={index}>
						<Stack direction="row" alignItems="center" spacing={1}>
							<Box
								component={'input'}
								type="checkbox"
								checked={
									filters?.organisations
										? filters.organisations.split(',').some((item) => item === org.UserOrganizationId.toString())
										: false
								}
								onChange={() => handleChange(org.UserOrganizationId)}
								sx={{
									cursor: 'pointer',
									mx: 1
								}}
							/>
							<Typography variant="body1" component={'p'} fontFamily={'600'}>
								{org.UserOrganizationName}
							</Typography>
						</Stack>
					</Box>
				))}
			</Box>
		</Stack>
	);
};

export default OrganizationFilters3;
