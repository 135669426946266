import { Status } from "models/api";
import { ConfigureTypes, TestNavigation } from "models/app";

export let statuses: Status[] = [
  { Id: 1, Name: 'Active' },
  { Id: 2, Name: 'On Hold' },
];

export let testNav: TestNavigation[] = [
  { Id: 0, Name: 'Create' },
  { Id: 1, Name: 'Configure' },
  { Id: 2, Name: 'Save & Publish' }
];

export let configureType: ConfigureTypes[] = [
  { Id: 1, Name: 'General' },
  { Id: 2, Name: 'Grading' }
];
