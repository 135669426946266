import { IconButton, Stack, TableCell, Typography } from '@mui/material';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import React, { useState } from 'react';
import List from 'components/UI/List';
import { EditGroupForm, OrganizationCellsWithGroups } from 'framework/organizations';
import { Feature } from 'models/api';
import MuiButton from 'components/UI/MuiButton';
import { Group } from 'models/organization';
import DeleteDialog from 'Pages/ResponseForm/partials/DeleteDialog';
import EditGroup from './EditGroup';
import { Permission } from 'models/permission';

interface Props {
	Groups: Group[];
	feature: Feature;
	onEdit: (row: EditGroupForm) => void;
	onDelete: (row: Group) => void;
}

const ListGroups: React.FC<Props> = ({ Groups, feature, onDelete, onEdit }) => {
	const [showDeleteGroub, setShowDeleteGroub] = useState<boolean>(false);
	const [showEditGroup, setShowEditGroup] = useState<boolean>(false);
	const [currentGroup, setCurrentGroup] = useState<Group | null>(null);
	const [editFormData, setEditFormData] = useState<EditGroupForm>({} as EditGroupForm);
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 5);

	const handleChangeFormData = (name: string, value: any) => {
		setEditFormData({ ...editFormData, [name]: value });
	};

	const handleClose = () => {
		setShowDeleteGroub(false);
		setShowEditGroup(false);
		setCurrentGroup(null);
	};
	const handleShowEdit = (e: any, row: Group) => {
		e.stopPropagation();
		setShowEditGroup(true);
		setEditFormData({ GroupId: row.GroupId!, GroupDescription: row?.GroupDescription, GroupName: row.GroupName });
		//onEdit(row);
	};

	const handleShowDelete = (e: any, row: Group) => {
		e.stopPropagation();
		setShowDeleteGroub(true);
		setCurrentGroup(row);
		//onDelete(row);
	};

	const handleDelete = () => {
		onDelete(currentGroup!);
		handleClose();
	};

	const handleEdit = () => {
		onEdit(editFormData);
		handleClose();
	};

	const getBodyCells = (row: Group) => {
		let cells = Object.keys(row).map((key: string, i: number) => {
			if (key === 'GroupName')
				return {
					id: 'GroupName',
					element: (
						<TableCell align={'left'} key={i}>
							<MuiButton
								onClick={(e) => handleShowEdit(e, row)}
								variant={'text'}
								sx={{
									flexDirection: 'row',
									justifyContent: 'flex-start',
									alignItems: 'center',
									maxHeight: '40px'
								}}>
								<Stack spacing={1}>
									<Typography color={'#0A8CC6'} fontWeight={600}>
										{row?.GroupName}
									</Typography>
								</Stack>
							</MuiButton>
						</TableCell>
					)
				};

			if (key === 'GroupId')
				return {
					id: 'actions',
					element: (
						<TableCell align={'left'} key={i}>
							<Stack direction={'row'} alignItems={'center'} spacing={1} useFlexGap>
								{!!currentPagePermissions?.HasModifyPermission && (
									<IconButton size="small" color="inherit" onClick={(e) => handleShowEdit(e, row)}>
										<EditOutlined fontSize="small" />
									</IconButton>
								)}

								{!!currentPagePermissions?.HasDeletePermission && (
									<IconButton size="small" color="inherit" onClick={(e) => handleShowDelete(e, row)}>
										<DeleteOutline fontSize="small" />
									</IconButton>
								)}
							</Stack>
						</TableCell>
					)
				};
			else return <></>;
		});
		return cells;
	};

	return (
		<>
			<List headCells={OrganizationCellsWithGroups} data={Groups} getBodyCells={getBodyCells} feature={feature} canEdit={false} canSelect={false} />
			<DeleteDialog open={showDeleteGroub} loading={false} onClose={handleClose} onSubmit={handleDelete} title="Groub" description={currentGroup?.GroupName} />
			<EditGroup open={showEditGroup} onClose={handleClose} onChange={handleChangeFormData} onSubmit={handleEdit} currentEditForm={editFormData} />
		</>
	);
};

export default ListGroups;
