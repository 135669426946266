import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import { InputTypes } from 'constants/enums';
import { useAlert } from 'contexts/alertContext';
import { getPasswordPolicy, getSiteSettings, translateKeyManagmentField, updatePasswordPolicy, updateSiteSettings } from 'framework/settings';
import TranslateIcon from '@mui/icons-material/Translate';
import { BackOfficeHeader } from 'helpers/utils';
import { Feature, ResponseResult } from 'models/api';
import { IFormField } from 'models/app';
import { Permission } from 'models/permission';
import { IPasswordPolicy, ISiteSettings } from 'models/settings';

import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import TranslateField from './partials/TranslateField';
import { TranslateFieldFormKeyManagment } from 'models/Competency';
import { getPages } from 'framework/testBank';
import { IPage } from 'models/testBank';
import useApp from 'hooks/useApp';
interface Props {
	feature: Feature;
	onHideHeader?: (status: boolean) => void;
}

const KeyManagement: React.FC<Props> = ({ feature, onHideHeader }) => {
	const [siteSettings, setSiteSettings] = useState<ISiteSettings | null>(null);
	const [passwordPolicy, setPasswordPolicy] = useState<IPasswordPolicy | null>(null);
	const [loadingSiteSettings, setLoadingSiteSettings] = useState<boolean>(false);
	const [loadingPasswordPolicy, setLoadingPasswordPolicy] = useState<boolean>(false);
	const [showCurrentTranslateForm, setShowCurrentTranslateForm] = useState<boolean>(false);
	const [translateFieldForm, setTranslateFieldForm] = useState<TranslateFieldFormKeyManagment | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [pages, setPages] = React.useState<IPage[] | null>(null);

	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 9);

	const { setAlert } = useAlert();
	const { push } = useApp();

	const handleChangeSiteSettings = (name: string, value: any) => {
		setSiteSettings({ ...siteSettings!, [name]: value });
	};
 
	useEffect(() => {
		if (!currentPagePermissions?.HasReadPermission) {
			push('/404');
		}
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [currentPagePermissions]);

	
	const queryClient = useQueryClient();
	React.useEffect(() => {
		if (onHideHeader) onHideHeader(true);
		return () => {
			if (onHideHeader) onHideHeader(false);
		};
	}, [feature, onHideHeader]);

	useEffect(() => {
		if (siteSettings?.ForceOTPForOrganization) {
			handleChangeSiteSettings('AllowUsersToManageOTP', false);
		}
		// eslint-disable-next-line
	}, [siteSettings?.ForceOTPForOrganization]);

	const updatePasswordMutation = useMutation({
		mutationFn: (data: IPasswordPolicy) => {
			return updatePasswordPolicy(data);
		},
		onSuccess: () => {
			fetchPasswordPolicy();
		}
	});

	const updateSiteMutation = useMutation({
		mutationFn: (data: ISiteSettings) => {
			return updateSiteSettings(data);
		},
		onSuccess: () => {
			fetchSiteSettings();
		}
	});

	const fetchSiteSettings = useCallback(async () => {
		setLoadingSiteSettings(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['siteSettings'], getSiteSettings);
			setSiteSettings(response.Data);
			setLoadingSiteSettings(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoadingSiteSettings(false);
		// eslint-disable-next-line
	}, []);

	const fetchRedirectPages = useCallback(async () => {
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['pages', { query: '' }], getPages);
			setPages(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);

	const fetchPasswordPolicy = useCallback(async () => {
		setLoadingPasswordPolicy(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['passwordPolicy'], getPasswordPolicy);
			setPasswordPolicy(response.Data);
			setLoadingPasswordPolicy(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoadingPasswordPolicy(false);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchSiteSettings();
		fetchPasswordPolicy();
		fetchRedirectPages();
		// eslint-disable-next-line
	}, []);

	if (loadingPasswordPolicy || loadingSiteSettings || loading) return <ActionLoader position={'absolute'} />;

	

	const handleClose = () => {
		setShowCurrentTranslateForm(false);
		setTranslateFieldForm(null);
	};

	const handleChangePasswordPolicy = (name: string, value: any) => {
		setPasswordPolicy({ ...passwordPolicy!, [name]: value });
	};
	const handleOnSubmitSiteSettings = async () => {
		const createInput: ISiteSettings = {
			AboutGraph: siteSettings?.AboutGraph!,
			AboutScore: siteSettings?.AboutScore!,
			AdminEmailAddress: siteSettings?.AdminEmailAddress!,
			AllowOTPAuthentication: siteSettings?.AllowOTPAuthentication || false,
			AllowPublicRegistration: siteSettings?.AllowPublicRegistration || false,
			AllowSelfRegister: siteSettings?.AllowSelfRegister || false,
			AllowUsersToManageOTP: siteSettings?.AllowUsersToManageOTP || false,
			DefaultLandingScreen: siteSettings?.DefaultLandingScreen!,
			// EnableReferenceSentenceEdit: siteSettings?.EnableReferenceSentenceEdit || false,
			ForceOTPForOrganization: siteSettings?.ForceOTPForOrganization || false,
			PrivacyPolicy: siteSettings?.PrivacyPolicy!,
			TermsOfService: siteSettings?.TermsOfService!,
 		};
		setLoadingPasswordPolicy(true);

		try {
			const res = await updateSiteMutation.mutateAsync(createInput!);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully updated',
				type: 'success'
			});
			setLoadingPasswordPolicy(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoadingPasswordPolicy(false);
		}
	};

	const handleOnSubmitPasswordPolicy = async () => {
		const createInput: IPasswordPolicy = {
			MinimumLength: passwordPolicy?.MinimumLength!,
			PasswordExpiresIn: passwordPolicy?.PasswordExpiresIn!,
			RequireLowercase: passwordPolicy?.RequireLowercase || false,
			RequireNumber: passwordPolicy?.RequireNumber || false,
			RequireSpecialCharacter: passwordPolicy?.RequireSpecialCharacter || false,
			RequireUppercase: passwordPolicy?.RequireUppercase || false
		};
		setLoadingPasswordPolicy(true);

		try {
			const res = await updatePasswordMutation.mutateAsync(createInput!);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully updated',
				type: 'success'
			});
			setLoadingPasswordPolicy(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoadingPasswordPolicy(false);
		}
	};

	

	if(!siteSettings) return <ActionLoader position={'absolute'} />;

	const sectionStyle = {
		borderTop: '1px solid #ccc'
	};
	const labelStyle = {
		fontSize: '20px',
		fontWeight: 400,
		pl: 2
	};

	const handleOpenTranslateForm = (fieldName: string) => {
		setShowCurrentTranslateForm(true);
		setTranslateFieldForm({ fieldName: fieldName, Description: '', languageId: null });
	};

	const handleTranslateFormField = async () => {
		setLoading(true);

		try {
			const res: ResponseResult = await translateKeyManagmentField(translateFieldForm!);

			if (res.Success === 0) throw new Error(res.Message);

			setAlert({
				open: true,
				message: 'Field Translated Successfully',
				type: 'success'
			});
			queryClient.refetchQueries(['getProfileBuilder', { query: '' }]);
			handleClose();
			fetchSiteSettings();
			setLoading(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	const handleChangeTranslateKeyManagmentForm = (name: string, value: any) => setTranslateFieldForm({ ...translateFieldForm!, [name]: value });



	 
	let pagesOptions = pages
		? pages.map((page: IPage) => ({
			value: `${page.Id}`,
			label: page.PageTitle
		}))
		: [];

	let graphPopWindow: IFormField[] = [
		{
			name: 'AboutGraph',
			type: InputTypes.EDITOR,
			defaultValue: siteSettings?.AboutGraph,
			isHorizontal: true,
			editorHeight: 400
		}
	];



	let score: IFormField[] = [
		{
			name: 'AboutScore',
			type: InputTypes.EDITOR,
			defaultValue: siteSettings?.AboutScore,
			isHorizontal: true,
			editorHeight: 400
		}
	];

	let terms: IFormField[] = [
		{
			name: 'TermsOfService',
			type: InputTypes.EDITOR,
			defaultValue: siteSettings?.TermsOfService,
			isHorizontal: true,
			editorHeight: 400
		}
	];

	let email: IFormField[] = [
		{
			name: 'AdminEmailAddress',
			type: InputTypes.TEXT,
			defaultValue: siteSettings?.AdminEmailAddress,
			placeholder: 'Enter Email Address'
		}
	];

	let privacy: IFormField[] = [
		{
			name: 'PrivacyPolicy',
			type: InputTypes.EDITOR,
			defaultValue: siteSettings?.PrivacyPolicy,
			isHorizontal: true,
			editorHeight: 400
		}
	];

	// let editReference: IFormField[] = [
	// 	{
	// 		name: 'EnableReferenceSentenceEdit',
	// 		type: InputTypes.SWITCH,
	// 		defaultValue: siteSettings?.EnableReferenceSentenceEdit,
	// 		isHorizontal: true
	// 	}
	// ];
	let userSelfRegister: IFormField[] = [
		{
			name: 'AllowSelfRegister',
			type: InputTypes.SWITCH,
			defaultValue: siteSettings?.AllowSelfRegister,
			isHorizontal: true
		}
	];
	// let userRegister: IFormField[] = [
	// 	{
	// 		name: 'AllowPublicRegistration',
	// 		type: InputTypes.SWITCH,
	// 		defaultValue: siteSettings?.AllowPublicRegistration,
	// 		isHorizontal: true
	// 	}
	// ];
	let otp: IFormField[] = [
		{
			name: 'AllowOTPAuthentication',
			defaultValue: siteSettings?.AllowOTPAuthentication,
			type: InputTypes.SWITCH
		}
	];
	let otpPerUser: IFormField[] = [
		{
			name: 'AllowUsersToManageOTP',
			defaultValue: siteSettings?.AllowUsersToManageOTP,
			type: InputTypes.SWITCH,
			forceUnchecked: siteSettings?.ForceOTPForOrganization ? true : false,
			disabled: siteSettings?.ForceOTPForOrganization ? true:false
		}
	];
	let otpPerOrganization: IFormField[] = [
		{
			name: 'ForceOTPForOrganization',
			defaultValue: siteSettings?.ForceOTPForOrganization,
			type: InputTypes.SWITCH,
			
		}
	];

	let minimumPassword: IFormField[] = [
		{
			name: 'MinimumLength',
			defaultValue: passwordPolicy?.MinimumLength,
			type: InputTypes.NUMBER
		}
	];
	let atLeastOneLower: IFormField[] = [
		{
			name: 'RequireLowercase',
			type: InputTypes.CHECKBOX,
			defaultValue: passwordPolicy?.RequireLowercase,
			labelColor: 'blue',
			checkBoxDirection: 'end'
		}
	];
	let atLeastOneUpper: IFormField[] = [
		{
			name: 'RequireUppercase',
			type: InputTypes.CHECKBOX,
			defaultValue: passwordPolicy?.RequireUppercase,
			labelColor: 'blue',
			checkBoxDirection: 'end'
		}
	];
	let atLeastOneNumber: IFormField[] = [
		{
			name: 'RequireNumber',
			type: InputTypes.CHECKBOX,
			defaultValue: passwordPolicy?.RequireNumber,
			labelColor: 'blue',
			checkBoxDirection: 'end'
		}
	];
	let atLeastOneSpecial: IFormField[] = [
		{
			name: 'RequireSpecialCharacter',
			type: InputTypes.CHECKBOX,
			defaultValue: passwordPolicy?.RequireSpecialCharacter,
			labelColor: 'blue',
			checkBoxDirection: 'end'
		}
	];
	let daysBeforePassChange: IFormField[] = [
		{
			name: 'PasswordExpiresIn',
			defaultValue: passwordPolicy?.PasswordExpiresIn,
			type: InputTypes.NUMBER
		}
	];

	// let landingScreen: IFormField[] = [
	// 	{
	// 		name: 'DefaultLandingScreen',
	// 		defaultValue: siteSettings?.DefaultLandingScreen,
	// 		type: InputTypes.TEXT
	// 	}
	// ];
	let basicRedirectionPageFields: IFormField[] = [
		{
			name: 'DefaultLandingScreen',
			type: InputTypes.SELECT,
			options: pagesOptions ? pagesOptions : [],
			defaultValue: pagesOptions.find((item) => item.value === `${siteSettings?.DefaultLandingScreen}`),
			placeholder: 'https://www.example.com'
		}
	];

	return (
		<Stack spacing={4} useFlexGap m={5}>
			<BackOfficeHeader title="Key Management" />

			<Stack direction="column" spacing={4}>
				{/* Reference Sentences */}
				{/* <Grid container spacing={2} sx={sectionStyle}>
					<Grid item xs={12} md={2}>
						<Typography sx={labelStyle}>Reference Sentences</Typography>
					</Grid>
					<Grid item xs={12} md={8}>
						<Stack direction={'column'} spacing={2}>
							<Typography sx={{ fontSize: '16px', fontWeight: '300' }}>Enable Editing </Typography>
							<Typography sx={{ fontSize: '14px', fontWeight: '400' }}>This option allows users to edit the reference sentences that the editor suggests.</Typography>
							<Form fields={editReference} onChange={handleChangeSiteSettings} />
						</Stack>
					</Grid>
				</Grid> */}

				{/* Admin notification email */}
				<Grid container spacing={2} sx={sectionStyle}>
					<Grid item xs={12} md={2}>
						<Typography sx={labelStyle}>Admin Notification Email</Typography>
					</Grid>
					<Grid item xs={12} md={8}>
						<Stack direction={'column'} spacing={2}>
							<Typography>This is the email address that will receive notifications about the system status, user activity, and other important events.</Typography>
							<Box width={'20%'}>
								<Form fields={email} onChange={handleChangeSiteSettings} />
							</Box>
						</Stack>
					</Grid>
				</Grid>

				{/* Self-Registration */}
				<Grid container spacing={2} sx={sectionStyle}>
					<Grid item xs={12} md={2}>
						<Typography sx={labelStyle}>Self-Registration</Typography>
					</Grid>
					<Grid item xs={12} md={8}>
						<Stack direction={'column'} spacing={2}>
							<Typography sx={{ fontWeight: 300 }}>Allow users to self-register and/or allow non-admin users to invite other users. </Typography>
							<Typography>
								When disabled, new users will be prevented from self-registering for an account. This feature is applicable only when you have claimed one or more of domains, and it applies to users with email
								addresses from those domains. Before using this feature, make sure you have claimed one or more of domains.
							</Typography>
							<Form fields={userSelfRegister} onChange={handleChangeSiteSettings} />
							{/* <Typography sx={{ fontWeight: 300 }} pt={4}>
								Allow public users to register
							</Typography>
							<Typography>You can use this switch to activate the public usage of the platform. When it is on, anyone can sign up and use it.</Typography>
							<Form fields={userRegister} onChange={handleChangeSiteSettings} /> */}
						</Stack>
					</Grid>
				</Grid>

				{/* Authentication */}
				<Grid container spacing={2} sx={sectionStyle}>
					<Grid item xs={12} md={2}>
						<Typography sx={labelStyle}>Authentication</Typography>
					</Grid>
					<Grid item xs={12} md={8} spacing={2}>
						<Grid container spacing={2}>
							<Grid item xs={12} my={1}>
								<Typography sx={{ fontSize: '16px', fontWeight: 300 }}>Sign in with OTP</Typography>
							</Grid>

							<Grid item xs={1}>
								<Form fields={otp} onChange={handleChangeSiteSettings} />
							</Grid>
							<Grid item xs={11}>
								<Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Allow OTP Authentication </Typography>
								<Typography sx={{ fontSize: '14px', fontWeight: 500 }}>When enabled, all users can use a mobile phone with a OTP. When disabled, all users will require only passwords to authenticate. </Typography>
							</Grid>

							<Grid item xs={1}>
								<Form fields={otpPerUser} onChange={handleChangeSiteSettings} />
							</Grid>
							<Grid item xs={11}>
								<Typography sx={{ fontSize: '14px', color: 'rgba(0,0,0,0.4)' }}>Allow OTP per user </Typography>
								<Typography>When enabled, a user can activate or deactivate OTP via their profile page. </Typography>
							</Grid>
							<Grid item xs={1}>
								<Form fields={otpPerOrganization} onChange={handleChangeSiteSettings} />
							</Grid>
							<Grid item xs={11}>
								<Typography sx={{ fontSize: '14px', color: 'rgba(0,0,0,0.4)' }}>Allow mandatory OTP for organization </Typography>
								<Typography>When enabled, all users in organizations need to use OTP to access, and they won’t be able to modify the OTP option on their profile page. </Typography>
							</Grid>
						</Grid>
						<Grid container my={4} py={2} pl={2} spacing={1} sx={{ border: '1px solid rgba(204, 204, 204, 1)' }}>
							<Grid item xs={12} mx={2}>
								<Stack direction={'row'} justifyContent={'space-between'}>
									<Typography sx={{ fontSize: '16px' }}>Password policy</Typography>

									{!!currentPagePermissions?.HasModifyPermission && (
										<MuiButton variant="outlined" size="medium" color="primary" sx={{ borderRadius: 0, borderColor: 'white', '&:hover': {} }} disabled={false} onClick={handleOnSubmitPasswordPolicy}>
											Save Changes
										</MuiButton>
									)}
								</Stack>

								<Typography sx={{ fontSize: '14px', color: '#707070' }} py={2}>
									All new passwords must match the selected requirements.
								</Typography>
							</Grid>

							<Grid item xs={6}>
								<Typography sx={{ fontWeight: 500 }}>Minimum character length (8-256)</Typography>
							</Grid>
							<Grid item xs={6}>
								<Box width={'70%'}>
									<Form fields={minimumPassword} onChange={handleChangePasswordPolicy} />
								</Box>
							</Grid>

							<Grid item xs={6}>
								<Typography sx={{ fontWeight: 500 }}>Contains at least one lowercase letter</Typography>
							</Grid>
							<Grid item xs={6}>
								<Box sx={{ width: 'fit-content' }}>
									<Form fields={atLeastOneLower} onChange={handleChangePasswordPolicy} />
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Typography sx={{ fontWeight: 500 }}>Contains at least one uppercase letter</Typography>
							</Grid>
							<Grid item xs={6}>
								<Box sx={{ width: 'fit-content' }}>
									<Form fields={atLeastOneUpper} onChange={handleChangePasswordPolicy} />
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Typography sx={{ fontWeight: 500 }}>Contains at least one number</Typography>
							</Grid>
							<Grid item xs={6}>
								<Box sx={{ width: 'fit-content' }}>
									<Form fields={atLeastOneNumber} onChange={handleChangePasswordPolicy} />
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Typography sx={{ fontWeight: 500 }}>Contains at least one special character</Typography>
							</Grid>
							<Grid item xs={6}>
								<Box sx={{ width: 'fit-content' }}>
									<Form fields={atLeastOneSpecial} onChange={handleChangePasswordPolicy} />
								</Box>
							</Grid>
							<Grid item xs={6}>
								<Typography sx={{ fontWeight: 500 }}>Days between password changes (90-1825)</Typography>
							</Grid>
							<Grid item xs={6}>
								<Box width={'70%'}>
									<Form fields={daysBeforePassChange} onChange={handleChangePasswordPolicy} />
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				{/*  Landing Screen for Scofolio App */}
				<Grid container spacing={2} sx={sectionStyle}>
					<Grid item xs={12} md={2}>
						<Typography sx={labelStyle}>Default Landing Screen for Scofolio App</Typography>
					</Grid>
					<Grid item xs={12} md={8}>
						<Stack direction={'column'} spacing={2}>
							<Typography sx={{ fontSize: '16px', fontWeight: '300' }}>Choose landing screen</Typography>
							<Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
								Customize which tab your users see when first opening the Webex app. If the default isn't available, Webex will use the top tab as an alternative.
							</Typography>
							<Box width={'30%'}>
								<Form fields={basicRedirectionPageFields} onChange={handleChangeSiteSettings} />
							</Box>
						</Stack>
					</Grid>
				</Grid>

				{/* About Graph Pop Window */}
				<Grid container spacing={2} sx={sectionStyle}>
					<Grid item xs={12} md={2}>
						<Typography sx={labelStyle}>About Graph Pop Window</Typography>
					</Grid>
					<Grid item xs={12} md={8}>
						<Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
							<Form fields={graphPopWindow} onChange={handleChangeSiteSettings} />
							<IconButton size="small" color="inherit" onClick={() => handleOpenTranslateForm('AboutGraph')}>
								<TranslateIcon fontSize="small" />
							</IconButton>
						</Stack>
					</Grid>
				</Grid>

				{/* About Score */}
				<Grid container spacing={2} sx={sectionStyle}>
					<Grid item xs={12} md={2}>
						<Typography sx={labelStyle}>About Score</Typography>
					</Grid>
					<Grid item xs={12} md={8}>
						<Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
							<Form fields={score} onChange={handleChangeSiteSettings} />
							<IconButton size="small" color="inherit" onClick={() => handleOpenTranslateForm('AboutScore')}>
								<TranslateIcon fontSize="small" />
							</IconButton>
						</Stack>
					</Grid>
				</Grid>

				{/* Privacy & Terms Of Service */}
				<Grid container spacing={2} sx={sectionStyle}>
					<Grid item xs={12} md={2}>
						<Typography sx={labelStyle}>Privacy & Terms Of Service</Typography>
					</Grid>
					<Grid item xs={12} md={8}>
						<Stack direction={'column'} spacing={2}>
							<Typography sx={{ fontSize: '16px', fontWeight: 400, pt: 2 }}>Privacy Policy</Typography>
							<Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
								<Form fields={privacy} onChange={handleChangeSiteSettings} />
								<IconButton size="small" color="inherit" onClick={() => handleOpenTranslateForm('PrivacyPolicy')}>
									<TranslateIcon fontSize="small" />
								</IconButton>
							</Stack>

							<Typography sx={{ fontSize: '16px', fontWeight: 400, pt: 2 }}>Terms of Service</Typography>
							<Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
								<Form fields={terms} onChange={handleChangeSiteSettings} />
								<IconButton size="small" color="inherit" onClick={() => handleOpenTranslateForm('TermsOfService')}>
									<TranslateIcon fontSize="small" />
								</IconButton>
							</Stack>
						</Stack>
					</Grid>
				</Grid>

				{/* Buttons */}
				<Grid container>
					<Grid item xs={12} md={10}>
						<Stack direction={'row'} my={5} gap={2} justifyContent={'end'}>
							{!!currentPagePermissions?.HasModifyPermission && (
								<MuiButton variant="outlined" size="medium" color="primary" sx={{ borderRadius: 0, borderColor: 'white', '&:hover': {} }} disabled={false} onClick={handleOnSubmitSiteSettings}>
									Apply Changes
								</MuiButton>
							)}
						</Stack>
					</Grid>
				</Grid>
			</Stack>
			{showCurrentTranslateForm && (
				<TranslateField translateForm={translateFieldForm!} onChange={handleChangeTranslateKeyManagmentForm} open={showCurrentTranslateForm} onClose={handleClose} onSubmit={handleTranslateFormField} />
			)}
		</Stack>
	);
};

export default KeyManagement;
