import { Box, Grid, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { useQuery } from 'react-query';
import React from 'react';
import { FiltersProps } from 'models/filters';
import { getReviewers } from 'framework/Reviewers';
import { ResponseResult, Reviewer } from 'models/api';

type Props = {
	onChange: (name: string, value: any) => void;
	filters: FiltersProps;
};

const ReviewerFilters: React.FC<Props> = ({ onChange, filters }) => {
	const { data: result, isError, isLoading } = useQuery<ResponseResult>(['reviewers', { query: '' }], getReviewers);

	if (isError)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);
	if (isLoading) return <ActionLoader position={'absolute'} />;

	let reviewers: Reviewer[] = result?.Data || [];

	const handleChange = (id: number) => {
		if (filters.reviewerId === id) {
			onChange('reviewerId', null);
			return;
		}
		onChange('reviewerId', id);

	};

	return (
		<Stack spacing={1} useFlexGap sx={{
			maxHeight: '20vh',
			overflowY: 'auto'
		}}>
			<Typography variant="h6" component={'p'} fontFamily={'600'}>
				Reviewer
			</Typography>
			<Stack>
				{reviewers.map((reviewer: Reviewer, index: number) => (
					<Stack direction={'row'} key={index} >
						<Box component={'input'} type='checkbox'
							checked={filters?.reviewerId ? filters.reviewerId === reviewer.Id : false}
							disabled={filters?.reviewerId ? filters.reviewerId !== reviewer.Id : false}
							onChange={() => handleChange(reviewer.Id)}
							sx={{
								cursor: 'pointer',
								mx: 1
							}}
						/>
						<Typography variant='body2' >{reviewer.Name}</Typography>
					</Stack>
				))}
			</Stack>
		</Stack>
	);
};

export default ReviewerFilters;
