import React, { useEffect, useState } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TextField from '@mui/material/TextField';
import { Button, IconButton, Stack } from '@mui/material';
import DeleteDialog from './DeleteDialog';
import { DeleteIcon } from 'components/UI/SvgIcons';
import { Permission } from 'models/permission';

interface FormField {
	Id: number;
	ResponseReferenceSentenceSetId: number;
	ReferenceSentence: string;
	SortOrder: number;
	IsDeleted: boolean;
}

interface DraggableFormProps {
	fields: FormField[];
	onDelete: (id: number) => void;
	onFieldSubmit: (field: { Id: number; ReferenceSentence: string }) => void;
	onSortUpdate: (drag: number, drop: number) => void;
}

const DraggableTextField: React.FC<{
	field: FormField;
	index: number;
	onDelete: () => void;
	onFieldSubmit: (field: { Id: number; ReferenceSentence: string }) => void;
	onSortUpdate: (drag: number, drop: number) => void;
}> = ({ field, index, onDelete, onFieldSubmit, onSortUpdate }) => {
	let permissionsLocalStorage: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissionsLocalStorage?.find((item) => item.PermissionId === 11);

	const [, ref] = useDrop({
		accept: 'FIELD',
		drop: (item: { index: number }) => {
			if (item.index !== index) {
				// onFieldSubmit({ Id: field.Id, ReferenceSentence: field.ReferenceSentence, SortOrder: item.index });
				// item.index = index;
				onSortUpdate(item.index, index);
			}
		}
	});

	const [{ isDragging }, drag] = useDrag({
		type: 'FIELD',
		item: { index },
		collect: (monitor) => ({
			isDragging: monitor.isDragging()
		})
	});

	const [openDialog, setOpenDialog] = useState(false);
	const [editedReference, setEditedReference] = useState(field.ReferenceSentence);

	const handleDeleteClick = () => {
		setOpenDialog(true);
	};

	const handleDeleteConfirm = () => {
		setOpenDialog(false);
		onDelete();
	};

	const handleDeleteCancel = () => {
		setOpenDialog(false);
	};

	const handleFieldSubmit = () => {
		onFieldSubmit({ Id: field.Id, ReferenceSentence: editedReference });
	};

	useEffect(() => {
		setEditedReference(field.ReferenceSentence);
	}, [field]);

	return (
		<div
			ref={(node) => ref(node)}
			style={{
				opacity: isDragging ? 0.5 : 1,
				cursor: 'move',
				marginBottom: 8,
				display: 'flex',
				alignItems: 'center'
			}}>
			<TextField fullWidth variant="outlined" value={editedReference} inputRef={drag} style={{ marginRight: '8px' }} onChange={(e) => setEditedReference(e.target.value)} />
			<Stack direction="row" spacing={2}>
				{!!currentPagePermissions?.HasDeletePermission && (
					<IconButton onClick={handleDeleteClick}>
						<DeleteIcon />
					</IconButton>
				)}

				{!!currentPagePermissions?.HasModifyPermission && (
					<Button variant="outlined" color="primary" onClick={handleFieldSubmit}>
						Submit
					</Button>
				)}
			</Stack>

			{/* Delete Confirmation Dialog */}
			<DeleteDialog open={openDialog} onClose={handleDeleteCancel} onSubmit={handleDeleteConfirm} loading={false} title="Delete Refrence Set" description={field.ReferenceSentence} />
		</div>
	);
};

const DraggableForm: React.FC<DraggableFormProps> = ({ fields, onDelete, onFieldSubmit, onSortUpdate }) => {
	return (
		<DndProvider backend={HTML5Backend}>
			<Stack spacing={2}>
				{fields?.map((field, index) => (
					<DraggableTextField key={field.Id} field={field} index={index} onDelete={() => onDelete(field.Id)} onFieldSubmit={onFieldSubmit} onSortUpdate={onSortUpdate} />
				))}
			</Stack>
		</DndProvider>
	);
};

export default DraggableForm;
