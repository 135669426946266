import { Stack } from '@mui/material';
import DialogModal from 'components/UI/DialogModal';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import { InputTypes } from 'constants/enums';
import { IFormField } from 'models/app';
import { IReplyInput, StreamListItem } from 'models/stream';
import React from 'react';


interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: IReplyInput) => void;   
  stream: StreamListItem;
 }

const AddReply: React.FC<Props> = ({ open, onClose, onSubmit,stream }) => {
  

  const [addReplyFormData, setAddReplyFormData] = React.useState<IReplyInput | null>({
    replySentence: '',
    responseId: stream.ResponseId,
  });

  const onChange = (name: string, value: any) => {
    setAddReplyFormData({
      ...addReplyFormData!,
      [name]: value
    });
     
  };

  let addTestFields: IFormField[] = [
    {
      name: 'replySentence',
      label: 'Reply:',
      type: InputTypes.TEXTAREA,
      placeholder: 'Enter Sentence',
     }, 
  ];

  const handleSubmit = () => {
    onSubmit(addReplyFormData!);
    onClose();
  };

  return (
    <>
      <DialogModal
        open={open}
        onClose={onClose}
        maxWidth="xl"
        title="New Reply"
        children={
          <Stack sx={{ width: '30vw', pt: 3 }}>
            <Form fields={addTestFields} onChange={onChange} />
          </Stack>
        }
        actions={
          <>
            <MuiButton
              variant="outlined"
              size="medium"
              color='warning'
              onClick={onClose}>
              Cancel
            </MuiButton>
            <MuiButton
              variant="outlined"
              size="medium"
              color='primary'
              disabled={!addReplyFormData?.replySentence}
              onClick={handleSubmit}>
              Send  
            </MuiButton>
          </>
        }
      />
    </>
  );
};

export default AddReply;