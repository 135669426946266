
import { PERMISSIONS } from "constants/api";
import axios from "../helpers/axiosInstance";


export async function getUserPermissions() {
  let user = JSON.parse(localStorage.getItem("user") || "{}");
  if (!user.UserId) throw new Error("User not found");
  const response = await axios.get(PERMISSIONS + "?userId=" + user.UserId);
  return response.data;
}
