import { ADD_PILLARS, DELETE_PILLARS, GET_PILLARS, TRANSLATE_PILLARS, UPDATE_PILLARS } from 'constants/api';
import axios from '../helpers/axiosInstance';
import { IPillarInput } from 'models/pillar';
import { TranslatePillarForm } from 'models/Competency';

export async function getPillar({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_PILLARS + _params.query);
	return response.data;
}

export async function addPillar(data: IPillarInput) {
	const response = await axios.post(ADD_PILLARS, data);
	return response.data;
}

export async function deletePillar(Id: number) {
	const response = await axios.post(DELETE_PILLARS + `?pillarId=${Id}`);
	return response.data;
}

export async function updatePillar(data: IPillarInput) {
	const response = await axios.post(UPDATE_PILLARS, data);
	return response.data;
}

export async function translatePillar(data: TranslatePillarForm) {
	const response = await axios.post(TRANSLATE_PILLARS, data);
	return response.data;
}
