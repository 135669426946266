import { Accordion, AccordionDetails, AccordionSummary, Divider, IconButton, Stack, TableCell, Typography } from '@mui/material';
import { ICategoryWithTest, ITest } from 'models/testBank';
import { DeleteOutline, EditOutlined, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import React from 'react';
import MuiButton from 'components/UI/MuiButton';
import ActionLoader from 'components/UI/ActionLoader';
import List from 'components/UI/List';
import { TestCells } from 'framework/testBank';
import { Feature } from 'models/api';
import useApp from 'hooks/useApp';
import { Permission } from 'models/permission';

interface Props {
	categories: ICategoryWithTest[];
	feature: Feature;
	onOpenEditCategory: (category: ICategoryWithTest) => void;
	onOpenDeleteCategory: (category: ICategoryWithTest) => void;
	openDeleteTest: (test: ITest) => void;
}

const ListCategories: React.FC<Props> = ({ categories, feature, onOpenEditCategory, onOpenDeleteCategory, openDeleteTest }) => {
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 8);

	const { push } = useApp();

	const handleEdit = (e: any, row: ITest) => {
		e.stopPropagation();
		push(`/${feature.slug}/${row.Id}`);
	};

	const handleDelete = (e: any, row: ITest) => {
		e.stopPropagation();
		openDeleteTest(row);
	};

	const getBodyCells = (row: any) => {
		let cells = Object.keys(row).map((key: string, i: number) => {
			if (key === 'Name')
				return {
					id: 'name',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.Name}</Typography>
						</TableCell>
					)
				};
			if (key === 'Competency')
				return {
					id: 'competency',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.Competency}</Typography>
						</TableCell>
					)
				};
			if (key === 'QuestionsPerPage')
				return {
					id: 'total_questions',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.NumberOfQuestions}</Typography>
						</TableCell>
					)
				};

			if (key === 'Indicator')
				return {
					id: 'indicator',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.Indicator}</Typography>
						</TableCell>
					)
				};
			if (key === 'StatusName')
				return {
					id: 'status',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row.StatusName}</Typography>
						</TableCell>
					)
				};
			if (key === 'Id')
				return {
					id: 'actions',
					element: (
						<TableCell align={'left'} key={i}>
							<Stack direction={'row'} alignItems={'center'} spacing={1} useFlexGap>
								{!!currentPagePermissions?.HasModifyPermission && (
									<IconButton size="small" color="inherit" onClick={(e) => handleEdit(e, row)}>
										<EditOutlined fontSize="small" />
									</IconButton>
								)}

								{!!currentPagePermissions?.HasDeletePermission && (
									<IconButton size="small" color="inherit" onClick={(e) => handleDelete(e, row)}>
										<DeleteOutline fontSize="small" />
									</IconButton>
								)}
							</Stack>
						</TableCell>
					)
				};
			else return <></>;
		});
		return cells;
	};

	return (
		<Stack divider={<Divider />}>
			{categories?.map((category) => (
				<Stack key={category.CategoryId} p={0} spacing={1} useFlexGap>
					<Accordion>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ padding: '6px' }} aria-controls="panel1a-content" id="panel1a-header">
							<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ width: '100%' }}>
								<MuiButton color="inherit">{category.CategoryName}</MuiButton>
								<Stack direction={'row'} alignItems={'center'} spacing={1} useFlexGap>
									{!!currentPagePermissions?.HasModifyPermission && (
										<IconButton size="small" color="inherit" onClick={() => onOpenEditCategory(category)}>
											<EditOutlined fontSize="small" />
										</IconButton>
									)}

									{!!currentPagePermissions?.HasDeletePermission && (
										<IconButton size="small" color="inherit" onClick={() => onOpenDeleteCategory(category)}>
											<DeleteOutline fontSize="small" />
										</IconButton>
									)}
								</Stack>
							</Stack>
						</AccordionSummary>
						<AccordionDetails style={{ padding: 0 }}>
							{!category?.Tests ? (
								<ActionLoader position={'static'} />
							) : category?.Tests?.length > 0 ? (
								<List headCells={TestCells} data={category.Tests} getBodyCells={getBodyCells} feature={feature} />
							) : (
								<Typography sx={{ textAlign: 'center', p: 3 }}>No Tests</Typography>
							)}
						</AccordionDetails>
					</Accordion>
				</Stack>
			))}
		</Stack>
	);
};

export default ListCategories;
