import { ShowcaseListItem } from 'models/showcase';
import { create } from 'zustand';

type State = {
	listing: ShowcaseListItem[];
	params: string;
};

type Actions = {
	setListing: (payload: ShowcaseListItem[]) => void;
	setParams: (payload: string) => void;
};

const useShowcaseStore = create<State & Actions>((set) => ({
	listing: [],
	params: '',
	setListing: (payload: ShowcaseListItem[]) => set((state) => ({ ...state, listing: payload })),
	setParams: (payload: string) => set((state) => ({ ...state, params: payload }))
}));

export default useShowcaseStore;
