import { Stack, Typography } from '@mui/material';
import { ShowcaseDetails } from 'models/showcase';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

import React from 'react';
import { getLongDateFormat } from 'helpers/utils';

interface Props {
  showcase: ShowcaseDetails;
}


const ShowcaseReports: React.FC<Props> = ({ showcase }) => {
  return (
    <Stack spacing={2} >
      <Typography variant="body2" fontWeight={500} fontSize={15} color={'TextGray'}>
        Showcase Reports
      </Typography>
      <Stack spacing={1} >
        {showcase.reports.map((report, index: number) => (
          <Stack p={2} bgcolor={'grey.100'} spacing={1} useFlexGap key={index}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <FormatQuoteIcon />
              <Typography variant="subtitle2" fontWeight={'bold'}>
                {report.Feedback}
              </Typography>
              <Typography variant="caption">via</Typography>
              <Typography variant="caption" color={'primary.main'}>
                {report.FirstName} {report.LastName}
              </Typography>
            </Stack>
            <Typography variant="caption" color={'TextGray'}>{report.EmailAddress} </Typography>
            <Typography variant="caption">{getLongDateFormat(report.ReportDate)}</Typography>
            <Typography variant="body2" fontWeight={500} fontSize={15} color={'#93989D'}>
              {report.ReasonName}
            </Typography>
            
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
};

export default ShowcaseReports;