import React, { useCallback, useEffect, useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Skeleton, Stack, Typography } from '@mui/material';
import { Feature, ResponseResult } from 'models/api';
import ListCategories from './partials/ListCategories';
import AddCategory from './partials/AddCategory';
import EditCategory from './partials/EditCategory';
import DeleteDialog from 'Pages/ResponseForm/partials/DeleteDialog';
import { useMutation, useQueryClient } from 'react-query';
import { useAlert } from 'contexts/alertContext';
import { Category, ITicket, addCategory, assignTicketToCategory, closeTicket, deleteCategory, deleteTicket, editCategory, getSupportCategories, getSupportTickets, inProgressTicket, spamTicket } from 'framework/support';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MuiButton from 'components/UI/MuiButton/MuiButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useApp from 'hooks/useApp';
import AddToCategory from './partials/AddToCategory';
import { TicketSupportCalenderIcon, TicketSupportIcon } from 'components/UI/SvgIcons';
import { BackOfficeHeader } from 'helpers/utils';
import { Permission } from 'models/permission';
import useSupportStore from 'store/support';

type Props = {
	feature: Feature;
};

const Support: React.FC<Props> = ({ feature }) => {
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 12);
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	const { push } = useApp();
	const [openAddCategory, setOpenAddCategory] = useState<boolean>(false);
	const [openEditCategory, setOpenEditCategory] = useState<boolean>(false);
	const [openDeleteCategory, setOpenDeleteCategory] = useState<boolean>(false);
	const [openDeleteTicket, setOpenDeleteTicket] = useState<boolean>(false);
	const [openAddToCategory, setOpenAddToCategory] = useState<boolean>(false);
	const [Categories, setCategories] = useState<Category[]>([]);
	const [selectedCategoryToSort, setSelectedCategoryToSort] = useState<Category | null>(null);
	const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
	const [currentTicket, setCurrentTicket] = useState<ITicket | null>(null);
	const [tickets, setTickets] = useState<ITicket[] | null>(null);
	const [addCategoryForm, setAddCategoryForm] = useState<Category | null>(null);
	const [editCategoryForm, setEditCategoryForm] = useState<Category | null>(null);
	const [category, setCategory] = useState<Category | null>(null);
	const params = useSupportStore((state) => state.params);
	const listing = useSupportStore((state) => state.listing);
	const setListing = useSupportStore((state) => state.setListing);

	const [loading, setLoading] = useState<boolean>(false);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

 
 
	useEffect(() => {
		if (!currentPagePermissions?.HasReadPermission) {
			push('/404');
		}
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [currentPagePermissions]);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>, ticket: any) => {
		setCurrentTicket(ticket);
		setAnchorEl(event.currentTarget);
	};

	const addCategoryMutation = useMutation({
		mutationFn: ({ queryKey }: any) => {
			return addCategory({ queryKey });
		},
		onSuccess: () => {
			fetchCategories();
		}
	});

	const editCategoryMutation = useMutation({
		mutationFn: ({ queryKey }: any) => {
			return editCategory({ queryKey });
		},
		onSuccess: () => {
			fetchCategories();
		}
	});

	const deleteCategoryMutation = useMutation({
		mutationFn: ({ queryKey }: any) => {
			return deleteCategory({ queryKey });
		},
		onSuccess: () => {
			fetchCategories();
		}
	});

	const deleteTicketMutation = useMutation({
		mutationFn: ({ queryKey }: any) => {
			return deleteTicket({ queryKey });
		},
		onSuccess: () => {
			fetchTickets();
		}
	});

	const spamTicketMutation = useMutation({
		mutationFn: ({ queryKey }: any) => {
			return spamTicket({ queryKey });
		},
		onSuccess: () => {
			fetchTickets();
		}
	});
	const assignTicketToCategoryMutation = useMutation({
		mutationFn: ({ queryKey }: any) => {
			return assignTicketToCategory({ queryKey });
		},
		onSuccess: () => {
			fetchTickets();
		}
	});
	const closeTicketMutation = useMutation({
		mutationFn: ({ queryKey }: any) => {
			return closeTicket({ queryKey });
		},
		onSuccess: () => {
			fetchTickets();
		}
	});

	const inProgressTicketMutation = useMutation({
		mutationFn: ({ queryKey }: any) => {
			return inProgressTicket({ queryKey });
		},
		onSuccess: () => {
			fetchTickets();
		}
	});

	const fetchCategories = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['categories', { query: '' }], getSupportCategories);
			setCategories(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);

	const fetchTickets = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['tickets', { query: params }], getSupportTickets);
			setListing(response.Data);
			setTickets(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [params]);

	useEffect(() => {
		fetchCategories();
		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		fetchTickets();
		// eslint-disable-next-line
	}, [params]);

	if (loading) return <Skeleton variant="rectangular" height={400} animation="wave" />;

	//	const handleOpenAddCompetency = () => setOpenAddCompetency(true);
	const handleOpenEditCompetency = (category: Category) => {
		setOpenEditCategory(true);
		setCategory(category);
		setEditCategoryForm({ Id: category?.Id, Name: category?.Name });
	};
	const handleOpenDeleteCompetency = (category: Category) => {
		setOpenDeleteCategory(true);
		setCategory(category);
	};

	const handleOpenDeleteTicket = () => {
		setOpenDeleteTicket(true);
		setAnchorEl(null);
	};

	const handleOpenAssignToCategory = () => {
		setOpenAddToCategory(true);
		setAnchorEl(null);
	};
	const handleSelectedCategoryToSort = (category:Category) => {
		if(category?.Id === selectedCategoryToSort?.Id) {
			setSelectedCategoryToSort(null);
			setListing(tickets!);
			return
		} else {
			setSelectedCategoryToSort(category);
			setListing(tickets!?.filter((ticket) => ticket?.SupportCategoryId === category?.Id));
		}
		 
	};
	const handleOpenTicket = (e: any, ticket: ITicket) => {
		e.stopPropagation();
		push(`/${feature.slug}/${ticket?.Id!}`);
	};

	const handleChangeAddCategoryForm = (name: string, value: any) => setAddCategoryForm({ ...addCategoryForm!, [name]: value });
	const handleChangeEditCategoryForm = (name: string, value: any) => setEditCategoryForm({ ...editCategoryForm!, [name]: value });
	const handleChangeAddToCategoryForm = (name: string, value: any) => setSelectedCategoryId(value);

	const handleClose = () => {
		setOpenAddCategory(false);
		setOpenEditCategory(false);
		setOpenDeleteCategory(false);
		setCategory(null);
		setAddCategoryForm(null);
		setEditCategoryForm(null);
		setAnchorEl(null);
		setCurrentTicket(null);
		setOpenAddToCategory(false);
		setOpenDeleteTicket(false);
	};

	const handleAddCategory = async () => {
		setLoading(true);
		try {
			const res = await addCategoryMutation.mutateAsync({ queryKey: `?Name=${addCategoryForm?.Name}` });
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Category Added successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleEditCategory = async () => {
		setLoading(true);
		try {
			const res = await editCategoryMutation.mutateAsync({ queryKey: `?Name=${editCategoryForm?.Name}&categoryId=${editCategoryForm?.Id}` });
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Category Edited successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleDeleteCategory = async () => {
		setLoading(true);
		try {
			const res = await deleteCategoryMutation.mutateAsync({ queryKey: `?Id=${category?.Id}` });
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Category Deleted successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleDeleteTicket = async () => {
		setLoading(true);
		try {
			const res = await deleteTicketMutation.mutateAsync({ queryKey: `?Id=${currentTicket?.Id}` });
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Ticket Deleted successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		handleClose();
	};

	const handleSpamTicket = async () => {
		setLoading(true);
		try {
			const res = await spamTicketMutation.mutateAsync({ queryKey: `?Id=${currentTicket?.Id}` });
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Ticket Spam successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		handleClose();
	};
	const handleAssignTicketToCategory = async () => {
		setLoading(true);
		try {
			const res = await assignTicketToCategoryMutation.mutateAsync({ queryKey: `?ticketId=${currentTicket?.Id!}&categoryId=${selectedCategoryId}` });
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Ticket Assigned successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		handleClose();
	};

	const handleCloseTicket = async () => {
		setLoading(true);
		try {
			const res = await closeTicketMutation.mutateAsync({ queryKey: `?Id=${currentTicket?.Id}` });
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Ticket Closed successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		handleClose();
	};

	const handleInProgressTicket = async () => {
		setLoading(true);
		try {
			const res = await inProgressTicketMutation.mutateAsync({ queryKey: `?Id=${currentTicket?.Id}` });
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Ticket In Progress successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		handleClose();
	};

	return (
		<Box m={5}>
			<BackOfficeHeader title="Support Tickets" />
			<Stack direction={['column', 'column', 'row']} justifyContent={'space-between'}>
				<Box
					width={['100%', '100%', '60%']}
					sx={{
						order: [2, 2, 1]
					}}>
					<Stack direction={'column'} spacing={1.5} sx={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }} px={2}>
						{listing?.map((ticket) => (
							<Stack direction={['column', 'column', 'row']} justifyContent={'space-between'} alignItems={['flex-start', 'flex-start', 'center']} py={2} sx={{ borderBottom: '1px solid rgba(221, 221, 221, 1)' }}>
								<Stack onClick={(e) => handleOpenTicket(e, ticket)} direction={'row'} alignItems={'center'} spacing={5} sx={{ cursor: 'pointer' }}>
									<TicketSupportIcon />
									<Stack direction={'column'} spacing={1}>
										<Typography variant="body1" sx={{ color: 'rgba(136, 136, 136, 1)' }}>
											{ticket?.Title}
										</Typography>
										<Typography variant="h5" sx={{ fontWeight: 500 }}>
											{ticket?.Description}
										</Typography>
										<Typography sx={{ color: 'rgba(136, 136, 136, 1)' }} variant="caption">
											{ticket?.CreatedByFirstName} {ticket?.CreateByLastName}{' '}
											<Box px={1} display={'inline'}>
												<TicketSupportCalenderIcon />
											</Box>{' '}
											{new Date(ticket?.CreatedOn).toLocaleDateString('en-GB', {
												day: 'numeric',
												month: 'short',
												year: 'numeric',
												hour: '2-digit',
												minute: '2-digit',
												second: '2-digit'
											})}
										</Typography>
									</Stack>
								</Stack>
								<Stack direction={'row'} spacing={3} alignItems={'center'}>
									<Typography variant="body1">{ticket?.SupportStatusName}</Typography>
									{(!!currentPagePermissions?.HasModifyPermission || !!currentPagePermissions?.HasDeletePermission) && (
										<IconButton
											aria-label="more"
											id="long-button"
											aria-controls={anchorEl ? 'long-menu' : undefined}
											aria-expanded={anchorEl ? 'true' : undefined}
											aria-haspopup="true"
											onClick={(e) => handleClick(e, ticket)}>
											<MoreVertIcon />
										</IconButton>
									)}
								</Stack>
							</Stack>
						))}
						{openAddToCategory && <AddToCategory currentTicket={currentTicket!} selectedCategoryId={selectedCategoryId!} categories={Categories} open={openAddToCategory} onClose={handleClose} onChange={handleChangeAddToCategoryForm} onSubmit={handleAssignTicketToCategory} />}
					</Stack>
				</Box>
				<Box
					width={['100%', '100%', '38%']}
					sx={{
						order: [1, 1, 2]
					}}>
					<Stack direction={['column', 'column', 'row']} justifyContent={'space-between'} alignItems={['flex-start', 'flex-start', 'center']} p={2} sx={{ backgroundColor: 'rgba(229, 229, 229, 1)' }}>
						<Typography variant="body1" sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
							Categories
						</Typography>
						<Box>
							{!!currentPagePermissions?.HasModifyPermission && (
								<MuiButton
									variant="text"
									size="large"
									onClick={() => setOpenAddCategory(true)}
									startIcon={
										<AddCircleIcon
											sx={{
												width: 30,
												height: 30,
												color: 'black'
											}}
										/>
									}>
									Add Category
								</MuiButton>
							)}
						</Box>
					</Stack>

					<ListCategories categories={Categories!} feature={feature} onOpenDeleteCategory={handleOpenDeleteCompetency} onOpenEditCategory={handleOpenEditCompetency} selectCategoryToSort={(category: Category) => handleSelectedCategoryToSort(category)} selectedCategoryToSort={selectedCategoryToSort} />

					{openDeleteCategory && <DeleteDialog loading={false} onClose={handleClose} open={openDeleteCategory} onSubmit={handleDeleteCategory} description={`${category?.Name}`} title="Delete Category" />}
					{openDeleteTicket && <DeleteDialog loading={false} onClose={handleClose} open={openDeleteTicket} onSubmit={handleDeleteTicket} description={`${currentTicket?.Title}`} title="Delete Ticket" />}
				</Box>
				<Menu
					id="long-menu"
					MenuListProps={{
						'aria-labelledby': 'long-button'
					}}
					sx={{
						'& .MuiPaper-root': {
							boxShadow: 'none',
							border: '1px solid #E5E5E5',
							minWidth: 120
						}
					}}
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleClose}>
					{/* <MenuItem onClick={handleOpenAddToCategory}>
						<Typography variant="body2" fontWeight={400} fontSize={15} color={'primary'}>
							Add to Category
						</Typography>
					</MenuItem> */}
					<MenuItem onClick={handleOpenAssignToCategory}>
						<Typography variant="body2" fontWeight={400} fontSize={15} color={'primary'}>
							Assign to Category
						</Typography>
					</MenuItem>
					{!!currentPagePermissions?.HasModifyPermission && (
						<>
							<MenuItem onClick={handleCloseTicket}>
								<Typography variant="body2" fontWeight={400} fontSize={15} color={'primary'}>
									Mark as Closed
								</Typography>
							</MenuItem>
							<MenuItem onClick={handleInProgressTicket}>
								<Typography variant="body2" fontWeight={400} fontSize={15} color={'primary'}>
									Mark as In Progress
								</Typography>
							</MenuItem>
							<MenuItem onClick={handleSpamTicket}>
								<Typography variant="body2" fontWeight={400} fontSize={15} color={''}>
									Spam
								</Typography>
							</MenuItem>
						</>
					)}

					{!!currentPagePermissions?.HasDeletePermission && (
						<MenuItem onClick={handleOpenDeleteTicket}>
							<Typography variant="body2" fontWeight={400} fontSize={15} color={'error'}>
								Delete
							</Typography>
						</MenuItem>
					)}
				</Menu>
			</Stack>

			{openAddCategory && <AddCategory open={openAddCategory} addCategoryForm={addCategoryForm} onClose={handleClose} onChange={handleChangeAddCategoryForm} onSubmit={handleAddCategory} />}
			{openEditCategory && <EditCategory open={openEditCategory} addCategoryForm={editCategoryForm} onClose={handleClose} onChange={handleChangeEditCategoryForm} onSubmit={handleEditCategory} />}
		</Box>
	);
};

export default Support;
