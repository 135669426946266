import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';

import MuiButton from 'components/UI/MuiButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IFormField } from 'models/app';
import React from 'react';
import Image from 'components/UI/Image';

interface Props extends IFormField {
	errors: any;
	control: any;
	required?: boolean;
	defaultValue?: any;
	watch?: any;
	onDeleteFile?: any;
	setValue?: any;
}
const DropzoneContainer = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: '2px',
	borderRadius: '2px',
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out'
});

function RHDropzone({ label, name, onChange, placeholder, disabled, autoFocus, control, errors, required, defaultValue, onDeleteFile, watch, hidden, setValue, limitFiles = 3, defaultImageUrl }: Props) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars

	const [files, setFiles] = React.useState<any[]>(defaultValue || []);
	const [defaultImageUrlState, setDefaultImageUrlState] = React.useState<string | any>(defaultImageUrl);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: (acceptedFiles: any) => {
			acceptedFiles.forEach((file: any) => {
				let newAcceptedFiles: any[] = watch(name) || [];
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (url) => {
					const fileUrl = url?.target?.result;
					Object.assign(file, { fileUrl });
					newAcceptedFiles.push(file);
					setFiles(newAcceptedFiles);
					setValue && setValue(name, newAcceptedFiles);
					onChange && onChange(file);
				};
			});
		},
		accept: {
			'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
			'text/*': ['.txt', '.pdf', '.doc', '.docx', '.odt'],
			'application/*': ['.zip', '.rar']
		}
	});
	const handleDelete = (file: File) => {
		const filteredFiles = files.filter((f: File) => f.name !== file.name);
		setFiles(filteredFiles);
		setValue && setValue(name, filteredFiles);
	};

	const handleDeleteCloudImage = () => {
		onDeleteFile && onDeleteFile();
		setDefaultImageUrlState(null);
	};

	const getIconByMimeType = (fileName: string, imageUrl: string = '') => {
		let type = fileName.split('.')[fileName.split('.').length - 1];
		//check if image types
		type = ['png', 'gif', 'jpeg', 'jpg', 'tif', 'tiff', 'bmp', 'svg', 'webp', 'ico', 'avif'].includes(type) ? 'image' : type;
		switch (type) {
			// case image types
			case 'image':
				return <Image url={imageUrl} alt={fileName} />;
			case 'pdf':
				return <Image url={require('assets/icons/pdf.png')} alt={fileName} />;
			case 'zip':
				return <Image url={require('assets/icons/zip.png')} alt={fileName} />;
			case 'rar':
				return <Image url={require('assets/icons/rar.jpeg')} alt={fileName} />;
			case 'docx' || 'doc' || 'odt':
				return <Image url={require('assets/icons/docs.jpg')} alt={fileName} />;
			default:
				return <Image url={require('assets/icons/file.png')} alt={fileName} />;
		}
	};

	return (
		<>
			{label && (
				<Typography variant="body2" fontWeight={600} textAlign={'start'} minWidth={'16ch'}>
					{label}
				</Typography>
			)}
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					//height: { xs: 200, md: 300 },
					border: '1px dashed #ccc',
					borderRadius: 1,
					p: 2,
					bgcolor: 'background.paper',
					overflow: 'auto'
				}}>
				{(!files || files?.length < limitFiles!) && !defaultImageUrlState && !disabled && (
					<DropzoneContainer {...getRootProps()}>
						<input {...getInputProps()} />
						{isDragActive ? (
							<Typography variant="h5" component="h2">
								Drop the files here ...
							</Typography>
						) : (
							<>
								<MuiButton>
									<CloudUploadIcon sx={{ fontSize: 48 }} />
								</MuiButton>
								<Typography variant="h6" component="h2">
									Drag 'n' drop some files here, or click to select files
								</Typography>
							</>
						)}
					</DropzoneContainer>
				)}
				<Box mx={2}>
					{files && files.length > 0 && !defaultImageUrlState && (
						<Stack direction={'row'} spacing={2} py={2}>
							{files.map((file: any, i: number) => (
								<Box
									key={i}
									sx={{
										height: 150,
										width: 150,
										position: 'relative',
										'&:hover': {
											opacity: 0.5
										},
										'&:hover #delete': {
											visibility: 'visible'
										},
										cursor: 'pointer'
									}}
									onClick={() => handleDelete(file)}>
									{getIconByMimeType(file.name, file.fileUrl)}
									<IconButton
										id="delete"
										aria-label="delete"
										size="small"
										sx={{
											position: 'absolute',
											top: '25%',
											right: '35%',
											color: 'red',
											visibility: 'hidden',
											transition: 'opacity 0.2s ease-in-out'
										}}>
										<DeleteIcon />
									</IconButton>

									<Typography
										variant="body2"
										sx={{
											lineClamp: 2,
											boxOrient: 'vertical',
											display: '-webkit-box',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											height: 50,
											width: 150,
											textAlign: 'center'
										}}>
										{file.name}
									</Typography>
								</Box>
							))}
						</Stack>
					)}
					{defaultImageUrlState && (
						<Box
							sx={{
								height: 150,
								width: 150,
								position: 'relative',
								'&:hover': {
									opacity: disabled ? 1 :0.5
								},
								'&:hover #delete': {
									visibility: 'visible'
								},
								cursor: disabled?'default':'pointer'
							}}>
							{/* {getIconByMimeType(defaultImageUrl.split("/")[defaultImageUrl.split("/").length - 1], defaultImageUrl)} */}
							<Box
								component={'img'}
								src={defaultImageUrlState}
								alt={defaultImageUrlState}
								sx={{
									height: 150,
									width: 150,
									objectFit: 'contain'
								}}
							/>
							{!disabled  && <IconButton
								id="delete"
								aria-label="delete"
								size="small"
								sx={{
									position: 'absolute',
									top: '25%',
									right: '35%',
									color: 'red',
									visibility: 'hidden',
									transition: 'opacity 0.2s ease-in-out'
								}}
								onClick={handleDeleteCloudImage}>
								<DeleteIcon />
							</IconButton>
}
							<Typography
								variant="body2"
								sx={{
									lineClamp: 2,
									boxOrient: 'vertical',
									display: '-webkit-box',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									height: 50,
									width: 150,
									textAlign: 'center'
								}}>
								{/* {defaultImageUrlState.split('/')[defaultImageUrlState.split('/').length - 1]} */}
							</Typography>
						</Box>
					)}
				</Box>
			</Box>
		</>
	);
}

export default RHDropzone;
