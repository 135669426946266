import { Controller } from 'react-hook-form';
import { Box, Grid, Stack, TextField, Typography } from '@mui/material';
import { IFormField } from 'models/app';
import MuiButton from '../MuiButton';

interface Props extends IFormField {
  errors: any;
  control: any;
  required?: boolean;
  defaultValue?: any;
  setValue?: any;
}

const RHTextField: React.FC<Props> = ({ label, name, type, placeholder, disabled, autoFocus, control, errors, required, defaultValue, onChange, setValue, hidden, isHorizontal, bulkAction, bulkActionLabel, bulkActionFn, endAdornment, helperText, startAdornment, maxInputNumber, minInputNumber, customItem, errorMessage }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Grid container alignItems={'center'} hidden={hidden}>
          <Grid item xs={12} md={label ? (!isHorizontal ? 12 : 2) : 12} justifyContent={'space-between'} alignItems={'center'}>
            {label && (
              <Typography variant="body2" fontWeight={600} textAlign={'start'} minWidth={'16ch'}>
                {label}
              </Typography>
            )}
            {bulkAction && bulkActionLabel && bulkActionFn && (
              <>
                <MuiButton variant="text" color="primary" size="small" sx={{ textTransform: 'none' }} onClick={bulkActionFn}>
                  {bulkActionLabel}
                </MuiButton>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={label ? (!isHorizontal ? 12 : 10) : 12}>
            <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'space-between'}>
              <TextField
                fullWidth
                size="small"
                {...field}
                required={required}
                type={type}
                disabled={disabled}
                autoFocus={autoFocus}
                placeholder={placeholder}
                error={!!errors[name]}
                onChange={(e) => {
                  onChange && onChange(e.target.value);
                  setValue && setValue(name, e.target.value);
                }}
                InputProps={{
                  endAdornment: endAdornment!,
                  startAdornment: startAdornment!
                }}

                inputProps={{
                  max: maxInputNumber || 100000,
                  min: minInputNumber || 0
                }}

                helperText={(errors[name] && `${errors[name].message}`) || helperText }
                defaultValue={defaultValue}
                sx={{
                  borderRadius: 1,
                  '& .MuiOutlinedInput-root': {
                    bgcolor: 'common.white'
                  },
                }}
                multiline={type === 'textarea'}
                rows={type === 'textarea' ? 5 : undefined}
              />
              {customItem && <Box sx={{ width: '10%' }}>{customItem}</Box>}
           </Stack>
              
          </Grid>
        </Grid>
      )}
    />
  );
};

export default RHTextField;
