import { Stack } from '@mui/material';
import DialogModal from 'components/UI/DialogModal';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import { InputTypes } from 'constants/enums';
import { Competency } from 'models/Competency';
import { IFormField } from 'models/app';
import { IEditStreamInput } from 'models/stream';
import { UserActivities } from 'models/user';
import React, { useEffect } from 'react';


interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: IEditStreamInput) => void;
  onSelectCompetency: (competency: Competency) => void;
  competencies: Competency[] | null;
  indicators: string[] | null;
  activity: UserActivities;
}

const EditInteraction: React.FC<Props> = ({ open, onClose, onSubmit, onSelectCompetency, activity, competencies, indicators
}) => {
  let CompetencyId = activity.CompetencyId
  let indicator = activity.Indicator
  const [editFormData, setEditFormData] = React.useState<IEditStreamInput | null>({
    Id: activity.InteractionId,
    competencyId: CompetencyId,
    indicator: indicator,
    description: '',
    confirm: false,
  });

  const onChange = (name: string, value: any) => {
    setEditFormData({
      ...editFormData!,
      [name]: value
    });
    if (name === 'competencyId') {
      const competency = competencies?.find((comp: Competency) => comp.Id === parseFloat(value));
      onSelectCompetency(competency as Competency);
    }

  };

  useEffect(() => {
    onSelectCompetency(competencies?.find((comp: Competency) => comp.Id === activity.CompetencyId) as Competency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competencies, activity.CompetencyId]);

  let addTestFields: IFormField[] = [


    {
      name: 'competencyId',
      label: 'Competency:',
      type: InputTypes.SELECT,
      options: competencies ? competencies.map((comp: Competency) => ({
        value: `${comp.Id}`,
        label: comp.Name
      })) : [],
      defaultValue: CompetencyId ? {
        label: competencies?.find((comp: Competency) => comp.Id === CompetencyId)?.Name,
        value: `${CompetencyId}`
      } : null,
      placeholder: 'Select Competency',
    },
    {
      name: 'indicator',
      label: 'Indicator:',
      type: InputTypes.SELECT,
      options: indicators ? indicators.map((indicator: string) => ({
        value: indicator,
        label: indicator
      })) : [],

      placeholder: 'Select Indicator',
      defaultValue: indicator ? {
        label: indicator,
        value: indicator
      } : null,
    },
    {
      name: 'description',
      label: 'Description:',
      type: InputTypes.TEXTAREA,
      defaultValue: ""
    },
    {
      name: 'confirm',
      label: 'Confirm:',
      type: InputTypes.SWITCH,
      isHorizontal: true,
    },
  ];

  const handleSubmit = () => {
    onSubmit(editFormData!);
    onClose();
  };

  return (
    <>
      <DialogModal
        open={open}
        onClose={onClose}
        maxWidth="xl"
        title="Handle Review"
        children={
          <Stack >
            <Form fields={addTestFields} onChange={onChange} />
            
          </Stack>
        }
        actions={
          <>
            <Stack alignItems={'center'} direction={'row'} spacing={2} justifyContent={'flex-end'} sx={{ mt: 2 }} >
              <MuiButton
                variant="outlined"
                size="medium"
                color='warning'
                onClick={onClose}>
                Cancel
              </MuiButton>
              <MuiButton
                variant="outlined"
                size="medium"
                color='primary'
                disabled={
                  !editFormData?.competencyId ||
                  !editFormData?.indicator ||
                  !editFormData?.description
                }
                onClick={handleSubmit}>
                Apply Changes
              </MuiButton>
            </Stack>
          </>
        }

      />
    </>
  );
};

export default EditInteraction;