import { BASE_URL, LOGIN, REGISTER, AUTH, FORGET_PASSWORD, RESET_PASSWORD, ACTIVATE_ACCOUNT } from 'constants/api';
import axios from 'axios';

export async function signIn(data: any) {
	const response = await axios.post(BASE_URL + LOGIN, data);
	return response.data;
}
export async function forgetPass(data: any) {
	const response = await axios.post(BASE_URL + FORGET_PASSWORD + '?Email=' + data.Email + '&FrombackOffice=' + data.FrombackOffice);
	return response.data;
}

export async function resetPass(data: any) {
	const response = await axios.post(BASE_URL + RESET_PASSWORD, data);
	return response.data;
}

export async function activateAccount(token: string) {
	const response = await axios.post(BASE_URL + ACTIVATE_ACCOUNT + "?token=" + token);
	return response.data;
}

export async function signUp(params: any) {
	let { data } = params;
	const response = await axios.post(BASE_URL + REGISTER, data);
	return response.data;
}
export async function authGuard(token: string) {
	if (!token) throw new Error('Token not found');
	const response = await axios.get(BASE_URL + AUTH + '?refreshToken=' + token);
	return response.data;
}
