import { StackProps, Stack, Typography, IconButton } from '@mui/material';
import {
	AccessPermissions,
	BacklogIcon,
	CompetenciesIcon,
	DataCenterIcon,
	DocumentationIcon,
	EmailIcon,
	InsightsActiveIcon,
	InsightsIcon,
	KeyManagement,
	MoreIcon,
	ReportsActiveIcon,
	ReportsIcon,
	ResponseFormIcon,
	SettingsIcon,
	ShowcasesIcon,
	StreamActiveIcon,
	StreamIcon,
	SupportIcon,
	TestBankIcon,
	UserManagementIcon,
	UserProfile
} from 'components/UI/SvgIcons';

import useApp from 'hooks/useApp';
import { Feature } from 'models/api';

interface Props extends StackProps {
	feature: Feature;
	selectedFeature?: Feature;
	color: 'white' | 'dark' | undefined;
	setOpenDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
	onSelectUnLinkFeature?: (feature: any) => void;
	hasChildren?: boolean;
}

const MenuItem: React.FC<Props> = ({ feature, setOpenDrawer, onSelectUnLinkFeature, hasChildren, color, selectedFeature, ...rest }) => {
	const { currentRoute, push } = useApp();
	const isSelected = currentRoute === `/${feature.slug!}`;

	const renderIcon = (icon: string): React.ReactNode => {
		if (icon === 'StreamIcon' && !isSelected) return <StreamIcon />;
		if (icon === 'StreamIcon' && isSelected) return <StreamActiveIcon />;
		if (icon === 'InsightsIcon' && !isSelected) return <InsightsIcon />;
		if (icon === 'InsightsIcon' && isSelected) return <InsightsActiveIcon />;
		if (icon === 'ReportsIcon' && !isSelected) return <ReportsIcon />;
		if (icon === 'ReportsIcon' && isSelected) return <ReportsActiveIcon />;
		if (icon === 'MoreIcon') return <MoreIcon />;
		if (icon === 'ShowcasesIcon') return <ShowcasesIcon />;
		if (icon === 'UserManagementIcon') return <UserManagementIcon />;
		if (icon === 'DataCenterIcon') return <DataCenterIcon />;
		if (icon === 'BacklogIcon') return <BacklogIcon />;
		if (icon === 'TestBankIcon') return <TestBankIcon />;
		if (icon === 'ResponseFormIcon') return <ResponseFormIcon />;
		if (icon === 'CompetenciesIcon') return <CompetenciesIcon />;
		if (icon === 'SettingsIcon') return <SettingsIcon />;
		if (icon === 'EmailIcon') return <EmailIcon />;
		if (icon === 'DocumentationIcon') return <DocumentationIcon />;
		if (icon === 'UserProfileIcon') return <UserProfile />;
		if (icon === 'KeyManagementIcon') return <KeyManagement />;
		if (icon === 'AccessPermissionsIcon') return <AccessPermissions />;
		if (icon === 'SupportIcon') return <SupportIcon />;
		return null;
	};

	const handleClick = () => {
		if (feature.slug === 'logout') {
			localStorage.clear();
			push('/login');
			return;
		}
		if (hasChildren) {
			selectedFeature?.slug === feature.slug ? onSelectUnLinkFeature!(null) : onSelectUnLinkFeature!(feature);
			return;
		} else onSelectUnLinkFeature!(null);

		push(`/${feature.slug!}`);
		if (setOpenDrawer) setOpenDrawer(false);
	};

	return (
		<>
			{!feature.isSettings && (
				<Stack
					component={feature.slug ? 'a' : 'button'}
					direction={'column'}
					alignItems={'center'}
					bgcolor={isSelected ? (color === 'white' ? '#FFFFFF40' : '#00000040') : 'transparent'}
					p={1}
					onClick={handleClick}
					sx={{ transition: '500ms', '&:hover': { bgcolor: color === 'white' ? '#FFFFFF40' : '#00000040' }, cursor: 'pointer' }}
					{...rest}>
					{renderIcon(feature.icon)}
					<Typography variant="body2" fontWeight={400} fontSize={12} color={color}>
						{feature.name}
					</Typography>
				</Stack>
			)}

			{feature.isSettings && (
				<IconButton sx={{ marginTop: 'auto !important' }} onClick={handleClick}>
					{renderIcon(feature.icon)}
				</IconButton>
			)}
		</>
	);
};

export default MenuItem;
