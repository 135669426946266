import { Box, Grid, Stack, Typography } from '@mui/material';
import { FeedbackIcon } from 'components/UI/SvgIcons';
import { IRelevanceVoteChangeInput, RelevanceVote, StreamListItem } from 'models/stream';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import React from 'react';
import MuiButton from 'components/UI/MuiButton';
import { IFormField, IOption } from 'models/app';
import { InputTypes } from 'constants/enums';
import Form from 'components/UI/Form';
import MuiOutlineButton from 'components/UI/MuiOutlineButton';
import ReplyIcon from '@mui/icons-material/Reply';
import useResponsive from 'hooks/useResponsive';
import { IMediaQuery } from 'types/mediaQuery';
import ConfirmDialog from '../../Global/Confirm';
import { Link } from 'react-router-dom';
import { Permission } from 'models/permission';

interface Props {
  stream: StreamListItem;
  relevanceVotes: RelevanceVote[];
  selectedFilter: string;
  permissions: Permission;
  onApproveInteraction: (id: number) => void;
  onAddAsNone: (id: number) => void;
  onChangeRelevanceVote: (data: IRelevanceVoteChangeInput) => void;
  onAddBacklog: (stream: StreamListItem) => void;
  onAddReply: (stream: StreamListItem) => void;
  onEditStream: (stream: StreamListItem) => void;
  onConfirmStream: (stream: StreamListItem) => void;
}
const enum Statuses {
  ITEMS_FOR_REVIEW = "ITEMS FOR REVIEW",
  APPROVED = "APPROVED",
  EDITED = "EDITED",
  PENDING = "PENDING",
  NONE = "NONE"
}
const StreamCard: React.FC<Props> = ({ stream, relevanceVotes, selectedFilter, onApproveInteraction, onAddAsNone, onChangeRelevanceVote,
  onAddBacklog, onAddReply, onEditStream, onConfirmStream, permissions
}) => {
  const mediaParams: IMediaQuery = { query: 'up', key: 'md' };
  const mdUp = useResponsive(mediaParams);
  const relevanceVotesOptions: IOption[] = relevanceVotes.map((item) => ({ label: item.Name, value: `${item.Id}` }));
  let relevanceVoteField: IFormField[] = [{
    name: 'relevanceVotes',
    type: InputTypes.SELECT,
    options: relevanceVotesOptions,
    defaultValue: relevanceVotesOptions.find((item) => parseFloat(item.value) === stream.RelevanceVoteId),
    placeholder: 'Select Relevance Vote',
    disabled: !permissions?.HasModifyPermission
  }];
  const [openConfirmApprove, setOpenConfirmApprove] = React.useState(false);
  const [openConfirmNone, setOpenConfirmNone] = React.useState(false);

  const onChange = (name: string, value: any) => {
    onChangeRelevanceVote({
      Id: stream.Id,
      voteId: parseFloat(value)
    })
  }

  const handleActions = (action: string) => {
    switch (action) {
      case "Approve": setOpenConfirmApprove(true);
        break;
      case "None": setOpenConfirmNone(true);
        break;
      case "Edit": onEditStream(stream)
        break;
      case "Confirm": onConfirmStream(stream)
        break;
      default:
        break;
    }

  }

  const handleSendToBacklog = (stream: StreamListItem) => onAddBacklog(stream);

  const getActions = (filter: string) => {
    if (filter === Statuses.PENDING) {
      return ["None"];
    }
    if (filter === Statuses.ITEMS_FOR_REVIEW) {
      return ["Approve", "None", "Edit"];
    }
    return [];
  };


  return <>
    <Stack direction={'row'} spacing={mdUp ? 5 : 1} >
      <Stack direction={'column'}>
        <Box border={'1px solid #DADADA'} px={4} py={1} sx={{
          color: "primary.main",
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          fontWeight: 600,
          textAlign: 'center'
        }}>
          {stream.Id}
        </Box>
        <Box px={4} py={1} sx={{
          backgroundColor: "primary.main",
          color: "white",
          borderBottomLeftRadius: 3,
          borderBottomRightRadius: 3,
          fontSize: 14,
          fontWeight: 600,
          textAlign: 'center'
        }}>
          #
        </Box>

      </Stack>
      <Stack direction={'column'} spacing={2} sx={{
        width: mdUp ? '100%' : 300,
        overflowX: 'auto',
      }} >
        <Stack direction={'row'} spacing={1} >
          <Typography variant='body2' sx={{ fontWeight: 600 }}><FeedbackIcon /></Typography>
          <Typography variant='body2' sx={{ fontWeight: 600 }}>{stream.Subject}</Typography>
          <Typography variant='body2' sx={{ fontWeight: 400 }} fontSize={14} color={'gray'}>via</Typography>
          <Typography variant='body2' sx={{ fontWeight: 400, color: 'primary.main' }}>{stream.AuthorFirstName + " " + stream.AuthorLastName}</Typography>
        </Stack>
        {stream.EnteredSentence && <Typography variant="body2" fontWeight={400}
          sx={{
            height: '100%',
            width: { xs: 200, md: "80vh", },
            overflow: 'hidden',
            boxOrient: 'vertical',
            display: '-webkit-box',
            textOverflow: 'ellipsis',
            fontSize: 12,
            lineClamp: 1,
            "*": {
              fontSize: 12,
            }
          }}
          component="div" dangerouslySetInnerHTML={{ __html: stream.EnteredSentence.replace(/<[^>]*>?/gm, '') }} />}
        <Grid container alignItems={'center'}  >
          <Grid item xs={12} md={8}>
            <Stack direction={'row'} spacing={1}>
              <Typography variant='body2' sx={{ fontWeight: 400 }} color={'gray'}>Referral URL
              </Typography>
              <Link to={stream.ReferralURL} target="_blank" rel="noopener noreferrer" >
                <Typography variant='body2' sx={{
                  fontWeight: 400, color: 'primary.main',
                  width: '100%',
                  overflow: 'hidden',
                  boxOrient: 'vertical',
                  display: '-webkit-box',
                  textOverflow: 'ellipsis',
                  lineClamp: 3,
                  WebkitLineClamp: 3,
                  flexWrap: 'wrap',
                  "*": {
                    fontSize: 12,
                  },
                  "&:hover": {
                    textDecoration: 'underline',
                  },

                }} fontSize={12} >{stream.ReferralURL}</Typography>
              </Link>
              <ArrowRightAltIcon sx={{ color: 'gray' }} />
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack alignItems={'center'} >
              <Typography variant='body2' sx={{ fontWeight: 400 }} color={'gray'}>Relevance vote  </Typography>
              <Form
                fields={relevanceVoteField}
                onChange={onChange}

              />
            </Stack>
          </Grid>
        </Grid>
        {/* All */}
        <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
          <Typography variant='body2' sx={{ fontWeight: 400 }} color={'gray'}>Automated Status </Typography>
          <MuiButton variant='text' size='small' sx={{
            color: 'white', fontWeight: 400,
            backgroundColor: selectedFilter === Statuses.EDITED ? 'gray' : "#6FBC00",
            borderRadius: 1
          }}>{stream.AutomatedCompetencyName} </MuiButton>
          <Box sx={{
            width: 10, height: 10, borderRadius: '50%',
            backgroundColor: selectedFilter === Statuses.EDITED ? 'gray' : '#6FBC00'
          }} />
          <Typography variant='body2' sx={{ fontWeight: 300 }} > {stream.AutomatedIndicator}</Typography>

        </Stack>

        {/* EDITED */}
        {selectedFilter === Statuses.EDITED && <Stack spacing={1} sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          p: 1,
        }}>
          <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
            <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
              <ArrowRightAltIcon sx={{ color: 'gray' }} />
              <Typography variant='body2' sx={{ fontWeight: 400 }} color={'primary'}>Edited to </Typography>
            </Stack>

            <MuiButton variant='text' size='small' sx={{ color: 'white', fontWeight: 400, backgroundColor: "#6FBC00", borderRadius: 1 }}>{stream.UpdatedCompetencyName} </MuiButton>

            <Box sx={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: '#6FBC00' }} />

            <Typography variant='body2' sx={{ fontWeight: 300 }} > {stream.UpdatedIndicator}</Typography>

          </Stack>

        </Stack>}

        {/* PENDING */}
        {selectedFilter === Statuses.PENDING && <Stack spacing={1} sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          p: 1,
        }}>
          <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
            <ArrowRightAltIcon sx={{ color: 'gray' }} />
            <Typography variant='body2' sx={{
              fontWeight: 400,
              // make text uppercase
              textTransform: 'uppercase',
            }} color={'primary'}>
              Pending Editing to {stream.UpdatedCompetencyName}, {stream.UpdatedIndicator}
            </Typography>
          </Stack>
          {!!permissions?.HasModifyPermission && <Stack direction={'row'} spacing={1} sx={{ alignItems: 'start' }} >
            {["Confirm", "Edit"].map((action, i) => (
              // text button without border
              <MuiButton key={i} variant='text' size='small' sx={{
                color: action === 'Edit' ? 'gray' : 'primary.main',
                fontWeight: 400,
                backgroundColor: "transparent",
                borderRadius: 1
              }}
                onClick={() => handleActions(action)}
              >{action}</MuiButton>
            ))}
          </Stack>}
        </Stack>}

        {(selectedFilter === Statuses.PENDING || selectedFilter === Statuses.ITEMS_FOR_REVIEW) && <Typography variant='body2' sx={{ fontWeight: 400 }} color={'gray'}>How do you evaluate this automated score?</Typography>}

        <Stack direction={'column'} spacing={1} >
          {!!permissions?.HasModifyPermission && <Stack direction={'row'} spacing={2}>
            {getActions(selectedFilter).map((action, i) => (
              <MuiOutlineButton
                key={i}
                onClick={() => handleActions(action)}
                sx={{
                  borderRadius: 1,
                  color: 'text.secondary',
                  backgroundColor: 'white',
                  borderColor: '#DADADA',
                  p: 2,
                }}
              >{action}</MuiOutlineButton>
            ))}
          </Stack>}
          {!!permissions?.HasModifyPermission && <Grid container justifyContent={'space-between'} >
            {selectedFilter !== Statuses.NONE && <MuiOutlineButton
              onClick={() => onAddReply(stream)}
              sx={{
                borderRadius: 1,
                color: 'text.secondary',
                backgroundColor: 'white',
                borderColor: '#DADADA',
                px: 2,
              }}
              startIcon={<ReplyIcon sx={{ width: 15 }} />}
            >Reply</MuiOutlineButton>}
            <MuiButton variant='contained' size='small' sx={{ color: 'white', fontWeight: 400, borderRadius: 1 }}
              onClick={() => handleSendToBacklog(stream)}
            >Send to Backlog</MuiButton>
          </Grid>}
        </Stack>
      </Stack>
    </Stack>
    {setOpenConfirmApprove && <ConfirmDialog
      open={openConfirmApprove}
      onClose={() => setOpenConfirmApprove(false)}
      onSubmit={() => onApproveInteraction(stream.Id)}
      message={'Are you sure you want to approve this stream?'}
      title={'Approve Stream'}
    />}
    {openConfirmNone && <ConfirmDialog
      open={openConfirmNone}
      onClose={() => setOpenConfirmNone(false)}
      onSubmit={() => onAddAsNone(stream.Id)}
      message={'Are you sure you want to add this stream as none?'}
      title={'Add as None'}
    />}

  </>;
};

export default StreamCard;