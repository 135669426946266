import { Box, Button, Grid, Stack } from '@mui/material';
import { Feature, ResponseResult } from 'models/api';
import React, { useCallback, useEffect, useState } from 'react';

import { IReportCompetency, IReportOrganizations, IReportTest, getReportsCompetencyList, getReportsOrganizations, getReportsTests, getReportsUsers } from 'framework/reports';
import { useAlert } from 'contexts/alertContext';
import { useQueryClient } from 'react-query';
import ActionLoader from 'components/UI/ActionLoader';
import { ReportUser } from 'models/user';
import Competency from './partials/Competency';
import Tests from './partials/Tests';
import Users from './partials/Users';
import Organizations from './partials/Organizations';
import useReportStore from 'store/reports';

interface Props {
	feature: Feature;
}

interface TAB {
	id: number;
	title: string;
	length: number;
}

const ReportsList: React.FC<Props> = ({ feature }) => {
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	const [index, setIndex] = useState<number>(1);
	const [loading, setLoading] = useState<boolean>(false);
	const [competencies, setCompetencies] = useState<IReportCompetency[] | null>(null);
	const [tests, setTests] = useState<IReportTest[] | null>(null);
	const [users, setUsers] = useState<ReportUser[] | null>(null);
	const [organizations, setOrganizations] = useState<IReportOrganizations[] | null>(null);
	const params = useReportStore((state) => state.params);

	const [TABS, setTABS] = useState<TAB[]>([
		{ id: 1, title: 'Competency', length: 0 },
		{ id: 2, title: 'Test & Survey', length: 0 },
		{ id: 3, title: 'Users', length: 0 },
		{ id: 4, title: 'Organizations', length: 0 }
	]);

	const fetchCompetencies = useCallback(async () => {
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['ReportCompetencies', { query: params }], getReportsCompetencyList);
			setCompetencies(response.Data);
			updateTabLength(1, response.Data?.length);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
		// eslint-disable-next-line
	}, [params]);

	const fetchTests = useCallback(async () => {
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['ReportsTest', { query: params }], getReportsTests);
			setTests(response.Data);
			updateTabLength(2, response.Data?.length);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
		// eslint-disable-next-line
	}, [params]);

	const fetchUsers = useCallback(async () => {
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['ReportUsers', { query: params }], getReportsUsers);
			setUsers(response.Data);
			updateTabLength(3, response.Data?.length);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
		// eslint-disable-next-line
	}, [params]);

	const fetchOrganizations = useCallback(async () => {
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['ReportsOrganizations', { query: params }], getReportsOrganizations);
			setOrganizations(response.Data);
			updateTabLength(4, response.Data?.length);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
		// eslint-disable-next-line
	}, [params]);

	useEffect(() => {
		fetchCompetencies();
		fetchTests();
		fetchUsers();
		fetchOrganizations();
		// eslint-disable-next-line
	}, [params]);

	const updateTabLength = (tabId: number, newLength: number) => {
		setTABS((prevTabs) => {
			return prevTabs.map((tab) => (tab.id === tabId ? { ...tab, length: newLength } : tab));
		});
	};
	const tabs = [
		<Competency feature={feature} data={competencies || []} />,
		<Tests feature={feature} data={tests || []} />,
		<Users feature={feature} data={users || []} />,
		<Organizations feature={feature} data={organizations || []} />
	];

	return (
		<Stack spacing={4} useFlexGap>
			<Stack spacing={2} useFlexGap direction={'row'}>
				{TABS.map((tab) => (
					<Button
						size="large"
						color="inherit"
						key={tab.id}
						onClick={() => setIndex(tab.id)}
						sx={{
							justifyContent: 'flex-start',
							bgcolor: tab.id === index ? 'grey.200' : 'transparent',
							borderRadius: { xs: '8px 8px 0px 0px', md: '8px 0 0 8px' },
							':hover': { bgcolor: tab.id === index ? 'grey.200' : 'transparent' },
							fontWeight: tab.id === index ? 600 : 400,
							color: tab.id === index ? 'info.main' : 'text.primary'
						}}
						disableRipple>
						{tab.title}
						<Box sx={{ m: 0.5, ml: 1, bgcolor: tab.id === index ? 'grey.300' : 'transparent', color: 'text.primary', p: '5px' }}>{tab.length}</Box>
					</Button>
				))}
			</Stack>
			<Box sx={{ overflowY: 'auto' }}>
				<Grid container height={1}>
					<Grid item xs={12} md={10}>
						{loading ? <ActionLoader position={'sticky'} /> : tabs[index - 1]}
					</Grid>
				</Grid>
			</Box>
		</Stack>
	);
};

export default ReportsList;
