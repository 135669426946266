import { Box, Breadcrumbs, Typography } from '@mui/material';
import { Order, SortOrder } from 'constants/enums';
import * as yup from 'yup';
import { FC } from 'react';
import useApp from 'hooks/useApp';

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

export function isValidDomain(domain: string) {
	var regex = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/i;
	return regex.test(domain);
}

export function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
	return order === SortOrder.DESC ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

export const handleErrors = (error: any) => {
	if (error.response) {
		// Request made and server responded
		return error.response.data;
	} else if (error.request) {
		// The request was made but no response was received
		return error.request;
	} else {
		// Something happened in setting up the request that triggered an Error
		return error.message;
	}
};

export const getInitial = (name: string): string => {
	return name ? name.charAt(0).toLocaleLowerCase() : '';
};
/**
 * Convert string hours to it's equivalent in numbers
 *
 * @param hours hours: string
 * @param format format: string
 *
 * @returns number
 */
export const hoursStringToNumber = (hours: string, format: string): number => {
	const time = hours.split(':')[0];

	return format === 'PM' ? (parseInt(time) + 12 === 24 ? 12 : parseInt(time) + 12) : parseInt(time) === 12 ? 0 : parseInt(time);
};

/**
 * Compare giver hour in number to current locale hour
 *
 * @param hour hour: number
 *
 * @returns number
 */
export const compareHoursToCurrent = (hour: number): number => {
	const d = new Date();
	let currentHour = d.getHours();

	return hour - currentHour;
};

/**
 * Capitalize first letter of a given string
 *
 * @param string string: string
 *
 * @returns string
 */
export function capitalizeFirstLetter(string: string): string {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function stableSort(array: readonly any[], comparator: any) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

export function randomStr(length: number) {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;

	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}

	return result;
}

export const formatAMPM = (isoDate: string) => {
	const date = new Date(isoDate);
	let hours: string | number = date.getHours();
	let minutes: string | number = date.getMinutes();
	const ampm = hours >= 12 ? 'pm ' : 'am';

	hours %= 12;
	hours = hours || 12;
	minutes = minutes < 10 ? `0${minutes}` : minutes;

	const strTime = `${hours}:${minutes} ${ampm}`;

	return strTime;
};

export function getDayNumber(dateStr: string, locale: string) {
	const date = new Date(dateStr);
	return date.getDate();
}

export function getDateFormatted(date: Date) {
	const today = date ? new Date(date) : new Date();
	const dd = String(today.getDate()).padStart(2, '0');
	const mm = String(today.getMonth() + 1).padStart(2, '0');
	const yyyy = today.getFullYear();

	return yyyy + '- ' + mm + '- ' + dd;
}

export const getLongDateFormat = (dateString: string): string => {
	const date = new Date(dateString);

	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		hour12: true
	};

	return new Intl.DateTimeFormat('en-US', options).format(date);
};

export function capitalizeWords(string: string) {
	return string.replace(/(?:^|\s)\S/g, function (a) {
		return a.toUpperCase();
	});
}

export const mergeListOfStringsByDash = (list: string[] | null) => {
	if (list !== null && list.length) return list.reduce((prev, current) => `${prev} - ${current}`);

	return '';
};

export const mergeListOfStrings = (list: (string | null)[], delimiter: string) => {
	if (list !== null && list.length) return list.reduce((prev, current) => `${prev} ${delimiter} ${current}`);

	return '';
};

export const makeFakePhoneNumbers = (index: number, length: number) => {
	var result = `01${index}`;
	var characters = '0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const getDomainName = () => {
	if (window.location.hostname === 'localhost') return 'itzone';
	return 'itzone';
};

export const createArray = (size: number) => Array.from({ length: size }, (_, i) => i);

export function convertToCamelCase(text: string) {
	return text.replace(/-([a-z])/g, function (g) {
		return g[1].toUpperCase();
	});
}

export function extractSelectedCheckboxes(keyName: string, data: any) {
	const selected: string[] = [];

	for (let key of Object.getOwnPropertyNames(data)) {
		if (key.includes(keyName)) {
			if (data[key] === true) {
				selected.push(key.replace(keyName, ''));
			}
		}
	}

	return selected;
}

export function validatePhone(phone: string) {
	var re = /^[0][1-9]\d{9}$|^[1-9]\d{9}$/;
	return re.test(phone);
}

export function convertToSlug(text: string): string {
	return text
		.toLowerCase()
		.replace(/ /g, '-')
		.replace(/[^\w-]+/g, '');
}

export function camelCaseToSpaces(text: string): string {
	const result: string = text.replace(/([A-Z])/g, '$1');
	return result.charAt(0).toUpperCase() + result.slice(1);
}

/**
	The pxToRem() function helps you to convert a px unit into a rem unit,
 */
export function pxToRem(number: number, baseNumber: number = 16): string {
	return `${number / baseNumber}rem`;
}

export function shuffleArray(array: any[]) {
	let currentIndex = array.length;

	while (currentIndex !== 0) {
		const randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
	}

	return array;
}

/**
	The hexToRgb() function helps you to change the hex color code to rgb
	using chroma-js library.
 */

/**
	The linearGradient() function helps you to create a linear gradient color background
 */

export function linearGradient(color: string, colorState: string, angle: number = 195): string {
	return `linear-gradient(${angle}deg, ${color}, ${colorState})`;
}

export function excerpt(str: string, maxLength: number): string {
	if (str.length > maxLength) return str.substring(0, maxLength) + '...';

	return str;
}

/**
	The rgba() function helps you to create a rgba color code, it uses the hexToRgb() function
	to convert the hex code into rgb for using it inside the rgba color format.
 */

/**
	The gradientChartLine() function helps you to create a gradient color for the chart line
 */

/**
	The boxShadow() function helps you to create a box shadow for an element
 */

export function getDayTime(): string {
	const today: Date = new Date();
	const curHr: number = today.getHours();

	if (curHr < 12) {
		return 'morning';
	} else if (curHr < 18) {
		return 'afternoon';
	} else {
		return 'evening';
	}
}

export const removeUndefined = (obj: any) => {
	Object.keys(obj).forEach((key) => (obj[key] === undefined ? delete obj[key] : {}));
	return obj;
};
/**
 * Reformats a number into a currency string
 *
 * @param number number: number
 *
 * @returns string
 */
export default function getCurrencyFormat(number: number): string {
	const formatter = Intl.NumberFormat('en', { maximumSignificantDigits: 3, style: 'currency', currency: 'USD' });
	return formatter.format(number);
}

export const getHashName = (fullName: string) => {
	let hash: string = '';
	fullName!.split(' ').forEach((name: string) => (hash += name[0] || ''));
	return hash;
};

export function generateBacklogColor(num: number): string {
	const colors: string[] = ['45, 55, 72, 0.1', '255, 204, 0, 0.1', '230, 23, 23, 0.1', '0, 175, 230, 0.1', '194, 48, 194, 0.1', '250, 140, 40, 0.1', '25, 153, 0, 0.1'];

	// Adjusting the input number to fit within the range of colors
	const index: number = num % colors.length;

	return colors[index];
}

export const generateBacklogColorDarker = (num: number): string => {
	const colors: string[] = ['45, 55, 72', '255, 204, 0', '230, 23, 23', '0, 175, 230', '194, 48, 194', '250, 140, 40', '25, 153, 0'];

	// Adjusting the input number to fit within the range of colors
	const index: number = num % colors.length;

	return colors[index];
};

export const RESET_PASSWORD = {
	password1: yup.string().required('You need to enter a password. This is a mandatory field.').min(8, 'Your password should have at least 8 characters.'),
	password2: yup
		.string()
		.oneOf([yup.ref('password1')], 'Passwords mismatch')
		.min(8, 'Your password should have at least 8 characters.')
};

export const parseHTML = (htmlString: string | undefined) => {
	if (!htmlString) return [];

	const parser = new DOMParser();
	const doc = parser.parseFromString(htmlString, 'text/html');
	const textContent = doc.body.textContent || '';

	// Split the text into lines based on line breaks
	return textContent.split('\n').map((line, index) => <p key={index}>{line}</p>);
};

interface BackOfficeHeaderProps {
	title: string | string[];
	resorseToNavigate?: string;
}

export const BackOfficeHeader: FC<BackOfficeHeaderProps> = ({ title, resorseToNavigate }) => {
	const { push } = useApp();
	const renderBreadcrumb = () => {
		if (Array.isArray(title)) {
			return (
				<Box sx={{ mb: [4, 5, 6] }}>
					<Breadcrumbs aria-label="breadcrumb" sx={{ '.MuiBreadcrumbs-separator': { mx: 2, fontWeight: 600 } }}>
						{title.map((element, index) => (
							<Typography
								variant="h4"
								sx={{
									fontWeight: 600,
									...(index === 0 && {
										'&:hover': {
											cursor: 'pointer',
											textDecoration: 'underline'
										}
									})
								}}
								onClick={index === 0 && resorseToNavigate ? () => push(`/${resorseToNavigate}`) : undefined}>
								{element}
							</Typography>
						))}
					</Breadcrumbs>
				</Box>
			);
		} else {
			return (
				<Typography variant="h4" sx={{ fontWeight: 600, mb: [4, 5, 6] }}>
					{title}
				</Typography>
			);
		}
	};

	return renderBreadcrumb();
};
