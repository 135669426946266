import { Badge, Box, Stack, Typography } from '@mui/material';
import { TicketSupportIcon } from 'components/UI/SvgIcons';
import { useAlert } from 'contexts/alertContext';
import { notificationMarkAsRead } from 'framework/services';
import { INotification } from 'models/app';

// import { NotificationMarkAsRead } from 'framework/users/mutation/update-notification';
import React from 'react';

interface Props {
	notification: INotification;
	refetch: () => void;
}

interface Props {
	notification: INotification;
}

const NotificationCard: React.FC<Props> = ({ notification, refetch }) => {
	const { setAlert } = useAlert();
 
 
	const markRead = async (Id: number) => {
		try {
			await notificationMarkAsRead(Id);
			redirect();
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err.Message || 'error.serverError',
				type: 'error'
			});
		}
	};

	const redirect = () => window.location.href = notification.RedirectionURL.replace(/\/\//g, "/").replace('http:/', 'http://');


	return (
		<Stack
			alignItems={'start'}
			useFlexGap
			sx={{
				cursor: !notification.IsRead ? 'pointer' : 'default',
				'&:hover': {
					boxShadow: !notification.IsRead ? '0px 0px 10px 0px rgba(0,0,0,0.1)' : null
				}
			}}
			onClick={() => (!notification.IsRead ? markRead(notification.Id) : redirect())}
			borderBottom={1}
			borderColor={'divider'}
			p={2}>
			<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
				<Badge variant={'dot'} color={'error'} invisible={notification.IsRead} />
				{notification.ImagePath && <Box component={'img'} src={notification.ImagePath}
					sx={{
						width: 50, height: 50,
						borderRadius: '50%',
						objectFit: 'contain'
					}} />}
				{!notification.ImagePath && <TicketSupportIcon />}
				<Stack spacing={1} useFlexGap>
					<Typography
						variant={'body2'}
						fontSize={15}
						fontWeight={400}
						color={'black'}
						sx={{
							lineClamp: 2,
							boxOrient: 'vertical',
							maxWidth: 350,
						}}>
						{notification.Message}
					</Typography>
					<Typography variant={'body2'} fontWeight={400} color={'GrayText'}>{
						notification.CreatedByFullName
					}</Typography>
					<Typography variant={'body2'} color={'GrayText'}>{
						new Date(notification.CreatedOn).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
						+ ' ' + new Date(notification.CreatedOn).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
					}</Typography>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default NotificationCard;
