import { Box, Grid, TableCell, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Feature, ResponseResult } from 'models/api';
import { InsightsCells, getInsights } from 'framework/insights';
import ActionLoader from 'components/UI/ActionLoader';
import useLoading from 'hooks/loading';
import useInsightsStore from 'store/insights';
import List from 'components/UI/List';
import { Permission } from 'models/permission';
import useApp from 'hooks/useApp';

interface Props {
	feature: Feature;
}

interface ColoredCircleProps {
	color: string;
	size: number;
}

const ColoredCircle: React.FC<ColoredCircleProps> = ({ color, size }) => {
	return <Box sx={{ backgroundColor: color, width: size, height: size, borderRadius: '50%' }} />;
};

const Insights: React.FC<Props> = ({ feature }) => {
	const { loading, setLoading } = useLoading();
	const [error, setError] = useState<boolean>(false);
	const setListing = useInsightsStore((state) => state.setListing);
	const listing = useInsightsStore((state) => state.listing);
	const params = useInsightsStore((state) => state.params);
	const queryClient = useQueryClient();


	const { push } = useApp();


	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 2);

	useEffect(() => {
		if (!currentPagePermissions?.HasReadPermission) {
			push('/404');
		}
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [currentPagePermissions]);

	const fetchInsights = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['insights', { query: params }], getInsights);

			setListing(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setError(true);
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [params]);

	useEffect(() => {
		fetchInsights();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params]);

	const handleRedirect = (id: number) => {
		let origin = window.location.origin;
		window.open(`${origin}/showcases/${id}`, '_blank');
	};

	if (error)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);
	if (loading) return <ActionLoader position={'absolute'} />;

	const getBodyCells = (row: any) => {
		let cells = Object.keys(row).map((key: string, i: number) => {
			if (key === 'EnteredSentence')
				return {
					id: 'EnteredSentence',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">
								{
									// show only first 30 characters
									row?.EnteredSentence?.length > 30 ? row?.EnteredSentence?.substring(0, 30) + '...' : row?.EnteredSentence
								}
							</Typography>
						</TableCell>
					)
				};
			if (key === 'FirstName')
				return {
					id: 'FirstName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.FirstName + ' ' + row?.LastName}</Typography>
						</TableCell>
					)
				};
			if (key === 'TopicName')
				return {
					id: 'TopicName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.TopicName}</Typography>
						</TableCell>
					)
				};
			if (key === 'OrganisationName')
				return {
					id: 'OrganisationName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.OrganisationName}</Typography>
						</TableCell>
					)
				};
			if (key === 'AutomatedCompetencyName') {
				const color = row?.MatchingStatus === 1 ? '226, 242, 204' : row?.MatchingStatus === 2 ? '216, 216, 216' : 'none';
				return {
					id: 'AutomatedCompetencyName',
					element: (
						<TableCell align={'left'} key={i} sx={{ minWidth: '200px' }}>
							{row?.AutomatedCompetencyName ? (
								<>
									{/* <Stack direction={'row'} spacing={2} alignItems={'center'}  pl={1}>
										<ColoredCircle color={`rgba(${color}, 1)`} size={7} />
									</Stack> */}

									<Typography variant="body1" sx={{ backgroundColor: `rgba(${color}, 1)`, textAlign: 'center', mb: 1 }}>
										{row?.AutomatedCompetencyName}
									</Typography>
									<Typography variant="caption" sx={{ textAlign: 'center', display: 'block' }}>
										{row?.AutomatedIndicator && `(${row?.AutomatedIndicator})`}
									</Typography>
								</>
							) : null}
						</TableCell>
					)
				};
			}
			if (key === 'InteractionStatusName')
				return {
					id: 'InteractionStatusName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.InteractionStatusName}</Typography>
						</TableCell>
					)
				};
			if (key === 'UpdatedCompetencyName') {
				const color = row?.MatchingStatus === 1 ? '226, 242, 204' : row?.MatchingStatus === 2 ? '216, 216, 216' : 'none';
				return {
					id: 'UpdatedCompetencyName',
					element: (
						<TableCell align={'left'} key={i} sx={{ minWidth: '200px' }}>
							{row?.UpdatedCompetencyName && row?.UpdatedIndicator ? (
								<>
									{/* <Stack direction={'row'} spacing={2} alignItems={'center'}  pl={1}>
										<ColoredCircle color={`rgba(${color}, 1)`} size={7} />
									</Stack> */}

									<Typography variant="body1" sx={{ backgroundColor: `rgba(${color}, 1)`, textAlign: 'center', mb: 1 }}>
										{row?.UpdatedCompetencyName}
									</Typography>
									<Typography variant="caption" sx={{ textAlign: 'center', display: 'block' }}>
										{row?.UpdatedIndicator && `(${row?.UpdatedIndicator})`}
									</Typography>
								</>
							) : null}
						</TableCell>
					)
				};
			}
			if (key === 'ReviewerFirstName') {
				return {
					id: 'ReviewerFirstName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{`${row?.ReviewerFirstName} ${row?.ReviewLastName}`}</Typography>
						</TableCell>
					)
				};
			}

			if (key === 'RelevanceVoteName')
				return {
					id: 'RelevanceVoteName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.RelevanceVoteName}</Typography>
						</TableCell>
					)
				};

			if (key === 'ShowCaseId')
				return {
					id: 'ShowCaseId',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography
								variant="body2"
								sx={{
									cursor: 'pointer',
									color: '#0077B6',
									':hover': {
										color: '#000',
										textDecoration: 'underline'
									}
								}}
								onClick={() => handleRedirect(row?.ShowCaseId)}>
								{row?.ShowCaseId}
							</Typography>
						</TableCell>
					)
				};
			if (key === 'UpdatedOn')
				return {
					id: 'UpdatedOn',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">
								{new Date(row?.UpdatedOn).toLocaleDateString('en-GB', {
									day: 'numeric',
									month: 'short',
									year: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
									second: '2-digit'
								})}
							</Typography>
						</TableCell>
					)
				};
			else return <></>;
		});
		return cells;
	};

	return (
		<Box m={5}>
			<List headCells={InsightsCells} data={listing} getBodyCells={getBodyCells} feature={feature} canEdit={false} />
		</Box>
	);
};

export default Insights;
