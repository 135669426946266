import { Divider, IconButton, Stack, TableCell, Typography } from '@mui/material';
import React from 'react';
import List from 'components/UI/List';
import { Feature } from 'models/api';
import { PillarCells } from 'framework/competency';
import { IPillar } from 'models/pillar';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import TranslateIcon from '@mui/icons-material/Translate';
import { Permission } from 'models/permission';

interface Props {
	pillars: IPillar[];
	feature: Feature;
	onOpenEditPillar: (pillar: IPillar) => void;
	onOpenDeletePillar: (pillar: IPillar) => void;
	onOpenTranslatePillar: (pillar: IPillar) => void;
}

const ListPillars: React.FC<Props> = ({ pillars, feature, onOpenEditPillar, onOpenDeletePillar, onOpenTranslatePillar }) => {
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 13);

	const getBodyCells = (row: IPillar) => {
		let cells = Object.keys(row)?.map((key: string, i: number) => {
			if (key === 'Name')
				return {
					id: 'name',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.Name}</Typography>
						</TableCell>
					)
				};
		 

			if (key === 'Id')
				return {
					id: 'actions',
					element: (
						<TableCell align={'left'} key={i}>
							<Stack direction={'row'} alignItems={'center'} spacing={1} useFlexGap>
								{!!currentPagePermissions?.HasModifyPermission && (
									<IconButton size="small" color="inherit" onClick={() => onOpenEditPillar(row)}>
										<EditOutlined fontSize="small" />
									</IconButton>
								)}

								{!!currentPagePermissions?.HasDeletePermission && (
									<IconButton size="small" color="inherit" onClick={() => onOpenDeletePillar(row)}>
										<DeleteOutline fontSize="small" />
									</IconButton>
								)}

								{!!currentPagePermissions?.HasModifyPermission && (
									<IconButton size="small" color="inherit" onClick={() => onOpenTranslatePillar(row)}>
										<TranslateIcon fontSize="small" />
									</IconButton>
								)}
							</Stack>
						</TableCell>
					)
				};
			else return <></>;
		});
		return cells;
	};

	return (
		<Stack divider={<Divider />}>
			<Stack p={0} spacing={1} useFlexGap>
				<List headCells={PillarCells} data={pillars || []} getBodyCells={getBodyCells} feature={feature} canEdit={false} canSelect={false} />
			</Stack>
		</Stack>
	);
};

export default ListPillars;
