import React, { useState } from 'react';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import DeleteDialog from 'Pages/ResponseForm/partials/DeleteDialog';
import DialogModal from 'components/UI/DialogModal';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import { InputTypes } from 'constants/enums';
import { IFormField } from 'models/app';
import { IEditUserProfileFormBuilder, IUserProfileFormValue } from 'models/settings';
import { useAlert } from 'contexts/alertContext';
import { ResponseResult } from 'models/api';
import { NewAddOptionForm, addProfileFieldOption, updateProfileFieldOption, updateProfileFieldOptionSort } from 'framework/settings';
import ActionLoader from 'components/UI/ActionLoader';
import { AddCircle } from '@mui/icons-material';
import AddProfileBuilderOption from './AddProfileBuilderOption';
import DraggableFormOptions from './DragableFormOptions';
import { Permission } from 'models/permission';

interface Props {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	onDelete: () => void;
	onDeleteOption: (id: number) => void;
	onChange: (name: string, value: string) => void;
	loading: boolean;
	currentFormField: IEditUserProfileFormBuilder;
	//onUpdateCurrentFormField: (updatedFormField: IEditUserProfileFormBuilder) => void;
	fetchData: () => void;
}

const EditProfileBuilder: React.FC<Props> = ({ open, loading, currentFormField, onChange, onClose, onSubmit, onDelete, fetchData, onDeleteOption }) => {
 	const { setAlert } = useAlert();
	const [showDelete, setShowDelete] = useState<boolean>(false);
	const [showDeleteAddOption, setShowDeleteAddOption] = useState<boolean>(false);
	const [newOptionValue, setNewOptionValue] = useState<NewAddOptionForm | null>(null);
	const [Loading, setLoading] = useState<boolean>(false);

	let permissionsLocalStorage: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissionsLocalStorage?.find((item) => item.PermissionId === 10);

	const NameField: IFormField[] = [
		{
			name: 'name',
			type: InputTypes.TEXT,
			label: 'Name',
			isHorizontal: false,
			defaultValue: currentFormField?.name
		}
	];

	const LabelField: IFormField[] = [
		{
			name: 'fieldLabel',
			type: InputTypes.TEXT,
			label: 'Label',
			isHorizontal: false,
			defaultValue: currentFormField?.fieldLabel
		}
	];

	const IsRequiredField: IFormField[] = [
		{
			name: 'isRequired',
			type: InputTypes.SWITCH,
			label: 'Required Field',
			isHorizontal: false,
			defaultValue: currentFormField?.isRequired
		}
	];

	const handleShowDelete = () => {
		setShowDelete(true);
	};

	const handleShowAddOption = () => {
		setShowDeleteAddOption(true);
	};

	const handleCloseDelete = () => {
		setShowDelete(false);
	};

	const handleCloseShowAddOption = () => {
		setShowDeleteAddOption(false);
	};

	const handleDeleteUser = () => {
		handleCloseDelete();
		onClose();
		onDelete();
	};

	const handleAddNewValue = async () => {
		handleCloseShowAddOption();

		setLoading(true);

		try {
			const res: ResponseResult = await addProfileFieldOption({
				fieldId: currentFormField?.Id,
				value: newOptionValue?.value!
			});

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Added',
				type: 'success'
			});
			setLoading(false);
			//queryClient.refetchQueries(['getProfileBuilder', { query: '' }]);
			fetchData();
			//onClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	const handleUpdateOption = async (data: Partial<IUserProfileFormValue>) => {
		setLoading(true);
		try {
			const res: ResponseResult = await updateProfileFieldOption(data);

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Updated',
				type: 'success'
			});
			setLoading(false);
			//queryClient.refetchQueries(['getProfileBuilder', { query: '' }]);
			fetchData();
			//onClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	if (loading || Loading) return <ActionLoader position={'absolute'} />;
	const handleChangeFormAddFormField = (name: string, value: any) => setNewOptionValue({ ...newOptionValue!, [name]: value });

	const handleSort = async (drag: number, drop: number) => {
		const newArray = [...currentFormField?.Values];
		const temp = newArray[drag];
		newArray[drag] = newArray[drop];
		newArray[drop] = temp;

		const resultString = newArray.map((item, index) => `${item.Id}-${index + 1}`).join(',');

		setLoading(true);
		try {
			const res: ResponseResult = await updateProfileFieldOptionSort(resultString);

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Updated',
				type: 'success'
			});
			setLoading(false);
			fetchData();
			//queryClient.refetchQueries(['getProfileBuilder', { query: '' }]);
			//onClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	return (
		<DialogModal
			open={open}
			onClose={onClose}
			maxWidth="xl"
			title="Form Builder"
			children={
				<Box sx={{ backgroundColor: 'rgba(235, 237, 238, 1)', p: 4, width: '40vw' }}>
					<Stack direction={'column'} spacing={2}>
						<Typography variant="h6" my={1}>
							{currentFormField?.fieldLabel}
						</Typography>
						<Form key={currentFormField?.name} fields={NameField} onChange={onChange} />
						<Form key={currentFormField?.fieldLabel} fields={LabelField} onChange={onChange} />
						<Form key={+currentFormField?.isRequired} fields={IsRequiredField} onChange={onChange} />

						{!!currentPagePermissions?.HasDeletePermission && (
							<Typography onClick={handleShowDelete} sx={{ color: 'rgba(255, 32, 32, 1)', cursor: 'pointer', width: 'fit-content' }}>
								Delete Field
							</Typography>
						)}
					</Stack>

					<DeleteDialog open={showDelete} title="Delete Form Field" description={`${currentFormField?.fieldLabel!} Field`} onSubmit={handleDeleteUser} loading={false} onClose={handleCloseDelete} />

					{currentFormField?.fieldTypeId === 3 && (
						<>
							<Stack direction={'row'} justifyContent={'end'} my={2}>
								{' '}
								{!!currentPagePermissions?.HasModifyPermission && (
									<Button
										startIcon={<AddCircle sx={{ width: '30px', height: '30px' }} />}
										size="large"
										id="basic-button"
										aria-controls={open ? 'basic-menu' : undefined}
										aria-haspopup="true"
										aria-expanded={open ? 'true' : undefined}
										onClick={handleShowAddOption}>
										Add New
									</Button>
								)}
							</Stack>
							<AddProfileBuilderOption
								NewFormField={newOptionValue!}
								onClose={handleCloseShowAddOption}
								open={showDeleteAddOption}
								onSubmit={handleAddNewValue}
								onChange={handleChangeFormAddFormField}
								loading={false}
							/>
							<DraggableFormOptions fields={currentFormField?.Values!} onDelete={onDeleteOption} onFieldSubmit={handleUpdateOption} onSortUpdate={handleSort} />
						</>
					)}
				</Box>
			}
			actions={
				<>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#F0F5FD',
							color: '#8B8BAE',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onClose}>
						Cancel
					</MuiButton>
					{!!currentPagePermissions?.HasModifyPermission && (
						<MuiButton
							variant="outlined"
							size="medium"
							sx={{
								backgroundColor: '#0A94D1',
								color: '#fff',
								fontWeight: 400,
								border: 'none',
								'&:hover': { color: '#0A94D1', border: 'none' }
							}}
							disabled={loading}
							onClick={onSubmit}>
							Save
							<CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
						</MuiButton>
					)}
				</>
			}
		/>
	);
};

export default EditProfileBuilder;
