import React from 'react';

import useApp from 'hooks/useApp';
import { useAlert } from 'contexts/alertContext';
import { useMutation } from 'react-query';
import { forgetPass } from 'framework/auth';
import { Container, Grid, Typography } from '@mui/material';
import { InputTypes } from 'constants/enums';
import { IFormField } from 'models/app';

import * as Yup from "yup";
import Form from 'components/UI/Form';
import { Link } from 'react-router-dom';


const ForgetPassword: React.FC = () => {
	const { push } = useApp();
	const { setAlert } = useAlert();

  
	const mutationForgetPassword = useMutation({
		mutationFn: (createInput: any) => {
			return forgetPass(createInput);
		}
	});

	const basicFields: IFormField[] = [
		{
			name: "Email",
			label: "Email",
			type: InputTypes.TEXT,
			placeholder: "Enter your email",
		},

		 
	];

	let validations: any = {
		Email: Yup.string().required("Email is required").matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, "The email should be like this 'test@example.com'"),	 
	}

	const onSubmit = async (data: any) => {
		try {
			const res = await mutationForgetPassword.mutateAsync({ Email: data.Email!, FrombackOffice : true});
			if (res.Success === 0 ) throw new Error(res.Message || 'Something went wrong');
			setAlert({
				open: true, message: `Please check your email for reset password link`,
				type: 'success'
			});
		} catch (error: any) {
			setAlert({ open: true, message: error.message, type: 'error' });
		}
	};

	return (
		<Container maxWidth={'xs'}>
			<Grid container direction={{ xs: 'column', md: 'row' }} sx={{
				position: 'relative',
 				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
 				overflow: 'hidden',
 				border: '1px solid #ccc',
				borderRadius: '10px',
				boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
				maxHeight: '50vh',
				marginTop: '20vh',
				padding: '20px',
			}}>
				<Typography variant="h4" sx={{ marginBottom: '20px' }}>Forget Password</Typography>
				<Form
					fields={basicFields}
					cb={onSubmit}
					buttonText={"Submit"}
					validations={validations}
				/>
				<Link to="/login">Back to Login page</Link>
			</Grid>
		</Container>
	);
};

export default ForgetPassword;
