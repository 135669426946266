import { CloseOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import DeleteDialog from 'Pages/ResponseForm/partials/DeleteDialog';
import ActionLoader from 'components/UI/ActionLoader';
import DialogModal from 'components/UI/DialogModal';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import MuiOutlineButton from 'components/UI/MuiOutlineButton';
import { InputTypes } from 'constants/enums';
import { useAlert } from 'contexts/alertContext';
import { getAdminUsers, getGroupAdmins } from 'framework/users';
import { ResponseResult } from 'models/api';
import { IFormField } from 'models/app';
import { GroupForm } from 'models/settings';
import { User } from 'models/user';

import React, { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

interface Props {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	onChange: (name: string, value: string) => void;
	loading: boolean;
	onDelete: (id: number) => void;
	currentGroup: GroupForm;
}

const EditGroup: React.FC<Props> = ({ open, loading, currentGroup, onChange, onClose, onSubmit, onDelete }) => {
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	const { data, isError, isLoading } = useQuery<ResponseResult>(['users', { query: '' }], getAdminUsers);
	const [loadingAdmins, setLoadingAdmins] = useState<boolean>(false);
	const [groupsAdmins, setGroupsAdmins] = useState<User[] | null>(null);
	const [showDelete, setShowDelete] = useState<boolean>(false);

	const getCurrentAdmin = useCallback(async () => {
		setLoadingAdmins(true);

		try {
			const res: ResponseResult = await queryClient.fetchQuery(['getAdminsByGroupId', { query: `?groupId=${currentGroup?.GroupId}` }], getGroupAdmins);

			if (res.Success === 0) throw new Error(res.Message);

			setGroupsAdmins(res.Data);
			onChange('Users', res.Data?.map((item: User) => item.Id) || []);

			setLoadingAdmins(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoadingAdmins(false);
	}, [currentGroup?.GroupId]);

	useEffect(() => {
		getCurrentAdmin();
	}, []);

	if (isError)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);

	if (loading || loadingAdmins || isLoading) return <ActionLoader position={'absolute'} />;
	let emailField: IFormField[] = [
		{
			name: 'Name',
			type: InputTypes.TEXT,
			placeholder: 'Enter Group Name',
			defaultValue: currentGroup?.Name
		}
	];

	let groupField: IFormField[] = [
		{
			name: 'Description',
			type: InputTypes.TEXTAREA,
			placeholder: 'Enter Group Description',
			defaultValue: currentGroup?.Description
		}
	];

	let usersField: IFormField[] = [
		{
			name: 'Users',
			type: InputTypes.MULTI_SELECT,
			multiple: true,
			placeholder: 'Select users',
			options: data?.Data.map((user: User) => ({ label: user.FirstName + ' ' + user.LastName, value: `${user.Id}` })),
			defaultValue: groupsAdmins?.map((user: User) => ({ label: user.FirstName + ' ' + user.LastName, value: `${user.Id}` })) || []
		}
	];
	const handleShowDelete = () => {
		setShowDelete(true);
	};

	const handleCloseDelete = () => {
		setShowDelete(false);
	};

	const handleDeleteUser = () => {
		handleCloseDelete();
		onClose();
		onDelete(currentGroup?.GroupId);
	};
	return (
		<DialogModal
			open={open}
			onClose={onClose}
			maxWidth="xl"
			hideTitle={true}
			title="Invite User"
			children={
				<Stack sx={{ width: '100%', maxWidth: '50vw' }} spacing={2}>
					<Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
						<Stack pb={{ xs: 2, sm: 0 }} direction={'row'} spacing={1}>
							<Typography sx={{ color: '#3D82AB' }}>Groups</Typography>
							<Typography>&#62; </Typography>
							<Typography>New </Typography>
						</Stack>
						<MuiOutlineButton sx={{ color: 'gray', minWidth: 40 }} size="small" onClick={onClose}>
							<CloseOutlined fontSize="small" />
						</MuiOutlineButton>
					</Stack>

					<Box p={3} border={'1px solid'} borderColor={'divider'} borderRadius={'8px'}>
						<Stack spacing={2}>
							<Grid container spacing={2}>
								<Grid item xs={2}>
									Name
								</Grid>
								<Grid item xs={10}>
									<Form fields={emailField} onChange={onChange} />
								</Grid>
								<Grid item xs={2}>
									Description
								</Grid>
								<Grid item xs={10}>
									<Form fields={groupField} onChange={onChange} />
								</Grid>
								<Grid item xs={2}>
									Users
								</Grid>
								<Grid item xs={10}>
									<Form fields={usersField} onChange={onChange} />
								</Grid>
							</Grid>
							<Typography onClick={handleShowDelete} sx={{ color: '#FF2020', fontWeight: 400, '&:hover': { cursor: 'pointer' } }}>
								Delete Group
							</Typography>
						</Stack>
						<DeleteDialog open={showDelete} title="Delete Group" description="Delete Group" onSubmit={handleDeleteUser} loading={false} onClose={handleCloseDelete} />
					</Box>
				</Stack>
			}
			actions={
				<>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#F0F5FD',
							color: '#8B8BAE',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onClose}>
						Cancel
					</MuiButton>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#0A94D1',
							color: '#fff',
							fontWeight: 400,
							border: 'none',
							'&:hover': { color: '#0A94D1', border: 'none' }
						}}
						disabled={loading}
						onClick={onSubmit}>
						Save
						<CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
					</MuiButton>
				</>
			}
		/>
	);
};

export default EditGroup;
