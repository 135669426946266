import { Grid, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import MuiButton from 'components/UI/MuiButton';
import { addRefrenceSentenceSet, getRefrenceSentenceSets } from 'framework/responseForm';
import { Feature, ResponseResult } from 'models/api';
import { useQuery, useQueryClient } from 'react-query';

import { IRSSFormData, IReferenceSentenceSet } from 'models/ResponseForm';

import { useEffect, useState } from 'react';
import { FormMode } from 'constants/enums';
import { useAlert } from 'contexts/alertContext';
import React from 'react';
import ListRefrenceSets from './partials/ListRefrencesSets';
import RefrenceSentenceSetForm from './partials/RefrenceSentenceSetForm';
import EditStanderedForm from '../EditStanderedForm/EditStanderedForm';
import { BackOfficeHeader } from 'helpers/utils';
import { Permission } from 'models/permission';
import useApp from 'hooks/useApp';

type Props = {
	feature: Feature;
	onHideHeader?: (status: boolean) => void;
};

const ResponseForm: React.FC<Props> = ({ feature, onHideHeader }) => {
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 11);

	const queryClient = useQueryClient();

	React.useEffect(() => {
		if (onHideHeader) onHideHeader(true);
		return () => {
			if (onHideHeader) onHideHeader(false);
		};
	}, [feature, onHideHeader]);

	const { push } = useApp();


 
	useEffect(() => {
		if (!currentPagePermissions?.HasReadPermission) {
			push('/404');
		}
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [currentPagePermissions]);

	

	const { data: result, isError, isLoading } = useQuery<ResponseResult>(['refrenceSets', {}], getRefrenceSentenceSets);
	const [showRSSForm, setShowRSSForm] = useState<boolean>(false);
	const [showEditStanderedForm, setShowEditStanderedForm] = useState<boolean>(false);
	const [RSSFormData, setRSSFormData] = useState<IRSSFormData | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const { setAlert } = useAlert();

	if (isError)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);

	if (isLoading || loading) return <ActionLoader position={'absolute'} />;

	const data: IReferenceSentenceSet[] = result?.Data;

	const handleClose = () => {
		setShowRSSForm(false);
		setShowEditStanderedForm(false);
		setRSSFormData(null);
	};

	const handleChangeFormRSS = (name: string, value: any) => setRSSFormData({ ...RSSFormData!, [name]: value });

	const handleCreateRSS = async () => {
		setLoading(true);
		try {
			const res = await addRefrenceSentenceSet({ queryKey: `?Name=${RSSFormData?.Name!}&CountryId=${RSSFormData?.CountryId!}` });
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Response Sentence Set Added successfully',
				type: 'success'
			});
			// Invalidate and refetch the query to get the updated data
			queryClient.invalidateQueries('refrenceSets');
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	return (
		<Stack spacing={4} useFlexGap m={5}>
			<BackOfficeHeader title="Response Form Builder" />
			<Stack bgcolor={'background.paper'} border={'1px solid'} borderColor={'divider'} borderRadius={1}>
				{!!currentPagePermissions?.HasModifyPermission && (
					<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} p={2} bgcolor={'grey.200'}>
						<MuiButton variant="contained" color="primary" size="large" sx={{ borderRadius: 0, borderColor: 'white', p: 2, px: 3, backgroundColor: 'rgba(30, 169, 234, 1)' }} onClick={() => setShowRSSForm(true)}>
							ADD NEW
						</MuiButton>
						<MuiButton variant="outlined" size="large" color="inherit" onClick={() => setShowEditStanderedForm(true)}>
							Edit Standard Form
						</MuiButton>
					</Stack>
				)}

				<ListRefrenceSets feature={feature} refrences={data} />
			</Stack>

			{/*  Show Refrence Sentence Set Form */}
			{showRSSForm && (
				<RefrenceSentenceSetForm mode={FormMode.CREATE} open={showRSSForm} onClose={handleClose} onChange={handleChangeFormRSS} onSubmit={handleCreateRSS} loading={isLoading} formData={RSSFormData!} />
			)}

			{/*  Show Refrence Sentence Set Form */}
			{showEditStanderedForm && <EditStanderedForm feature={feature} open={showEditStanderedForm} close={handleClose} />}
		</Stack>
	);
};

export default ResponseForm;
