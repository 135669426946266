import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Box, Grid, Stack } from '@mui/material';
import { DateRangeProps } from 'models/api';
import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Import the styles
import 'react-date-range/dist/theme/default.css'; // Import the theme
import MuiOutlineButton from '../MuiOutlineButton';





interface Props {
    selectedRange: DateRangeProps;
    onChange: (ranges: DateRangeProps) => void;
    onReset: () => void;
}

const DateRange: React.FC<Props> = ({ selectedRange, onChange, onReset }) => {
    const [showPicker, setShowPicker] = useState(false);

    const handleSelect = (ranges: any) => onChange(ranges.selection);
    const togglePicker = () => {
        setShowPicker(!showPicker);
    };

    const handleReset = () => {
        onReset();
        setShowPicker(false);
    }
    return (
        <Stack direction={'column'} spacing={2} >
            <Box
                onClick={togglePicker}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                    width: { xs: '100%', md: 200, lg: 300 },
                    p: 1,
                    borderRadius: 1,
                    '&:hover': {
                        bgcolor: '#1B6EE4',
                    },
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    border: '1px solid rgba(0, 0, 0, 0.2)',
                    color: "#fff",
                    height: 50,

                }}
            >
                <Box sx={{ fontSize: 14, fontWeight: 500 }}>
                    {selectedRange.startDate?.toDateString()} - {selectedRange.endDate?.toDateString()}
                </Box>
                {showPicker && <ArrowDropUp />}
                {!showPicker && <ArrowDropDown />}
            </Box>


            {showPicker && (<Grid container justifyContent={'start'} sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: '#fff',
                borderRadius: '10px',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                padding: 2,
                position: 'absolute',
                top: 50,
                left: 140,
                maxWidth: 600,
            }}><DateRangePicker
                    ranges={[selectedRange]}
                    onChange={handleSelect}
                     maxDate={new Date()}
                    headerContent={<>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{ mb: 2,mx:1 }}>
                            <MuiOutlineButton onClick={togglePicker} sx={{ color: 'gray', minWidth: 40 }} size="small" >
                                <ArrowDropUp fontSize="small" />
                            </MuiOutlineButton>
                            <MuiOutlineButton onClick={handleReset} sx={{ color: 'primary', minWidth: 40 }} size="small" >
                                Reset  
                            </MuiOutlineButton>
                       </Stack>

                    </>}
                /></Grid>)}

        </Stack>
    );
};

export default DateRange;