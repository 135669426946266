import { Stack, Typography } from '@mui/material';
import MuiButton from 'components/UI/MuiButton';
import React, { useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useQueryClient } from 'react-query';
import { Feature, ResponseResult } from 'models/api';
import { addRefrenceQuickReply, deleteRefrenceQuickReplyById, getRefrenceQuickReplyById, updateRefrenceQuickReplyById, updateRefrenceQuickReplyIndex } from 'framework/responseForm';
import { useParams } from 'react-router-dom';
import DraggableForm from 'Pages/ResponseForm/partials/Dragable';
import ActionLoader from 'components/UI/ActionLoader';
import { useAlert } from 'contexts/alertContext';
import AddReference from './AddReference';
import { IQuickReply } from 'models/ResponseForm';
import { Permission } from 'models/permission';

type Props = {
	feature: Feature;
};

interface ReferencesFormData {
	referenceSentence: string;
}

interface IndexField {
	Id: number;
	Index: number;
}

function modifyFieldsOrder(firstNumber: number, secondNumber: number, fields: IQuickReply[]): IndexField[] {
	const modifiedFields: IndexField[] = [];

	fields.forEach((field) => {
		const index = field.SortOrder === firstNumber ? secondNumber : field.SortOrder === secondNumber ? firstNumber : field.SortOrder;

		modifiedFields.push({
			Id: field.Id,
			Index: index
		});
	});

	return modifiedFields.sort((a, b) => a.Index - b.Index);
}

const QuickReply: React.FC<Props> = ({ feature }) => {
	const urlParams = useParams();
	const queryClient = useQueryClient();
	let id: string = urlParams[`${feature.singleName}Id`] as string;
	const [loading, setLoading] = useState<boolean>(false);
	const [showAddReference, setShowAddReference] = useState<boolean>(false);
	const [quickReply, setQuickReply] = useState<IQuickReply[] | null>(null);
	const [addReferenceFormData, setAddReferenceFormData] = useState<ReferencesFormData | null>(null);

	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 11);

	const { setAlert } = useAlert();

	const fetchQuickReplyById = async () => {
		setLoading(true);

		try {
			const res: ResponseResult = await queryClient.fetchQuery(['QuickReplyById', { query: `?Id=${id}` }], getRefrenceQuickReplyById);

			if (res.Success === 0) throw new Error(res.Message);

			const data = res.Data;
			data.forEach((item: IQuickReply, index: number) => {
				item.SortOrder = index + 1;
			});
			setQuickReply(data);

			setLoading(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchQuickReplyById();
	}, []);

	const onDelete = async (id: number) => {
		setLoading(true);

		try {
			const res = await queryClient.fetchQuery(['deleteQuickReference', { query: `?Id=${id}` }], deleteRefrenceQuickReplyById);

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Deleted',
				type: 'success'
			});
			setLoading(false);

			fetchQuickReplyById();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	const handleChangeFormInputAddReference = (name: string, value: any) => setAddReferenceFormData({ ...addReferenceFormData!, [name]: value });
	const handleClose = () => {
		setShowAddReference(false);
		setAddReferenceFormData(null);
	};
	const handleAddNewReference = async () => {
		setLoading(true);

		try {
			const res = await queryClient.fetchQuery(['addQuickReference', { query: `?referenceSentenceSetId=${id}&referenceSentence=${addReferenceFormData?.referenceSentence}` }], addRefrenceQuickReply);

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Added',
				type: 'success'
			});
			setLoading(false);

			fetchQuickReplyById();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
		handleClose();
	};

	const handleUpdateNewReference = async (data: Partial<IQuickReply>) => {
		setLoading(true);

		try {
			const res = await queryClient.fetchQuery(['updateQuickReference', { query: `?Id=${data?.Id}&responseReferenceSentenceSetId=${id}&referenceSentence=${data?.ReferenceSentence}` }], updateRefrenceQuickReplyById);

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Updated',
				type: 'success'
			});
			setLoading(false);

			fetchQuickReplyById();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
		handleClose();
	};

	const handleQuickReplySort = async (drag: number, drop: number) => {
		setLoading(true);

		try {
			const res = await updateRefrenceQuickReplyIndex(modifyFieldsOrder(drag + 1, drop + 1, quickReply!)); //provide data

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Updated',
				type: 'success'
			});
			setLoading(false);
			fetchQuickReplyById();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	if (loading) return <ActionLoader position={'absolute'} />;
	return (
		<Stack direction={'column'} sx={{ backgroundColor: 'rgba(241, 241, 241, 1)' }} p={4}>
			<Stack direction={'row'} spacing={2} justifyContent={'end'} alignItems={'center'} my={3}>
				{!!currentPagePermissions?.HasModifyPermission && (
					<>
						<Typography>Reference Sentence</Typography>

						<MuiButton
							startIcon={<AddCircleIcon sx={{ color: 'rgba(30, 169, 234, 1)' }} />}
							variant="outlined"
							color="primary"
							size="large"
							sx={{ width: 'fit-content', borderRadius: 0, borderColor: 'rgba(241, 241, 241, 1)', p: 2, px: 3, color: 'rgba(30, 169, 234, 1)', ml: 'auto' }}
							onClick={() => setShowAddReference(true)}>
							Add New
						</MuiButton>
					</>
				)}
			</Stack>

			{/* Conditionally render DraggableForm only when data is available */}
			{quickReply && <DraggableForm key={quickReply.length} fields={quickReply} onDelete={onDelete} onFieldSubmit={handleUpdateNewReference} onSortUpdate={handleQuickReplySort} />}

			{/* Create Reference */}
			{showAddReference && (
				<AddReference open={showAddReference} onClose={handleClose} onChange={handleChangeFormInputAddReference} onSubmit={handleAddNewReference} loading={false} addReferenceFormData={addReferenceFormData!} />
			)}
		</Stack>
	);
};

export default QuickReply;
