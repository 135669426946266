import { Stack, Typography } from '@mui/material';
import DialogModal from 'components/UI/DialogModal';
import MuiButton from 'components/UI/MuiButton';
import { Competency } from 'models/Competency';

import React from 'react';

interface Props {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	currentCompetency: Competency;
}

const DeleteCompetency: React.FC<Props> = ({ open, onClose, onSubmit, currentCompetency }) => {
	return (
		<DialogModal
			open={open}
			onClose={onClose}
			maxWidth="xl"
			title="Delete Competency "
			children={
				<Stack sx={{ width: '30vw', pt: 3 }}>
					<Typography sx={{ textAlign: 'center' }}>
						Are You Sure You Want To Delete <span style={{ fontWeight: 700 }}>{`${currentCompetency?.Name}`}</span> ?
					</Typography>
				</Stack>
			}
			actions={
				<>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#F0F5FD',
							color: '#8B8BAE',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onClose}>
						Cancel
					</MuiButton>
					<MuiButton
						variant="outlined"
						color="error"
						size="medium"
						sx={{
							fontWeight: 400,
							borderColor: 'white',
							'&:hover': { color: '#F44335' }
						}}
						disabled={false}
						onClick={onSubmit}>
						Delete
					</MuiButton>
				</>
			}
		/>
	);
};

export default DeleteCompetency;
