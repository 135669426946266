import { Box, Grid, IconButton, Menu, MenuItem, Stack } from '@mui/material';
import { ShowcaseListItem } from 'models/showcase';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Typography } from '@mui/material';
import React from 'react';
import { showCaseStatus } from 'constants/enums';
import { FlagIcon, MessageIcon, TelegramIcon } from 'components/UI/SvgIcons';
import MuiOutlineButton from 'components/UI/MuiOutlineButton';

interface Props {
	openEdit: (showcase: ShowcaseListItem) => void;
	showcase: ShowcaseListItem;
	onChangeStatus: (id: number, statusId: number) => void;
}

const ShowcaseCard: React.FC<Props> = ({ openEdit, showcase, onChangeStatus }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const getCaseStatusLayout = (StatusId: number, StatusName: string) => {
		switch (StatusId) {
			case 1:
				return (
					<>
						<Stack
							sx={{
								backgroundColor: '#D2EAAF',
								px: 2,
								py: 1,
								borderRadius: '0px 0px 6px 6px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							}}
							direction={'row'}
							spacing={1}>
							<Box sx={{ width: 10, height: 10, backgroundColor: '#5CBA47', borderRadius: '100%' }} />
							<Typography variant="body2" fontWeight={500} fontSize={14} color={'#6B6F6F'}>
								{StatusName}
							</Typography>
						</Stack>
					</>
				);
			case 2:
				return (
					<>
						<Stack
							sx={{
								backgroundColor: '#D6D7F5',
								px: 2,
								py: 1,
								borderRadius: '0px 0px 6px 6px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							}}
							direction={'row'}
							spacing={1}>
							<Box sx={{ width: 10, height: 10, backgroundColor: '#7D7EDF', borderRadius: '100%' }} />
							<Typography variant="body2" fontWeight={500} fontSize={14} color={'#6B6F6F'}>
								{StatusName}
							</Typography>
						</Stack>
					</>
				);
			case 3:
				return (
					<>
						<Stack
							sx={{
								backgroundColor: '#F5D6D6',
								px: 2,
								py: 1,
								borderRadius: '0px 0px 6px 6px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							}}
							direction={'row'}
							spacing={1}>
							<Box
								sx={{
									width: 10,
									height: 10,
									backgroundColor: 'error.main',
									borderRadius: '100%'
								}}
							/>
							<Typography variant="body2" fontWeight={500} fontSize={14} color={'#6B6F6F'}>
								{StatusName}
							</Typography>
						</Stack>
					</>
				);
			default:
				return (
					<>
						<Stack
							sx={{
								backgroundColor: '#D2EAAF',
								px: 2,
								py: 1,
								borderRadius: '0px 0px 6px 6px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							}}
							direction={'row'}
							spacing={1}>
							<Box sx={{ width: 10, height: 10, backgroundColor: '#5CBA47', borderRadius: '100%' }} />
							<Typography variant="body2" fontWeight={500} fontSize={14} color={'#6B6F6F'}>
								{StatusName}
							</Typography>
						</Stack>
					</>
				);
		}
	};
	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Stack direction={'column'} spacing={2} maxWidth={300}>
			<Grid container justifyContent={'space-between'}>
				{getCaseStatusLayout(showcase.StatusId, showcase.StatusName)}
				<IconButton aria-label="more" id="long-button" aria-controls={anchorEl ? 'long-menu' : undefined} aria-expanded={anchorEl ? 'true' : undefined} aria-haspopup="true" onClick={handleClick}>
					<MoreVertIcon />
				</IconButton>
				<Menu
					id="long-menu"
					MenuListProps={{
						'aria-labelledby': 'long-button'
					}}
					sx={{
						'& .MuiPaper-root': {
							boxShadow: 'none',
							border: '1px solid #E5E5E5',
							minWidth: 120
						}
					}}
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleClose}>
					<MenuItem
						onClick={() => {
							openEdit(showcase);
							handleClose();
						}}>
						<Typography variant="body2" fontWeight={400} fontSize={15} color={'primary'}>
							Update
						</Typography>
					</MenuItem>
					<MenuItem
						onClick={() => {
							onChangeStatus(showcase.Id, showcase.StatusId === 1 ? showCaseStatus.OnHold : showCaseStatus.Active);
							handleClose();
						}}>
						<Typography variant="body2" fontWeight={400} fontSize={15} color={'#6B6F6F'}>
							{showcase.StatusId === 1 ? 'On Hold' : 'Active'}
						</Typography>
					</MenuItem>
					<MenuItem
						onClick={() => {
							onChangeStatus(showcase.Id, showCaseStatus.Deleted);
							handleClose();
						}}>
						<Typography variant="body2" fontWeight={400} fontSize={15} color={'error'}>
							Delete
						</Typography>
					</MenuItem>
				</Menu>
			</Grid>

			<Stack spacing={2} onClick={() => openEdit(showcase)} sx={{ cursor: 'pointer' }}>
				<Typography variant="body2" fontWeight={400} fontSize={12} color={'#6B6F6F'}>
					{showcase.Id}#
				</Typography>
				{showcase.Description && (
					<Typography
						variant="body2"
						fontWeight={400}
						sx={{
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							display: '-webkit-box',
							WebkitLineClamp: 3,
							WebkitBoxOrient: 'vertical',
							fontSize: 12,

							'*': {
								fontSize: 12
							}
						}}
						component="div"
						dangerouslySetInnerHTML={{ __html: showcase.Description.replace(/<[^>]*>?/gm, '') }}
					/>
				)}
			</Stack>

			<Stack direction={'row'} spacing={2}>
				<Stack direction={'row'} spacing={1}>
					<TelegramIcon />
					<Typography variant="body2" fontWeight={300} fontSize={13}>
						{showcase.NumberOfInteractions || 0}
					</Typography>
				</Stack>
				<Stack direction={'row'} spacing={1}>
					<MessageIcon />
					<Typography variant="body2" fontWeight={300} fontSize={13}>
						{showcase.NumberOfReplies || 0}
					</Typography>
				</Stack>
				<Stack direction={'row'} spacing={1}>
					<FlagIcon />
					<Typography variant="body2" fontWeight={300} fontSize={13}>
						{showcase.NumberReported || 0}
					</Typography>
				</Stack>
			</Stack>
			<Grid container>
				<MuiOutlineButton
					variant="outlined"
					size="small"
					sx={{
						backgroundColor: '#F0F5FD',
						color: '#8B8BAE',
						fontWeight: 400,
						border: 'none',
						fontSize: 12,
						cursor: 'default',
						'&:hover': {
							border: 'none'
						}
					}}
					onClick={() => {}}>
					{showcase.ProprityName}
				</MuiOutlineButton>
			</Grid>
		</Stack>
	);
};

export default ShowcaseCard;
