import { Box, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { getRefrenceSentenceSetsById } from 'framework/responseForm';
import { Feature, ResponseResult } from 'models/api';
import { useQuery } from 'react-query';
import { useState } from 'react';
import { useAlert } from 'contexts/alertContext';
import React from 'react';
import { useParams } from 'react-router-dom';
import useApp from 'hooks/useApp';
import ReferenceSentence from './partials/RefrenceSentence';
import QuickReply from './partials/QuickReply';
import { Resources } from 'constants/enums';
import { BackOfficeHeader } from 'helpers/utils';
type Props = {
	feature: Feature;
	onHideHeader?: (status: boolean) => void;
};

const ResponseFormEdit: React.FC<Props> = ({ feature, onHideHeader }) => {
	React.useEffect(() => {
		if (onHideHeader) onHideHeader(true);
		return () => {
			if (onHideHeader) onHideHeader(false);
		};
	}, [feature, onHideHeader]);

	const urlParams = useParams();
	let id: string = urlParams[`${feature.singleName}Id`] as string;
	const { data: result, isError, isLoading } = useQuery<ResponseResult>(['refrenceSetsById', { query: `?Id=${id}` }], getRefrenceSentenceSetsById);

	const [loading, setLoading] = useState<boolean>(false);
	const [index, setIndex] = useState<number>(1);
	const [selectedTab, setSelectedTab] = useState<number>(0);
	const { setAlert } = useAlert();
	const { push } = useApp();

	if (isError)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);

	if (isLoading || loading) return <ActionLoader position={'absolute'} />;

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`
		};
	}

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		event.preventDefault();
		setSelectedTab(newValue);
	};

	return (
		<Stack spacing={3} useFlexGap m={5}>
			<BackOfficeHeader
				title={['Response Form Builder', `Reference Sentence Sets #${result?.Data?.Id || ''} 	(${result?.Data?.LanguageName} : ${result?.Data?.countryName})`]}
				resorseToNavigate={Resources.RESPONSE_FORM}
			/>

			<Grid container justifyContent={'space-between'} alignItems={'center'}>
				<Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
					<Tab
						label={
							<Typography variant="body2" fontWeight={400} fontSize={12} color={'TextGray'}>
								Reference Sentence
							</Typography>
						}
						{...a11yProps(0)}
					/>
					<Tab
						label={
							<Typography variant="body2" fontWeight={400} fontSize={12} color={'TextGray'}>
								Quick Reply
							</Typography>
						}
						{...a11yProps(1)}
					/>
				</Tabs>
			</Grid>
			<Box sx={{ overflowY: 'auto' }}>
				{selectedTab === 0 && <ReferenceSentence feature={feature} />}
				{selectedTab === 1 && <QuickReply feature={feature} />}
			</Box>
		</Stack>
	);
};

export default ResponseFormEdit;
