import { Box, Stack, Typography } from '@mui/material';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import { InputTypes } from 'constants/enums';
import React, { useCallback, useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useAlert } from 'contexts/alertContext';
import { Feature, ResponseResult } from 'models/api';
import { useQueryClient } from 'react-query';
import {
	addRefrenceSentenceSetData,
	deleteRefrenceSetData,
	getCharcterByFeelingId,
	getFeelings,
	getPurposesByCharacterId,
	getRefrenceSentenceSetsByPurposeId,
	updateRefrenceSentenceSetIndex,
	updateRefrenceSetData
} from 'framework/responseForm';
import { IFormField } from 'models/app';
import { useParams } from 'react-router-dom';
import ActionLoader from 'components/UI/ActionLoader';
import AddReference from './AddReference';
import DraggableForm from 'Pages/ResponseForm/partials/Dragable';
import { ICharchter, IPurpose, IQuickReply, IReferences } from 'models/ResponseForm';
import { Permission } from 'models/permission';

type Props = {
	feature: Feature;
};

interface Fields {
	FeelingId: number;
	CharachterId: number | null;
	PurposeId: number | null;
}

interface Feeling {
	Id: number;
	Name: string;
}

interface IndexField {
	Id: number;
	Index: number;
}

function modifyFieldsOrder(firstNumber: number, secondNumber: number, fields: IReferences[]): IndexField[] {
	const modifiedFields: IndexField[] = [];

	fields.forEach((field) => {
		const index = field.SortOrder === firstNumber ? secondNumber : field.SortOrder === secondNumber ? firstNumber : field.SortOrder;

		modifiedFields.push({
			Id: field.Id,
			Index: index
		});
	});

	return modifiedFields.sort((a, b) => a.Index - b.Index);
}

interface ReferencesFormData {
	referenceSentence: string;
}

const ReferenceSentence: React.FC<Props> = ({ feature }) => {
	const queryClient = useQueryClient();
	const [showAddReference, setShowAddReference] = useState<boolean>(false);
	const [showDeleteReference, setShowDeleteReference] = useState<boolean>(false);
	const [fields, setFields] = useState<Fields | null>(null);
	const [feelings, setFeelings] = useState<Feeling[] | null>(null);
	const [charcters, setCharcters] = useState<ICharchter[] | null>(null);
	const [purposes, setPurposes] = useState<IPurpose[] | null>(null);
	const [refrences, setReferences] = useState<IReferences[] | null>(null);
	const [addReferenceFormData, setAddReferenceFormData] = useState<ReferencesFormData | null>(null);

	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 11);

	const urlParams = useParams();
	let id: string = urlParams[`${feature.singleName}Id`] as string;
	const [loading, setLoading] = useState<boolean>(false);

	const { setAlert } = useAlert();

	const fetchFeelings = useCallback(async () => {
		//setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['feelings'], getFeelings);
			setFeelings(response.Data);
			//	setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		//	setLoading(false);
		// eslint-disable-next-line
	}, []);

	const fetchCharcter = useCallback(async () => {
		if (!fields?.FeelingId) {
			setCharcters(null);
			setFields({ ...fields!, CharachterId: null });
			return;
		}
		//	setLoading(true);
		let params = `?feelingId=${fields?.FeelingId!}&languageId=1`;
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['characters', { query: params }], getCharcterByFeelingId);
			setCharcters(response.Data);
			//setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		//	setLoading(false);
		// eslint-disable-next-line
	}, [fields?.FeelingId]);

	const fetchPurpose = useCallback(async () => {
		if (!fields?.CharachterId) {
			setPurposes(null);
			setFields({ ...fields!, PurposeId: null });
			return;
		}
		//	setLoading(true);

		let params = `?feelingId=${fields?.CharachterId!}&languageId=1`;
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['purposes', { query: params }], getPurposesByCharacterId);
			setPurposes(response.Data);
			//	setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			////	setLoading(false);
		}

		// eslint-disable-next-line
	}, [fields?.CharachterId]);

	const fetchRefrenceSentence = useCallback(async () => {
		setLoading(true);

		let params = `?referenceSentenceSetId=${id}&purposeId=${fields?.PurposeId}`;
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['refrencesByPurposeId', { query: params }], getRefrenceSentenceSetsByPurposeId);

			// Handle the logic on successful data fetching
			const data = response.Data;
			data.forEach((item: IReferences, index: number) => {
				item.SortOrder = index + 1;
			});
			setReferences(data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		} finally {
			setLoading(false);
		}
	}, [fields?.PurposeId, setPurposes]);

	useEffect(() => {
		fetchFeelings();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchCharcter();
		// eslint-disable-next-line
	}, [fields?.FeelingId]);

	useEffect(() => {
		fetchPurpose();
		// eslint-disable-next-line
	}, [fields?.CharachterId]);

	useEffect(() => {
		fetchRefrenceSentence();
		// eslint-disable-next-line
	}, [fields?.PurposeId]);

	if (loading) return <ActionLoader position={'absolute'} />;

	let feelingsOptions =
		feelings?.map((feeling: Feeling) => ({
			value: `${feeling.Id}`,
			label: feeling.Name
		})) || [];

	let charactersOptions =
		charcters?.map((charchter: ICharchter) => ({
			value: `${charchter.Id}`,
			label: charchter.Label
		})) || [];

	let purposesOptions =
		purposes?.map((purpose: IPurpose) => ({
			value: `${purpose.Id}`,
			label: purpose.Label
		})) || [];

	let feelingsField: IFormField[] = [
		{
			name: 'FeelingId',
			label: 'Feeling',
			type: InputTypes.SELECT,
			options: feelingsOptions ? feelingsOptions : [],
			defaultValue: fields?.FeelingId ? feelingsOptions?.find((option) => option.value === fields?.FeelingId!?.toString()) : undefined,
			placeholder: 'Select Feeling'
		}
	];

	let charactersField: IFormField[] = [
		{
			name: 'CharachterId',
			label: 'Charchters',
			type: InputTypes.SELECT,
			options: charactersOptions ? charactersOptions : [],
			defaultValue: fields?.CharachterId ? charactersOptions?.find((option) => option.value === fields?.CharachterId!?.toString()) : undefined,
			placeholder: 'Select Charchter'
		}
	];

	let purposesField: IFormField[] = [
		{
			name: 'PurposeId',
			label: 'Purposes',
			type: InputTypes.SELECT,
			options: purposesOptions ? purposesOptions : [],
			defaultValue: fields?.PurposeId ? purposesOptions?.find((option) => option.value === fields?.PurposeId!?.toString()) : undefined,
			placeholder: 'Select Purpose'
		}
	];

	const onChangeFields = (name: string, value: any) => setFields({ ...fields!, [name]: value });
	const handleChangeFormInputAddReference = (name: string, value: any) => setAddReferenceFormData({ ...addReferenceFormData!, [name]: value });
	const handleClose = () => {
		setShowAddReference(false);
		setAddReferenceFormData(null);
	};
	const handleAddNewReference = async () => {
		setLoading(true);

		try {
			const res = await queryClient.fetchQuery(
				['addReferenceSetData', { query: `?referenceSentenceSetId=${id}&referenceSentence=${addReferenceFormData?.referenceSentence}&purposeId=${fields?.PurposeId}` }],
				addRefrenceSentenceSetData
			);

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Added',
				type: 'success'
			});
			setLoading(false);
			fetchRefrenceSentence();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);

		handleClose();
	};

	const onDelete = async (id: number) => {
		setLoading(true);

		try {
			const res = await queryClient.fetchQuery(['deleteReferenceSetData', { query: `?Id=${id}` }], deleteRefrenceSetData);

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Deleted',
				type: 'success'
			});
			setLoading(false);
			fetchRefrenceSentence();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
		handleClose();
	};

	const handleUpdateNewReference = async (data: Partial<IQuickReply>) => {
		setLoading(true);

		try {
			const res = await queryClient.fetchQuery(['updateReferenceSetData', { query: `?Id=${data?.Id}&referenceSentence=${data?.ReferenceSentence}` }], updateRefrenceSetData);

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Updated',
				type: 'success'
			});
			setLoading(false);
			fetchRefrenceSentence();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
		handleClose();
	};

	const handleReferenceSentenceSort = async (drag: number, drop: number) => {
		setLoading(true);

		try {
			const res = await updateRefrenceSentenceSetIndex(modifyFieldsOrder(drag + 1, drop + 1, refrences!)); //provide data

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Updated',
				type: 'success'
			});
			setLoading(false);
			fetchRefrenceSentence();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	return (
		<Stack sx={{ backgroundColor: 'rgba(241, 241, 241, 1)' }} direction={'column'} p={4}>
			<Box>
				<Typography py={2} variant="h6">
					Section
				</Typography>
				<Stack direction={'row'} spacing={4}>
					<Form fields={feelingsField} onChange={onChangeFields} />
					<Form fields={charactersField} onChange={onChangeFields} />
					<Form fields={purposesField} onChange={onChangeFields} />
				</Stack>
			</Box>
			<Stack direction={'row'} spacing={2} justifyContent={'end'} alignItems={'center'} my={3}>
				{!!currentPagePermissions?.HasModifyPermission && (
					<>
						<Typography>Reference Sentence</Typography>
						<MuiButton
							startIcon={<AddCircleIcon sx={{ color: 'rgba(30, 169, 234, 1)' }} />}
							disabled={!fields?.PurposeId}
							variant="outlined"
							color="primary"
							size="large"
							sx={{ width: 'fit-content', borderRadius: 0, borderColor: 'rgba(241, 241, 241, 1)', p: 2, px: 3, color: 'rgba(30, 169, 234, 1)', ml: 'auto' }}
							onClick={() => setShowAddReference(true)}>
							Add New
						</MuiButton>
					</>
				)}
			</Stack>
			{fields?.PurposeId && fields?.CharachterId && fields?.FeelingId && <DraggableForm fields={refrences!} onDelete={onDelete} onFieldSubmit={handleUpdateNewReference} onSortUpdate={handleReferenceSentenceSort} />}

			{/* Create Reference */}
			{showAddReference && (
				<AddReference open={showAddReference} onClose={handleClose} onChange={handleChangeFormInputAddReference} onSubmit={handleAddNewReference} loading={false} addReferenceFormData={addReferenceFormData!} />
			)}
		</Stack>
	);
};

export default ReferenceSentence;
