import { Box, IconButton, Input, Skeleton, Stack, Typography } from "@mui/material";
import { IFormField } from "models/app";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { AttachmentIcon } from "../SvgIcons";
import Image from "../Image";

interface Props extends IFormField {
    errors: any;
    control: any;
    required?: boolean;
    defaultValue?: any;
    watch?: any;
    onDeleteFile?: any;
    setValue?: any;
}

const RHFiles: React.FC<Props> = ({
    label,
    name,
    onChange,
    placeholder,
    disabled,
    autoFocus,
    control,
    errors,
    required,
    defaultValue,
    onDeleteFile,
    watch,
    hidden,
    setValue,
    maxSize,
    defaultImageUrl,
    showFileUrl
}) => {
    const [selectedFile, setSelectedFile] = React.useState<any | null>(null);

    const handleFileChange = (e: any) => {
        const file = e.target.files[0];
        onChange && onChange(file);
        handleDrop(file);
        setValue && setValue(name, file);
    };

    const handleDrop = (file: File) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (url) => {
            Object.assign(file, { url: url?.target?.result });
            setSelectedFile(file);
        };
    };
    const handleDelete = () => {
        onDeleteFile && onDeleteFile();
        setSelectedFile(null);
        const fileInput: any = document.getElementById(name);
        if (fileInput) fileInput.value = ""; // Clear the value of the file input
        setValue && setValue(name, null);

    };
    const handleUploadClick = () => {
        document.getElementById(name)?.click();
    };
    const getMaxSize = () => {
        // convert from KB to MB
        return maxSize ? maxSize / 1024 / 1024 : 2;
    }

    const getIconByMimeType = (fileName: string, imageUrl: string = "") => {
        let type = fileName.split(".")[fileName.split(".").length - 1];
        //check if image types 
        type = ["png", "gif", "jpeg", "jpg", "tif", "tiff", "bmp", "svg", "webp", "ico", "avif"
        ].includes(type) ? "image" : type;
        switch (type) {
            // case image types
            case 'image': return <Image url={imageUrl} alt={fileName} />;
            case "pdf": return <Image url={require('assets/icons/pdf.png')} alt={fileName} />;
            case "zip": return <Image url={require('assets/icons/zip.png')} alt={fileName} />;
            case "rar": return <Image url={require('assets/icons/rar.jpeg')} alt={fileName} />;
            case "docx" || "doc" || "odt": return <Image url={require('assets/icons/docs.jpg')} alt={fileName} />;
            default: return <Image url={require('assets/icons/file.png')} alt={fileName} />;
        }
    }

    const handleDeleteCloudImage = () => {
        onDeleteFile && onDeleteFile();
        setValue && setValue(name, null);
        setSelectedFile(null);
    }

    return (
        <Box display={hidden ? "none" : "block"} position={'relative'} >
            <>
                <Input
                    id={name}
                    name={name}
                    size="small"
                    required={required}
                    type="file"
                    inputProps={{ accept: "*" }}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    error={!!errors[name]}
                    onChange={handleFileChange}
                    sx={{
                        "& .MuiInputBase-input": { display: "none" },
                    }}
                />
                {(selectedFile && !showFileUrl) && (
                    <>
                        {getIconByMimeType(selectedFile.name, selectedFile.url)}
                        <IconButton
                            onClick={handleDelete}
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 1,
                                color: "error.main",
                                cursor: 'pointer'
                            }}
                        >
                            <DeleteIcon sx={{ cursor: 'pointer' }} />
                        </IconButton>
                    </>
                )}
                {(selectedFile && showFileUrl) && <Stack alignItems={'start'} spacing={1} >
                    {defaultImageUrl  && <Typography variant="body2" fontSize={12} fontWeight={400} sx={{
                        underline: 'hover',
                        color: "#65B6F1",
                    }}>{defaultImageUrl}
                        <IconButton
                            onClick={handleDeleteCloudImage}
                            sx={{                               
                                color: "error.main",
                                cursor: 'pointer'
                            }}
                        >
                            <DeleteIcon sx={{ cursor: 'pointer' }} />
                        </IconButton>
                    </Typography>}
                    {!defaultImageUrl && <Skeleton variant="text" width={200} height={20}  animation="wave" />}
                    
                    <Typography variant="body2" fontSize={12} fontWeight={400} color={"gray"}>* Copy url and put it in editor media path</Typography>
                   
                </Stack>}
                {(!selectedFile) && (
                    <Stack direction={'row'} spacing={4}>
                        <Stack direction={'row'} spacing={0}>
                            <AttachmentIcon />
                            <Typography variant="body2"
                                fontSize={17}
                                fontWeight={400}
                                onClick={handleUploadClick}
                                sx={{
                                    cursor: 'pointer',
                                    color: "#65B6F1"
                                }} >
                                {label}
                            </Typography>
                        </Stack>
                        <Typography variant="body2"
                            fontSize={16}
                            fontWeight={400}
                            onClick={handleUploadClick}
                            color={'gray'}
                            sx={{
                                cursor: 'pointer',
                            }} >
                            Max {getMaxSize()} MB
                        </Typography>
                    </Stack>
                    // <IconButton
                    //     onClick={handleUploadClick}
                    //     sx={{
                    //         position: "absolute",
                    //         top: "50%",
                    //         left: "50%",
                    //         transform: "translate(-50%, -50%)",
                    //         zIndex: 1,
                    //         color: "primary.main",
                    //         cursor: 'pointer'
                    //     }}
                    // >
                    //     <label htmlFor={`${name}`}>
                    //         <UploadFile sx={{
                    //             width: 84,
                    //             height: 84,
                    //             cursor: 'pointer'
                    //         }} />
                    //     </label>
                    // </IconButton>
                )}
            </>
        </Box>
    );
};

export default RHFiles;