import { FiltersProps } from 'models/filters';
import { StreamListItem } from 'models/stream';
import { create } from 'zustand';

type State = {
	listing: StreamListItem[];
	params: string;
	historyFilters: FiltersProps;
};

type Actions = {
	setListing: (payload: StreamListItem[]) => void;
	setParams: (payload: string) => void;
	setHistoryFilters: (payload: FiltersProps) => void;
};

const useStreamStore = create<State & Actions>((set) => ({
	listing: [],
	params: '',
	historyFilters: {
		ShowcaseId: null,
		LevelOfInteraction: null,
		FromDate: null,
		ToDate: null,
		Organisations: null,
		Groups: null,
		Topics: null,
		Feelings: null,
		SearchText: null
	},
	setHistoryFilters: (payload: FiltersProps) => set((state) => ({ ...state, historyFilters: payload })),
	setListing: (payload: StreamListItem[]) => set((state) => ({ ...state, listing: payload })),
	setParams: (payload: string) => set((state) => ({ ...state, params: payload }))
}));

export default useStreamStore;
