import { useForm } from 'react-hook-form';
import { Stack } from '@mui/material';

import FormFields from '../FormFields';
import { IFormField } from 'models/app';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

interface Props {
  fields: IFormField[];
  defaultValues?: any;
  onChange?: (name: string, value: any) => void;
  validations?: any;
  reset?: Boolean;
  isHorizontal?: Boolean;
  width?: string | number;
  setFormValue?: (callback: (name: string, value: any) => void) => void;
}

const validation = (validations: any) => {
  if (validations) return Yup.object().shape(validations);
  else return Yup.object().shape({});
};

const CustomForm: React.FC<Props> = ({ fields, defaultValues, onChange, setFormValue, validations, reset, isHorizontal, width }) => {
  const {
    control,
    watch,
    setValue,
    reset: _reset,
    register,
    formState: { errors }
  } = useForm<any>({ defaultValues, mode: 'all', resolver: yupResolver(validation(validations) as any) });


  useEffect(() => {
    if (reset) {
      _reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  useEffect(() => {
    if (setFormValue) {
      setFormValue((name: string, value: any) => {
        setValue(name, value);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFormValue]);

  return (
    <>
      {fields.length > 0 && (
        <Stack direction={isHorizontal ? 'row' : 'column'} spacing={2} width={width||'100%'}>
          {fields.map((field: any, index: number) => {
            return (
              <FormFields
                key={index}
                {...field}
                control={control}
                errors={errors}
                watch={watch}
                setValue={setValue}
                register={register}
                onChange={(e: any) => {
                  onChange && onChange(field.name, (!e && e !== false) ? null : e.target?.value! || e?.value! || e!);
                }}
              />
            );
          })}
        </Stack>
      )}
    </>
  );
};

export default CustomForm;
