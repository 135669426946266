import DateRange from 'components/UI/DateRangePicker/DateRangePicker';
import { Resources } from 'constants/enums';
import { DateRangeProps, Feature } from 'models/api';
import React, { useEffect, useState } from 'react';
import useBacklogStore from 'store/backlog';
import useDataCenterStore from 'store/dataCenter';
import useInsightsStore from 'store/insights';
import useReportStore from 'store/reports';
import useShowcaseStore from 'store/showcase';
import useStreamStore from 'store/stream';
import useSupportStore from 'store/support';

type Props = {
  feature: Feature;
};




const FilterByDate: React.FC<Props> = ({ feature }) => {

  const defaultRange: DateRangeProps = {
    startDate: new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()),
    endDate: new Date(),
    key: 'selection'
  }
  const showcasesParams = useShowcaseStore((state) => state.params);
  const streamsParams = useStreamStore((state) => state.params);
  const insightsParams = useInsightsStore((state) => state.params);
  const backlogParams = useBacklogStore((state) => state.params);
  const reportsParams = useReportStore((state) => state.params);
  const dataCenterParams = useDataCenterStore((state) => state.params);
  const supportParams = useSupportStore((state) => state.params);



  const setShowcasesParams = useShowcaseStore((state) => state.setParams);
  const setStreamsParams = useStreamStore((state) => state.setParams);
  const setInsightsParams = useInsightsStore((state) => state.setParams);
  const setBacklogParams = useBacklogStore((state) => state.setParams);
  const setReportsParams = useReportStore((state) => state.setParams);
  const setDataCenterParams = useDataCenterStore((state) => state.setParams);
  const setSupportParams = useSupportStore((state) => state.setParams);

  const [selectedDefaultRange, setSelectedDefaultRange] = useState<DateRangeProps>(defaultRange);
  const [selectedShowcaseRange, setSelectedShowcaseRange] = useState<DateRangeProps>(defaultRange);
  const [selectedStreamsRange, setSelectedStreamsRange] = useState<DateRangeProps>(defaultRange);
  const [selectedInsightsRange, setSelectedInsightsRange] = useState<DateRangeProps>(defaultRange);
  const [selectedBacklogRange, setSelectedBacklogRange] = useState<DateRangeProps>(defaultRange);
  const [selectedReportsRange, setSelectedReportsRange] = useState<DateRangeProps>(defaultRange);
  const [selectedDataCenterRange, setSelectedDataCenterRange] = useState<DateRangeProps>(defaultRange);
  const [selectedSupportRange, setSelectedSupportRange] = useState<DateRangeProps>(defaultRange);


  useEffect(() => {
    if (feature.slug.toLowerCase() === Resources.SHOW_CASES) {
      setSelectedShowcaseRange(defaultRange);
      setShowcasesParams(getQueryParams(showcasesParams, defaultRange));
    }
    if (feature.slug.toLowerCase() === Resources.INSIGHTS) {
      setSelectedInsightsRange(defaultRange);
      setInsightsParams(getQueryParams(insightsParams, defaultRange));
    }
    if (feature.slug.toLowerCase() === Resources.STREAM) {
      setSelectedStreamsRange(defaultRange);
      setStreamsParams(getQueryParams(streamsParams, defaultRange));
    }
    if (feature.slug.toLowerCase() === Resources.BACKLOG) {
      setSelectedBacklogRange(defaultRange);
      setBacklogParams(getQueryParams(backlogParams, defaultRange));
    }
    if (feature.slug.toLowerCase() === Resources.REPORTS) {
      setSelectedReportsRange(defaultRange);
      setReportsParams(getQueryParams(reportsParams, defaultRange));
    }

    if (feature.slug.toLowerCase() === Resources.DATA_CENTER) {
      setSelectedDataCenterRange(defaultRange);
      setDataCenterParams(getQueryParams(dataCenterParams, defaultRange));
    }

    if (feature.slug.toLowerCase() === Resources.SUPPORT) {
      setSelectedSupportRange(defaultRange);
      setSupportParams(getQueryParams(supportParams, defaultRange));
    }

    else setSelectedDefaultRange(defaultRange);
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [feature]);



  const getQueryParams = (params: string, ranges: DateRangeProps) => {
    let existParams = params.split('?');
    if (existParams.length > 0 && params !== '') {
      let queryParams = existParams[1].split('&');
      queryParams = queryParams.filter((item) => !item.includes('fromDate') && !item.includes('toDate'));
      queryParams.push(`fromDate=${new Date(ranges.startDate).toISOString()}`);
      queryParams.push(`toDate=${new Date(ranges.endDate).toISOString()}`);

      return `?${queryParams.join('&')}`;
    } else {
      return `?fromDate=${new Date(ranges.startDate).toISOString()}&toDate=${new Date(ranges.endDate).toISOString()}`;
    }
  };


  // Select Date Range
  const handleSelectDefault = (ranges: DateRangeProps) => setSelectedDefaultRange(ranges);

  const handleSelectForShowcase = (ranges: DateRangeProps) => {
    setSelectedShowcaseRange(ranges);
    setShowcasesParams(getQueryParams(showcasesParams, ranges));
  }
  const handleSelectStreams = (ranges: DateRangeProps) => {
    setSelectedStreamsRange(ranges);
    setStreamsParams(getQueryParams(streamsParams, ranges));
  }
  const handleSelectInsights = (ranges: DateRangeProps) => {
    setSelectedInsightsRange(ranges);
    setInsightsParams(getQueryParams(insightsParams, ranges));
  }
  const handleSelectBacklog = (ranges: DateRangeProps) => {
    setSelectedBacklogRange(ranges);
    setBacklogParams(getQueryParams(backlogParams, ranges));
  }

  const handleSelectReports = (ranges: DateRangeProps) => {
    setSelectedReportsRange(ranges);
    setReportsParams(getQueryParams(reportsParams, ranges));
  }

  const handleSelectDataCenter = (ranges: DateRangeProps) => {
    setSelectedDataCenterRange(ranges);
    setDataCenterParams(getQueryParams(dataCenterParams, ranges));
  }

  const handleSelectSupport = (ranges: DateRangeProps) => {
    setSelectedSupportRange(ranges);
    setSupportParams(getQueryParams(supportParams, ranges));
  }

  // Reset Date Range

  const handleResetDefaultFilterDate = () => {
    setSelectedDefaultRange(defaultRange);
  }

  const handleResetShowcaseFilterDate = () => {
    setSelectedShowcaseRange(defaultRange);
    setShowcasesParams(getQueryParams(showcasesParams, defaultRange));
  }
  const handleResetStreamFilterDate = () => {
    setSelectedStreamsRange(defaultRange);
    setStreamsParams(getQueryParams(streamsParams, defaultRange));
  }
  const handleResetInsightsFilterDate = () => {
    setSelectedInsightsRange(defaultRange);
    setInsightsParams(getQueryParams(insightsParams, defaultRange));
  }
  const handleResetBacklogFilterDate = () => {
    setSelectedBacklogRange(defaultRange);
    setBacklogParams(getQueryParams(backlogParams, defaultRange));
  }
  const handleResetReportsFilterDate = () => {
    setSelectedReportsRange(defaultRange);
    setReportsParams(getQueryParams(reportsParams, defaultRange));
  }

  const handleResetDataCenterFilterDate = () => {
    setSelectedDataCenterRange(defaultRange);
    setDataCenterParams(getQueryParams(dataCenterParams, defaultRange));
  }

  const handleResetSupportFilterDate = () => {
    setSelectedSupportRange(defaultRange);
    setSupportParams(getQueryParams(supportParams, defaultRange));
  }

  const getDatePickerByPage = () => {
    if (!feature) return null;
    if (feature.slug.toLowerCase() === Resources.SHOW_CASES) {
      return <DateRange selectedRange={selectedShowcaseRange} onChange={handleSelectForShowcase}
        onReset={handleResetShowcaseFilterDate}
      />;
    }
    if (feature.slug.toLowerCase() === Resources.INSIGHTS) {
      return <DateRange selectedRange={selectedInsightsRange} onChange={handleSelectInsights}
        onReset={handleResetInsightsFilterDate}
      />;
    }
    if (feature.slug.toLowerCase() === Resources.STREAM) {
      return <DateRange selectedRange={selectedStreamsRange} onChange={handleSelectStreams}
        onReset={handleResetStreamFilterDate}
      />;
    }
    if (feature.slug.toLowerCase() === Resources.BACKLOG) {
      return <DateRange selectedRange={selectedBacklogRange} onChange={handleSelectBacklog}
        onReset={handleResetBacklogFilterDate}
      />;
    }
     
    if (feature.slug.toLowerCase() === Resources.REPORTS) {
      return <DateRange selectedRange={selectedReportsRange} onChange={handleSelectReports}
        onReset={handleResetReportsFilterDate}
      />;
    }

    if (feature.slug.toLowerCase() === Resources.DATA_CENTER) {
      return <DateRange selectedRange={selectedDataCenterRange} onChange={handleSelectDataCenter}
        onReset={handleResetDataCenterFilterDate}
      />;
    }

    if (feature.slug.toLowerCase() === Resources.SUPPORT) {
      return <DateRange selectedRange={selectedSupportRange} onChange={handleSelectSupport}
        onReset={handleResetSupportFilterDate}
      />;
    }

    else return <DateRange selectedRange={selectedDefaultRange} onChange={handleSelectDefault}
      onReset={handleResetDefaultFilterDate}
    />

  };


  return (
    <>
      {getDatePickerByPage()}

    </>
  );
};

export default FilterByDate;
