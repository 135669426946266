import { Box, Grid, Input, Stack, Typography } from "@mui/material";
import React from "react";
import { TelegramIcon } from "../SvgIcons";

interface Props {
  onChange: (file: File) => void;
}

const CustomUploadFile: React.FC<Props> = ({ onChange }) => {

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    onChange(file);
  };

  const handleUploadClick = () => {
    document.getElementById('file')?.click();
  };

  return (
    <Box position={'relative'} >
      <>
        <Input
          id={'file'}
          name={'file'}
          size="small"
          type="file"
          onChange={handleFileChange}
          sx={{
            "& .MuiInputBase-input": { display: "none" },
          }}
        />

        <Grid container
          alignItems={'center'}
          justifyContent={'center'}
          direction={'column'}
          sx={{
            border: '1px dashed #ccc',
            borderRadius: 1,
            bgcolor: '#F2FBFC',
            p: 2
          }}
          onClick={handleUploadClick}
        >
          <Stack direction={'row'} spacing={1}>
            <TelegramIcon fill={"#1E8CC6"} />
            <Typography variant="body2" fontWeight={500} fontSize={13} sx={{
              color: "#1E8CC6",
            }}>
              Upload filled template here</Typography>
          </Stack>
          <Typography variant="body2" fontWeight={300} fontSize={13} color={"#6B6F6F"}>Drag and drop, or click to select</Typography>
        </Grid>
      </>
    </Box>
  );
};

export default CustomUploadFile;