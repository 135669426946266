import { HeadCell } from 'models/app';
import axios from '../helpers/axiosInstance';
import {
	ADD_SUPPORT_CATEGORY,
	ADD_SUPPORT_REPLY,
	ASSIGN_TICKET_TO_CATEGORY,
	CLOSE_SUPPORT_TICKET,
	DELETE_SUPPORT_CATEGORY,
	DELETE_SUPPORT_TICKET,
	EDIt_SUPPORT_CATEGORY,
	GET_SUPPORT_CATEGORIES_LIST,
	GET_TICKETS,
	GET_TICKET_BY_ID,
	GET_TICKET_REPLIES,
	IN_PROGRESS_SUPPORT_TICKET,
	SPAM_SUPPORT_TICKET
} from 'constants/api';

export async function getSupportCategories({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_SUPPORT_CATEGORIES_LIST + query);
	return response.data;
}

export async function getSupportTickets({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_TICKETS + query);
	return response.data;
}

export async function getTicketReply({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_TICKET_REPLIES + query);
	return response.data;
}

export async function getTicketById({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_TICKET_BY_ID + query);
	return response.data;
}

export async function addCategory({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(ADD_SUPPORT_CATEGORY + queryKey);
	return response.data;
}

export async function editCategory({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(EDIt_SUPPORT_CATEGORY + queryKey);
	return response.data;
}

export async function deleteCategory({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(DELETE_SUPPORT_CATEGORY + queryKey);
	return response.data;
}
export async function deleteTicket({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(DELETE_SUPPORT_TICKET + queryKey);
	return response.data;
}

export async function spamTicket({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(SPAM_SUPPORT_TICKET + queryKey);
	return response.data;
}

export async function assignTicketToCategory({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(ASSIGN_TICKET_TO_CATEGORY + queryKey);
	return response.data;
}

export async function closeTicket({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(CLOSE_SUPPORT_TICKET + queryKey);
	return response.data;
}

export async function inProgressTicket({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(IN_PROGRESS_SUPPORT_TICKET + queryKey);
	return response.data;
}

export async function addReply(data: FormData) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(ADD_SUPPORT_REPLY, data);
	return response.data;
}

export const CategoryCells: HeadCell[] = [
	{
		id: 'name',
		numeric: false,
		disablePadding: false,
		label: 'Name'
	},
	{
		id: 'actions',
		numeric: false,
		disablePadding: false,
		label: '',
		isNotSortable: true
	}
 
];

export interface Category {
	Id: number;
	Name: string;
}

export interface AddReplyForm {
	SupportId: number;
	Title: string;
	Description: string;
	AttachmentPath?: string;
}

// export interface AddToCategoryForm {
// 	SupportId: number;
// 	Title: string;
// 	Description: string;
// 	AttachmentPath: string;
// }

export interface ITicket {
	Id: number;
	SupportCategoryId: number;
	SupportCategoryName: string;
	SupportStatusId: number;
	SupportStatusName: string;
	Title: string;
	Description: string;
	Attachment: string;
	CreatedBy: number;
	CreatedByFirstName: string;
	CreateByLastName: string;
	CreatedOn: string;
	UpdatedBy: number;
	UpdatedByFirstName: string;
	UpdatedByLastName: string;
	UpdatedOn: string;
}

export interface IReply {
	Id: number;
	SupportId: number;
	Title: string;
	Description: string;
	Attachment: string;
	CreatedBy: number;
	CreatedByFirstName: string;
	CreatedByLastName: string;
	CreatedOn: string;
	CreatedByEmail: string;
}
