import { create } from 'zustand';

type State = {
	// selectedReportSection: "competencies" | "users" | "tests" | "organizations" | "reports";
	// listingCompetencies: IReportCompetency[];
	// listingUsers: User[];
	// listingTests: IReportTest[];
	// listingOrganizations: IReportOrganizations[];
	params: string;
	listing: any[];
};

type Actions = {
	// setSelectedReportSection: (payload: "competencies" | "users" | "tests" | "organizations" | "reports") => void;
	// setListingCompetencies: (payload: IReportCompetency[]) => void;
	// setListingUsers: (payload: User[]) => void;
	// setListingTests: (payload: IReportTest[]) => void;
	// setListingOrganizations: (payload: IReportOrganizations[]) => void;
	setListing: (payload: any[]) => void;
	setParams: (payload: string) => void;

};

const useReportStore = create<State & Actions>((set) => ({
	// selectedReportSection: "competencies",
	// listingCompetencies: [],
	// listingUsers: [],
	// listingTests: [],
	// listingOrganizations: [],
	params: '',
	listing: [],
	// setSelectedReportSection: (payload) => set(() => ({ selectedReportSection: payload })),
	// setListingCompetencies: (payload) => set(() => ({ listingCompetencies: payload })),
	// setListingUsers: (payload) => set(() => ({ listingUsers: payload })),
	// setListingTests: (payload) => set(() => ({ listingTests: payload })),
	// setListingOrganizations: (payload) => set(() => ({ listingOrganizations: payload })),
	setListing: (payload) => set(() => ({ listing: payload })),
	setParams: (payload) => set(() => ({ params: payload })),
}));
 
export default  useReportStore;
