import { useState } from 'react';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

type Props = {
	onSelectDuration: (value: number) => void;
};

const DurationsFilter = ({ onSelectDuration }: Props) => {
	const [selectedDuration, setSelectedDuration] = useState<number>(1);

	const handleDurationChange = (event: React.MouseEvent<HTMLElement>, newDuration: number | null) => {
		if (newDuration !== null) {
			setSelectedDuration(newDuration);
			onSelectDuration(newDuration);
		}
	};

	return (
		<ToggleButtonGroup size="medium" value={selectedDuration} exclusive onChange={handleDurationChange} aria-label="active users" sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
			<ToggleButton value={1} aria-label="daily active users">
				<Typography textTransform={'none'}>Daily</Typography>
			</ToggleButton>
			<ToggleButton value={2} aria-label="weekly active users">
				<Typography textTransform={'none'}>Weekly</Typography>
			</ToggleButton>
			<ToggleButton value={3} aria-label="monthly active users">
				<Typography textTransform={'none'}>Monthly</Typography>
			</ToggleButton>
		</ToggleButtonGroup>
	);
};

export default DurationsFilter;
