import React, { useCallback, useEffect, useState } from 'react';
import { IconButton, Stack, TableCell, Typography } from '@mui/material';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import List from 'components/UI/List';
import { Feature, ResponseResult } from 'models/api';
import { UserCells, deleteUser, getUsers } from 'framework/users';
import { User } from 'models/user';
import DeleteUser from '../DeleteUser/DeleteUser';
import EditUserAccount from '../EditUserAccount';
import { useAlert } from 'contexts/alertContext';
import { useMutation, useQueryClient } from 'react-query';
import ActionLoader from 'components/UI/ActionLoader';
import { editUser } from 'framework/organizations';
import useUserManagementStore from 'store/userManagement';
import { Permission } from 'models/permission';
import { formatDistanceToNow } from 'date-fns';
import { enUS } from 'date-fns/locale';

interface Props {
	feature: Feature;
}

const ListUsers: React.FC<Props> = ({ feature }) => {
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 5);

	const [currentUser, setCurrentUser] = useState<User>({} as User);
	const [showDelete, setShowDelete] = useState<boolean>(false);
	const [showEdit, setShowEdit] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	const listing = useUserManagementStore((state) => state.listing);
	const setListing = useUserManagementStore((state) => state.setListing);
	const params = useUserManagementStore((state) => state.params);

	const editUserMutation = useMutation({
		mutationFn: (data: FormData) => editUser(data),
		onSuccess: () => {
			fetchUsers();
		}
	});

	const fetchUsers = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['users', { query: params }], getUsers);
			setListing(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [params]);

	useEffect(() => {
		fetchUsers();
		// eslint-disable-next-line
	}, [params]);

	if (loading) return <ActionLoader position={'absolute'} />;

	const handleEdit = (e: any, row: User) => {
		e.stopPropagation();
		setShowEdit(true);
		setCurrentUser(row);
	};

	const handleClose = () => {
		setShowDelete(false);
		setShowEdit(false);
		setCurrentUser({} as User);
	};

	const handleDelete = (e: any, row: User) => {
		e.stopPropagation();
		setShowDelete(true);
		setCurrentUser(row);
	};

	const getBodyCells = (row: User) => {
		let cells = Object.keys(row).map((key: string, i: number) => {
			if (key === 'FirstName')
				return {
					id: 'FirstName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.FirstName + ' ' + row.LastName}</Typography>
						</TableCell>
					)
				};
			if (key === 'OrganizationName')
				return {
					id: 'OrganizationName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.OrganizationName}</Typography>
						</TableCell>
					)
				};
			if (key === 'GroupName')
				return {
					id: 'GroupName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.GroupName}</Typography>
						</TableCell>
					)
				};
			if (key === 'LastLogin')
				return {
					id: 'LastLogin',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">
								{row?.LastLogin && <Typography variant="caption">{`${formatDistanceToNow(new Date(row?.LastLogin), { addSuffix: true, locale: enUS })}`}</Typography>}
								{!row?.LastLogin && <Typography variant="caption">Never</Typography>}
								{/* {new Date(row?.LastLogin).toLocaleString('en-US', {
									year: 'numeric',
									month: '2-digit',
									day: '2-digit',
									hour: '2-digit',
									minute: '2-digit',
									hour12: true
								})} */}
							</Typography>
						</TableCell>
					)
				};

			if (key === 'EmailAddress')
				return {
					id: 'EmailAddress',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.EmailAddress}</Typography>
						</TableCell>
					)
				};

			if (key === 'UserStatusName')
				return {
					id: 'UserStatusName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.UserStatusName}</Typography>
						</TableCell>
					)
				};

			if (key === 'Id')
				return {
					id: 'actions',
					element: (
						<TableCell align={'left'} key={i}>
							<Stack direction={'row'} alignItems={'center'} spacing={1} useFlexGap>
								{!!currentPagePermissions?.HasModifyPermission && (
									<IconButton size="small" color="inherit" onClick={(e) => handleEdit(e, row)}>
										<EditOutlined fontSize="small" />
									</IconButton>
								)}

								{!!currentPagePermissions?.HasDeletePermission && (
									<IconButton size="small" color="inherit" onClick={(e) => handleDelete(e, row)}>
										<DeleteOutline fontSize="small" />
									</IconButton>
								)}
							</Stack>
						</TableCell>
					)
				};
			else return <></>;
		});
		return cells;
	};

	const handleDeleteUser = async () => {
		setLoading(true);
		try {
			const res = await deleteUser(currentUser?.Id!);
			if (res.Success === 0) throw new Error(res.Message);
			setLoading(false);
			setAlert({
				open: true,
				message: 'User deleted successfully',
				type: 'success'
			});
			fetchUsers();
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleChangeEditUserFormData = (name: string, value: any) => {
		if (name === 'OrganizationId') setCurrentUser({ ...currentUser, OrganizationId: value, GroupId: null });
		else setCurrentUser({ ...currentUser, [name]: value });
	};

	const handleEditUser = async () => {
		setLoading(true);
		let formData = new FormData();

		formData.append(`userId`, `${currentUser.Id}`);
		formData.append(`firstName`, currentUser.FirstName);
		formData.append(`lastName`, `${currentUser.LastName}`);
		formData.append(`GroupId`, `${currentUser.GroupId}`);
		formData.append(`notificationEmailAddress`, currentUser.NotificationEmailAddress!);
		formData.append(`phoneCountryCode`, `${currentUser.PhoneCountryCode}`);
		formData.append(`phoneNumber`, currentUser.PhoneNumber);
		formData.append(`organizationId`, `${currentUser.OrganizationId || ''}`);
		formData.append(`countryId`, `${currentUser.CountryId || ''}`);
		formData.append(`languageId`, `${currentUser.LanguageId || ''}`);
		formData.append(`ProfileImagePath`, currentUser.ProfileImagePath);
		try {
			const res = await editUserMutation.mutateAsync(formData);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'User updated successfully',
				type: 'success'
			});

			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	return (
		<>
			<List headCells={UserCells} data={listing} getBodyCells={getBodyCells} feature={feature} canEdit={false} />
			<DeleteUser currentUser={currentUser!} open={showDelete} onClose={handleClose} onSubmit={handleDeleteUser} loading={false} />
			{showEdit && currentUser && <EditUserAccount onSubmit={handleEditUser} open={showEdit} onClose={handleClose} CurrentUser={currentUser!} onChange={handleChangeEditUserFormData} />}
		</>
	);
};

export default ListUsers;
