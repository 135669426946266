import { CircularProgress, Stack } from '@mui/material';
import DialogModal from 'components/UI/DialogModal';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import { InputTypes } from 'constants/enums';
import { IFormField } from 'models/app';
import { ICategoryWithTest, ICategoryInput } from 'models/testBank';
import React from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onChange: (name: string, value: string) => void;
  loading: boolean;
  editCategoryFormData: ICategoryInput;
  currentCategory: ICategoryWithTest
};


const EditCategory: React.FC<Props> = ({ open, loading, editCategoryFormData, currentCategory, onChange, onClose, onSubmit }) => {

  let editCategoryFields: IFormField[] = [
    {
      name: 'Name',
      type: InputTypes.TEXT,
      placeholder: 'Enter Category Name',
      defaultValue: currentCategory?.CategoryName
    }
  ];
  return (
    <DialogModal
      open={open}
      onClose={onClose}
      maxWidth="xl"
      title="Edit Category"
      children={
        <Stack sx={{ width: '30vw', pt: 3 }}>
          <Form fields={editCategoryFields} onChange={onChange} />
        </Stack>
      }
      actions={
        <>
          <MuiButton
            variant="outlined"
            size="medium"
            sx={{
              backgroundColor: '#F0F5FD',
              color: '#8B8BAE',
              fontWeight: 400,
              border: 'none',
              '&:hover': { border: 'none' }
            }}
            onClick={onClose}>
            Cancel
          </MuiButton>
          <MuiButton
            variant="outlined"
            size="medium"
            sx={{
              backgroundColor: '#0A94D1',
              color: '#fff',
              fontWeight: 400,
              border: 'none',
              '&:hover': { color: '#0A94D1', border: 'none' }
            }}
            disabled={loading || !editCategoryFormData?.Name}
            onClick={onSubmit}>
            Update
            <CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
          </MuiButton>
        </>
      }
    />
  );
};

export default EditCategory;