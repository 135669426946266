import { Box, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import MuiButton from 'components/UI/MuiButton';
import { FiltersProps } from 'models/filters';
import StatusesFilters from '../GlobalFilters/StatusesFilters';
import CategoriesFilters from '../GlobalFilters/CategoriesFilters';
import useTestBankStore from 'store/testBank';
import LanguageFilters2 from '../GlobalFilters/LanguageFilters2';
import CompetencyFilters2 from '../GlobalFilters/CompetencyFilters2';

type Props = {
	handleFiltersClose: () => void;
	setSelectedFilters?: (data: FiltersProps) => void;
	selectedFilters?: FiltersProps;
};

const TestFilters: React.FC<Props> = ({ handleFiltersClose, setSelectedFilters, selectedFilters }) => {
 	const setParams = useTestBankStore((state) => state.setParams);
	const params = useTestBankStore((state) => state.params);
 
	const [filters, setFilters] = useState<FiltersProps>({
		LanguageId: selectedFilters?.LanguageId || null,
		TestStatuses: selectedFilters?.TestStatuses || null,
		Competencies: selectedFilters?.Competencies || null,
		Categories: selectedFilters?.Categories || null,
 	});

 
	const handleChange = (name: string, value: any) => {
		setFilters((prev) => ({ ...prev, [name]: value }));
		// remove null values and update selected filters
		const _selectedFilters: FiltersProps = Object.fromEntries(Object.entries({ ...filters, [name]: value }).filter(([_, v]) => v));
		if (!_selectedFilters.organizationIds) delete _selectedFilters.userGroupIds;
		setSelectedFilters && setSelectedFilters(_selectedFilters);
	};

	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		let newParams = params ? JSON.parse(params) : {};
		newParams = { ...newParams, ...filters };
		let query = JSON.stringify(newParams)		 
 		setParams(query);
 		handleFiltersClose();
	};

	return (
		<Box component={'form'} onSubmit={onSubmit} p={2} minWidth={'30dvw'}>
			<Stack spacing={4} useFlexGap>
				<LanguageFilters2 onChange={handleChange} filters={filters} />
				<CompetencyFilters2 onChange={handleChange} filters={filters} />
				<StatusesFilters onChange={handleChange} filters={filters} />
				<CategoriesFilters onChange={handleChange} filters={filters} />
				<Grid container justifyContent={'center'}>
					<MuiButton type="submit" variant="contained" size="large">
						Search
					</MuiButton>
				</Grid>
			</Stack>
		</Box>
	);
};

export default TestFilters;
