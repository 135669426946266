import { Alert } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { Resources } from 'constants/enums';
import { getUserPermissions } from 'framework/permissions';
import { fetchServices } from 'framework/services';
import useApp from 'hooks/useApp';
import { Feature, User } from 'models/api';
import { Permission } from 'models/permission';
import React from 'react';
import { useEffect } from 'react';
import { QueryClient, useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';

interface Props {

}


const DashboardView = (props: Props) => {
    const { push } = useApp();
    const { pathname, search } = useLocation();
    const [features, setFeatures] = React.useState<Feature[]|null>(null);
    const [permissions, setPermissions] = React.useState < Permission[] | null>(null);
    const queryClient = new QueryClient();



    const mutationGetUserPermissions = useMutation({
        mutationFn: () => {
            return getUserPermissions();
        }
    });
    const fetchFeatures = async () => {
        const response = await queryClient.fetchQuery('features', fetchServices);
        return setFeatures(response);
    }


    const getAuth = async () => {
        const token = localStorage.getItem('refresh-token');
        if (!token) {
            const pathname = window.location.pathname;
            if (pathname !== '/login' && pathname !== '/') sessionStorage.setItem('redirect', pathname)
            return push('/login');
        }
        try {
            let response = await mutationGetUserPermissions.mutateAsync();
            if (response?.Data && response?.Data?.length > 0) {
                localStorage.setItem('permissions', JSON.stringify(response?.Data));
                handleRedirect(response.Data, features!);
            }
        } catch (err) {
            const pathname = window.location.pathname;
            if (pathname !== '/login' && pathname !== '/') sessionStorage.setItem('redirect', pathname)
            push('/login');
        }
    };

    useEffect(() => {
         fetchFeatures();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (features) getAuth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [features]);

    const handleRedirect = (permissions: Permission[], features: Feature[]) => {
        let user: User | null = JSON.parse(localStorage.getItem('user')!) || null;
        if (!user) push('/login');
        if (features && features.length > 0) {
            features = features.filter(
                (feature: Feature) => permissions.some((permission: Permission) => feature.PermissionId === `${permission.PermissionId}` && permission.HasReadPermission === 1) || feature.PermissionId === '-1'
            );

            features.forEach((feature: Feature) => {
                feature.children = feature.children.filter(
                    (child: Feature) => permissions.some((permission: Permission) => child.PermissionId === `${permission.PermissionId}` && permission.HasReadPermission === 1) || child.PermissionId === '-1'
                );
            });
        }

        features = features?.filter((feature: Feature) => feature.PermissionId === '-1' ? feature.children.length > 0 : feature);

        let activePermission: Permission[] = permissions.filter((permission: Permission) => permission.HasReadPermission === 1);
        let isStream = activePermission.some((permission: Permission) => permission.PermissionId === 1);

        if (isStream) {
            push(`/${Resources.STREAM}`);
        } else {
            push(`/${features[0].slug}`);
        }


    }
  

    return null;
}

export default DashboardView;