import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import DialogModal from 'components/UI/DialogModal';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import { IPermissions, IPermissionsUserForm } from 'models/settings';

import React, { useEffect, useState } from 'react';

interface Props {
	open: boolean;
	onClose: () => void;
	onSubmit: (data: IPermissionsUserForm[]) => void;
	onChange: (name: string, value: string) => void;
	loading: boolean;
	permissions: IPermissions[];
	currentUserName: string;
}

const EditUserPermissions: React.FC<Props> = ({ open, loading, permissions, onChange, onClose, onSubmit, currentUserName }) => {
	const [selectedPermission, setSelectedPermission] = useState<IPermissions | null>(null);
	const [controls, setControls] = useState<IPermissions[]>(
		permissions?.map((item: IPermissions) => ({
			Id: item?.Id,
			GroupId: item.GroupId,
			PermissionDescription: item.PermissionDescription,
			PermissionName: item.PermissionName,
			UserId: item?.UserId,
			HasDeletePermission: item?.HasDeletePermission,
			HasFullControl: item?.HasFullControl,
			HasModifyPermission: item?.HasModifyPermission,
			HasReadPermission: item?.HasReadPermission,
			PermissionId: item?.PermissionId
		}))
	);

	useEffect(() => {
		setControls(
			permissions?.map((item: IPermissions) => ({
				Id: item?.Id,
				GroupId: item.GroupId,
				PermissionDescription: item.PermissionDescription,
				PermissionName: item.PermissionName,
				UserId: item?.UserId,
				HasDeletePermission: item?.HasDeletePermission,
				HasFullControl: item?.HasFullControl,
				HasModifyPermission: item?.HasModifyPermission,
				HasReadPermission: item?.HasReadPermission,
				PermissionId: item?.PermissionId
			}))
		);
	}, [permissions]);
	const handlePermissionClick = (permission: IPermissions) => {
		if (selectedPermission?.PermissionId === permission?.PermissionId) return setSelectedPermission(null);

		setSelectedPermission(controls?.find((item: IPermissions) => item?.PermissionId === permission?.PermissionId) || null);
	};

	function updateState(permissionId: number, controlType: string, value: any) {
		const newState = controls.map((item) => {
			if (item.PermissionId === permissionId) {
				return {
					...item,
					[controlType]: value ? 1 : 0 // Set to 1, assuming you want to override with true
				};
			} else {
				return item;
			}
		});
		setControls(newState);
	}

	const handleChange = (name: string, value: string) => {
		updateState(selectedPermission?.PermissionId!, name, value);
	};

	const handleSubmitPermissions = () => {
		setSelectedPermission(null);

		onSubmit(controls);
	};

	if (loading) return <ActionLoader position={'absolute'} />;
	return (
		<DialogModal
			open={open}
			onClose={onClose}
			maxWidth="xl"
			title={`Permissions Tab For : ${currentUserName}`}
			children={
				<Box my={3} border={'1px solid'} borderColor={'divider'} borderRadius={'8px'} display="flex">
					<Grid container sx={{ minWidth: '50vw' }} alignContent={'stretch'} alignItems={'stretch'} alignSelf={'stretch'}>
						<Grid item xs={12} md={6}>
							<Box>
								<Typography
									sx={{
										backgroundColor: '#6D8199',
										padding: '1rem 1.5rem',
										borderBottom: '1px solid #E0E0E0',
										color: '#FFFFFF'
									}}>
									Permissions
								</Typography>
								<Stack direction={'column'} spacing={1.5} p={3} sx={{ backgroundColor: '#EFF3FB' }}>
									{permissions?.map((permission) => {
										return (
											<Typography
												key={permission.Id}
												onClick={() => handlePermissionClick(permission)}
												sx={{
													fontWeight: selectedPermission?.PermissionId === permission?.PermissionId ? 'bold' : 'normal',
													cursor: 'pointer',
													userSelect: 'none'
												}}>
												{permission.PermissionName}
											</Typography>
										);
									})}
								</Stack>
							</Box>
						</Grid>
						<Grid item xs={12} md={6} sx={{ backgroundColor: '#F8F7F7', overflowY: 'auto' }}>
							{selectedPermission && (
								<Stack direction={'column'} spacing={1.5} px={3} pt={2} pb={4}>
									<Typography variant="h6">{selectedPermission.PermissionName} Controls</Typography>
									<Form
										key={selectedPermission.PermissionName}
										fields={[
											{ name: 'HasFullControl', type: 'checkbox', label: 'Full Control', defaultValue: Boolean(selectedPermission.HasFullControl), checkBoxDirection: 'start' },
											{ name: 'HasReadPermission', type: 'checkbox', label: 'Read', defaultValue: Boolean(selectedPermission.HasReadPermission), checkBoxDirection: 'start' },
											{ name: 'HasModifyPermission', type: 'checkbox', label: 'Modify', defaultValue: Boolean(selectedPermission.HasModifyPermission), checkBoxDirection: 'start' },
											{ name: 'HasDeletePermission', type: 'checkbox', label: 'Delete', defaultValue: Boolean(selectedPermission.HasDeletePermission), checkBoxDirection: 'start' }
										]}
										onChange={handleChange}
									/>
								</Stack>
							)}
						</Grid>
					</Grid>
				</Box>
			}
			actions={
				<Stack sx={{ backgroundColor: '#F8F7F7', padding: '1rem 1.5rem', borderTop: '1px solid #E0E0E0', width: '100%' }} direction={'row'} justifyContent={'end'} spacing={2}>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#F0F5FD',
							color: '#8B8BAE',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onClose}>
						Cancel
					</MuiButton>

					<MuiButton variant="outlined" color="primary" size="medium" sx={{ borderRadius: 0, borderColor: 'white' }} disabled={loading} onClick={handleSubmitPermissions}>
						Apply
						<CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
					</MuiButton>
				</Stack>
			}
		/>
	);
};

export default EditUserPermissions;
