import { Box, Grid, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { ResponseResult } from 'models/api';
import { useQuery } from 'react-query';
import { FiltersProps } from 'models/filters';
import { getTestStatuses } from 'framework/testBank';
 
type Props = {
	onChange: (name: string, value: any) => void;
	filters: FiltersProps
};

const StatusesFilters: React.FC<Props> = ({ onChange, filters }) => {
	const { data: result, isError, isLoading } = useQuery<ResponseResult>(['Statuses', { query: '' }], getTestStatuses);

	if (isError)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);
	if (isLoading) return <ActionLoader position={'absolute'} />;

	let Statuses: any[] = result?.Data || [];
	const handleChange = (id: number) => {
		let SelectedStatuses = filters.TestStatuses ? filters.TestStatuses.split(',').map((item) => Statuses.find((status) => status.Id === parseInt(item))!) : [];
		const index = SelectedStatuses.findIndex((item) => item.Id === id);
		if (index === -1) {
			SelectedStatuses.push(Statuses.find((item) => item.Id === id)!);
		} else {
			SelectedStatuses.splice(index, 1);
		}

		onChange('TestStatuses', SelectedStatuses.map((item) => item.Id).join(','));
	};
 

	return (
		<Stack spacing={1} useFlexGap sx={{
			maxHeight: '20vh',
			overflowY: 'auto'		
		}}>
			<Typography variant="h6" component={'p'} fontFamily={'600'}>
				Statuses
			</Typography>
			<Stack>
				{Statuses.map((status: any, index: number) => (
					<Stack direction={'row'} key={index} >
						<Box component={'input'} type='checkbox'
							checked={filters?.TestStatuses ? filters.TestStatuses.split(',').some((item) => item === status.Id.toString()) : false}
							onChange={() => handleChange(status.Id)}
							sx={{
								cursor: 'pointer',
								mx: 1
							}}
						/>
						<Typography variant='body2' >{status.Name}</Typography>
					</Stack>
				))}
			</Stack>
		</Stack>
	);
};

export default StatusesFilters;
