import {
	ADD_PROFILE_BUILDER,
	ADD_PROFILE_BUILDER_OPTION,
	DELETE_PROFILE_BUILDER,
	DELETE_PROFILE_BUILDER_OPTION,
	GET_EMAIL_TEMPLATES,
	GET_PASSWORD_POLICY,
	GET_PRIVACY_POLICY,
	GET_PROFILE_BUILDER,
	GET_SITE_SETTINGS,
	GET_TERMS,
	GET_TRANSLATE_PROFILE_BUILDER,
	GET_TRANSLATION_KEY_MANAGMENT,
	TRANSLATE_KEY_MANAGMENT,
	TRANSLATE_PROFILE_BUILDER,
	UPDATE_EMAIL_TEMPLATES,
	UPDATE_PASSWORD_POLICY,
	UPDATE_PROFILE_BUILDER,
	UPDATE_PROFILE_BUILDER_OPTION,
	UPDATE_PROFILE_BUILDER_OPTION_SORT,
	UPDATE_SITE_SETTINGS
} from 'constants/api';
import axios from '../helpers/axiosInstance';
import { IAddUserProfileFormBuilder, IEditUserProfileFormBuilder, IEmailTemplate, IPasswordPolicy, ISiteSettings, IUserProfileFormValue } from 'models/settings';
import { TranslateFieldForm, TranslateFieldFormKeyManagment } from 'models/Competency';

export async function getSiteSettings() {
	const response = await axios.get(GET_SITE_SETTINGS);
	return response.data;
}
export async function getPasswordPolicy() {
	const response = await axios.get(GET_PASSWORD_POLICY);
	return response.data;
}
export async function getPrivacyPolicy() {
	const response = await axios.get(GET_PRIVACY_POLICY);
	return response.data;
}
export async function getTerms() {
	const response = await axios.get(GET_TERMS);
	return response.data;
}

export async function updatePasswordPolicy(data: IPasswordPolicy) {
	const response = await axios.post(`${UPDATE_PASSWORD_POLICY}`, data);
	return response.data;
}

export async function updateSiteSettings(data: ISiteSettings) {
	const response = await axios.post(`${UPDATE_SITE_SETTINGS}`, data);
	return response.data;
}

export async function getEmailTemplates() {
	const response = await axios.get(GET_EMAIL_TEMPLATES);
	return response.data;
}
export async function updateEmailTemplates(data: Partial<IEmailTemplate>) {
	const response = await axios.post(UPDATE_EMAIL_TEMPLATES, [data]);
	return response.data;
}

export async function getProfileBuilderFields({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_PROFILE_BUILDER + _params.query);
	return response.data;
}
export async function getTranslationProfileBuilderFields({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_TRANSLATE_PROFILE_BUILDER + _params.query);
	return response.data;
}

export async function deleteProfileField(Id: number) {
	const response = await axios.post(DELETE_PROFILE_BUILDER + `?Id=${Id}`);
	return response.data;
}

export async function addProfileField(data: IAddUserProfileFormBuilder) {
	const response = await axios.post(ADD_PROFILE_BUILDER + `?name=${data?.name}&fieldTypeId=${data?.fieldTypeId}&fieldLabel=${data?.fieldLabel}&isRequired=${data?.isRequired}`);
	return response.data;
}

export async function translateProfileField(data: TranslateFieldForm) {
	const response = await axios.post(TRANSLATE_PROFILE_BUILDER + `?customProfileField=${data?.customProfileField}&languageId=${data?.languageId}&label=${data?.label}`);
	return response.data;
}

export async function getTranslationKeyManagment({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_TRANSLATION_KEY_MANAGMENT + _params.query);
	return response.data;
}

export async function translateKeyManagmentField(data: TranslateFieldFormKeyManagment) {
	const response = await axios.post(TRANSLATE_KEY_MANAGMENT, data);
	return response.data;
}

export async function updateProfileField(data: IEditUserProfileFormBuilder) {
	const response = await axios.post(UPDATE_PROFILE_BUILDER + `?Id=${data?.Id}&name=${data?.name}&fieldTypeId=${data?.fieldTypeId}&fieldLabel=${data?.fieldLabel}&isRequired=${data?.isRequired}`);
	return response.data;
}

export async function deleteProfileFieldOption(Id: number) {
	const response = await axios.post(DELETE_PROFILE_BUILDER_OPTION + `?Id=${Id}`);
	return response.data;
}

export async function updateProfileFieldOption(data: Partial<IUserProfileFormValue>) {
	const response = await axios.post(UPDATE_PROFILE_BUILDER_OPTION + `?Id=${data?.Id}&fieldId=${data?.FieldId}&value=${data?.Value}`);
	return response.data;
}

export async function updateProfileFieldOptionSort(data: string) {
	const response = await axios.post(UPDATE_PROFILE_BUILDER_OPTION_SORT + `?orders=${data}`);
	return response.data;
}

export async function addProfileFieldOption(data: NewAddOptionForm) {
	const response = await axios.post(ADD_PROFILE_BUILDER_OPTION + `?fieldId=${data?.fieldId}&value=${data?.value}`);
	return response.data;
}

export interface NewAddOptionForm {
	fieldId: number;
	value: number;
}
