import React, { useRef, useEffect } from 'react';
import { Chart } from 'chart.js/auto';

interface BarChartProps {
	labels: string[];
	values: number[];
}

const BarChart: React.FC<BarChartProps> = ({ labels, values }) => {
	const chartRef = useRef<Chart | null>(null);

	useEffect(() => {
		// Destroy the previous chart before rendering a new one
		if (chartRef.current) {
			chartRef.current.destroy();
		}

		// Create a new chart
		const ctx = document.getElementById('myChart') as HTMLCanvasElement | null;
		if (ctx) {
			chartRef.current = new Chart(ctx, {
				type: 'bar',
				data: {
					labels: labels,
					datasets: [
						{
							label: 'Values',
							data: values,
							backgroundColor: 'rgba(75,192,192,1)',
							borderColor: 'rgba(75,192,192,1)',
							borderWidth: 1
						}
					]
				},
				options: {
					scales: {
						y: {
							beginAtZero: true
						}
					},
					plugins: {
						legend: {
							display: false // Set to false to hide the legend
						}
					}
				}
			});
		}

		// Cleanup function to destroy the chart when the component unmounts
		return () => {
			if (chartRef.current) {
				chartRef.current.destroy();
			}
		};
	}, [labels, values]);

	return <canvas id="myChart" style={{
		maxWidth: '100%',
		maxHeight: 400,
		overflow: 'auto'
	}} />;
};

export default BarChart;
