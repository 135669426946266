import { Stack } from '@mui/material';
import { useQueryClient } from 'react-query';
import useLoading from 'hooks/loading';
import { useCallback, useEffect, useState } from 'react';
import { IErrorLog, getErrorLog } from 'framework/dataCenter';
import { Feature, ResponseResult } from 'models/api';
import ActionLoader from 'components/UI/ActionLoader';
import React from 'react';
import ListErrors from './ListErrorLogs';
import useDataCenterStore from 'store/dataCenter';

type Props = {
	feature: Feature;
};

const Troubleshooting: React.FC<Props> = ({ feature }) => {
	const queryClient = useQueryClient();
	const { loading, setLoading } = useLoading();
	const [errorLog, setErrorLog] = useState<IErrorLog[] | null>(null);
	const params = useDataCenterStore((state) => state.params);


	const fetchErrorLog = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['fetchErrorLog', { query: params }], getErrorLog);
			setErrorLog(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [params]);

	useEffect(() => {
		fetchErrorLog();

		// eslint-disable-next-line
	}, [params]);

	if (loading) return <ActionLoader position={'absolute'} />;

	return (
		<Stack alignItems={'flex-start'} p={4} spacing={4} useFlexGap sx={{ width: '100%' }}>
			<ListErrors errorLogs={errorLog!} feature={feature} />
		</Stack>
	);
};

export default Troubleshooting;
