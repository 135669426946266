import { Box, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import MuiButton from 'components/UI/MuiButton';
import OrganizationFilters from '../GlobalFilters/OrganizationFilters';
import InteractionFilters from '../GlobalFilters/InteractionFilters';
import { FiltersProps } from 'models/filters';
import TopicFilters from '../GlobalFilters/TopicFilters';
import useStreamStore from 'store/stream';
import FeelingsFilters from '../GlobalFilters/FeelingsFilters';

type Props = {
	handleFiltersClose: () => void;
	setSelectedFilters?: (data: FiltersProps) => void;
	selectedFilters?: FiltersProps;
};

const StreamFilters: React.FC<Props> = ({ handleFiltersClose, setSelectedFilters, selectedFilters }) => {
 	const setParams = useStreamStore((state) => state.setParams);
	const setHistoryFilters = useStreamStore((state) => state.setHistoryFilters);
	const selectedParams = useStreamStore((state) => state.params);

	const [filters, setFilters] = useState<FiltersProps>({
		levelOfInteraction: selectedFilters?.levelOfInteraction || null,
		userGroupIds: selectedFilters?.userGroupIds || null,
		organizationIds: selectedFilters?.organizationIds || null,
		topicIds: selectedFilters?.topicIds || null,
		feelingIds: selectedFilters?.feelingIds || null,
		searchText: selectedFilters?.searchText || null
	});

	const getQueryParams = () => {
		const params = [];

		for (const filter in filters) {
			if (filters.hasOwnProperty(filter)) {
				const value = filters[filter as keyof FiltersProps];
				if(!value) continue;
				const encodedValue = value ? encodeURIComponent(value) : 'null';
				params.push(`${filter}=${encodedValue}`);
			}
		}

		return params.join('&');
	};

	const handleChange = (name: string, value: any) => {
		setFilters((prev) => ({ ...prev, [name]: value }));
		// remove null values and update selected filters
		const _selectedFilters: FiltersProps = Object.fromEntries(Object.entries({ ...filters, [name]: value }).filter(([_, v]) => v));
 		setSelectedFilters && setSelectedFilters(_selectedFilters);
	};
	const handleChangeMultiple = (data:{ name: string, value: any }[]) => {
		setFilters((prev) => ({ ...prev, ...Object.fromEntries(data.map((item) => [item.name, item.value])) }));
		// remove null values and update selected filters
		const _selectedFilters: FiltersProps = Object.fromEntries(Object.entries({ ...filters, ...Object.fromEntries(data.map((item) => [item.name, item.value])) }).filter(([_, v]) => v));
		setSelectedFilters && setSelectedFilters(_selectedFilters);
	};

	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		let query: string = "";
		let existParams = selectedParams.split('?');
		if (existParams.length > 0 && selectedParams !== '') {
			let queryParams = existParams[1].split('&');
			queryParams = queryParams.filter((item) => item.includes('searchText') || item.includes('fromDate') || item.includes('toDate'));
			query = `?${queryParams.join('&') + '&' + getQueryParams()}`;
		}
		else query = `?${getQueryParams()}`;
		// setSearchParams({ query });
		setParams(query);
		setHistoryFilters(filters)
		// queryClient.invalidateQueries('showcases');
		handleFiltersClose();
	};

	return (
		<Box component={'form'} onSubmit={onSubmit} p={2} minWidth={'30dvw'}>
			<Stack spacing={4} useFlexGap>
				<InteractionFilters onChange={handleChange} filters={filters} />
				<TopicFilters onChange={handleChange} filters={filters} />
				<FeelingsFilters onChange={handleChange} filters={filters} />
				<OrganizationFilters handleChangeMultiple={handleChangeMultiple} onChange={handleChange} filters={filters} />
				<Grid container justifyContent={'center'}>
					<MuiButton type="submit" variant="contained" size="large">
						Search
					</MuiButton>
				</Grid>
			</Stack>
		</Box>
	);
};

export default StreamFilters;
