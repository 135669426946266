import { Box, Grid, Skeleton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import StreamList from './partials/StreamList/StreamList';
import ActivityHistoryView from './partials/ActivityHistory/ActivityHistory';
import { useMutation, useQueryClient } from 'react-query';
import { AddBacklog, AddReplay, SetInteractionAsNone, SetRelevanceVote, approveInteraction, editStream } from 'framework/stream';
import useStreamStore from 'store/stream';
import { useAlert } from 'contexts/alertContext';
import { IBacklogInput, IEditStreamInput, IRelevanceVoteChangeInput, IReplyInput, StreamListItem } from 'models/stream';
import { ResponseResult } from 'models/api';
import { Competency } from 'models/Competency';
import { getIndicators } from 'framework/indicator';
import { getActivityHistory } from 'framework/activityHistory';
import { ActivityHistory } from 'models/activityHistory';
import { getCompetencyList } from 'framework/competency';
import { Permission } from 'models/permission';
import useApp from 'hooks/useApp';

interface Props {}

const Stream: React.FC<Props> = () => {
	const params = useStreamStore((state) => state.params);
	const historyFilters = useStreamStore((state) => state.historyFilters);
	const setParams = useStreamStore((state) => state.setParams);
	const [competencies, setCompetencies] = useState<Competency[] | null>(null);
	const [selectedCompetency, setSelectedCompetency] = useState<Competency | null>(null);
	const [indicators, setIndicators] = useState<string[] | null>(null);
	const [activityHistory, setActivityHistory] = useState<ActivityHistory[] | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const { setAlert } = useAlert();
	const queryClient = useQueryClient();

	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 1);

	const { push } = useApp();


	 
	useEffect(() => {
		if (!currentPagePermissions?.HasReadPermission) {
			push('/404');
		}
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [currentPagePermissions]);

	const approveInteractionMutation = useMutation({
		mutationFn: (id: number) => {
			return approveInteraction(id);
		},
		onSuccess: () => {
			setParams(`?${params}`);
		}
	});

	const setRelevanceVoteMutation = useMutation({
		mutationFn: (data: IRelevanceVoteChangeInput) => {
			return SetRelevanceVote(data);
		}
	});
	const addBacklogMutation = useMutation({
		mutationFn: (data: IBacklogInput) => {
			return AddBacklog(data);
		}
	});
	const addReplayMutation = useMutation({
		mutationFn: (data: IReplyInput) => {
			return AddReplay(data);
		}
	});
	const editStreamMutation = useMutation({
		mutationFn: (data: IEditStreamInput) => {
			return editStream(data);
		},
		onSuccess: () => {
			setParams(`?${params}`);
		}
	});
	const setInteractionAsNoneMutation = useMutation({
		mutationFn: (Id: number) => {
			return SetInteractionAsNone(Id);
		},
		onSuccess: () => {
			setParams(`?${params}`);
		}
	});

	// Get Competencies
	const fetchActivityHistory = useCallback(async () => {
		try {
			setLoading(true);
			const response: ResponseResult = await queryClient.fetchQuery(['activityHistory', { filters: historyFilters }], getActivityHistory);
			setActivityHistory(response.Data || []);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [historyFilters]);
	// Get Competencies
	const fetchCompetencies = useCallback(async () => {
		try {
			setLoading(true);
			const response: ResponseResult = await queryClient.fetchQuery(['competencies', { query: '' }], getCompetencyList);
			setCompetencies(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);

	const fetchIndicator = useCallback(async () => {
		if (!selectedCompetency?.Id) return setIndicators(null);
		let params = '?competencyId=' + selectedCompetency?.Id!;
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['indicators', { query: params }], getIndicators);
			setIndicators(response.Data);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}

		// eslint-disable-next-line
	}, [selectedCompetency?.Id]);

	useEffect(() => {
		fetchActivityHistory();
		// eslint-disable-next-line
	}, [historyFilters]);
	useEffect(() => {
		fetchCompetencies();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchIndicator();
		// eslint-disable-next-line
	}, [selectedCompetency?.Id]);

	const handleSelectCompetency = (competency: Competency | null) => setSelectedCompetency(competency);

	const handleApproveInteraction = async (id: number) => {
		try {
			let res = await approveInteractionMutation.mutateAsync(id);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: ' Interaction Approved Successfully',
				type: 'success'
			});
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};
	const handleAddAddReply = async (data: IReplyInput) => {
		try {
			let res = await addReplayMutation.mutateAsync(data);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Reply added Successfully',
				type: 'success'
			});
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};
	const handleAddBacklog = async (data: IBacklogInput) => {
		try {
			let res = await addBacklogMutation.mutateAsync(data);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Added to backlog Successfully',
				type: 'success'
			});
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};
	const handleSetInteractionAsNone = async (id: number) => {
		try {
			let res = await setInteractionAsNoneMutation.mutateAsync(id);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: ' Interaction Added as None Successfully',
				type: 'success'
			});
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};
	const handleEditStream = async (data: IEditStreamInput) => {
		try {
			let res = await editStreamMutation.mutateAsync(data);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Interaction updated Successfully',
				type: 'success'
			});
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};
	const handleConfirmStream = async (data: StreamListItem) => {
		try {
			let createInput: IEditStreamInput = {
				Id: data.Id,
				competencyId: data.UpdatedCompetencyId,
				indicator: data.UpdatedIndicator,
				confirm: true,
				description: data.Description
			};
			let res = await editStreamMutation.mutateAsync(createInput);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Interaction updated Successfully',
				type: 'success'
			});
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};
	const handleSetRelevanceVote = async (data: IRelevanceVoteChangeInput) => {
		try {
			let res = await setRelevanceVoteMutation.mutateAsync(data);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: ' Relevance Vote Updated Successfully',
				type: 'success'
			});
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};

	if (loading) return <Skeleton animation={'wave'} variant="rectangular" width={'100%'} height={600} />;

	if (!activityHistory)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);

	return (
		<Box m={5}>
			<Box
				sx={{
					height: 60,
					backgroundColor: (theme) => theme.palette.background.default,
					borderBottom: '3px solid rgba(0, 0, 0, 0.05)'
				}}
			/>
			<Grid container spacing={2}>
				<Grid item lg={3} md={12}>
					<ActivityHistoryView activityHistory={activityHistory!} />
				</Grid>
				<Grid item lg={9} md={12}>
					<StreamList
						onApproveInteraction={handleApproveInteraction}
						onChangeRelevanceVote={handleSetRelevanceVote}
						onAddAsNone={handleSetInteractionAsNone}
						onAddBacklog={handleAddBacklog}
						competencies={competencies}
						indicators={indicators}
						onSelectCompetency={handleSelectCompetency}
						selectedCompetency={selectedCompetency}
						activityHistory={activityHistory!}
						onAddReply={handleAddAddReply}
						onEditStream={handleEditStream}
						onConfirmStream={handleConfirmStream}
						permissions={currentPagePermissions!}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Stream;
