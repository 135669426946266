import DialogModal from 'components/UI/DialogModal';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import { InputTypes } from 'constants/enums';
import { IFormField } from 'models/app';
import * as Yup from 'yup';
import React from 'react';
import Section from 'components/UI/Section';

interface Props {
	open: boolean;
	onClose: () => void;
 	onChange: (name: string, value: string) => void;
}

const NewPassword: React.FC<Props> = ({ open, onChange, onClose }) => {
	const basicFields: IFormField[] = [
		{
			name: "Password",
			label: "New Password",
			type: InputTypes.PASSWORD,
			placeholder: "*********",
		},
		{
			name: "ConfirmPassword",
			label: "Confirm Password",
			type: InputTypes.PASSWORD,
			placeholder: "*********",
		},
	];

	let validations: any = {

		Password: Yup.string().required("Password is required").matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
		),
		ConfirmPassword: Yup
			.string()
			.oneOf([Yup.ref('Password')], 'Passwords mismatch')
			.min(8, 'Your password should have at least 8 characters.')
	}

	const handleSubmit = async (data: any) => {
		onChange('Password', data.Password);
		onClose();
	 };

	return (
		<DialogModal
			open={open}
			onClose={onClose}
			maxWidth="xl"
			title="Update Password"
			children={
				<Section sx={{ mx: 2 }}>
					<Form fields={basicFields} onChange={onChange} validations={validations}
						buttonText="Update Password" cb={handleSubmit}
 					/>
			 </Section>
			}
			actions={
				<>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#F0F5FD',
							color: '#8B8BAE',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onClose}>
						Cancel
					</MuiButton>
				 
				</>
			}
		/>
	);
};

export default NewPassword;
