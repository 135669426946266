
import { GET_STREAMS, GET_RELEVANCE_VOTES, APPROVE_INTERACTION, GET_STREAM_TABS, SET_RELEVANCE_VOTE, SET_INTERACTION_AS_NONE, ADD_TO_BACKLOG, ADD_REPLY, EDIT_STREAM } from "constants/api";
import axios from "../helpers/axiosInstance";
import { IBacklogInput, IEditStreamInput, IRelevanceVoteChangeInput, IReplyInput } from "models/stream";


export async function getStreams({ queryKey }: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, _params] = queryKey;
  const response = await axios.get(GET_STREAMS + _params.query);
  return response.data;
}
export async function getRelevanceVotes({ queryKey }: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, _params] = queryKey;
  const response = await axios.get(GET_RELEVANCE_VOTES + _params.query);
  return response.data;
}
export async function approveInteraction(id: number) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const response = await axios.post(APPROVE_INTERACTION + "?id=" + id);
  return response.data;
}
export async function SetRelevanceVote(data: IRelevanceVoteChangeInput) {
  const { Id, voteId } = data;
  const response = await axios.post(SET_RELEVANCE_VOTE + "?Id=" + Id + "&voteId=" + voteId);
  return response.data;
}
export async function editStream(data: IEditStreamInput) {
  const response = await axios.post(EDIT_STREAM, data);
  return response.data;
}
export async function AddBacklog(data: IBacklogInput) {
  const { competencyId, indicator, sentence } = data;
  const response = await axios.post(ADD_TO_BACKLOG + "?competencyId=" + competencyId + "&indicator=" + indicator + "&sentence=" + sentence + "&showcaseId=" + data.showcaseId);
  return response.data;
}
export async function AddReplay(data: IReplyInput) {
  const { responseId, replySentence } = data;
  const response = await axios.post(ADD_REPLY , {
    responseId,
    replySentence
  
  });
  return response.data;
}
export async function SetInteractionAsNone(Id: number) {
  const response = await axios.post(SET_INTERACTION_AS_NONE + "?Id=" + Id);
  return response.data;
}

export async function getStreamTabs({ queryKey }: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, _params] = queryKey;
  const response = await axios.post(GET_STREAM_TABS + _params.query);
  return response.data;
}