import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import Form from 'components/UI/Form';
import { IFormField } from 'models/app';
import { InputTypes } from 'constants/enums';
import { IAdvancedSettings } from 'models/ResponseForm';

type Props = {
	onChange: (name: string, value: string) => void;
	advancedSettings: IAdvancedSettings;
};

const Restrictions: React.FC<Props> = ({ onChange, advancedSettings }) => {
	const formFields: IFormField[] = [
		{
			name: 'LimitContentErrorMessage',
			type: InputTypes.EDITOR,
			label: 'LIMIT REACHED MESSAGE',
			defaultValue: advancedSettings?.LimitContentErrorMessage
		}
	];

	return (
		<Stack direction={'column'} sx={{ backgroundColor: 'rgba(241, 241, 241, 1)' }}>
			<Typography variant="h5">Restrictions</Typography>

			<Box sx={{ border: '1px solid rgba(204, 204, 204, 1)' }} p={3} minHeight={400}>
				<Typography my={2} mb={4}>
					INSUFFICIENT CONTENT
				</Typography>
				<Typography variant="caption">If the user picks a suggested reference sentence without writing any content, this will not be acceptable and the error message below will appear.</Typography>
				<Stack direction={'row'} my={3} spacing={2}>
					<Typography variant="h6" width={'25%'}>
						Minimum number of words
					</Typography>
					<Box>
						<Stack direction={'row'} width={{ md: '40%', xs: '80%' }}>
							<Box sx={{ width: 'fit-content' }}>
								<Form fields={[{ name: 'EnableInsufficientContent', type: InputTypes.CHECKBOX, defaultValue: advancedSettings?.EnableInsufficientContent }]} onChange={onChange} />
							</Box>
							<Form fields={[{ name: 'InsufficientContentLength', type: InputTypes.NUMBER, defaultValue: advancedSettings?.InsufficientContentLength }]} onChange={onChange} />
						</Stack>
						<Typography variant="caption">Set the minimum number of words for the response to be accepted. Otherwise, any written content length from the user will be accepted.</Typography>
					</Box>
				</Stack>
				<Form
					fields={[{ name: 'InsufficientContentErrorMessage', type: InputTypes.TEXT, label: 'Insufficient content Error Message', defaultValue: advancedSettings?.InsufficientContentErrorMessage }]}
					onChange={onChange}
				/>
				<Stack direction={'row'} my={3} spacing={2}>
					<Typography variant="h6" width={'25%'}>
						Max number of Reference Sentences
					</Typography>
					<Box>
						<Stack direction={'row'} width={{ md: '40%', xs: '80%' }}>
							<Box sx={{ width: 'fit-content' }}>
								<Form fields={[{ name: 'EnableMaxNumberOfReferenceSentences', type: InputTypes.CHECKBOX, defaultValue: advancedSettings?.EnableInsufficientContent }]} onChange={onChange} />
							</Box>

							<Form fields={[{ name: 'MaxNumberOfReferenceSentences', type: InputTypes.NUMBER, defaultValue: advancedSettings?.MaxNumberOfReferenceSentences }]} onChange={onChange} />
						</Stack>
						<Typography variant="caption">Set the maximum number of reference sentences to be added per response.</Typography>
					</Box>
				</Stack>
				<Typography my={3} variant="body1">
					LIMIT SUBMISSIONS
				</Typography>
				<Form fields={[{ name: 'SubmissionWordsLimit', type: InputTypes.NUMBER, label: 'Submission Words Limit', defaultValue: advancedSettings?.SubmissionWordsLimit }]} onChange={onChange} />
				<Stack my={5} spacing={1}>
					<Typography variant="body1">Enable Editing </Typography>
					<Typography pb={2} variant="body2">
						This option allows user to edit the reference sentences that the editor suggests.
					</Typography>
					<Form fields={[{ name: 'EnableReferenceSentenceEditing', type: InputTypes.SWITCH, defaultValue: advancedSettings?.EnableReferenceSentenceEditing }]} onChange={onChange} />
				</Stack>

				<Form fields={formFields} onChange={onChange} />
			</Box>
		</Stack>
	);
};

export default Restrictions;
