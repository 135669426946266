import { TableCell, Typography } from '@mui/material';
import List from 'components/UI/List';
import { UserTestsHeads } from 'framework/reports';
import { Feature } from 'models/api';
import { UserTests } from 'models/user';
import React from 'react';

interface Props {
  userTests: UserTests[];
  feature: Feature;

}


const UserTestsList: React.FC<Props> = ({ userTests, feature }) => {
  const getBodyCells = (row: UserTests) => {
    let cells = Object.keys(row).map((key: string, i: number) => {
      if (key === 'CompetencyName')
        return {
          id: 'CompetencyName',
          element: (
            <TableCell align={'left'} key={i} >
              {row?.CompetencyName}
            </TableCell>
          )
        };
      if (key === 'TestName')
        return {
          id: 'TestName',
          element: (
            <TableCell align={'left'} key={i} >
              {row?.TestName}
            </TableCell>
          )
        };
      if (key === 'TestRound')
        return {
          id: 'TestRound',
          element: (
            <TableCell align={'left'} key={i}>
              <Typography variant="body2">{row?.TestRound}</Typography>
            </TableCell>
          )
        };
      if (key === 'Score')
        return {
          id: 'Score',
          element: (
            <TableCell align={'left'} key={i}>
              <Typography variant="body2">{row?.Score}</Typography>
            </TableCell>
          )
        };
      if (key === 'DateTaken')
        return {
          id: 'DateTaken',
          element: (
            <TableCell align={'left'} key={i}>
              <Typography variant="body2">
                {new Date(row?.DateTaken).toLocaleString('en-US', {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true
                })}
              </Typography>
            </TableCell>
          )
        };

      
      else return <></>;
    });
    return cells;
  };
  return <List headCells={UserTestsHeads} data={userTests!} getBodyCells={getBodyCells} feature={feature} canSelect={false} canEdit={false} />;
};

export default UserTestsList;