import { Controller } from 'react-hook-form';
import { Box, Stack, Switch, Typography, styled } from '@mui/material';
import { IFormField } from 'models/app';
import { useEffect } from 'react';

interface Props extends IFormField {
	errors: any;
	watch: any;
	control: any;
	defaultValue?: any;
	onChange?: (e: any) => void;
	setValue?: any;
}
const AntSwitch = styled(Switch)(({ theme }) => ({
	width: 28,
	height: 16,
	padding: 0,
	display: 'flex',
	'&:active': {
		'& .MuiSwitch-thumb': {
			width: 15
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			transform: 'translateX(9px)'
		}
	},
	'& .MuiSwitch-switchBase': {
		padding: 2,
		'&.Mui-checked': {
			transform: 'translateX(12px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
			}
		}
	},
	'& .MuiSwitch-thumb': {
		boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
		width: 12,
		height: 12,
		borderRadius: 6,
		transition: theme.transitions.create(['width'], {
			duration: 200
		})
	},
	'& .MuiSwitch-track': {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
		boxSizing: 'border-box'
	}
}));
const RHSwitch: React.FC<Props> = ({ label, name, disabled, control, defaultValue, hidden, isHorizontal, forceUnchecked, watch, onChange, setValue }) => {
	useEffect(() => {
		if (forceUnchecked) {
			setValue(name, false);
		}
	}, [forceUnchecked, setValue, name]);
	return (
		<>
			<Stack direction={'row'} display={hidden ? 'none' : 'flex'} flexDirection={isHorizontal ? 'row' : 'column'} my={2} alignItems={isHorizontal ? 'center' : 'flex-start'} spacing={1}>
				<Typography component="label" htmlFor={`${name}`} pb={0.5}>
					{label}
				</Typography>
				<Box>
					<Controller
						control={control}
						name={name}
						render={({ field }) => (
							<>
								{!forceUnchecked && <AntSwitch
								inputProps={{ 'aria-label': 'ant design' }}
								name={field.name}
								onChange={(e: any) => onChange && onChange(e.target.checked)}
								onBlur={field.onBlur}
								ref={field.ref}
								disabled={disabled}
								defaultChecked={defaultValue}
							/>}
								{forceUnchecked && <AntSwitch
								inputProps={{ 'aria-label': 'ant design' }}
								name={field.name}
								onChange={(e: any) => onChange && onChange(e.target.checked)}
								onBlur={field.onBlur}
								ref={field.ref}
								disabled={disabled}
								defaultChecked={false}
							/>}
							</>
						)}
					/>
				</Box>
			</Stack>
		</>
	);
};

export default RHSwitch;
