import React, { useCallback, useEffect, useState } from 'react';
import { Box, Stack, Typography, Button } from '@mui/material';
import { AddCircleRounded } from '@mui/icons-material';
import { Sidebar } from 'primereact/sidebar';
import ActionLoader from 'components/UI/ActionLoader';
import AddOrganization from '../AddOrganization';
import ListOrganization from '../ListOrganization/ListOrganization';
import EditOrganization from '../EditOrganization';
import DeleteOrganization from '../Deleteorganization/DeleteOrganization';
import { useMutation, useQueryClient } from 'react-query';
import { createOrganization, deleteOrganization, editOrganization, getCountries, getOrganizations } from 'framework/organizations';
import { useAlert } from 'contexts/alertContext';
import { Country, Organization, OrganizationInput } from 'models/organization';
import { Feature, ResponseResult } from 'models/api';
import { Permission } from 'models/permission';

interface Props {
	open: boolean;
	feature: Feature;
	selectedOrganization: Organization;
	onClose: () => void;
	onSelectedOrganization: (organization: Organization) => void;
}

const OrganizationModal: React.FC<Props> = ({ open, feature, selectedOrganization, onSelectedOrganization, onClose }) => {
	const [openAddOrganization, setOpenAddOrganization] = useState(false);
	const [openEditOrganization, setOpenEditOrganization] = useState(false);
	const [openDeleteOrganization, setOpenDeleteOrganization] = useState(false);
	const [addOrganizationFormData, setAddOrganizationFormData] = useState<OrganizationInput>({} as OrganizationInput);
	const [editOrganizationFormData, setEditOrganizationFormData] = useState<OrganizationInput>({} as OrganizationInput);
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 5);

	const [organizations, setOrganizations] = useState<Organization[]>([]);
	const [countries, setCountries] = useState<Country[]>([]);
	const [loading, setLoading] = useState(false);

	const queryClient = useQueryClient();
	const { setAlert } = useAlert();

	const editOrganizationMutation = useMutation({
		mutationFn: (data: FormData) => editOrganization(data),
		onSuccess: () => {
			fetchOrganizations();
		}
	});

	const addOrganizationMutation = useMutation({
		mutationFn: (data: FormData) => createOrganization(data),
		onSuccess: () => {
			fetchOrganizations();
		}
	});

	const deleteOrganizationMutation = useMutation({
		mutationFn: (id: number) => deleteOrganization(id),
		onSuccess: () => {
			fetchOrganizations();
		}
	});

	const fetchOrganizations = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['organizations', { query: '' }], getOrganizations);
			setOrganizations(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);

	const fetchCountries = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['countries', { query: '' }], getCountries);
			setCountries(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchCountries();
		fetchOrganizations();
		// eslint-disable-next-line
	}, []);

	if (loading) return <ActionLoader position={'absolute'} />;

	const handleAddOrganization = async () => {
		setLoading(true);
		let formData = new FormData();
		if (addOrganizationFormData.LogoPath) formData.append(`LogoPath`, addOrganizationFormData.LogoPath);
		formData.append(`Name`, addOrganizationFormData.Name);
		formData.append(`CountryId`, `${addOrganizationFormData.CountryId}`);
		formData.append(`Groups`, `${addOrganizationFormData.Groups}`);
		formData.append(`ClaimedDomain`, addOrganizationFormData.ClaimedDomain!);
		formData.append(`UseOwnLogo`, `${addOrganizationFormData.UseOwnLogo}`);
		formData.append(`ColorHEXCode`, addOrganizationFormData.ColorHEXCode!);
		formData.append(`UseCustomColor`, `${addOrganizationFormData.UseCustomColor!}`);
		try {
			const res = await addOrganizationMutation.mutateAsync(formData);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Organization added successfully',
				type: 'success'
			});
			fetchOrganizations();
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleDeleteOrganization = async () => {
		setLoading(true);
		try {
			const res = await deleteOrganizationMutation.mutateAsync(selectedOrganization.Id);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Organization deleted successfully',
				type: 'success'
			});
			fetchOrganizations();
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		handleClose();
	};

	const handleEditOrganization = async () => {
		setLoading(true);
		let formData = new FormData();
		formData.append(`LogoPath`, editOrganizationFormData.LogoPath || selectedOrganization?.LogoPath!);

		formData.append(`OrganizationId`, `${editOrganizationFormData.Id! || selectedOrganization.Id! || ''}`);
		formData.append(`Name`, editOrganizationFormData.Name || selectedOrganization.Name || '');
		formData.append(`CountryId`, `${editOrganizationFormData.CountryId || selectedOrganization.CountryId || ''}`);
		//formData.append(`Groups`, `${editOrganizationFormData.Groups}`);
		formData.append(`ClaimedDomain`, editOrganizationFormData.ClaimedDomain! || selectedOrganization.ClaimedDomain! || '');
		formData.append(`UseOwnLogo`, `${editOrganizationFormData.UseOwnLogo || selectedOrganization.UseOwnLogo || ''}`);
		formData.append(`ColorHEXCode`, editOrganizationFormData.ColorHEXCode! || selectedOrganization.ColorHEXCode! || '');
		formData.append(`UseCustomColor`, `${editOrganizationFormData.UseCustomColor! || selectedOrganization.UseCustomColor || ''}`);
		try {
			const res = await editOrganizationMutation.mutateAsync(formData);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Organization updated successfully',
				type: 'success'
			});

			fetchOrganizations();
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleClose = () => {
		setOpenAddOrganization(false);
		setOpenEditOrganization(false);
		setOpenDeleteOrganization(false);
		onSelectedOrganization({} as Organization);
		setAddOrganizationFormData({} as OrganizationInput);
		setEditOrganizationFormData({} as OrganizationInput);
	};

	const handleChangeAddOrganizationForm = (name: string, value: any) => setAddOrganizationFormData({ ...addOrganizationFormData, [name]: value });

	const handleChangeEditOrganizationForm = (name: string, value: any) => setEditOrganizationFormData({ ...editOrganizationFormData, [name]: value });

	const handleOpenAddOrganization = () => setOpenAddOrganization(true);

	const handleOpenEditOrganization = (row: Organization) => {
		setOpenEditOrganization(true);
		onSelectedOrganization(row);
		setEditOrganizationFormData(row);
	};

	const handleOpenDeleteOrganization = (row: Organization) => {
		setOpenDeleteOrganization(true);
		onSelectedOrganization(row);
	};

	return (
		<Sidebar visible={open} position="right" onHide={onClose} style={{ width: '50%' }} title="Organization & Groups">
			<Box sx={{ paddingBlock: 3, paddingInline: 1 }}>
				<Stack direction={'row'} spacing={1} sx={{ width: '100%', alignItems: 'center', justifyContent: 'flex-end', mb: 3 }}>
					{!!currentPagePermissions?.HasModifyPermission && (
						<Button onClick={handleOpenAddOrganization}>
							<AddCircleRounded sx={{ marginInlineEnd: 1 }} />
							<Typography fontSize={12} color={'#0A8CC6'}>
								Add Organization
							</Typography>
						</Button>
					)}
				</Stack>
				<ListOrganization organizations={organizations} onEdit={handleOpenEditOrganization} onDelete={handleOpenDeleteOrganization} feature={feature} />

				{openAddOrganization && (
					<AddOrganization
						open={openAddOrganization}
						onClose={handleClose}
						countries={countries}
						addOrganizationFormData={addOrganizationFormData}
						onChange={handleChangeAddOrganizationForm}
						onSubmit={handleAddOrganization}
					/>
				)}
				{openEditOrganization && (
					<EditOrganization
						feature={feature}
						open={openEditOrganization}
						onClose={handleClose}
						countries={countries}
						editOrganizationFormData={editOrganizationFormData}
						onChange={handleChangeEditOrganizationForm}
						onSubmit={handleEditOrganization}
						organization={selectedOrganization}
					/>
				)}
				{openDeleteOrganization && <DeleteOrganization open={openDeleteOrganization} onClose={handleClose} onSubmit={handleDeleteOrganization} currentOrganization={selectedOrganization} loading={false} />}
			</Box>
		</Sidebar>
	);
};

export default OrganizationModal;
