import React, { useEffect } from 'react';

import useApp from 'hooks/useApp';
import { useAlert } from 'contexts/alertContext';
import { useMutation } from 'react-query';
import { activateAccount } from 'framework/auth';
import { Box, Container, Grid } from '@mui/material';

import { useParams } from 'react-router-dom';
import { InputTypes } from 'constants/enums';
import { IFormField } from 'models/app';
import Form from 'components/UI/Form';
import { resetPassFirstTime } from 'framework/users';
import * as Yup from 'yup';
import logo from 'assets/images/Logo.jpeg';




const ActivateAccount: React.FC = () => {
	const { push } = useApp();
	const { setAlert } = useAlert();
	const { token } = useParams();
	const [isInvalidToken, setIsInvalidToken] = React.useState(false);
	const [isActivated, setIsActivated] = React.useState(false);

	const resetPasswordFirstTimeMutation = useMutation({
		mutationFn: (password: string) => {
			return resetPassFirstTime(password);
		},
	});
	const activateMutation = useMutation({
		mutationFn: () => {
			return activateAccount(token!);
		},
		onSuccess: (data) => {
			if (data.Success === 0) throw new Error(data.Message || 'Something went wrong');
			setAlert({
				open: true,
				message: `Account activated successfully`,
				type: 'success'
			});
			localStorage.setItem('access-token', data.AccessToken);
			localStorage.setItem('refresh-token', data.RefreshToken);
			localStorage.setItem('user', data.Data);
			setIsActivated(true);
		},
		onError: (error: any) => {
			setIsInvalidToken(true);
		}
	});

	useEffect(() => {
		if (token) activateMutation.mutate();
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [token]);

	const basicFields: IFormField[] = [
		{
			name: "Password",
			label: "Password",
			type: InputTypes.PASSWORD,
			placeholder: "*********",
		},
		{
			name: "ConfirmPassword",
			label: "Confirm Password",
			type: InputTypes.PASSWORD,
			placeholder: "*********",
		},
	];
 
	let validations: any = {

		Password: Yup.string().required("Password is required").matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
		),
		ConfirmPassword: Yup
			.string()
			.oneOf([Yup.ref('Password')], 'Passwords mismatch')
			.min(8, 'Your password should have at least 8 characters.')
	}

	const onSubmit = async (data: any) => {
		try {
			const res = await resetPasswordFirstTimeMutation.mutateAsync(data.Password!);
			if (res.Success === 0) throw new Error(res.Message || 'Something went wrong');
			setAlert({
				open: true,
				message: `Password Set successfully`,
				type: 'success'
			});
			push('/login');
		} catch (error: any) {
			setAlert({ open: true, message: error.message, type: 'error' });
			setAlert({
				open: true,
				message: error.Message || 'Something went wrong',
				type: 'error'
			});
		}
	};

	return (
		<>
			<Container maxWidth={'xs'}>
				<Grid
					direction={{ xs: 'column', md: 'row' }}
					container
					sx={{
						position: 'relative',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						overflow: 'hidden',
						border: '1px solid #ccc',
						borderRadius: '10px',
						boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
						maxHeight:  '70vh',
						marginTop: '20vh',
						padding: '20px',
						
					}}>
					<Box width={216} alignSelf={'center'} mb={2}>
						<Box component={'img'} src={logo} alt="scofolio" sx={{ display: 'block', maxWidth: '100%', height: 'auto' }} />
					</Box>
					{isInvalidToken && <h2>Invalid Token</h2>}
					{isActivated && <>
						<h2>Account activated successfully</h2>
						<h3>Please set your password</h3>
						<Form
							fields={basicFields}
							cb={onSubmit}
							buttonText={"Set Password"}
							validations={validations}
						/>
					</>}
				</Grid>
			</Container>
		</>
	);
};

export default ActivateAccount;
