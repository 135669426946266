import React, { useState } from 'react';
import { IProgress } from 'models/progress';
import ProgressToolbar from './Partials/ProgressToolbar';
import ProgressChart from './Partials/chart';
import { Container, Grid } from '@mui/material';
import { ReportUser } from 'models/user';
import ProgressCount from './Partials/ProgressCount';
import { ProgressShowcasesSvg } from 'components/UI/SvgIcons';
 
interface Props {
  progress: IProgress;
  user:ReportUser;
}

const enum scoresGroup {
  SCORE_WORLD_WIDE = 'ScoreWorldWide',
  SCORE_COUNTRY = 'ScoreCountry',
  SCORE_BY_ORGANIZATION = 'ScoreByOrganisation'
}

const ProgressOverview: React.FC<Props> = ({ progress, user }) => { 
  const [selectedScoreName, setSelectedScoreName] = useState<string>(scoresGroup.SCORE_WORLD_WIDE);
  let group = [scoresGroup.SCORE_WORLD_WIDE, scoresGroup.SCORE_COUNTRY, scoresGroup.SCORE_BY_ORGANIZATION];

  return (
    <Container sx={{py:5}}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <ProgressToolbar
          scores={progress.ScoreForChart}
          selectedScore={selectedScoreName}
          setSelectedScore={setSelectedScoreName}
          group={group}
        />
     </Grid>
      <ProgressChart ScoreForChart={progress.ScoreForChart} selectedScore={selectedScoreName} />  
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ProgressCount icon={<ProgressShowcasesSvg />} count={progress.NumberOfShowcases} title={"Showcases"} />
        </Grid>
        <Grid item xs={6}>
          <ProgressCount icon={<ProgressShowcasesSvg />} count={progress.NumberOfTests} title={"Tests"} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProgressOverview;