import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { InputTypes } from 'constants/enums';
import { IFormField } from 'models/app';
import Form from 'components/UI/Form';

type Props = {
	onChange: (name: string, value: string) => void;
	PostSubmissionMessage: string;
};

const PostSubmission: React.FC<Props> = ({ onChange, PostSubmissionMessage }) => {
	const formFields: IFormField[] = [
		{
			name: 'PostSubmissionMessage',
			type: InputTypes.TEXTAREA,
			label: 'Post-Submission Message',
			defaultValue: PostSubmissionMessage || undefined
		}
	];

	return (
		<Stack direction={'column'} sx={{ backgroundColor: 'rgba(241, 241, 241, 1)' }}>
			<Typography variant="h5">Post Submission</Typography>

			<Box sx={{ border: '1px solid rgba(204, 204, 204, 1)' }} p={3} minHeight={400}>
				<Typography pb={3}>THANKS MESSAGE</Typography>
				<Form fields={formFields} onChange={onChange} />
			</Box>
		</Stack>
	);
};

export default PostSubmission;
