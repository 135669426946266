import axios from 'axios';
import axiosInstance from '../helpers/axiosInstance';
import { GET_ADMIN_NOTIFICATIONS, MARK_NOTIFICATION_AS_READ, NOTIFICATION_MARK_MULTIPLE_READ } from 'constants/api';
import { Feature } from 'models/api';

export async function fetchServices() {
	const url = window.location.protocol + '//' + window.location.host + '/features.json';
	const response = await axios.get<Feature[]>(url);

	return response.data;
}

export async function getNotifications() {
	const response = await axiosInstance.get(GET_ADMIN_NOTIFICATIONS);
	return response.data;
}

export async function notificationMarkAsRead(id: number) {
	const response = await axiosInstance.post(MARK_NOTIFICATION_AS_READ + `?Id=${id}`);
	return response.data;
}
export async function notificationMarkAsReadMultiple(ids: string) {
	const response = await axiosInstance.post(NOTIFICATION_MARK_MULTIPLE_READ + `?notificationIds=${ids}`);
	return response.data;
}
