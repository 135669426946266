import { ADD_SHOWCASE, DELETE_SHOWCASE, GENERATE_SHOWCASE_TEMPLATE, GET_SHOWCASE, GET_SHOWCASE_INTERACTIONS, GET_SHOWCASE_INTERACTIONS_DETAILS, GET_SINGLE_SHOWCASE, IMPORT_SHOWCASE_TEMPLATE, UPDATE_SHOWCASE, UPDATE_SHOWCASE_STATUS, UPLOAD_ATTACHMENT } from 'constants/api';
import axios from '../helpers/axiosInstance';
import { ShowcaseAddInput } from 'models/showcase';

export async function getShowcase({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_SHOWCASE + query);
	return response.data;
}
export async function getSingleShowcase({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_SINGLE_SHOWCASE + _params.query);
	return response.data;
}
export async function getShowcaseInteractions({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_SHOWCASE_INTERACTIONS + _params.query);
	return response.data;
}
export async function getShowcaseInteractionsDetails({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_SHOWCASE_INTERACTIONS_DETAILS + _params.query);
	return response.data;
}
export async function addShowcase(data: ShowcaseAddInput) {
	const response = await axios.post(ADD_SHOWCASE, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
}
export async function editShowcase(data: any) {
	const response = await axios.post(UPDATE_SHOWCASE, data);
	return response.data;
}
export async function updateShowcaseStatus(params: any) {
	const { showcaseId, statusId } = params;
	const response = await axios.post(`${UPDATE_SHOWCASE_STATUS}?showCaseId=${showcaseId}&statusId=${statusId}`);
	return response.data;
}
export async function deleteShowcase(showcaseId: number) {
	const response = await axios.post(DELETE_SHOWCASE + '?showCaseId=' + showcaseId);
	return response.data;
}
export async function generateShowcaseTemplate() {
	const response = await axios.get(GENERATE_SHOWCASE_TEMPLATE);
	return response.data;
}
export async function importShowcaseTemplate(data: FormData) {
	const response = await axios.post(IMPORT_SHOWCASE_TEMPLATE, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
});
	
	return response.data;
}
export async function UploadShowCaseImage(data: FormData) {
	const response = await axios.post(UPLOAD_ATTACHMENT, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
}
