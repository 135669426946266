import { Box, Collapse, Grid, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { Feature, ResponseResult } from 'models/api';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAlert } from 'contexts/alertContext';
import useApp from 'hooks/useApp';
import { AddReplyForm, IReply, ITicket, addReply, getTicketById, getTicketReply } from 'framework/support';
import MuiButton from 'components/UI/MuiButton';
import { IFormField } from 'models/app';
import { InputTypes, Resources } from 'constants/enums';
import { TicketSupportIcon, TranslateIcon } from 'components/UI/SvgIcons';
import CustomForm from 'components/UI/CustomForm';
import { BackOfficeHeader, parseHTML } from 'helpers/utils';
import { Permission } from 'models/permission';
import { BASE_URL } from 'constants/api';

interface Props {
	feature: Feature;
	onHideHeader?: (status: boolean) => void;
}

const Ticket: React.FC<Props> = ({ feature, onHideHeader }) => {
	const urlParams = useParams();
	let id: string = urlParams[`${feature.singleName}Id`] as string;
	const queryClient = useQueryClient();
	const { push } = useApp();
	const { setAlert } = useAlert();

	const [ticket, setTicket] = React.useState<ITicket | null>(null);
	const [replies, setReplies] = React.useState<IReply[] | null>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [isBoxOpen, setIsBoxOpen] = React.useState<boolean>(false);
	const [addReplyForm, setAddReplyForm] = useState<AddReplyForm | null>(null);
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 12);

	const handleButtonClick = () => {
		setIsBoxOpen((prev) => !prev);
	};

	const replyQueryKey = ['replies', { query: `?supportId=${id}` }];
	const ticketQueryKey = ['ticket', { query: `?Id=${id}` }];

	const {
		isError: isErrorReplies,
		isLoading: isLoadingReplies,
		refetch
	} = useQuery<ResponseResult>(replyQueryKey, getTicketReply, {
		onSuccess: (data) => {
			// Assuming `data` contains the result of the query
			setReplies(data?.Data);
		}
	});

	const { isError: isErrorTicket, isLoading: isLoadingTicket } = useQuery<ResponseResult>(ticketQueryKey, getTicketById, {
		onSuccess: (data) => {
			// Assuming `data` contains the result of the query
			setTicket(data?.Data);
		}
	});

	const addReplyMutation = useMutation({
		mutationFn: (data: FormData) => {
			return addReply(data);
		},
		onSuccess: () => {
			refetch();
		}
	});

	React.useEffect(() => {
		if (onHideHeader) onHideHeader(true);
		return () => {
			if (onHideHeader) onHideHeader(false);
		};
	}, [onHideHeader]);

	if (isErrorReplies || isErrorTicket)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);
	if (isLoadingReplies || isLoadingTicket || loading) return <ActionLoader position={'absolute'} />;

	const handleAddReplyForm = (name: string, value: any) => setAddReplyForm({ ...addReplyForm!, [name]: value });

	const handlClose = () => {
		setIsBoxOpen(false);
		setAddReplyForm(null);
	};

	const handleBack = () => push(`/${feature.slug}`);

	const getImageLink = (path: string) => {
		if (path) {
			let ifHasHostName = path.includes('http');
			if (ifHasHostName) return path;

			let _path = path.split('\\Uploads')[1];

			return `${BASE_URL}//Uploads${_path}`;
		}
		return '';
	};

	let descriptionFields: IFormField[] = [
		{
			name: 'Title',
			type: InputTypes.TEXT,
			isHorizontal: true,
			placeholder: 'Enter Title'
		},
		{
			name: 'Description',
			type: InputTypes.EDITOR,
			isHorizontal: true
		}
	];

	let fileField: IFormField[] = [
		{
			name: 'AttachmentPath',
			type: InputTypes.MULTIPLE_FILES,
			isHorizontal: true,
			limitFiles: 1
		}
	];

	const handleAddReply = async () => {
		setLoading(true);

		let formData = new FormData();
		formData.append(`Title`, addReplyForm?.Title!);
		formData.append(`Description`, `${addReplyForm?.Description}`);
		formData.append(`AttachmentPath`, addReplyForm?.AttachmentPath!);
		formData.append(`SupportId`, `${id}`);

		try {
			const res = await addReplyMutation.mutateAsync(formData);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Reply Added successfully',
				type: 'success'
			});
			setLoading(false);
			handlClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		handlClose();
	};

	return (
		<Box m={5}>
			<BackOfficeHeader title={['Support Tickets', `	Ticket #${ticket?.Id || ''}`]} resorseToNavigate={Resources.SUPPORT} />

			<Box sx={{ background: 'rgba(55, 177, 142, 1)', p: 3 }}>
				<Stack direction={'row'} spacing={2} alignItems={'center'}>
					<TranslateIcon />
					<Typography variant="h6" sx={{ color: 'white' }}>
						TICKET #{ticket?.Id}
					</Typography>
				</Stack>
			</Box>
			<Stack p={3} sx={{ backgroundColor: 'white' }} spacing={2}>
				<img src={getImageLink(ticket?.Attachment!)} style={{ maxWidth: '200px' }} />
				<Typography variant="h4">{ticket?.Title}</Typography>
				{parseHTML(ticket?.Description)}
			</Stack>

			{!!currentPagePermissions?.HasModifyPermission && (
				<Box p={2} sx={{ backgroundColor: 'rgba(229, 229, 229, 1)' }}>
					<MuiButton sx={{ backgroundColor: 'white', px: 2 }} color="inherit" onClick={handleButtonClick}>
						POST A REPLY
					</MuiButton>
					<Collapse in={isBoxOpen}>
						<Stack p={2} mt={2} alignItems={'end'} spacing={2}>
							<CustomForm fields={descriptionFields} onChange={handleAddReplyForm} />
							<Box width={'fit-content'} alignSelf={'start'}>
								<CustomForm fields={fileField} onChange={handleAddReplyForm} />
							</Box>
							<MuiButton disabled={!addReplyForm?.Title || !addReplyForm?.Description} onClick={handleAddReply}>
								Save
							</MuiButton>
						</Stack>
					</Collapse>
				</Box>
			)}

			<Stack direction={'column'} spacing={1.5}>
				{replies?.map((reply) => (
					<Stack
						direction={'row'} // Adjust direction based on screen size
						alignItems={'center'} // Adjust alignment based on screen size
						spacing={5}
						//py={2}
						p={4}
						sx={{ borderBottom: '1px solid #cfcfcf' }}>
						<TicketSupportIcon />
						{/* <img src={reply?.Attachment} /> */}
						<Stack direction={'column'} spacing={0.5}>
							<Typography variant="h4" sx={{ fontSize: '19px', fontWeight: 500 }}>
								{reply?.Title}
							</Typography>
							<Typography variant="body1" sx={{ color: 'rgba(170, 170, 170, 1)' }}>
								{new Date(reply?.CreatedOn).toLocaleDateString('en-GB', {
									day: 'numeric',
									month: 'long',
									year: 'numeric',
									hour: 'numeric',
									minute: 'numeric'
								})}
							</Typography>
							<Typography variant="body1" sx={{ fontWeight: 400, fontSize: '18px' }}>
								{parseHTML(reply?.Description)}
							</Typography>
						</Stack>
					</Stack>
				))}
			</Stack>
		</Box>
	);
};

export default Ticket;
