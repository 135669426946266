import { Container, Drawer, Stack, Typography } from '@mui/material';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import Section from 'components/UI/Section';
import { InputTypes } from 'constants/enums';
import { Competency } from 'models/Competency';
import { IFormField } from 'models/app';
import { IBacklogInput, StreamListItem } from 'models/stream';
import React from 'react';


interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: IBacklogInput) => void;
  onSelectCompetency: (competency: Competency) => void;
  competencies: Competency[] | null;
  indicators: string[] | null;
  selectedCompetency: Competency | null;
  stream: StreamListItem;
}

const AddBacklog: React.FC<Props> = ({ open, onClose, onSubmit, onSelectCompetency, competencies, indicators, selectedCompetency, stream }) => {

  const [addBacklogFormData, setAddBacklogFormData] = React.useState<IBacklogInput | null>({
    sentence: null,
    competencyId: null,
    indicator: null,
    showcaseId: stream.ShowCaseId
  });

  const onChange = (name: string, value: any) => {
    setAddBacklogFormData({
      ...addBacklogFormData!,
      [name]: value
    });
    if (name === 'competencyId') {
      const competency = competencies?.find((comp: Competency) => comp.Id === parseFloat(value));
      onSelectCompetency(competency as Competency);
    }
  };

  let addTestFields: IFormField[] = [
    {
      name: 'sentence',
      label: 'Sentence:',
      type: InputTypes.TEXTAREA,
      placeholder: 'Enter Sentence',
     },

    {
      name: 'competencyId',
      label: 'Competency:',
      type: InputTypes.SELECT,
      options: competencies ? competencies.map((comp: Competency) => ({
        value: `${comp.Id}`,
        label: comp.Name
      })) : [],
      placeholder: 'Select Competency',
    },
    {
      name: 'indicator',
      label: 'Indicator:',
      type: InputTypes.SELECT,
      options: indicators ? indicators.map((indicator: string) => ({
        value: indicator,
        label: indicator
      })) : [],
      placeholder: 'Select Indicator',
      disabled: !selectedCompetency,
      errorMessage: !selectedCompetency ? 'Please select competency first' : undefined,
    }
  ];

  const handleSubmit = () => {
    onSubmit(addBacklogFormData as IBacklogInput);
    onClose();
  };

  return (
    <>
      <Drawer
        open={open}
        onClose={onClose}
        anchor="right"
        title="Add Backlog"
        sx={{ width: '100%', maxWidth: '100%' }}
      >
        <Container>
          <Typography variant="h6" sx={{ fontWeight: 600, my: 2 }}>
            Add Backlog
          </Typography>
          <Section>
            <Stack sx={{ width: '30vw', pt: 3 }}>
              <Form fields={addTestFields} onChange={onChange} />
            </Stack>
          </Section>
          <Stack direction={'row'} spacing={2} justifyContent={'flex-end'} sx={{ mt: 2 }} >
            <MuiButton
              variant="outlined"
              size="medium"
              color='warning'
              onClick={onClose}>
              Cancel
            </MuiButton>
            <MuiButton
              variant="outlined"
              size="medium"
              color='primary'
              disabled={!addBacklogFormData?.sentence || !addBacklogFormData?.competencyId || !addBacklogFormData?.indicator }
              onClick={handleSubmit}>
              Send
            </MuiButton>
          </Stack>
        </Container>
      </Drawer>


    </>
  );
};

export default AddBacklog;