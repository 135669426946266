
import { GET_ACTIVITY_HISTORY } from "constants/api";
import axios from "../helpers/axiosInstance";


export async function getActivityHistory({ queryKey }: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, _params] = queryKey;

  const response = await axios.post(GET_ACTIVITY_HISTORY, _params.filters);
  return response.data;
}
