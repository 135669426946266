import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { Feature } from 'models/api';
import React, { useEffect } from 'react';
import Overview from './partials/Overview';
import Troubleshooting from './partials/Troubleshooting';
import useApp from 'hooks/useApp';
import { Permission } from 'models/permission';

interface Props {
	feature: Feature;
}

const DataCenter: React.FC<Props> = ({ feature }) => {
	const [selectedTab, setSelectedTab] = React.useState<number>(0);


	const { push } = useApp();


	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 6);

	useEffect(() => {
		if (!currentPagePermissions?.HasReadPermission) {
			push('/404');
		}
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [currentPagePermissions]);
	
	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`
		};
	}

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		event.preventDefault();
		setSelectedTab(newValue);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Grid container justifyContent={'space-between'} alignItems={'center'} sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
					<Tab
						label={
							<Typography variant="body2" fontWeight={400} fontSize={12} color={'TextGray'}>
								Overview
							</Typography>
						}
						{...a11yProps(0)}
					/>
					<Tab
						label={
							<Typography variant="body2" fontWeight={400} fontSize={12} color={'TextGray'}>
								Troubleshooting
							</Typography>
						}
						{...a11yProps(1)}
					/>
				</Tabs>
			</Grid>
			<Box sx={{ overflowY: 'auto' }} m={5}>
				{selectedTab === 0 && <Overview />}
				{selectedTab === 1 && <Troubleshooting feature={feature} />}
			</Box>
		</Box>
	);
};

export default DataCenter;
