import {
	GET_TESTS,
	GET_TEST_QUESTIONS_TYPE,
	GET_CATEGORIES,
	DELETE_CATEGORY,
	ADD_CATEGORY,
	UPDATE_CATEGORY,
	ADD_TEST,
	TEST_DETAIL,
	TEST_LANGUAGES,
	ADD_TEST_QUESTION,
	DELETE_TEST,
	DELETE_TEST_QUESTION,
	GET_QUESTION_BY_ID,
	EDIT_TEST_QUESTION,
	EDIT_TEST,
	TEST_FULL_INFORMATION,
	ADD_TEST_QUESTION_OPTION,
	EDIT_TEST_QUESTION_OPTION,
	DELETE_TEST_QUESTION_OPTION,
	STATUS_FOR_TEST,
	GET_PAGES
} from 'constants/api';
import axios from '../helpers/axiosInstance';
import { ICategoryInput, IQuestionInputItem, ITestEditInput, ITestInput } from 'models/testBank';
import { HeadCell } from 'models/app';

export async function getTests({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.post(GET_TESTS, { ..._params.query });
	return response.data;
}

export async function getTestQuestionsType({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_TEST_QUESTIONS_TYPE + query);
	return response.data;
}

export async function getCategories({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_CATEGORIES + query);
	return response.data;
}
export async function getPages({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_PAGES + query);
	return response.data;
}

export async function getTestDetail(id: number) {
	const response = await axios.get(`${TEST_DETAIL}?testId=${id}`);
	return response.data;
}
export async function getTestFullInfo(id: number) {
	const response = await axios.get(`${TEST_FULL_INFORMATION}?testId=${id}`);
	return response.data;
}

export async function getTestLanguages() {
	const response = await axios.get(TEST_LANGUAGES);
	return response.data;
}

export async function addTest(data: ITestInput) {
	const response = await axios.post(`${ADD_TEST}`, data);
	return response.data;
}
export async function editTest(data: ITestEditInput) {
	const response = await axios.post(`${EDIT_TEST}`, data);
	return response.data;
}
export async function deleteTest(id: number) {
	const response = await axios.post(DELETE_TEST + `?testId=${id}`);
	return response.data;
}

export async function getQuestionById(id: number) {
	const response = await axios.get(`${GET_QUESTION_BY_ID}?questionId=${id}`);
	return response.data;
}
export async function addTestQuestion(data: IQuestionInputItem) {
	const response = await axios.post(`${ADD_TEST_QUESTION}`, data);
	return response.data;
}
export async function editTestQuestion(data: IQuestionInputItem) {
	const response = await axios.post(`${EDIT_TEST_QUESTION}`, data);
	return response.data;
}

export async function deleteQuestion(id: number) {
	const response = await axios.post(DELETE_TEST_QUESTION + `?questionId=${id}`);
	return response.data;
}
// answers

export async function addTestQuestionOption(data: FormData) {
	const response = await axios.post(`${ADD_TEST_QUESTION_OPTION}`, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
}
export async function editTestQuestionOption(data: FormData) {
	const response = await axios.post(`${EDIT_TEST_QUESTION_OPTION}`, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
}
export async function deleteQuestionOption(id: number) {
	const response = await axios.post(DELETE_TEST_QUESTION_OPTION + `?questionOptionId=${id}`);
	return response.data;
}

export async function getQuestionTypes() {
	const response = await axios.get(GET_TEST_QUESTIONS_TYPE);
	return response.data;
}

export async function addCategory(data: ICategoryInput) {
	const { Name } = data;
	const response = await axios.post(`${ADD_CATEGORY}?Name=${Name}`);
	return response.data;
}
export async function updateCategory(data: ICategoryInput) {
	const { Name, Id } = data;
	const response = await axios.post(`${UPDATE_CATEGORY}?Name=${Name}&categoryId=${Id}`);
	return response.data;
}
export async function deleteCategory(data: ICategoryInput) {
	const { Id } = data;
	const response = await axios.post(`${DELETE_CATEGORY}?categoryId=${Id}`);
	return response.data;
}
export async function getTestStatuses() {
	const response = await axios.get(STATUS_FOR_TEST);
	return response.data;
}

export const TestCells: HeadCell[] = [
	{
		id: 'name',
		numeric: false,
		disablePadding: false,
		label: 'Name'
	},
	{
		id: 'competency',
		numeric: false,
		disablePadding: false,
		label: 'Competency'
	},
	{
		id: 'total_questions',
		numeric: false,
		disablePadding: false,
		label: 'Total Questions'
	},
	{
		id: 'indicator',
		numeric: false,
		disablePadding: false,
		label: 'Indicator'
	},
	{
		id: 'status',
		numeric: false,
		disablePadding: false,
		label: 'Status'
	},
	{
		id: 'actions',
		numeric: false,
		disablePadding: false,
		label: '',
		isNotSortable: true
	}
];
export const QuestionCells: HeadCell[] = [
	{
		id: 'QuestionTypeName',
		numeric: false,
		disablePadding: false,
		label: 'Question Type Name'
	},
	{
		id: 'Description',
		numeric: false,
		disablePadding: false,
		label: 'Description'
	},
	{
		id: 'IsRequired',
		numeric: false,
		disablePadding: false,
		label: 'Is Required'
	},

	{
		id: 'actions',
		numeric: false,
		disablePadding: false,
		label: '',
		isNotSortable: true
	}
];
