import { Box, Button, Grid, Stack } from '@mui/material';
import { Feature } from 'models/api';
import { useState } from 'react';
import React from 'react';
import AdvancedEditStanderedForm from './partials/Advanced/Advanced';
import StanderedField from './partials/StanderedField/StanderedField';
import DialogModal from 'components/UI/DialogModal';
type Props = {
	feature: Feature;
	onHideHeader?: (status: boolean) => void;
	open: boolean;
	close: () => void;
};

const EditStanderedForm: React.FC<Props> = ({ feature, onHideHeader, open, close }) => {
	React.useEffect(() => {
		if (onHideHeader) onHideHeader(true);
		return () => {
			if (onHideHeader) onHideHeader(false);
		};
	}, [feature, onHideHeader]);

	const [index, setIndex] = useState<number>(1);
	const tabs = [<StanderedField />, <AdvancedEditStanderedForm />];
	const TABS = [
		{ id: 1, title: 'Standard Field' },
		{ id: 2, title: 'Advanced' }
	];

	return (
		<DialogModal
			open={open}
			onClose={close}
			maxWidth="xl"
			fullScreen
			title={`Edit Standered Form`}
			children={
				<Stack spacing={3} useFlexGap m={5}>
					<Stack spacing={2} useFlexGap my={1} direction={'row'}>
						{TABS.map((tab) => (
							<Button
								size="large"
								color="inherit"
								key={tab.id}
								onClick={() => setIndex(tab.id)}
								sx={{
									justifyContent: 'flex-start',

									borderBottom: tab.id === index ? '2px solid black' : 'none',
									borderRadius: 0,
									':hover': { bgcolor: tab.id === index ? 'grey.200' : 'transparent' },
									fontWeight: tab.id === index ? 600 : 400
									//color: tab.id === index ? 'info.main' : 'text.primary'
								}}
								disableRipple>
								{tab.title}
							</Button>
						))}
					</Stack>

					<Box sx={{ overflowY: 'auto' }}>
						<Grid container height={1}>
							<Grid item xs={12}>
								{tabs[index - 1]}
							</Grid>
						</Grid>
					</Box>
				</Stack>
			}
			actions={<></>}
		/>
	);
};

export default EditStanderedForm;
