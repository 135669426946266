import { Stack, Grid, Tooltip, IconButton } from '@mui/material';
import { Country, OrganizationInput } from 'models/organization';
import { IFormField } from 'models/app';
import { InputTypes } from 'constants/enums';
import CustomForm from 'components/UI/CustomForm';
import { ColorPicker } from 'primereact/colorpicker';
import React from 'react';
import { Sidebar } from 'primereact/sidebar';
import MuiButton from 'components/UI/MuiButton';

interface Props {
	open: boolean;
	onClose: () => void;
	countries: Country[];
	addOrganizationFormData: OrganizationInput;
	onChange: (name: string, value: string | number | boolean) => void;
	onSubmit: () => void;
}

const AddOrganization: React.FC<Props> = ({ open, countries, addOrganizationFormData, onChange, onSubmit, onClose }) => {
	const [color, setColor] = React.useState('#1B6EE4');

	let basicFields: IFormField[] = [
		{
			name: 'Name',
			label: 'Organization Name',
			type: InputTypes.TEXT,
			placeholder: 'Enter email or name'
		},
		{
			name: 'CountryId',
			label: 'Country',
			type: InputTypes.SELECT,
			placeholder: 'Select Country',
			options: countries.map((country) => ({ label: country.Name, value: `${country.Id}` }))
		},

		{
			name: 'ClaimedDomain',
			label: 'Claimed Domain',
			type: InputTypes.TEXT,
			placeholder: 'http://',
			helperText: '(Link to original ticket, tweet, etc.)'
		}
	];

	let logoFields: IFormField[] = [
		{
			name: 'UseOwnLogo',
			label: 'Use Own Logo',
			type: InputTypes.SWITCH,
			isHorizontal: true
		},
		{
			name: 'LogoPath',
			label: 'Logo',
			type: InputTypes.MULTIPLE_FILES,
			limitFiles: 1,
			hidden: !addOrganizationFormData?.UseOwnLogo,
			helperText: 'For best results, logo should be transparent, with no padding around the outside of the image. Image file size must be less than 1 MB.'
		}
	];

	let basicColorFields: IFormField[] = [
		{
			name: 'UseCustomColor',
			label: 'Use Custom Color',
			type: InputTypes.CHECKBOX,
			checkBoxDirection: 'start'
		}
	];

	return (
		<Sidebar
			maskStyle={{
				// show sidebar over other modals
				zIndex: 10000000
			}}
			visible={open}
			position="right"
			onHide={onClose}
			style={{ width: '50%' }}
			title="Add Organization">
			<Grid container height={1} spacing={2} p={3}>
				<Grid item lg={12}>
					<Stack direction={'row'} spacing={2} alignItems={'start'}>
						<CustomForm fields={basicFields} onChange={onChange} width={'80%'} />
					</Stack>
				</Grid>
				<Grid item lg={12}>
					<Stack direction={'row'} spacing={2} alignItems={'start'}>
						<CustomForm fields={basicColorFields} onChange={onChange} width={'30%'} />
					</Stack>
					{addOrganizationFormData.UseCustomColor && (
						<Tooltip title="Select Color" arrow>
							<IconButton
								sx={{
									color: '#000',
									'&:hover': {
										color: '#000'
									}
								}}>
								<ColorPicker
									value={color}
									onChange={(e) => {
										setColor('#' + e.value);
										onChange('ColorHEXCode', '#' + e.value);
									}}
								/>
							</IconButton>
						</Tooltip>
					)}
				</Grid>
				<Grid item lg={12}>
					<Stack direction={'row'} spacing={2} alignItems={'start'}>
						<CustomForm fields={logoFields} onChange={onChange} width={'80%'} />
					</Stack>
				</Grid>
				<Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ position: 'absolute', bottom: 0, right: 0, p: 2 }}>
					<MuiButton onClick={onClose} sx={{ color: '#5C5F66', paddingInline: 6, paddingBlock: 1, border: '1px solid #5C5F66' }}>
						Cancel
					</MuiButton>
					<MuiButton
						onClick={onSubmit}
						sx={{ backgroundColor: '#1B6EE4', color: '#fff', paddingInline: 6, paddingBlock: 1, '&:hover': { color: '#1B6EE4' } }}
						disabled={
							!addOrganizationFormData?.Name || !addOrganizationFormData?.CountryId || !addOrganizationFormData?.ClaimedDomain
							// addOrganizationFormData?.UseOwnLogo ||
							// (addOrganizationFormData?.UseOwnLogo && !addOrganizationFormData?.LogoPath)
						}>
						Save
					</MuiButton>
				</Stack>
			</Grid>
		</Sidebar>
	);
};

export default AddOrganization;
