import { CircularProgress, Stack, Typography } from '@mui/material';
import DialogModal from 'components/UI/DialogModal';
import MuiButton from 'components/UI/MuiButton';

import React from 'react';

interface Props {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	loading: boolean;
	description?: string;
	title?: string;
}

const DeleteDialog: React.FC<Props> = ({ open, loading, onClose, onSubmit, description, title }) => {
	return (
		<DialogModal
			open={open}
			onClose={onClose}
			maxWidth="xl"
			title={title || ''}
			children={
				<Stack sx={{ width: '30vw', pt: 3, m: 5 }}>
					<Typography sx={{ textAlign: 'center' }}>
						Are You Sure You Want To Delete <span style={{ fontWeight: 700 }}>{`${description || ''}`}</span> ?
					</Typography>
				</Stack>
			}
			actions={
				<>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#F0F5FD',
							color: '#8B8BAE',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onClose}>
						Cancel
					</MuiButton>
					<MuiButton
						variant="outlined"
						color="error"
						size="medium"
						sx={{
							fontWeight: 400,
							borderColor: '#fff',
							'&:hover': { color: '#F44335' }
						}}
						disabled={loading}
						onClick={onSubmit}>
						Delete
						<CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
					</MuiButton>
				</>
			}
		/>
	);
};

export default DeleteDialog;
