import { Grid, Stack } from '@mui/material';
import { useState } from 'react';
import OrganizationFilters from '../GlobalFilters/OrganizationFilters';
import MuiButton from 'components/UI/MuiButton';
import TopicFilters from '../GlobalFilters/TopicFilters';
import LanguageFilters from '../GlobalFilters/LanguageFilters';
import useShowcaseStore from 'store/showcase';
import { FiltersProps } from 'models/filters';

type Props = {
	handleFiltersClose: () => void;
	setSelectedFilters?: (data: FiltersProps) => void;
	selectedFilters?: FiltersProps;
};

const ShowcaseFilters = ({ handleFiltersClose, setSelectedFilters, selectedFilters }: Props) => {
 	const selectedParams = useShowcaseStore((state) => state.params);
	const setParams = useShowcaseStore((state) => state.setParams);

	const [filters, setFilters] = useState<FiltersProps>({
		topicIds: selectedFilters?.topicIds || null,
		languageId: selectedFilters?.languageId || null,
		userGroupIds: selectedFilters?.userGroupIds || null,
		organizationIds: selectedFilters?.organizationIds || null,
	});

	const getQueryParams = () => {
		const params = [];

		for (const filter in filters) {
			if (filters.hasOwnProperty(filter)) {
				const value = filters[filter as keyof FiltersProps];
				if (!value) continue;
				const encodedValue = value ? encodeURIComponent(value) : 'null';
				params.push(`${filter}=${encodedValue}`);
			}
		}

		return params.join('&').replace('topicIds', 'topics');
	};

	const handleChange = (name: string, value: any) => {
		setFilters((prev) => ({ ...prev, [name]: value }));
		// remove null values and update selected filters
		const _selectedFilters: FiltersProps = Object.fromEntries(Object.entries({ ...filters, [name]: value }).filter(([_, v]) => v));
		if (!_selectedFilters.organizationIds) delete _selectedFilters.userGroupIds;
		setSelectedFilters && setSelectedFilters(_selectedFilters);
	};

	const handleChangeMultiple = (data: { name: string, value: any }[]) => {
		setFilters((prev) => ({ ...prev, ...Object.fromEntries(data.map((item) => [item.name, item.value])) }));
		// remove null values and update selected filters
		const _selectedFilters: FiltersProps = Object.fromEntries(Object.entries({ ...filters, ...Object.fromEntries(data.map((item) => [item.name, item.value])) }).filter(([_, v]) => v));
		setSelectedFilters && setSelectedFilters(_selectedFilters);
	};

	const onSubmit = async () => {
		let query: string = "";
		let existParams = selectedParams.split('?');
		if (existParams.length > 0 && selectedParams !== '') {
			let queryParams = existParams[1].split('&');
			queryParams = queryParams.filter((item) => item.includes('searchText') || item.includes('fromDate') || item.includes('toDate'));
			query = `?${queryParams.join('&') + '&' + getQueryParams()}`;
		}
		else query = `?${getQueryParams()}`;
		// setSearchParams({ query });
		setParams(query);
		// queryClient.invalidateQueries('showcases');
		handleFiltersClose();
	};

	return (
		<Stack spacing={4} useFlexGap>
			<TopicFilters onChange={handleChange} filters={filters} />
			<LanguageFilters onChange={handleChange} filters={filters} />
			<OrganizationFilters onChange={handleChange} filters={filters} handleChangeMultiple={handleChangeMultiple} />
		
			<Grid container alignItems={'center'} justifyContent={'center'}>
				<MuiButton type="submit" variant="contained" size="small" onClick={onSubmit}>
					Search
				</MuiButton>
			</Grid>
		</Stack>
	);
};

export default ShowcaseFilters;
