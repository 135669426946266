import { useCallback, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import Modal from '../../../../components/UI/DialogModal';
import { IFormField } from 'models/app';
import { InputTypes } from 'constants/enums';
import MuiButton from 'components/UI/MuiButton';
import Form from 'components/UI/Form';
import { User } from 'models/user';
import { Language, ResponseResult } from 'models/api';
import { getCountries, getOrganizationsWithGroups } from 'framework/organizations';
import { Country, OrganizationWithGroups } from 'models/organization';
import { useAlert } from 'contexts/alertContext';
import { useQueryClient } from 'react-query';
import { getLanguages } from 'framework/language';
import ActionLoader from 'components/UI/ActionLoader';
import CustomForm from 'components/UI/CustomForm';

interface Props {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	CurrentUser: User;
	onChange: (name: string, value: any) => void;
}

const EditUserAccount: React.FC<Props> = ({ open, onClose, CurrentUser, onChange, onSubmit }) => {
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	const [organizationWithGroups, setOrganizationsWithGroubs] = useState<OrganizationWithGroups[] | null>(null);
	const [countries, setCountries] = useState<Country[] | null>(null);
	const [languages, setLanguages] = useState<Language[] | null>(null);
	const [loading, setLoading] = useState(false);

	const fetchOrganizations = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['getOrganizationsWithGroups', { query: '' }], getOrganizationsWithGroups);
			setOrganizationsWithGroubs(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);

	const fetchCountries = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['countries', { query: '' }], getCountries);
			setCountries(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);

	const fetchLanguages = useCallback(async () => {
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['languages', { query: '' }], getLanguages);

			setLanguages(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchCountries();
		fetchOrganizations();
		fetchLanguages();
		// eslint-disable-next-line
	}, []);

	if (loading) return <ActionLoader position={'absolute'} />;

	if (!organizationWithGroups || !countries || !languages) return <></>;

	let userFields: IFormField[] = [
		{
			name: 'FirstName',
			type: InputTypes.TEXT,
			placeholder: 'Enter First Name',
			label: 'First Name',
			defaultValue: CurrentUser?.FirstName
		},
		{
			name: 'LastName',
			type: InputTypes.TEXT,
			placeholder: 'Enter Last Name',
			label: 'Last Name',
			defaultValue: CurrentUser?.LastName
		},
		{
			name: 'Email',
			type: InputTypes.TEXT,
			defaultValue: CurrentUser?.EmailAddress,
			disabled: true,
			label: 'Email Address'
		},
		{
			name: 'NotificationEmailAddress',
			type: InputTypes.TEXT,
			placeholder: 'Enter Notification Email Address',
			label: 'Notification Email Address',
			defaultValue: CurrentUser?.NotificationEmailAddress
		},

		{
			name: 'PhoneCountryCode',
			type: InputTypes.TEXT,
			placeholder: 'Enter Phone Country Code',
			label: 'Phone Country Code',
			defaultValue: CurrentUser?.PhoneCountryCode
		},
		{
			name: 'PhoneNumber',
			type: InputTypes.TEXT,
			placeholder: 'Enter Phone Number',
			label: 'Phone Number',
			defaultValue: CurrentUser?.PhoneNumber
		},
		{
			name: 'OrganizationId',
			type: InputTypes.SELECT,
			placeholder: 'Select Organization',

			label: 'Organization',
			options: organizationWithGroups.map((organization) => ({ label: organization.UserOrganizationName, value: `${organization.UserOrganizationId}` })),
			defaultValue: organizationWithGroups
				.map((organization) => ({ label: organization.UserOrganizationName, value: `${organization.UserOrganizationId}` }))
				?.find((item) => +item.value === CurrentUser?.OrganizationId)
		},
		{
			name: 'GroupId',
			type: InputTypes.SELECT,
			placeholder: 'Select Group',

			label: 'Group',
			//	disabled: CurrentUser?.OrganizationId ? false : true,
			options: organizationWithGroups?.find((item) => item.UserOrganizationId === +CurrentUser?.OrganizationId)?.Groups.map((item) => ({ label: item.GroupName, value: `${item.GroupId}` })) || [],

			defaultValue: organizationWithGroups
				.find((item) => item.UserOrganizationId === +CurrentUser?.OrganizationId)
				?.Groups.map((item) => ({ label: item.GroupName, value: `${item.GroupId}` }))
				?.find((item) => +item.value === CurrentUser?.GroupId)
		},
		{
			name: 'LanguageId',
			type: InputTypes.SELECT,
			placeholder: 'Select Language',
			label: 'Language',
			options: languages.map((language) => ({ label: language.Name, value: `${language.Id}` })),
			defaultValue: languages.map((language) => ({ label: language.Name, value: `${language.Id}` }))?.find((item) => +item.value === CurrentUser?.LanguageId)
		},
		{
			name: 'CountryId',
			type: InputTypes.SELECT,
			placeholder: 'Select Country',
			label: 'Country',
			options: countries.map((country) => ({ label: country.Name, value: `${country.Id}` })),
			defaultValue: countries.map((country) => ({ label: country.Name, value: `${country.Id}` }))?.find((item) => +item.value === CurrentUser?.CountryId)
		}
	];

	let profileImageField: IFormField[] = [
		{
			name: 'ProfileImagePath',
			label: 'Logo',
			type: InputTypes.MULTIPLE_FILES,
			limitFiles: 1,
			defaultImageUrl: CurrentUser.ProfileImagePath!,
			disabled: true
		}
	];

	const handleChangeDefaultData = (callback: (name: string, value: any) => void) => {
		return callback('GroupId', CurrentUser.GroupId);
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth={'lg'}
			title="Edit User"
			actions={
				<Stack direction={'row'} spacing={2}>
					<MuiButton onClick={onClose} sx={{ color: '#5C5F66', paddingInline: 6, paddingBlock: 1, border: '1px solid #5C5F66' }}>
						Cancel
					</MuiButton>
					<MuiButton onClick={onSubmit} sx={{ backgroundColor: '#1B6EE4', color: '#fff', paddingInline: 6, paddingBlock: 1, '&:hover': { color: '#1B6EE4' } }}>
						Save
					</MuiButton>
				</Stack>
			}>
			<Box sx={{ paddingBlock: 3, paddingInline: 1 }}>
				<Box sx={{ width: '100%' }}>
					<Form fields={userFields} onChange={onChange} />
					<Box width={'fit-content'}>
						<CustomForm fields={profileImageField} onChange={onChange} setFormValue={handleChangeDefaultData} />
					</Box>
				</Box>
			</Box>
		</Modal>
	);
};

export default EditUserAccount;
