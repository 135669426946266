import { Box, Container, Grid, Skeleton, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import MuiButton from 'components/UI/MuiButton';
import { useAlert } from 'contexts/alertContext';
import { addTestQuestion, addTestQuestionOption, deleteQuestion, deleteQuestionOption, editTest, editTestQuestion, editTestQuestionOption, getTestDetail, getTestFullInfo } from 'framework/testBank';
import { Feature } from 'models/api';
import { IQuestion, IQuestionAnswer, IQuestionInputItem, ITestDetails, ITestEditInput, ITestFullInfo } from 'models/testBank';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderTest from '../Global/HeaderTest';
import { testNav } from 'constants/constants';
import ListQuestions from '../Question/ListQuestions';
import AddQuestion from '../Question/AddQuestion';
import { useMutation } from 'react-query';
import DeleteQuestion from '../Question/DeleteQuestion';
import EditQuestion from '../Question/EditQuestion';
import ConfigureTest from '../Global/ConfiguteTest';
import SaveTestChanges from '../Global/SaveTestChanges';
import { IFormFieldData } from 'models/app';
import { Resources } from 'constants/enums';
import { Permission } from 'models/permission';
import { BackOfficeHeader } from 'helpers/utils';
interface Props {
	feature: Feature;
	onHideHeader?: (status: boolean) => void;
}

const EditTest: React.FC<Props> = ({ feature, onHideHeader }) => {
	const urlParams = useParams();
	let Id: string = urlParams[`${feature.singleName}Id`] as string;
	const [loading, setLoading] = React.useState(false);
	const [testDetail, setTestDetail] = React.useState<ITestDetails | null>(null);
	const [testFullInfo, setTestFullInfo] = React.useState<ITestFullInfo | null>(null);
	const [selectedSection, setSelectedSection] = useState<Number>(0);
	const [addQuestionForm, setAddQuestionForm] = React.useState<IQuestionInputItem | null>(null);
	const [editQuestionForm, setEditQuestionForm] = React.useState<IQuestionInputItem | null>(null);
	const [editTestForm, setEditTestForm] = React.useState<ITestEditInput | null>(null);
	const [showAddQuestion, setShowAddQuestion] = useState<boolean>(false);
	const [showEditQuestion, setShowEditQuestion] = useState<boolean>(false);
	const [showDeleteQuestion, setShowDeleteQuestion] = useState<boolean>(false);
	const [selectedQuestion, setSelectedQuestion] = useState<IQuestion | null>(null);
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 8);

 	const { setAlert } = useAlert();

	const editTestMutation = useMutation({
		mutationFn: (data: ITestEditInput) => {
			return editTest(data);
		},
		onSuccess: () => {
			fetchTestFullInfo();
		}
	});

	const addQuestionMutation = useMutation({
		mutationFn: (data: IQuestionInputItem) => {
			return addTestQuestion(data);
		},
		onSuccess: () => {
			fetchTestDetail();
		}
	});
	const editQuestionMutation = useMutation({
		mutationFn: (data: IQuestionInputItem) => {
			return editTestQuestion(data);
		},
		onSuccess: () => {
			fetchTestDetail();
		}
	});
	const deleteQuestionMutation = useMutation({
		mutationFn: (id: number) => {
			return deleteQuestion(id);
		},
		onSuccess: () => {
			fetchTestDetail();
		}
	});
	const addQuestionOptionMutation = useMutation({
		mutationFn: (data: FormData) => {
			return addTestQuestionOption(data);
		},
		onSuccess: () => {
			fetchTestDetail();
		}
	});
	const editQuestionOptionMutation = useMutation({
		mutationFn: (data: FormData) => {
			return editTestQuestionOption(data);
		},
		onSuccess: () => {
			fetchTestDetail();
		}
	});
	const deleteQuestionOptionMutation = useMutation({
		mutationFn: (id: number) => {
			return deleteQuestionOption(id);
		},
		onSuccess: () => {
			fetchTestDetail();
		}
	});

	React.useEffect(() => {
		if (onHideHeader) onHideHeader(true);
		return () => {
			if (onHideHeader) onHideHeader(false);
		};
	}, [feature, onHideHeader]);

	const fetchTestDetail = async () => {
		setLoading(true);
		try {
			const res = await getTestDetail(parseFloat(Id));
			setTestDetail(res.Data);
			if (res.Success === 0) throw new Error(res.Message);
			setLoading(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};
	const fetchTestFullInfo = async () => {
		setLoading(true);
		try {
			const res = await getTestFullInfo(parseFloat(Id));
			setTestFullInfo(res.Data);
			if (res.Success === 0) throw new Error(res.Message);
			setLoading(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const setDefaultValues = useCallback(() => {
		if (!testFullInfo) return;
		let values: IFormFieldData[] = [] as IFormFieldData[];
		Object.keys(testFullInfo!).forEach((key: string) => {
			if (key === 'TestDesciption')
				values.push({
					name: 'Description',
					value: testFullInfo![key]
				});
			else
				values.push({
					name: key,
					value: testFullInfo![key]
				});
		});
		handleChangeMultipleEditTestForm(values);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [testFullInfo]);

	React.useEffect(() => {
		fetchTestFullInfo();
		fetchTestFullInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	React.useEffect(() => {
		fetchTestDetail();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	React.useEffect(() => {
		setDefaultValues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [testFullInfo]);

 
	const handleNavItemSelect = (selectedItem: Number) => {
		setSelectedSection(selectedItem);
	};

	const handleChangeAddQuestionForm = (name: string, value: any) => {
		setAddQuestionForm((prev: any) => ({ ...prev, [name]: value }));
	};
	const handleChangeEditQuestionForm = (name: string, value: any) => {
		setEditQuestionForm((prev: any) => ({ ...prev, [name]: value }));
	};
	const handleChangeEditTestForm = (name: string, value: any) => {
		if (name === 'Indicator') {
			// concat the value with the previous value and add comma
			let newValue =  value.map((item: any) => item.value).join(',');
			setEditTestForm((prev: any) => ({ ...prev, [name]: newValue }));
		}
		else setEditTestForm((prev: any) => ({ ...prev, [name]: value }));
	};
	const handleChangeMultipleEditTestForm = (data: IFormFieldData[]) => {
		let newFormData: any = {};
		data.forEach((item: IFormFieldData) => {
			newFormData[item.name] = item.value;
		});
		return setEditTestForm({ ...editTestForm!, ...newFormData });
	};

	const handleClose = () => {
		setShowAddQuestion(false);
		setShowEditQuestion(false);
		setShowDeleteQuestion(false);
		setSelectedQuestion(null);
	};

	const handleOpenAddQuestion = () => {
		setShowAddQuestion(true);
	};

	const handleOpenEditQuestion = (question: IQuestion) => {
		setSelectedQuestion(question);
		setShowEditQuestion(true);
	};

	const handleOpenDeleteQuestion = (question: IQuestion) => {
		setSelectedQuestion(question);
		setShowDeleteQuestion(true);
	};

	const handleEditTest = async () => {
		if (!editTestForm) return;
		if (editTestForm?.TestRound > 2 || editTestForm?.TestRound < 1 || !editTestForm?.TestRound) {
			setAlert({
				open: true,
				message: 'Test round must be between 1 and 2',
				type: 'error'
			});
			return;
		}

		let createInput: ITestEditInput = {
			Id: testFullInfo?.Id!,
			Name: editTestForm?.Name || testFullInfo?.Name!,
			Description: editTestForm?.Description || testFullInfo?.Description!,
			CategoryId: editTestForm?.CategoryId || testFullInfo?.CategoryId!,
			CompetencyId: editTestForm?.CompetencyId || testFullInfo?.CompetencyId!,
			Indicator: editTestForm?.Indicator,
			StatusId: editTestForm?.StatusId || testFullInfo?.StatusId!,
			DisplayTestName: editTestForm?.DisplayTestName === false ? false : editTestForm?.DisplayTestName || testFullInfo?.DisplayTestName!,
			DisplayPageTitles: editTestForm?.DisplayPageTitles === false ? false : editTestForm?.DisplayPageTitles || testFullInfo?.DisplayPageTitles!,
			DisplayFlexiQuizLink: editTestForm?.DisplayFlexiQuizLink === false ? false : editTestForm?.DisplayFlexiQuizLink || testFullInfo?.DisplayFlexiQuizLink!,
			DisplayProgressBar: editTestForm?.DisplayProgressBar === false ? false : editTestForm?.DisplayProgressBar || testFullInfo?.DisplayProgressBar!,
			DisplayPageNumberBar: editTestForm?.DisplayPageNumberBar === false ? false : editTestForm?.DisplayPageNumberBar || testFullInfo?.DisplayPageNumberBar!,
			DisplayLogo: editTestForm?.DisplayLogo === false ? false : editTestForm?.DisplayLogo || testFullInfo?.DisplayLogo!,
			HasTimeLimit: editTestForm?.HasTimeLimit === false ? false : editTestForm?.HasTimeLimit || testFullInfo?.HasTimeLimit!,
			TimeLimit: editTestForm?.TimeLimit || testFullInfo?.TimeLimit!,
			LanguageId: editTestForm?.LanguageId || testFullInfo?.LanguageId!,
			TestRound: editTestForm?.TestRound || testFullInfo?.TestRound!,
			AutoNumberQuestions: editTestForm?.AutoNumberQuestions === false ? false : editTestForm?.AutoNumberQuestions || testFullInfo?.AutoNumberQuestions!,
			QuestionsPerPage: editTestForm?.QuestionsPerPage || testFullInfo?.QuestionsPerPage!,
			AllowPreviousPagePagination: editTestForm?.AllowPreviousPagePagination === false ? false : editTestForm?.AllowPreviousPagePagination || testFullInfo?.AllowPreviousPagePagination!,
			RedirectionPage: editTestForm?.RedirectionPage || testFullInfo?.RedirectionPage!,
			AllowCopy: editTestForm?.AllowCopy === false ? false : editTestForm?.AllowCopy || testFullInfo?.AllowCopy!,
			AllowCut: editTestForm?.AllowCut === false ? false : editTestForm?.AllowCut || testFullInfo?.AllowCut!,
			AllowPaste: editTestForm?.AllowPaste === false ? false : editTestForm?.AllowPaste || testFullInfo?.AllowPaste!,
			AllowRightClick: editTestForm?.AllowRightClick === false ? false : editTestForm?.AllowRightClick || testFullInfo?.AllowRightClick!,
			AllowPrint: editTestForm?.AllowPrint === false ? false : editTestForm?.AllowPrint || testFullInfo?.AllowPrint!,
			ConfirmBeforeSubmit: editTestForm?.ConfirmBeforeSubmit === false ? false : editTestForm?.ConfirmBeforeSubmit || testFullInfo?.ConfirmBeforeSubmit!,
			ConfirmBeforeCloseBrowserTab: editTestForm?.ConfirmBeforeCloseBrowserTab === false ? false : editTestForm?.ConfirmBeforeCloseBrowserTab || testFullInfo?.ConfirmBeforeCloseBrowserTab!,
			AllowRetryTest: editTestForm?.AllowRetryTest === false ? false : editTestForm?.AllowRetryTest || testFullInfo?.AllowRetryTest!,
			NumberOfAllowedRetries: editTestForm?.NumberOfAllowedRetries || testFullInfo?.NumberOfAllowedRetries!,
			PassingGrade: editTestForm?.PassingGrade || testFullInfo?.PassingGrade!
		};
		setLoading(true);
		try {
			const res = await editTestMutation.mutateAsync(createInput!);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Test updated successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
			setSelectedSection(0);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};
	const handleAddQuestion = async (answers: IQuestionAnswer[]) => {
		const createInput: IQuestionInputItem = addQuestionForm!;
		if (!createInput.IsRequired) createInput.IsRequired = false;
		if (!createInput.RandomizeOptions) createInput.RandomizeOptions = false;
		if (!createInput.Points) createInput.Points = 0;
		if (!createInput.QuestionTypeId) createInput.QuestionTypeId = 1;
		if (!createInput.Description) createInput.Description = '';
		setLoading(true);
		try {
			Object.assign(createInput, { TestId: parseFloat(Id) });
			const res = await addQuestionMutation.mutateAsync(createInput);
			if (res.Success === 0) throw new Error(res.Message);
			await handleAddQuestionOption(answers, res.Data);
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};
	const handleEditQuestion = async (answers: IQuestionAnswer[], Points: number) => {
		const createInput: IQuestionInputItem = {
			QuestionId: selectedQuestion?.Id!,
			QuestionTypeId: editQuestionForm?.QuestionTypeId || selectedQuestion?.QuestionTypeId!,
			Points: editQuestionForm?.Points || Points,
			Description: editQuestionForm?.Description || selectedQuestion?.Description!,
			IsRequired: editQuestionForm?.IsRequired !== false ? editQuestionForm?.IsRequired || selectedQuestion?.IsRequired! : false,
			RandomizeOptions: editQuestionForm?.RandomizeOptions !== false ? editQuestionForm?.RandomizeOptions || selectedQuestion?.RandomizeOptions! : false,
			TestId: parseFloat(Id)
		};
		setLoading(true);
		try {
			const res = await editQuestionMutation.mutateAsync(createInput);
			if (res.Success === 0) throw new Error(res.Message);
			await handleAddQuestionOption(answers, selectedQuestion?.Id!);
			setAlert({
				open: true,
				message: 'Updated successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleDeleteQuestion = async () => {
		setLoading(true);
		try {
			const res = await deleteQuestionMutation.mutateAsync(selectedQuestion?.Id!);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Deleted successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};
	const handleAddQuestionOption = async (answers: IQuestionAnswer[], QuestionId: number) => {
		let formData = new FormData();
		let newAnswers = answers.filter((a) => !a.Id);

		newAnswers.forEach((answer: IQuestionAnswer, index: number) => {
			formData.append(`answers[${index}].IsCorrectOption`, `${newAnswers.some((a) => a.Points !== 0) ? true : answer.IsCorrectOption}`);
			if (answer.imagePath) formData.append(`answers[${index}].imagePath`, answer.imagePath);
			if (answer.Name) formData.append(`answers[${index}].Name`, answer.Name);
			if (answer.Points) formData.append(`answers[${index}].Points`, `${answer.Points}`);
		});
		formData.append(`QuestionId`, `${QuestionId}`);
		formData.append(`AnswersCount`, `${newAnswers.length}`);
		try {
			const res = await addQuestionOptionMutation.mutateAsync(formData);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Added successfully',
				type: 'success'
			});
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};
	const handleEditQuestionOption = async (answer: IQuestionAnswer) => {
		if (!answer.Id) return handleAddQuestionOption([answer], selectedQuestion?.Id!);
		let formData = new FormData();
		if (answer.imagePath) formData.append(`answers[${0}].imagePath`, answer.imagePath);
		if (answer.Name) formData.append(`answers[${0}].Name`, answer.Name);
		if (answer.Points) {
			formData.append(`answers[${0}].Points`, `${answer.Points}`);
		}
		formData.append(`answers[${0}].IsCorrectOption`, answer.IsCorrectOption ? answer.IsCorrectOption.toString() : 'false');

		formData.append(`QuestionOptionId`, answer?.Id!.toString()!);
		formData.append(`AnswersCount`, '1');
		try {
			const res = await editQuestionOptionMutation.mutateAsync(formData);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Updated successfully',
				type: 'success'
			});
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};

	const handleDeleteQuestionOption = async (id: number) => {
		try {
			const res = await deleteQuestionOptionMutation.mutateAsync(id!);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Deleted successfully',
				type: 'success'
			});
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};

	// wave skeleton
	if (loading)
		return (
			<>
				<Container>
					<Box sx={{ width: '100%' }}>
						<Skeleton animation="wave" height={600} />
					</Box>
				</Container>
			</>
		);

	return (
		<>
			<Box sx={{ width: '100%' }} px={0}>
				<Grid container>
					<HeaderTest onNavItemSelect={handleNavItemSelect} navItems={testNav} />
				</Grid>
				<Grid container justifyContent={'end'} alignItems={'center'} sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: 'white', p: 1 }}>
					<Box style={{ display: 'flex', gap: '15px', marginRight: '160px', fontSize: '11px' }}>
						<Box component={'span'} style={{ padding: '0 10px', color: '#737373', fontWeight: 700 }}>
							Test:
						</Box>
						{Id}
						<Box component={'span'} style={{ padding: '0 10px', color: '#737373', fontWeight: 700 }}>
							Status:
						</Box>
						{testFullInfo?.StatusName}
					</Box>
				</Grid>
				<Grid container alignItems={'center'} sx={{ ml: 3, p: 2 }}>
					<BackOfficeHeader title={['Test Bank', `	Test #${testFullInfo?.Id || ''}`]} resorseToNavigate={Resources.TEST_BANK} />
				</Grid>
			</Box>

			{selectedSection === 0 && (
				<Stack direction={'column'} sx={{ width: '70%', margin: '0 auto' }} justifyContent={'center'}>
					<Box sx={{ pt: 3, pb: 2, alignSelf: 'end' }}>
						{!!currentPagePermissions?.HasModifyPermission && (
							<MuiButton
								variant="contained"
								size="large"
								sx={{ borderRadius: 1, fontWeight: 400, backgroundColor: '#0078B4', '&:hover': { color: '#0078B4', backgroundColor: 'inherit' } }}
								onClick={handleOpenAddQuestion}>
								Add Item
							</MuiButton>
						)}
					</Box>
					<Box
						sx={{
							bgcolor: 'white',
							boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
							mb: 7
						}}>
						<Box
							sx={{
								bgcolor: '#DDD',
								p: 1,
								m: 0
							}}>
							<Typography
								variant="h6"
								sx={{
									color: '#A8A8A8',
									fontWeight: 400,
									pl: 1
								}}>
								Questions
							</Typography>
						</Box>
						{!testDetail ? (
							<ActionLoader position={'static'} />
						) : testDetail?.Questions?.length > 0 ? (
							<ListQuestions feature={feature} questions={testDetail?.Questions} openEdit={handleOpenEditQuestion} openDelete={handleOpenDeleteQuestion} />
						) : (
							<Typography sx={{ textAlign: 'center', p: 4 }}>No Questions Found</Typography>
						)}
					</Box>
				</Stack>
			)}

			{selectedSection === 1 && (
				<>
					<ConfigureTest onChange={handleChangeEditTestForm} editTestForm={editTestForm!} />
				</>
			)}
			{selectedSection === 2 && (
				<>
					<SaveTestChanges onSubmit={handleEditTest} testFullInfo={testFullInfo!} onChange={handleChangeEditTestForm} />
				</>
			)}

			{/* Add Question */}
			{showAddQuestion && <AddQuestion open={showAddQuestion} onClose={handleClose} onSubmit={handleAddQuestion} onChange={handleChangeAddQuestionForm} addQuestionForm={addQuestionForm!} />}
			{/* Edit Question */}
			{showEditQuestion && (
				<EditQuestion
					open={showEditQuestion}
					onClose={handleClose}
					onSubmit={handleEditQuestion}
					onChange={handleChangeEditQuestionForm}
					selectedQuestion={selectedQuestion}
					onDeleteAnswer={handleDeleteQuestionOption}
					onEditAnswer={handleEditQuestionOption}
					editQuestionForm={editQuestionForm}
				/>
			)}

			{/* Delete Question */}
			{showDeleteQuestion && <DeleteQuestion open={showDeleteQuestion} onClose={handleClose} onSubmit={handleDeleteQuestion} loading={loading} selectedQuestion={selectedQuestion!} />}
		</>
	);
};

export default EditTest;
