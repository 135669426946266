import { Divider, IconButton, Stack, TableCell, Typography } from '@mui/material';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import React from 'react';
import List from 'components/UI/List';
import { Feature } from 'models/api';
import { Competency } from 'models/Competency';
import { CompetencyCells } from 'framework/competency';
import TranslateIcon from '@mui/icons-material/Translate';
import { Permission } from 'models/permission';

interface Props {
	competencies: Competency[];
	feature: Feature;
	onOpenEditCompetency: (competency: Competency) => void;
	onOpenDeleteCompetency: (competency: Competency) => void;
	onOpenTranslateCompetency: (competency: Competency) => void;
}

const ListCompetencies: React.FC<Props> = ({ competencies, feature, onOpenEditCompetency, onOpenDeleteCompetency, onOpenTranslateCompetency }) => {
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 13);

	const getBodyCells = (row: any) => {
		let cells = Object.keys(row)?.map((key: string, i: number) => {
			if (key === 'Name')
				return {
					id: 'name',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.Name}</Typography>
						</TableCell>
					)
				};
			if (key === 'PillarName')
				return {
					id: 'pillarName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.PillarName}</Typography>
						</TableCell>
					)
				};
			if (key === 'QuestionsPerPage')
				return {
					id: 'total_questions',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.QuestionsPerPage}</Typography>
						</TableCell>
					)
				};

			if (key === 'NumIndicators')
				return {
					id: 'indicators',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.NumIndicators}</Typography>
						</TableCell>
					)
				};

			if (key === 'Id')
				return {
					id: 'actions',
					element: (
						<TableCell align={'left'} key={i}>
							<Stack direction={'row'} alignItems={'center'} spacing={1} useFlexGap>
								{!!currentPagePermissions?.HasModifyPermission && (
									<IconButton size="small" color="inherit" onClick={() => onOpenEditCompetency(row)}>
										<EditOutlined fontSize="small" />
									</IconButton>
								)}

								{!!currentPagePermissions?.HasDeletePermission && (
									<IconButton size="small" color="inherit" onClick={() => onOpenDeleteCompetency(row)}>
										<DeleteOutline fontSize="small" />
									</IconButton>
								)}

								{!!currentPagePermissions?.HasModifyPermission && (
									<IconButton size="small" color="inherit" onClick={() => onOpenTranslateCompetency(row)}>
										<TranslateIcon fontSize="small" />
									</IconButton>
								)}
							</Stack>
						</TableCell>
					)
				};
			else return <></>;
		});
		return cells;
	};

	return (
		<Stack divider={<Divider />}>
			<Stack p={0} spacing={1} useFlexGap>
				<List headCells={CompetencyCells} data={competencies!} getBodyCells={getBodyCells} feature={feature} canEdit={false} canSelect={false} />
			</Stack>
		</Stack>
	);
};

export default ListCompetencies;
