import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import { useQueryClient } from 'react-query';
import useLoading from 'hooks/loading';
import { useCallback, useEffect, useState } from 'react';
import { getActiveUsers, getToTalUsers, getTotalOrganizations, getTotalReports } from 'framework/dataCenter';
import useDataCenterStore from 'store/dataCenter';
import { ResponseResult } from 'models/api';
import BarChart from 'components/UI/Charts/Barchart';
import ActionLoader from 'components/UI/ActionLoader';
import DurationsFilter from './DurationsFilter';
import React from 'react';

type Props = {};

const Overview: React.FC<Props> = () => {
	const queryClient = useQueryClient();
	const { loading, setLoading } = useLoading();
	const [loadingGraph, setLoadingGraph] = useState<boolean>(false);
	const [chartType, setChartType] = useState<number>(1);
	const totalUsers = useDataCenterStore((state) => state.totalUsers);
	const setUserNumber = useDataCenterStore((state) => state.setUsersNumber);
	const activeUsers = useDataCenterStore((state) => state.activeUsers);
	const setActiveUsers = useDataCenterStore((state) => state.setActiveUsers);
	const totalOrganizations = useDataCenterStore((state) => state.totalOrganizations);
	const setTotalOrganizations = useDataCenterStore((state) => state.setTotalOrganizations);
	const totalReports = useDataCenterStore((state) => state.totalReports);
	const setTotalReports = useDataCenterStore((state) => state.setTotalReports);
	const params = useDataCenterStore((state) => state.params);

	const fetchTotalUsers = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['totalUsers', { query: params }], getToTalUsers);
			setUserNumber(response.Data[0]);
			setLoading(false);
		} catch (err: Error | any) {
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [params]);

	const fetchTotalOrganizations = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['totalOrganizations', { query: params }], getTotalOrganizations);
			setTotalOrganizations(response.Data[0]);
			setLoading(false);
		} catch (err: Error | any) {
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [params]);

	const fetchActiveUsers = useCallback(async () => {
		setLoadingGraph(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['activeUsers', { query: `?chartType=${chartType}` }], getActiveUsers);
			setActiveUsers(response.Data);
			setLoadingGraph(false);
		} catch (err: Error | any) {
			setLoadingGraph(false);
		}

		// eslint-disable-next-line
	}, [chartType, setChartType]);

	const fetchTotalReports = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['totalReports', { query: params }], getTotalReports);
			setTotalReports(response.Data[0]);
			setLoading(false);
		} catch (err: Error | any) {
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [params]);

	useEffect(() => {
		fetchTotalUsers();
		fetchTotalOrganizations();
		fetchTotalReports();
		fetchActiveUsers();

		// eslint-disable-next-line
	}, [params]);

	useEffect(() => {
		fetchActiveUsers();
		// eslint-disable-next-line
	}, [chartType]);

	if (loading) return <ActionLoader position={'absolute'} />;

	const handleDurationChange = (selectedDuration: number) => {
		// Handle the selected duration, e.g., update the params or perform other actions
		// ...
		setChartType(selectedDuration);
		// For demonstration purposes, you can log the selected duration
	};

	return (
		<Stack alignItems={'flex-start'} p={4} spacing={4} useFlexGap>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={4}>
							<Stack border={'1px solid'} borderColor={'divider'} borderRadius={2} p={2} spacing={2} useFlexGap>
								<Typography fontWeight={'500'}>Total Users</Typography>
								<Typography variant="h3" fontWeight={'400'} component={'p'}>
									{totalUsers || 0}
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Stack border={'1px solid'} borderColor={'divider'} borderRadius={2} p={2} spacing={2} useFlexGap>
								<Typography fontWeight={'500'}>Total Organizations</Typography>
								<Typography variant="h3" fontWeight={'400'} component={'p'}>
									{totalOrganizations || 0}
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Stack border={'1px solid'} borderColor={'divider'} borderRadius={2} p={2} spacing={2} useFlexGap>
								<Typography fontWeight={'500'}>Total Reports Received</Typography>
								<Typography variant="h3" fontWeight={'400'} component={'p'}>
									{totalReports || 0}
								</Typography>
							</Stack>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} justifyContent={'center'}>
					<Stack border={'1px solid'} borderColor={'divider'} borderRadius={2} p={2} spacing={2} useFlexGap justifyContent={'center'}>
						<Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
							<Typography fontWeight={'500'} variant="h5">
								Active Users
							</Typography>

							<DurationsFilter onSelectDuration={handleDurationChange} />
							{/* {loadingGraph && <Skeleton variant="rectangular" width={400} height={400} animation="wave" />}
							{!loadingGraph && <LinearProgress variant="determinate" value={100} />} */}
						</Stack>
						<Stack
							justifyContent={'center'}
							alignItems={'center'}
							spacing={2}
							useFlexGap
							sx={{
								overflowY: 'auto',
								maxHeight: 400
							}}>
							{loadingGraph ? (
								<Skeleton variant="rectangular" width={'100%'} height={400} animation="wave" />
							) : activeUsers ? (
								<BarChart labels={activeUsers.ChartData.Labels} values={activeUsers.ChartData.Values} />
							) : (
								<Typography color={'text.secondary'}>No Data</Typography>
							)}
						</Stack>
					</Stack>
				</Grid>
			</Grid>
		</Stack>
	);
};

export default Overview;
