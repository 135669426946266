import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { useAlert } from 'contexts/alertContext';
import { getEmailTemplates, updateEmailTemplates } from 'framework/settings';
import { Feature, ResponseResult } from 'models/api';
import { IEmailTemplate } from 'models/settings';
import EditIcon from '@mui/icons-material/Edit';

import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import EditEmailTemplate from './partials/EditEmailTemplate';
import { BackOfficeHeader, parseHTML } from 'helpers/utils';
import { Permission } from 'models/permission';
import useApp from 'hooks/useApp';
import { UploadShowCaseImage } from 'framework/showcase';
import { BASE_URL } from 'constants/api';
interface Props
{
	feature: Feature;
	onHideHeader?: (status: boolean) => void;
}

const Emails: React.FC<Props> = ({ feature, onHideHeader }) =>
{
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	const [emailTemplate, setEmailTemplate] = useState<Partial<IEmailTemplate> | null>(null);
	const [emails, setEmails] = useState<IEmailTemplate[] | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [showEmailTemplateForm, setShowEmailTemplateForm] = useState<boolean>(false);

	let permissionsLocalStorage: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissionsLocalStorage?.find((item) => item.PermissionId === 15);
	const UploadShowMutation = useMutation({
		mutationFn: (data: FormData) =>
		{
			return UploadShowCaseImage(data);
		}
	});
	React.useEffect(() =>
	{
		if (onHideHeader) onHideHeader(true);
		return () =>
		{
			if (onHideHeader) onHideHeader(false);
		};
	}, [feature, onHideHeader]);

	const { push } = useApp();



	useEffect(() =>
	{
		if (!currentPagePermissions?.HasReadPermission)
		{
			push('/404');
		}
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [currentPagePermissions]);

	const fetchEmailTemplates = async () =>
	{
		setLoading(true);

		try
		{
			const res: ResponseResult = await queryClient.fetchQuery(['getEmailTemplates'], getEmailTemplates);

			if (res.Success === 0) throw new Error(res.Message);

			setEmails(res.Data);

			setLoading(false);
		} catch (err: any)
		{
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
	};

	React.useEffect(() =>
	{
		fetchEmailTemplates();
	}, []);

	if (loading) return <ActionLoader position={ 'absolute' } />;

	const handleChangeEmailTemplates = (name: string, value: any) =>
	{
		if (name === 'Attachment')
		{
			return handleUploadImage(value);
		} else return setEmailTemplate({ ...emailTemplate!, [name]: value });

	};

	const handleOnSubmit = async () =>
	{
		setLoading(true);

		try
		{
			const res: ResponseResult = await updateEmailTemplates(emailTemplate!);

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Email Template Updated Successfully',
				type: 'success'
			});
			handleCloseForm();
			fetchEmailTemplates();
			setLoading(false);
		} catch (err: any)
		{
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
	};

	const handleOpenForm = (email: Partial<IEmailTemplate>) =>
	{
		setShowEmailTemplateForm(true);
		setEmailTemplate({ Id: email?.Id, Body: email?.Body, Subject: email?.Subject, Name: email?.Name });
	};

	const handleCloseForm = () =>
	{
		setShowEmailTemplateForm(false);
		setEmailTemplate(null);
	};

	const sectionStyle = {
		paddingRight: '32px'
	};
	const labelStyle = {
		fontSize: '20px',
		fontWeight: 400,
		pl: 2
	};

	const handleUploadImage = async (file: File) =>
	{
		const formData = new FormData();
		formData.append('AttachmentPath', file);
		try
		{
			let res = await UploadShowMutation.mutateAsync(formData);
			if (res.Success === 0) throw new Error(res.Message);
			handleChangeEmailTemplates('AttachmentpPath', res.Data.replace('http://scofolio.tridmark.com', BASE_URL).replace('https://devapi.scofolio.com', BASE_URL));
		} catch (err: any)
		{
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};

	return (
		<Stack spacing={ 4 } useFlexGap m={ 5 }>
			<BackOfficeHeader title="Email Templates" />

			{ emails?.map((email) => (
				<Grid container spacing={ 2 } sx={ sectionStyle }>
					<Grid item xs={ 12 } md={ 2 }>
						<Stack direction={ 'row' } justifyContent={ 'space-between' } mr={ 2 }>
							<Typography sx={ labelStyle }>{ email?.Name }</Typography>
							{ !!currentPagePermissions?.HasModifyPermission && (
								<IconButton onClick={ () => handleOpenForm(email) }>
									<EditIcon />
								</IconButton>
							) }
						</Stack>
					</Grid>
					<Grid item xs={ 12 } md={ 8 }>
						<Stack direction={ 'column' } spacing={ 2 }>
							<Box sx={ { background: '#fff', p: 2 } }>{ email?.Subject }</Box>
							<Box sx={ { background: '#fff', p: 2 } }>{ parseHTML(email?.Body) }</Box>
						</Stack>
					</Grid>
				</Grid>
			)) }

			{ showEmailTemplateForm && (
				<EditEmailTemplate onSubmit={ handleOnSubmit } currentEmailTemplate={ emailTemplate! } loading={ false } onChange={ handleChangeEmailTemplates } onClose={ handleCloseForm } open={ showEmailTemplateForm } />
			) }
			{/* Buttons */ }
			{/* <Grid container>
					<Grid item xs={12} md={10}>
						<Stack direction={'row'} my={5} gap={2} justifyContent={'end'}>
							<MuiButton
								variant="outlined"
								size="medium"
								sx={{
									backgroundColor: '#F0F5FD',
									color: '#8B8BAE',
									fontWeight: 400,
									border: 'none',
									'&:hover': { border: 'none' }
								}}>
								Cancel
							</MuiButton>
							<MuiButton variant="outlined" size="medium" color="primary" sx={{ borderRadius: 0, borderColor: 'white', '&:hover': {} }} disabled={false} onClick={handleOnSubmit}>
								Apply Changes
							</MuiButton>
						</Stack>
					</Grid>
				</Grid> */}
		</Stack>
	);
};

export default Emails;
