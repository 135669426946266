import { Box, Stack, Typography } from '@mui/material';
import { FiltersProps } from 'models/filters';
 
type Props = {
	onChange: (name: string, value: any) => void;
	filters: FiltersProps;
};

const InteractionFilters: React.FC<Props> = ({ onChange, filters }) => {

	let interactions = [{
		Id: 1,
		Name: 'First Level'
	},
	{
		Id: 2,
		Name: 'Second Level'
	}
	]


	const handleChange = (id: number) => {
		if (filters.levelOfInteraction === id) {
			onChange('levelOfInteraction', null);
			return;
		}
		onChange('levelOfInteraction', id);
	};


	return (
		<Stack spacing={1} useFlexGap sx={{
			maxHeight: '20vh',
			overflowY: 'auto'
		}}>
			<Typography variant="h6" component={'p'} fontFamily={'600'}>
				Interactions
			</Typography>
			<Stack>
				{interactions.map((interaction: any, index: number) => (
					<Stack direction={'row'} key={index} >
						<Box component={'input'} type='checkbox'
							checked={filters?.levelOfInteraction ? filters.levelOfInteraction === interaction.Id : false}
 							onChange={() => handleChange(interaction.Id)}
							sx={{
								cursor: 'pointer',
								mx: 1
							}}
						/>
						<Typography variant='body2' >{interaction.Name}</Typography>
					</Stack>
				))}
			</Stack>
		</Stack>
	);
};

export default InteractionFilters;
