import { Grid, Stack, Typography } from '@mui/material';
import MuiButton from 'components/UI/MuiButton';
import { Feature, ResponseResult } from 'models/api';
import React, { useEffect, useState } from 'react';
import ListUsers from './partials/ListAcoountUsers';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getAdminUsers, inviteAdminUser } from 'framework/users';
import { UserInvitation } from 'models/settings';
import ActionLoader from 'components/UI/ActionLoader';
import InviteUser from './partials/InviteUser';
import { useAlert } from 'contexts/alertContext';
import { Permission } from 'models/permission';
import useApp from 'hooks/useApp';

interface Props {
	feature: Feature;
	onHideHeader?: (status: boolean) => void;
}

const AccountUsers: React.FC<Props> = ({ feature, onHideHeader }) => {
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	const [showInviteUser, setShowInviteUser] = useState<boolean>(false);
	const [inviteUserFormData, setInviteUserFormData] = useState<UserInvitation | null>(null);

	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 14);

	const { push } = useApp();

	const inviteMutation = useMutation({
		mutationFn: (data: any) => {
			return inviteAdminUser(data);
		},	 
	});

 
	useEffect(() => {
		if (!currentPagePermissions?.HasReadPermission) {
			push('/404');
		}
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [currentPagePermissions]);

	const { data, isError, isLoading } = useQuery<ResponseResult>(['users', { query: '' }], getAdminUsers);

	React.useEffect(() => {
		if (onHideHeader) onHideHeader(true);
		return () => {
			if (onHideHeader) onHideHeader(false);
		};
	}, [feature, onHideHeader]);

	if (isError)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);

	if (isLoading) return <ActionLoader position={'absolute'} />;

	const handleClose = () => {
		setShowInviteUser(false);
		setInviteUserFormData(null);
	};
	const handleChangeFormInviteUser = (name: string, value: any) => setInviteUserFormData({ ...inviteUserFormData!, [name]: value });

	const handleInviteUser = async () => {
		handleClose();
		try {
			let query = `?Email=${inviteUserFormData?.Email}&groupIds=${inviteUserFormData?.Group
				?.map((item:any) => item.value).join(',')}`
			const res: ResponseResult = await  inviteMutation.mutateAsync(query);
			if (res.Success === 0) throw new Error(res.Message);

			//fetch Users Again
			queryClient.refetchQueries(['users', { query: '' }]);

			setAlert({
				open: true,
				message: 'User Invited Successfully',
				type: 'success'
			});
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		handleClose();
	};

	return (
		<Stack spacing={4} useFlexGap>
			{/* Headers */}
			<Stack direction={'row'} justifyContent={'end'} alignItems={'center'}>
				{!!currentPagePermissions?.HasModifyPermission && (
					<MuiButton variant="outlined" color="primary" size="large" sx={{ borderRadius: 0, borderColor: 'white', '&:hover': {} }} onClick={() => setShowInviteUser(true)}>
						Invite User
					</MuiButton>
				)}
			</Stack>

			{/* Users list */}
			<Stack border={'1px solid'} borderColor={'divider'} borderRadius={1}>
				<ListUsers users={data?.Data} feature={feature} />
			</Stack>

			{/* Invite User */}
			{showInviteUser && <InviteUser open={showInviteUser} onClose={handleClose} onChange={handleChangeFormInviteUser} onSubmit={handleInviteUser} loading={isLoading} userInvitationForm={inviteUserFormData!} />}
		</Stack>
	);
};

export default AccountUsers;
