import React from 'react';

interface VerticalProgressBarProps {
	percent: number; // Percentage of the progress (0 to 100)
	fillColor: string; // Color of the filled portion
}

const VerticalProgressBar: React.FC<VerticalProgressBarProps> = ({ percent, fillColor }) => {
	const progressStyle = {
		height: `${percent}%`,
		backgroundColor: `rgba(${fillColor},1)`,
		borderRadius: '1px'
	};

	return (
		<div style={{ width: 40, height: '100px', backgroundColor: `rgba(${fillColor},0.1)`, position: 'relative', borderRadius: '5px' }}>
			<div style={{ ...progressStyle, position: 'absolute', bottom: 0, left: 0, right: 0 }} />
		</div>
	);
};

export default VerticalProgressBar;
