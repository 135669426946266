import { CircularProgress, Stack } from '@mui/material';
import DialogModal from 'components/UI/DialogModal';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import { InputTypes } from 'constants/enums';
import { IFormField } from 'models/app';
import { ICategoryInput } from 'models/testBank';
import React from 'react';


interface Props { 
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onChange: (name: string, value: string) => void;
  loading: boolean;
  addCategoryFormData:ICategoryInput;
};


const AddCategory: React.FC<Props> = ({ open, loading, addCategoryFormData, onChange, onClose, onSubmit }) => {
  
  let addCategoryFields: IFormField[] = [
    {
      name: 'Name',
      type: InputTypes.TEXT,
      placeholder: 'Enter Category Name',
      defaultValue: ''
    }
  ];

  return (
    <>
      <DialogModal
        open={open}
        onClose={onClose}
        maxWidth="xl"
        title="New Category"
        children={
          <Stack sx={{ width: '30vw',pt:3 }}>
            <Form fields={addCategoryFields} onChange={onChange} />
          </Stack>
        }
        actions={
          <>
            <MuiButton
              variant="outlined"
              size="medium"
              sx={{
                backgroundColor: '#F0F5FD',
                color: '#8B8BAE',
                fontWeight: 400,
                border: 'none',
                '&:hover': { border: 'none' }
              }}
              onClick={onClose}>
              Cancel
            </MuiButton>
            <MuiButton
              variant="outlined"
              size="medium"
              sx={{
                backgroundColor: '#0A94D1',
                color: '#fff',
                fontWeight: 400,
                border: 'none',
                '&:hover': { color: '#0A94D1', border: 'none' }
              }}
              disabled={loading || !addCategoryFormData?.Name}
              onClick={onSubmit}>
              Add
              <CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
            </MuiButton>
          </>
        }
      />
    </>
  );
};

export default AddCategory;