import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, Divider, Grid, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { Feature, ResponseResult } from 'models/api';
import { IAddUserProfileFormBuilder, IEditUserProfileFormBuilder, IUserProfileFormBuilder } from 'models/settings';
import { addProfileField, deleteProfileField, deleteProfileFieldOption, getProfileBuilderFields, translateProfileField, updateProfileField } from 'framework/settings';
import { useQueryClient } from 'react-query';
import ActionLoader from 'components/UI/ActionLoader';
import EditProfileBuilder from './Partials/EditProfileBuilder';
import AddProfileBuilder from './Partials/AddProfileBuilder';
import CloseIcon from '@mui/icons-material/Close';
import AddCircle from '@mui/icons-material/AddCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAlert } from 'contexts/alertContext';
import TranslateIcon from '@mui/icons-material/Translate';
import TranslateField from './Partials/TranslateField';
import { TranslateFieldForm } from 'models/Competency';
import { BackOfficeHeader } from 'helpers/utils';
import { Permission } from 'models/permission';
import useApp from 'hooks/useApp';

interface Props {
	feature: Feature;
	onHideHeader?: (status: boolean) => void;
}

const UserProfile: React.FC<Props> = ({ feature, onHideHeader }) => {
	const [formFields, setFormFields] = useState<IUserProfileFormBuilder[] | null>(null);
	const [currentFormField, setCurrentFormField] = useState<IUserProfileFormBuilder | null>(null);
	const [formFieldsPending, setFormFieldsPending] = useState<IAddUserProfileFormBuilder[]>([]);
	const [CurrentForm, setCurrentForm] = useState<IEditUserProfileFormBuilder | null>(null);
	const [CurrentAddForm, setCurrentAddForm] = useState<IAddUserProfileFormBuilder | null>(null);
	const [showCurrentForm, setShowCurrentForm] = useState<boolean>(false);
	const [showCurrentAddForm, setShowCurrentAddForm] = useState<boolean>(false);
	const [showCurrentTranslateForm, setShowCurrentTranslateForm] = useState<boolean>(false);
	const [translateFieldForm, setTranslateFieldForm] = useState<TranslateFieldForm | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	let permissionsLocalStorage: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissionsLocalStorage?.find((item) => item.PermissionId === 10);

	const queryClient = useQueryClient();
	const { setAlert } = useAlert();

	const { push } = useApp();


	useEffect(() => {
		if (!currentPagePermissions?.HasReadPermission) {
			push('/404');
		}
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [currentPagePermissions]);
	const fetchData = useCallback(async () => {
		setLoading(true);
		try {
			setLoading(true);
			const response: ResponseResult = await queryClient.fetchQuery(['getProfileBuilder', { query: '' }], getProfileBuilderFields);
			setFormFields(response?.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
		// eslint-disable-next-line
	}, []);



	useEffect(() => {
		if (onHideHeader) onHideHeader(true);
		return () => {
			if (onHideHeader) onHideHeader(false);
		};
	}, [feature, onHideHeader]);
	useEffect(() => {
		fetchData();
		// eslint-disable-next-line
	}, []);

 

	if (loading) {
		return <ActionLoader position={'absolute'} />;
	}

	const sectionStyle = {
		paddingRight: '32px'
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setShowCurrentForm(false);
		setShowCurrentAddForm(false);
		setCurrentForm(null);
		setCurrentAddForm(null);
		setShowCurrentTranslateForm(false);
		setTranslateFieldForm(null);
	};

	const handleOpenTranslation = (e: any, item: IUserProfileFormBuilder) => {
		e.stopPropagation();
		setShowCurrentTranslateForm(true);
		setTranslateFieldForm({ customProfileField: item?.Field?.Id!, label: '', languageId: null! });
	};

	const renderPendingFields = () => {
		return formFieldsPending?.map((field, index) => (
			<Box
				key={index}
				onClick={() => handleSelectAddForm(field, index)}
				sx={{
					width: '100%',
					backgroundColor: '#fff',
					p: 4,
					cursor: 'pointer',
					boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
					mb: 3,
					position: 'relative'
				}}>
				<Tooltip title="Remove Field" placement="top">
					<IconButton
						aria-label="Delete"
						color="error"
						sx={{
							position: 'absolute',
							top: '8px',
							right: '8px'
						}}
						onClick={(e) => {
							e.stopPropagation();
							handleDeleteField(index);
						}}>
						<CloseIcon />
					</IconButton>
				</Tooltip>

				<Typography sx={{ fontSize: '16px', fontWeight: 700, py: 1, pb: 2 }}>{field?.fieldLabel}</Typography>
				{field?.fieldTypeId === 3 ? (
					<Box
						sx={{
							width: '100%',
							backgroundColor: 'rgba(196, 196, 196, 0.3)',
							border: '2px solid rgba(196, 196, 196, 1)',
							height: '50px'
						}}>
						<Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', height: '100%', p: 1 }}>
							<KeyboardArrowDownIcon sx={{ width: '30px', height: '30px' }} />
						</Box>
					</Box>
				) : (
					<Box
						sx={{
							width: '100%',
							backgroundColor: 'rgba(196, 196, 196, 0.3)',
							border: '2px solid rgba(196, 196, 196, 1)',
							height: field?.fieldTypeId === 2 ? '150px' : '50px'
						}}
					/>
				)}
			</Box>
		));
	};

	const renderFields = () => {
		return formFields?.map((field) => (
			<Box
				key={field.Field?.Id}
				onClick={() => handleSelectForm(field)}
				sx={{
					width: '100%',
					backgroundColor: '#fff',
					p: 4,
					cursor: 'pointer',
					boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'
				}}>
				{!!currentPagePermissions?.HasModifyPermission && (
					<Stack justifyContent={'end'} direction={'row'}>
						<IconButton sx={{ width: 'fit-content' }} size="small" color="inherit" onClick={(e) => handleOpenTranslation(e, field)}>
							<TranslateIcon fontSize="small" />
						</IconButton>
					</Stack>
				)}

				<Typography sx={{ fontSize: '16px', fontWeight: 700, py: 1, pb: 2 }}>{field.Field?.FieldLabel}</Typography>
				{field?.Field?.FieldTypeId === 3 ? (
					<Box
						sx={{
							width: '100%',
							backgroundColor: 'rgba(196, 196, 196, 0.3)',
							border: '2px solid rgba(196, 196, 196, 1)',
							height: '30px'
						}}>
						<Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', height: '100%', p: 1 }}>
							<KeyboardArrowDownIcon sx={{ width: '30px', height: '30px' }} />
						</Box>
					</Box>
				) : (
					<Box
						sx={{
							width: '100%',
							backgroundColor: 'rgba(196, 196, 196, 0.3)',
							border: '2px solid rgba(196, 196, 196, 1)',
							height: field.Field?.FieldTypeId === 2 ? '78px' : '30px'
						}}
					/>
				)}
			</Box>
		));
	};

	const handleSelectForm = (formField: IUserProfileFormBuilder) => {
		setCurrentFormField(formField);
		setShowCurrentForm(true);
		// setCurrentForm({
		// 	Id: formField.Field?.Id!,
		// 	fieldLabel: formField.Field?.FieldLabel!,
		// 	fieldTypeId: formField.Field?.FieldTypeId,
		// 	isRequired: formField.Field?.IsRequired!,
		// 	Values: formField?.Values!,
		// 	name: formField?.Field?.Name
		// });
	};

	const handleSelectAddForm = (formField: IAddUserProfileFormBuilder, index: number) => {
		setShowCurrentAddForm(true);
		setCurrentAddForm({ ...formField, index: index });
	};
	const handleChangeTranslatePillarForm = (name: string, value: any) => setTranslateFieldForm({ ...translateFieldForm!, [name]: value });

	const handleDeleteForm = async () => {
		setLoading(true);

		try {
			const res: ResponseResult = await deleteProfileField(currentEditForm?.Id!);

			if (res.Success === 0) throw new Error(res.Message);

			setAlert({
				open: true,
				message: 'Field Deleted Successfully',
				type: 'success'
			});
			fetchData();
			setLoading(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			//handleClose();
			setLoading(false);
		}
		setLoading(false);
	};

	const handleAddPendingFormField = (fieldTypeId: number) => {
		setFormFieldsPending((prevData) => [...prevData, { fieldLabel: '-', fieldTypeId, isRequired: false, name: '-', index: null! }]);
		handleClose();
	};

	const handleChangeFormEditFormField = (name: string, value: any) => {
		const updatedValue = !value ? false : value;
		setCurrentForm({ ...CurrentForm!, [name]: updatedValue });
	};

	const handleChangeFormAddFormField = (name: string, value: any) => {
		const updatedValue = !value ? false : value;
		setCurrentAddForm({ ...CurrentAddForm!, [name]: updatedValue });
	};

	const handleAddFormField = async () => {
		setLoading(true);

		try {
			const res: ResponseResult = await addProfileField(CurrentAddForm!);

			if (res.Success === 0) throw new Error(res.Message);

			setAlert({
				open: true,
				message: 'Field Added Successfully',
				type: 'success'
			});
			fetchData();
			handleDeleteField(CurrentAddForm?.index!);
			handleClose();
			setLoading(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	const handlEditFormField = async () => {
		setLoading(true);

		const updateInput: IEditUserProfileFormBuilder = {
			fieldLabel: CurrentForm?.fieldLabel || currentEditForm?.fieldLabel!,
			fieldTypeId: CurrentForm?.fieldTypeId || currentEditForm?.fieldTypeId!,
			Id: CurrentForm?.Id || currentEditForm?.Id!,
			isRequired: CurrentForm?.isRequired || false,
			name: CurrentForm?.name || currentEditForm?.name!,
			Values: CurrentForm?.Values || currentEditForm?.Values!
		};

		try {
			const res: ResponseResult = await updateProfileField(updateInput!);

			if (res.Success === 0) throw new Error(res.Message);

			setAlert({
				open: true,
				message: 'Field Updated Successfully',
				type: 'success'
			});
			fetchData();
			//handleClose();
			setLoading(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	const handleDeleteOption = async (id: number) => {
		setLoading(true);

		try {
			const res: ResponseResult = await deleteProfileFieldOption(id);

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Deleted',
				type: 'success'
			});
			setLoading(false);
			//queryClient.refetchQueries(['getProfileBuilder', { query: '' }]);
			fetchData();
			//onClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	const handleTranslateFormField = async () => {
		setLoading(true);

		try {
			const res: ResponseResult = await translateProfileField(translateFieldForm!);

			if (res.Success === 0) throw new Error(res.Message);

			setAlert({
				open: true,
				message: 'Field Translated Successfully',
				type: 'success'
			});
			fetchData();
			handleClose();
			setLoading(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	const handleDeleteField = (index: number) => {
		const updatedFormFields = [...formFieldsPending!];

		updatedFormFields.splice(index, 1);

		setFormFieldsPending(updatedFormFields);
	};

	const currenFormAfterFetch: IUserProfileFormBuilder = formFields?.find((item: IUserProfileFormBuilder) => item?.Field?.Id === currentFormField?.Field?.Id)!;
	const currentEditForm: IEditUserProfileFormBuilder = {
		fieldLabel: currenFormAfterFetch?.Field?.FieldLabel,
		fieldTypeId: currenFormAfterFetch?.Field?.FieldTypeId,
		Id: currenFormAfterFetch?.Field?.Id,
		isRequired: currenFormAfterFetch?.Field?.IsRequired,
		name: currenFormAfterFetch?.Field?.Name,
		Values: currenFormAfterFetch?.Values
	};

	return (
		<Stack spacing={4} useFlexGap m={5}>
			<BackOfficeHeader title="User Profile Builder" />

			<Box p={2}>
				{!!currentPagePermissions?.HasModifyPermission && (
					<Button
						startIcon={<AddCircle sx={{ width: '30px', height: '30px' }} />}
						size="large"
						id="basic-button"
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}>
						Add New
					</Button>
				)}

				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button'
					}}>
					<MenuItem onClick={() => handleAddPendingFormField(1)}>Single Line Text</MenuItem>
					<MenuItem onClick={() => handleAddPendingFormField(2)}>Paragraph Text</MenuItem>
					<MenuItem onClick={() => handleAddPendingFormField(3)}>Drop Menu</MenuItem>
				</Menu>
			</Box>
			<Grid container spacing={4} sx={sectionStyle} p={4}>
				<Grid item xs={12} md={6}>
					<Stack direction={'column'} spacing={3} alignItems={'end'}>
						{formFieldsPending && formFieldsPending.length > 0 && (
							<Box sx={{ width: '100%' }}>
								<Typography variant="h6" pb={2}>
									Pending Form Fields
								</Typography>
								{renderPendingFields()}
								<Divider sx={{ my: 4, borderBottom: '2px dashed #000' }} />
							</Box>
						)}
						{renderFields()}
					</Stack>
				</Grid>
				{showCurrentForm && (
					<EditProfileBuilder
						fetchData={fetchData}
						currentFormField={currentEditForm!}
						onChange={handleChangeFormEditFormField}
						loading={false}
						open={showCurrentForm}
						onClose={handleClose}
						onDelete={handleDeleteForm}
						onDeleteOption={handleDeleteOption}
						onSubmit={handlEditFormField}
					/>
				)}
				{showCurrentAddForm && (
					<AddProfileBuilder NewFormField={CurrentAddForm!} onChange={handleChangeFormAddFormField} loading={false} open={showCurrentAddForm} onClose={handleClose} onSubmit={handleAddFormField} />
				)}

				{showCurrentTranslateForm && (
					<TranslateField translateForm={translateFieldForm!} onChange={handleChangeTranslatePillarForm} open={showCurrentTranslateForm} onClose={handleClose} onSubmit={handleTranslateFormField} />
				)}
			</Grid>
		</Stack>
	);
};

export default UserProfile;
