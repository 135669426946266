import { createContext, useContext, useState } from "react";



export interface CustomAlertObj {
  open: boolean;
  messages: string[];
  type: 'success' | 'error' | 'warning' | 'info';
}

type CustomAlertContextType = {
  CustomAlert: CustomAlertObj | null;
  setCustomAlert: (site: CustomAlertObj | null) => void;
};

const CustomAlertContextDefaultValues: CustomAlertContextType = {
  CustomAlert: { open: false, messages: [], type: "success" },
  setCustomAlert: () => { },
};

const CustomAlertContext = createContext<CustomAlertContextType>(CustomAlertContextDefaultValues);

export function useCustomAlert() {
  return useContext(CustomAlertContext);
}

interface Props {
  children: React.ReactNode;
}

interface IState {
  CustomAlert: CustomAlertObj | null;
}

const INITIAL_STATE: IState = {
  CustomAlert: { open: false, messages:[], type: "success" }
};

export const CustomAlertProvider: React.FC<Props> = ({ children }) => {
  const [state, setState] = useState<IState>(INITIAL_STATE);
  const { CustomAlert } = state;

  const setCustomAlert = (CustomAlert: CustomAlertObj | null) => setState({ ...state, CustomAlert });
  const value = { CustomAlert, setCustomAlert };
  return <CustomAlertContext.Provider value={value}>{children}</CustomAlertContext.Provider>;
};
