import {
	ADD_ORGANIZATIONS,
	ADD_ORGANIZATION_GROUP,
	DELETE_ORGANIZATIONS,
	DELETE_ORGANIZATION_GROUP,
	EDIT_ORGANIZATIONS,
	EDIT_ORGANIZATION_GROUP,
	EDIT_USER,
	GET_COUNTRIES,
	GET_ORGANIZATIONS,
	GET_ORGANIZATIONS_WITH_GROUPS
} from 'constants/api';
import axios from '../helpers/axiosInstance';
import { HeadCell } from 'models/app';

export async function getOrganizations({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_ORGANIZATIONS + _params.query);
	return response.data;
}
export async function getOrganizationsWithGroups({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_ORGANIZATIONS_WITH_GROUPS + _params.query);
	return response.data;
}
export async function createOrganization(data: FormData) {
	const response = await axios.post(ADD_ORGANIZATIONS, data);
	return response.data;
}
export async function editOrganization(data: FormData) {
	const response = await axios.post(EDIT_ORGANIZATIONS, data);
	return response.data;
}

export async function editUser(data: FormData) {
	const response = await axios.post(EDIT_USER, data);
	return response.data;
}

export async function deleteOrganization(id: number) {
	const response = await axios.post(DELETE_ORGANIZATIONS + '?orgranizationId=' + id);
	return response.data;
}

export async function deleteGroup(id: number) {
	const response = await axios.post(DELETE_ORGANIZATION_GROUP + '?groupId=' + id);
	return response.data;
}

export async function addGroup(data: AddGroupForm) {
	const response = await axios.post(ADD_ORGANIZATION_GROUP, data);
	return response.data;
}

export async function editGroup(data: EditGroupForm) {
	const response = await axios.post(EDIT_ORGANIZATION_GROUP, data);
	return response.data;
}

export async function getCountries({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_COUNTRIES + _params.query);
	return response.data;
}

export const OrganizationCells: HeadCell[] = [
	{
		id: 'Name',
		numeric: false,
		disablePadding: false,
		label: 'Name'
	},
	{
		id: 'GroupsCount',
		numeric: false,
		disablePadding: false,
		label: 'Groups Count'
	},
	{
		id: 'UsersCount',
		numeric: false,
		disablePadding: false,
		label: 'Users Count'
	},
	{
		id: 'actions',
		numeric: false,
		disablePadding: false,
		label: '',
		isNotSortable: true
	}
];

export const OrganizationCellsWithGroups: HeadCell[] = [
	{
		id: 'GroupName',
		numeric: false,
		disablePadding: false,
		label: 'Name'
	},
	{
		id: 'actions',
		numeric: false,
		disablePadding: false,
		label: '',
		isNotSortable: true
	}
];

export interface AddGroupForm {
	OrganizationId: number;
	GroupName: string;
	GroupDescription: string;
}

export interface EditGroupForm {
	GroupId: number;
	GroupName: string;
	GroupDescription: string;
}
