
import { GET_ADMIN_GROUPS, GET_GROUP } from "constants/api";
import axios from "../helpers/axiosInstance";


export async function getGroups({ queryKey }: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, _params] = queryKey;
  const response = await axios.get(GET_GROUP + _params.query);
  return response.data;
}
export async function GetAdminGroupsListByAdminId({ queryKey }: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, _params] = queryKey;
  const response = await axios.get(GET_ADMIN_GROUPS + _params.query);
  return response.data;
}
