import {
	GET_USERS,
	ADD_USERS_AND_GROUPS,
	GET_ADMIN_USERS,
	GET_USER_PERMISSIONS,
	DELETE_ADMIN_USER,
	INVITE_USER,
	GET_GROUPS_LIST,
	UPDATE_USER_PERMISSION,
	UPDATE_GROUP_PERMISSION,
	ADD_GROUP,
	GET_ADMINS_GROUP,
	GET_GROUP_PERMISSIONS,
	UPDATE_ADMIN_USER,
	DELETE_USER,
	BULK_IMPORT,
	UPDATE_GROUP,
	DELETE_ADMIN_GROUP,
	DOWNLOAD_USER_TEMPLATE,
	RESET_PASSWORD_FIRST_TIME
} from 'constants/api';
import axios from '../helpers/axiosInstance';
import { HeadCell } from 'models/app';
import { GroupForm, IEditUser, IPermissionsGroupForm, IPermissionsUserForm } from 'models/settings';

export async function getUsers({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_USERS + _params.query);
	return response.data;
}

export async function getAdminUsers({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_ADMIN_USERS + _params.query);
	return response.data;
}

export async function getAdminGroubs({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_GROUPS_LIST + _params.query);
	return response.data;
}

export async function deleteAdminUser({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.post(DELETE_ADMIN_USER + _params.query);
	return response.data;
}

export async function deleteUser(Id: number) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars

	const response = await axios.post(DELETE_USER + `?userId=${Id}`);
	return response.data;
}

export async function deleteAdminGroup({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.post(DELETE_ADMIN_GROUP + _params.query);
	return response.data;
}
export async function resetPassFirstTime(password: string) {
	const response = await axios.post(RESET_PASSWORD_FIRST_TIME + '?userpass=' + password);
	return response.data;	
}
export async function inviteAdminUser(query:string) {
  	const response = await axios.post(INVITE_USER +query);
	return response.data;
}

export async function getUsersPermissions({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_USER_PERMISSIONS + _params.query);
	return response.data;
}

export async function getGroupPermissions({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_GROUP_PERMISSIONS + _params.query);
	return response.data;
}

export async function getGroupAdmins({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_ADMINS_GROUP + _params.query);
	return response.data;
}

export async function UpdateUserPermission(data: IPermissionsUserForm[]) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(UPDATE_USER_PERMISSION, data);
	return response.data;
}

export async function updateAdminUser(data: IEditUser) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(UPDATE_ADMIN_USER, data);
	return response.data;
}

export async function UpdateGroupPermission(data: IPermissionsGroupForm[]) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(UPDATE_GROUP_PERMISSION, data);
	return response.data;
}

export async function UpdateGroup(data: GroupForm) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(UPDATE_GROUP, data);
	return response.data;
}

export async function bulkImport(data: FormData) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(BULK_IMPORT, data, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
}

export async function addGroup(data: GroupForm) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(ADD_GROUP, data);
	return response.data;
}

export async function addUsersAndGroups(data: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(ADD_USERS_AND_GROUPS, data);
	return response.data;
}
export async function DownloadUsersTemplate() {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const response = await axios.post(DOWNLOAD_USER_TEMPLATE);
	return response.data;
}

export const UserCells: HeadCell[] = [
	{
		id: 'FirstName',
		numeric: false,
		disablePadding: false,
		label: 'Name'
	},
	{
		id: 'OrganizationName',
		numeric: false,
		disablePadding: false,
		label: 'Organization'
	},
	{
		id: 'GroupName',
		numeric: false,
		disablePadding: false,
		label: 'Group'
	},
	{
		id: 'LastLogin',
		numeric: false,
		disablePadding: false,
		label: 'Last Login',
		isDate: true
	},
	{
		id: 'EmailAddress',
		numeric: false,
		disablePadding: false,
		label: 'Email'
	},
	{
		id: 'UserStatusName',
		numeric: false,
		disablePadding: false,
		label: 'User Status '
	},
	{
		id: 'actions',
		numeric: false,
		disablePadding: false,
		label: '',
		isNotSortable: true
	}
];

export const UserCellsPermission: HeadCell[] = [
	{
		id: 'FirstName',
		numeric: false,
		disablePadding: false,
		label: 'Name'
	},
	{
		id: 'EmailAddress',
		numeric: false,
		disablePadding: false,
		label: 'Email'
	},
	{
		id: 'GroupName',
		numeric: false,
		disablePadding: false,
		label: 'Group'
	},

	{
		id: 'UserStatusName',
		numeric: false,
		disablePadding: false,
		label: 'Status'
	},
	{
		id: 'LastLogin',
		numeric: false,
		disablePadding: false,
		label: 'Last Login',
		isDate: true
	}
];

export const GroupCellsPermission: HeadCell[] = [
	{
		id: 'Name',
		numeric: false,
		disablePadding: false,
		label: 'Name'
	},
	{
		id: 'Description',
		numeric: false,
		disablePadding: false,
		label: 'Description'
	}
];
