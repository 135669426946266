export const BASE_URL = 'https://devapi.scofolio.com';

// Authentication
export const LOGIN = '/api/Authentication/login';
export const FORGET_PASSWORD = '/api/User/ForgetPassword';
export const RESET_PASSWORD = '/api/User/ChangePassword';
export const RESET_PASSWORD_FIRST_TIME = '/api/User/ChangePasswordFromProfile';
export const REGISTER = '/api/Authentication/register';
export const AUTH = '/api/Authentication/GenerateRefreshToken';
export const PERMISSIONS = '/api/AdminUserPermission/GetAdminPagePermissions';
export const ACTIVATE_ACCOUNT = '/api/User/Activate';

// Showcase
export const GET_SHOWCASE = '/api/Showcase/GetAdminShowcases'; // GET
export const GET_SINGLE_SHOWCASE = '/api/Showcase/GetAdminShowcaseDetailsById'; // GET {showcaseID} in query params
export const GET_SHOWCASE_INTERACTIONS = '/api/Showcase/GetShowcaseInteractionsSummary'; // GET {showcaseId} in query params
export const GET_SHOWCASE_INTERACTIONS_DETAILS = '/api/Showcase/GetAdminHistoryByShowcase'; // GET {showcaseResponseId} in query params
export const ADD_SHOWCASE = '/api/Showcase/SaveShowCase'; // POST
export const DELETE_SHOWCASE = '/api/Showcase/DeleteShowCase'; // POST
export const UPDATE_SHOWCASE = '/api/Showcase/UpdateShowCase'; // POST
export const UPDATE_SHOWCASE_STATUS = '/api/Showcase/UpdateShowcaseStatus'; // POST
export const GENERATE_SHOWCASE_TEMPLATE = '/api/Showcase/GenerateTemplate'; // GET
export const IMPORT_SHOWCASE_TEMPLATE = '/api/Showcase/ImportShowcases'; // GET
export const UPLOAD_ATTACHMENT = '/api/Showcase/UploadShowCaseImage'; // POST

// History
export const GET_HISTORY = '/api/Showcase/GetHistory'; // GET
export const GET_SINGLE_HISTORY = '/api/Showcase/GetUserHistoryDetailByShowcase'; // GET {showCaseId} in query params

// Tags
export const GET_TAGS = '/api/tag/Get'; // GET
export const ADD_TAG = '/api/Tag/Create'; // POST {?Name} in query params

// Topics
export const GET_TOPICS = '/api/topic/GetTopics'; // GET
export const ADD_TOPIC = '/api/Topic/CreateTopic'; // POST {?Name} in query params

// Languages
export const GET_LANGUAGES = '/api/showcase/GetLanguageList'; // GET
export const GET_TRANSLATION_LANGUAGES = '/api/Language/GetTranslationLanguages'; // GET
export const GET_TRANSLATION_COMPETENCY = '/api/Competency/GetCompetencyTranslation'; // GET
export const GET_TRANSLATION_PILLAR = '/api/Pillar/GetPillarTranslation'; // GET
export const GET_TRANSLATION_KEY_MANAGMENT = '/api/Setting/GetSiteSettingsTranslation'; // GET
export const GET_TRANSLATION_CHARACTER = '/api/ResponseForm/GetCharacterTranslation'; // GET
export const GET_TRANSLATION_PURPOSE = '/api/ResponseForm/GetPurposeTranslation'; // GET

// Priorities
export const GET_PRIORITIES = '/api/showcase/GetPrioriyList'; // GET

// Response Form
export const GET_REFERENCE_SENTENCE_SETS = '/api/ResponseForm/GetReferenceSentenceSets'; //GET
export const GET_REFERENCE_SENTENCE_SETS_BY_ID = '/api/ResponseForm/GetReferenceSentenceSetById'; //GET
export const GET_DIRECTS = '/api/ResponseForm/GetDialicts'; //GET
export const ADD_REFERENCE_SENTENCE_SET = '/api/ResponseForm/AddReferenceSentenceSet'; //POST
export const UPDATE_REFERENCE_SENTENCE_SET = '/api/ResponseForm/UpdateReferenceSentenceSet'; //POST
export const DELETE_REFERENCE_SENTENCE_SET = '/api/ResponseForm/DeleteReferenceSentenceSet'; //POST
export const GET_REFERENCE_QUICK_REPLY = '/api/ResponseForm/GetReferenceQuickReplyByReferenceSetId'; //GET
export const DELETE_REFERENCE_QUICK_REPLY = '/api/ResponseForm/DeleteReferenceQuickReply'; //POST
export const ADD_REFERENCE_QUICK_REPLY = '/api/ResponseForm/AddReferenceQuickReply'; //POST
export const UPDATE_REFERENCE_QUICK_REPLY = '/api/ResponseForm/UpdateReferenceQuickReply'; //POST
export const UPDATE_REFERENCE_SENTENCE_DATA = '/api/ResponseForm/UpdateReferenceSentenceData'; //POST
export const GET_FEELINGS = '/api/ResponseForm/GetFeelings'; //GET
export const GET_CHARCTER_BY_FEELING_ID = '/api/ResponseForm/GetCharacterByFeelingId'; //GET
export const GET_PURPOSE_BY_CHARCTER_ID = '/api/ResponseForm/GetPurposeByCharacterId'; //GET
export const GET_REFRENCE_BY_PURPOSE_ID = '/api/ResponseForm/GetReferenceSentenceDataByPurposeId'; //GET
export const ADD_REFRENCE_BY_PURPOSE_ID = '/api/ResponseForm/AddReferenceSentenceData'; //POST
export const DELETE_REFRENCE_SET_DATA = '/api/ResponseForm/DeleteReferenceSentenceData'; //POST
export const GET_LABELS = 'api/ResponseForm/GetLabels'; //GET
export const GET_ADVANCED_SETTINGS = '/api/ResponseForm/GetAdvancedSettings'; //GET
export const UPDATE_ADVANCED_SETTINGS = '/api/ResponseForm/UpdateAdvancedSettings'; //POST
export const UPDATE_LABELS = '/api/ResponseForm/UpdateLabels'; //POST
export const DELETE_CHARACTER = '/api/ResponseForm/DeleteCharacter'; //POST
export const DELETE_PURPOSE = '/api/ResponseForm/DeletePurpose'; //POST
export const UPDATE_CHARACTER = '/api/ResponseForm/UpdateCharacter'; //POST
export const UPDATE_PURPOSE = '/api/ResponseForm/UpdatePurpose'; //POST
export const ADD_PURPOSE = '/api/ResponseForm/AddPurpose'; //POST
export const ADD_CHARACTER = '/api/ResponseForm/AddCharacter'; //POST
export const TRANSLATE_CHARACTER = '/api/ResponseForm/AddUpdateCharacterTranslation'; //POST
export const TRANSLATE_PURPOSE = '/api/ResponseForm/AddUpdatePurposeTranslation'; //POST
export const SORT_CHARACTER = '/api/ResponseForm/UpdateCharactersSortOrder'; //POST
export const SORT_PURPOSE = '/api/ResponseForm/UpdatePurposeSortOrder'; //POST
export const SORT_REFERENCE_SENTENCE_DATA = '/api/ResponseForm/UpdateReferenceSentencesSortOrder'; //POST
export const SORT_REFERENCE_QUICK_REPLY = '/api/ResponseForm/UpdateQuickReplySortOrder'; //POST

// Statuses
export const GET_STATUSES = '/api/showcase/GetStatusList'; // GET

// Insights
export const GET_INSIGHTS = '/api/insights/get'; // GET {InsightId}

// Organization
export const GET_ORGANIZATION = '/api/Orgranization/GetById'; // GET {OrganizationId}
export const GET_ORGANIZATIONS = '/api/Orgranization/Get'; // GET
export const GET_ORGANIZATIONS_WITH_GROUPS = '/api/Orgranization/GetOrganizationsWithGroups'; // GET
export const ADD_ORGANIZATIONS = '/api/Orgranization/Create'; // POST
export const EDIT_ORGANIZATIONS = '/api/Orgranization/Update'; // POST
export const EDIT_USER = '/api/User/UpdateUser'; // POST

export const DELETE_ORGANIZATIONS = '/api/Orgranization/Delete'; // POST
export const DELETE_ORGANIZATION_GROUP = '/api/Orgranization/DeleteOrganizationGroup'; // POST
export const ADD_ORGANIZATION_GROUP = '/api/Orgranization/AddOrganizationGroup'; // POST
export const EDIT_ORGANIZATION_GROUP = '/api/Orgranization/UpdateOrganizationGroup'; // POST

export const GET_COUNTRIES = '/api/Orgranization/GetCountries'; // GET

// Organization
export const GET_USER = '/api/Users/GetById'; // GET {OrganizationId}
export const GET_USERS = '/api/User/GetUsers'; // GET
export const GET_ADMIN_USERS = '/api/AdminUserPermission/GetAdminUsers'; // GET
export const ADD_USERS_AND_GROUPS = '/api/User/AddUsers'; // GET
export const GET_USER_PERMISSIONS = '/api/AdminUserPermission/GetAdminUserPermissions'; // GET
export const GET_GROUP_PERMISSIONS = '/api/AdminGroupPermission/GetAdminGroupPermissions'; // GET
export const DOWNLOAD_USER_TEMPLATE = '/api/ImportUsers/DownloadUsersTemplate'; // POST

// Group
export const GET_GROUP = '/api/group/Get'; // GET {GroupId}
export const GET_ADMIN_GROUPS = '/api/User/GetAdminGroupsListByAdminId'; // GET {GroupId}

// Data Center
export const GET_DATA_CENTER = '/api/DataCenter'; // GET {?fromDate} {?toDate} in query params
export const GET_TOTAL_USERS = '/api/DataCenter/GetTotalUsers';
export const GET_ERROR_LOG = '/api/DataCenter/GetErrorLog';
export const GET_TOTAL_ORGANIZATIONS = '/api/DataCenter/GetTotalOrganization';
export const GET_TOTAL_REPORTS = '/api/DataCenter/GetTotalReportsReceived';
export const GET_ACTIVE_USERS = '/api/DataCenter/GetActiveUsers';

// Backlog
export const GET_BACKLOG = '/api/BackLog/Get';
export const DELETE_BACKLOG = '/api/BackLog/Delete';
export const UPDATE_BACKLOG = '/api/BackLog/Update';

// Stream
export const GET_STREAMS = '/api/Streams/Get'; // GET
export const GET_RELEVANCE_VOTES = '/api/Streams/GetRelevanceVotes'; // GET
export const APPROVE_INTERACTION = '/api/Streams/ApproveInteraction'; // POST {id} in query params
export const SET_RELEVANCE_VOTE = '/api/Streams/SetRelevanceVote'; // POST {id,voteId} in query params
export const SET_INTERACTION_AS_NONE = '/api/Streams/SetInterfactionAsNone'; // POST {id,voteId} in query params
export const ADD_TO_BACKLOG = '/api/Streams/AddBacklog'; // POST {id,voteId} in query params
export const ADD_REPLY = '/api/Streams/AddAdminReply'; // POST {id,voteId} in query params
export const EDIT_STREAM = '/api/Streams/EditInteraction'; // POST {id,voteId} in query params

// Activity History
export const GET_ACTIVITY_HISTORY = '/api/Streams/GetActivityHistory'; // GET

// Interaction
export const GET_INTERACTIONS = '/api/Interaction/Get'; // GET

// Competency
export const GET_COMPETENCY_BY_ID = '/api/Competency/GetById';
export const GET_COMPETENCIES = '/api/Competency/get';
export const DELETE_COMPETENCY = '/api/Competency/Delete';
export const TRANSLATE_COMPETENCY = '/api/Competency/AddUpdateTranslation';
export const ADD_COMPETENCY = '/api/Competency/Create';
export const EDIT_COMPETENCY = '/api/Competency/Update';

// Support
export const ADD_SUPPORT_CATEGORY = '/api/Support/AddCreateSupportCategory';
export const EDIt_SUPPORT_CATEGORY = '/api/Support/UpdateSupportCategory';
export const DELETE_SUPPORT_CATEGORY = '/api/Support/DeleteSupportCategory';
export const DELETE_SUPPORT_TICKET = '/api/Support/Delete';
export const SPAM_SUPPORT_TICKET = '/api/Support/Spam';
export const ASSIGN_TICKET_TO_CATEGORY = '/api/Support/AssignTicketToCategory';
export const CLOSE_SUPPORT_TICKET = '/api/Support/Close';
export const IN_PROGRESS_SUPPORT_TICKET = '/api/Support/InProgress';
export const ADD_SUPPORT_REPLY = '/api/Support/AddSupportReply';
export const GET_SUPPORT_STATUSES = '/api/Support/GetSupportTicketStatus';

// Indicators
export const GET_INDICATORS = '/api/Competency/GetIndicatorsByCompetency'; // GET

//  Stream Tabs
export const GET_STREAM_TABS = '/api/streams/Gettabs'; // POST

// Reviewer
export const GET_REVIEWER = '/api/streams/getreviewers'; // GET

// TestCases
export const GET_TESTS = '/api/Test/GetTests'; //POST
export const GET_CATEGORIES = '/api/Test/GetAllTestCategories'; //GET
export const GET_PAGES = '/api/Test/GetPageTitles'; //GET
export const GET_TEST_QUESTIONS_TYPE = '/api/Test/GetTestQuestionType'; //GET
export const GET_COMPETENCY_LIST = '/api/Test/GetCompetencyList'; //GET
export const GET_SUPPORT_CATEGORIES_LIST = '/api/Support/GetSupportCategories'; //GET
export const GET_TICKETS = '/api/Support/GetSupportTickets'; //GET
export const GET_TICKET_REPLIES = '/api/Support/GetReplies'; //GET
export const GET_TICKET_BY_ID = '/api/Support/GetSupportTicketById'; //GET
export const GET_PILLAR_LIST = '/api/Pillar/GetList'; //GET
export const ADD_CATEGORY = '/api/Test/AddTestCategory'; //POST
export const UPDATE_CATEGORY = '/api/Test/UpdateTestCategory'; //POST
export const DELETE_CATEGORY = '/api/Test/DeleteTestCategory'; //POST
export const ADD_TEST = 'api/Test/AddTest'; //GET
export const EDIT_TEST = '/api/Test/UpdateTest'; //GET
export const DELETE_TEST = '/api/Test/DeleteTest'; //GET
export const TEST_DETAIL = '/api/Test/GetTestDetils'; //GET
export const TEST_FULL_INFORMATION = '/api/Test/GetTestInformation'; //GET
export const TEST_LANGUAGES = '/api/Test/GetLanguageList'; //GET
export const GET_QUESTION_BY_ID = '/api/Test/GetQuestionById'; // GET
export const ADD_TEST_QUESTION = '/api/Test/addtestquestion'; // POST
export const EDIT_TEST_QUESTION = '/api/Test/UpdateTestQuestion'; // POST
export const DELETE_TEST_QUESTION = '/api/Test/DeleteTestQuestion'; // POST
export const ADD_TEST_QUESTION_OPTION = '/api/Test/AddQuestionOptions'; // POST
export const EDIT_TEST_QUESTION_OPTION = '/api/Test/UpdateQuestionOptions'; // POST
export const DELETE_TEST_QUESTION_OPTION = '/api/Test/DeleteQuestionOption'; // POST
export const GET_QUESTION_TYPES = '/api/Test/GetTestQuestionType'; // GET
export const STATUS_FOR_TEST = '/api/Test/GetStatuses'; // GET

// Pillar
export const GET_PILLARS = '/api/Pillar/GetList'; // GET
export const ADD_PILLARS = '/api/Pillar/Create'; // POST
export const DELETE_PILLARS = '/api/Pillar/Delete'; // POST
export const UPDATE_PILLARS = '/api/Pillar/Update'; // POST
export const TRANSLATE_PILLARS = '/api/Pillar/AddUpdateTranslation'; // POST

//Bulk

export const BULK_IMPORT = '/api/ImportUsers/Import';
// settings
export const GET_SITE_SETTINGS = '/api/Setting/GetSiteSettings';
export const GET_PASSWORD_POLICY = '/api/Setting/GetPasswordPolicy';
export const GET_PRIVACY_POLICY = '/api/Setting/GetPrivacyPolicy';
export const GET_TERMS = '/api/Setting/GetTerms';
export const GET_EMAIL_TEMPLATES = '/api/Emails/GetEmailTemplates';
export const UPDATE_EMAIL_TEMPLATES = '/api/Emails/UpdateEmailTemplates';
export const UPDATE_SITE_SETTINGS = '/api/Setting/UpdateSiteSettings';
export const UPDATE_PASSWORD_POLICY = '/api/Setting/UpdatePasswordPolicy';

//repoorts
export const GET_REPORTS_COMPETENCIES = '/api/Reports/GetCompetencies';
export const GET_REPORTS_TESTS = '/api/Reports/GetTests';
export const GET_REPORTS_USERS = '/api/Reports/GetUsers';
export const GET_REPORTS_USER_TESTS = '/api/AdminUserPermission/GetUserTests';
export const GET_REPORTS_USER_ACTIVITIES = '/api/AdminUserPermission/GetUserActivity';
export const GET_REPORTS_ORGANIZATIONS = '/api/Reports/GetOrganisations';
export const GET_REPORTS_FIRST_LEVEL_INTERACTION = '/api/Reports/GetNumberOfFirstLevelOfInteraction';
export const GET_REPORTS_SECOND_LEVEL_INTERACTION = '/api/Reports/GetNumberOfSecondLevelOfInteraction';
export const GET_REPORTS_ASSESMENTS_WITHOUT_TESTS = '/api/Reports/GetNumberOfUsersCompletedCompetencyAssessmentWithoutTests';
export const GET_REPORTS_ASSESMENTS_WITH_TESTS = '/api/Reports/GetNumberOfUsersCompletedCompetencyAssessmentWithTests';
export const GET_REPORTS_TOP_PERFORMANCE = '/api/Reports/GetTopPerformanceByCompetency';
export const GET_COMPETENCY_INDICATOR = '/api/Reports/GetCompetenciesIndicators';
export const GET_NUMBER_OF_INTERACTION_SET_AS_NONE = '/api/Reports/GetNumberOfInteractionsSetAsNone';

// access permissions
export const UPDATE_USER_PERMISSION = '/api/AdminUserPermission/UpdateAdminUserPermissions'; //POST
export const UPDATE_GROUP_PERMISSION = '/api/AdminGroupPermission/UpdateAdminGroupPermissions'; //POST
export const UPDATE_GROUP = '/api/AdminGroupPermission/UpdateAdminGroup'; //POST
export const DELETE_ADMIN_USER = '/api/AdminUserPermission/DeleteUser'; //POST
export const DELETE_USER = '/api/User/DeleteUser'; //POST
export const DELETE_ADMIN_GROUP = '/api/AdminGroupPermission/DeleteAdminGroup'; //POST
export const INVITE_USER = '/api/AdminUserPermission/InviteUser'; //POST
export const GET_GROUPS_LIST = '/api/AdminGroupPermission/GetAdminGroupsList'; //GET
export const ADD_GROUP = '/api/AdminGroupPermission/AddAdminGroup'; //POST
export const GET_ADMINS_GROUP = '/api/User/GetAdminUsersByGroupId'; //GET
export const GET_PROFILE_BUILDER = '/api/AdminProfile/GetProfileFields'; //GET
export const DELETE_PROFILE_BUILDER = '/api/AdminProfile/DeleteCustomProfileField'; //GET
export const ADD_PROFILE_BUILDER = '/api/AdminProfile/InsertCustomProfileField'; //GET
export const TRANSLATE_PROFILE_BUILDER = '/api/AdminProfile/UpsertFieldTranslation'; //POST
export const GET_TRANSLATE_PROFILE_BUILDER = '/api/Profile/GetFieldTranslation'; //GET
export const TRANSLATE_KEY_MANAGMENT = '/api/Setting/UpsertSiteSettingsTranslation'; //POST
export const UPDATE_PROFILE_BUILDER = '/api/AdminProfile/UpdateCustomProfileField'; //GET
export const DELETE_PROFILE_BUILDER_OPTION = '/api/AdminProfile/DeleteCustomProfileFieldDropdownValue'; //GET
export const UPDATE_PROFILE_BUILDER_OPTION = '/api/AdminProfile/UpdateCustomProfileFieldDropdownValue'; //GET
export const UPDATE_PROFILE_BUILDER_OPTION_SORT = '/api/AdminProfile/UpdateFieldValuesSortOrder'; //GET
export const ADD_PROFILE_BUILDER_OPTION = '/api/AdminProfile/InsertCustomProfileFieldDropdownValue'; //GET
export const UPDATE_ADMIN_USER = '/api/AdminUserPermission/UpdateAdminUser'; //POST

// Progress
export const GET_PROGRESS = '/api/Reports/GetProgres'; // GET

//notifications

export const GET_ADMIN_NOTIFICATIONS = '/api/Notification/GetAdminNotifications';
export const MARK_NOTIFICATION_AS_READ = '/api/Notification/MarkAsRead';
export const NOTIFICATION_MARK_MULTIPLE_READ = '/api/Notification/MarkMultipleAsRead';


// Help
export const GET_HELP = '/api/User/GetUserGuideForAdmin'; // GET
export const UPDATE_HELP = '/api/User/UpsertUserGuide'; // POST

// Admin Profile
export const GET_ADMIN_PROFILE = '/api/AdminProfile/GetProfile'; // GET
export const UPDATE_ADMIN_PROFILE = '/api/AdminProfile/UpdateProfile'; // POST
