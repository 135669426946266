import { CloseOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import DialogModal from 'components/UI/DialogModal';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import MuiOutlineButton from 'components/UI/MuiOutlineButton';
import { InputTypes } from 'constants/enums';
import { getAdminUsers } from 'framework/users';
import { ResponseResult } from 'models/api';
import { IFormField } from 'models/app';
import { GroupForm } from 'models/settings';
import { User } from 'models/user';

import React from 'react';
import { useQuery } from 'react-query';

interface Props {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	onChange: (name: string, value: string) => void;
	loading: boolean;
	groupForm: GroupForm;
}

const CreateGroup: React.FC<Props> = ({ open, loading, groupForm, onChange, onClose, onSubmit }) => {
	const { data, isError, isLoading } = useQuery<ResponseResult>(['users', { query: '' }], getAdminUsers);

	if (isError)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);

	if (isLoading) return <ActionLoader position={'absolute'} />;

	let emailField: IFormField[] = [
		{
			name: 'Name',
			type: InputTypes.TEXT,
			placeholder: 'Enter Group Name'
		}
	];

	let groupField: IFormField[] = [
		{
			name: 'Description',
			type: InputTypes.TEXTAREA,
			placeholder: 'Enter Group Description'
		}
	];

	let usersField: IFormField[] = [
		{
			name: 'Users',
			type: InputTypes.MULTI_SELECT,
			placeholder: 'Select users',
			multiple: true,
			options: data?.Data.map((user: User) => ({ label: user.FirstName + ' ' + user.LastName, value: `${user.Id}` })),
			helperText: 'Enter user groups separated by commas'
		}
	];
	return (
		<DialogModal
			open={open}
			onClose={onClose}
			maxWidth="xl"
			hideTitle={true}
			title="Invite User"
			children={
				<Stack sx={{ width: '100%', maxWidth: '50vw' }} spacing={2}>
					<Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
						<Stack pb={{ xs: 2, sm: 0 }} direction={'row'} spacing={1}>
							<Typography sx={{ color: '#3D82AB' }}>Groups</Typography>
							<Typography>&#62; </Typography>
							<Typography>New </Typography>
						</Stack>
						<MuiOutlineButton sx={{ color: 'gray', minWidth: 40 }} size="small" onClick={onClose}>
							<CloseOutlined fontSize="small" />
						</MuiOutlineButton>
					</Stack>

					<Box p={3} border={'1px solid'} borderColor={'divider'} borderRadius={'8px'}>
						<Stack spacing={2}>
							<Grid container spacing={2}>
								<Grid item xs={2}>
									Name
								</Grid>
								<Grid item xs={10}>
									<Form fields={emailField} onChange={onChange} />
								</Grid>
								<Grid item xs={2}>
									Description
								</Grid>
								<Grid item xs={10}>
									<Form fields={groupField} onChange={onChange} />
								</Grid>
								<Grid item xs={2}>
									Users
								</Grid>
								<Grid item xs={10}>
									<Form fields={usersField} onChange={onChange} />
								</Grid>
							</Grid>
						</Stack>
					</Box>
				</Stack>
			}
			actions={
				<>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#F0F5FD',
							color: '#8B8BAE',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onClose}>
						Cancel
					</MuiButton>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#0A94D1',
							color: '#fff',
							fontWeight: 400,
							border: 'none',
							'&:hover': { color: '#0A94D1', border: 'none' }
						}}
						disabled={loading}
						onClick={onSubmit}>
						Save
						<CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
					</MuiButton>
				</>
			}
		/>
	);
};

export default CreateGroup;
