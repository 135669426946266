import { Stack } from '@mui/material';
import { Feature } from 'models/api';
import React from 'react';
import ListOrganizations from './partials/ListOrganizations';
import { IReportOrganizations } from 'framework/reports';

interface Props {
	feature: Feature;
	data: IReportOrganizations[];
}

const Organizations: React.FC<Props> = ({ feature, data }) => {
	return (
		<Stack spacing={4} useFlexGap border={'1px solid'} borderColor={'divider'} borderRadius={1}>
			<ListOrganizations organizations={data!} feature={feature} />
		</Stack>
	);
};

export default Organizations;
