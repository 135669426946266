import { Feature } from 'models/api';
import React, { useEffect } from 'react';
import ReportsList from './List/ReportsList';
import ReportsView from './View/ReportView';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { Permission } from 'models/permission';
import useApp from 'hooks/useApp';

interface Props {
	feature: Feature;
}

const Reports: React.FC<Props> = ({ feature }) => {
	const [selectedTab, setSelectedTab] = React.useState<number>(0);

	const { push } = useApp();


	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 3);

	useEffect(() => {
		if (!currentPagePermissions?.HasReadPermission) {
			push('/404');
		}
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [currentPagePermissions]);

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`
		};
	}

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		event.preventDefault();
		setSelectedTab(newValue);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Grid container justifyContent={'space-between'} alignItems={'center'} sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
					<Tab
						label={
							<Typography variant="body2" fontWeight={400} fontSize={12} color={'TextGray'}>
								Overview
							</Typography>
						}
						{...a11yProps(0)}
					/>
					<Tab
						label={
							<Typography variant="body2" fontWeight={400} fontSize={12} color={'TextGray'}>
								Activity
							</Typography>
						}
						{...a11yProps(1)}
					/>
				</Tabs>
			</Grid>
			<Box m={5}>
				{selectedTab === 0 && <ReportsList feature={feature} />}
				{selectedTab === 1 && <ReportsView feature={feature} />}
			</Box>
		</Box>
	);
};

export default Reports;
