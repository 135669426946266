import { Stack, TableCell, Typography } from '@mui/material';
import List from 'components/UI/List';
import { Feature } from 'models/api';
import React from 'react';
import { IReportOrganizations, OrganizationsCellsReports } from 'framework/reports';

interface Props {
	organizations: IReportOrganizations[];
	feature: Feature;
}

const ListOrganizations: React.FC<Props> = ({ organizations, feature }) => {
	const getBodyCells = (row: IReportOrganizations) => {
		let cells = Object.keys(row).map((key: string, i: number) => {
			if (key === 'Name')
				return {
					id: 'Name',
					element: (
						<TableCell align={'left'} key={i}>
							<Stack direction={'row'} spacing={1}>
								<Typography variant="body2" fontWeight={500}>
									{row?.Name}
								</Typography>
							</Stack>
						</TableCell>
					)
				};
			if (key === 'CompetencyName')
				return {
					id: 'CompetencyName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.CompetencyName}</Typography>
						</TableCell>
					)
				};
			if (key === 'NumberOfUsers')
				return {
					id: 'NumberOfUsers',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.NumberOfUsers}</Typography>
						</TableCell>
					)
				};
			if (key === 'NumberofInteractions')
				return {
					id: 'NumberofInteractions',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.NumberofInteractions}</Typography>
						</TableCell>
					)
				};
			else return <></>;
		});
		return cells;
	};

	return <List headCells={OrganizationsCellsReports} data={organizations!} getBodyCells={getBodyCells} feature={feature} canSelect={false} canEdit={false} />;
};

export default ListOrganizations;
