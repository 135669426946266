import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import CustomForm from 'components/UI/CustomForm';
import { InputTypes } from 'constants/enums';
import { useAlert } from 'contexts/alertContext';
import { getCompetencyList } from 'framework/competency';
import { getIndicators } from 'framework/indicator';
import { getLanguages } from 'framework/language';
import { getCategories, getPages } from 'framework/testBank';
import { Competency } from 'models/Competency';
import { Language, ResponseResult } from 'models/api';
import { IFormField } from 'models/app';
import { ICategory, IPage, ITestEditInput } from 'models/testBank';
import React, { useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';

interface Props {
	editTestForm: ITestEditInput;
	onChange: (name: string, value: any) => void;
}

const GeneralTestItems: React.FC<Props> = ({ editTestForm, onChange }) => {
	const [loading1, setLoading1] = React.useState(true);
	const [loading2, setLoading2] = React.useState(true);
	const [loading3, setLoading3] = React.useState(true);
	const [loading4, setLoading4] = React.useState(true);
	const [competencies, setCompetencies] = React.useState<Competency[] | null>(null);
	const [indicators, setIndicators] = React.useState<string[] | null>(null);
	const [languages, setLanguages] = React.useState<Language[] | null>(null);
	const [categories, setCategories] = React.useState<ICategory[] | null>(null);
	const [pages, setPages] = React.useState<IPage[] | null>(null);
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();

	const fetchLanguages = useCallback(async () => {
		setLoading1(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['languages', { query: '' }], getLanguages);
			setLanguages(response.Data);
			setLoading1(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading1(false);
		}

		// eslint-disable-next-line
	}, []);
	const fetchCompetencies = useCallback(async () => {
		setLoading2(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['competencies', { query: '' }], getCompetencyList);
			setCompetencies(response.Data);
			setLoading2(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading2(false);
		}

		// eslint-disable-next-line
	}, []);

	const fetchRedirectPages = useCallback(async () => {
		setLoading4(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['pages', { query: '' }], getPages);
			setPages(response.Data);
			setLoading4(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading4(false);
		}

		// eslint-disable-next-line
	}, []);

	const fetchCategories = useCallback(async () => {
		setLoading3(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['categories', { query: '' }], getCategories);
			setCategories(response.Data);
			setLoading3(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading3(false);
		}

		// eslint-disable-next-line
	}, []);
	const fetchIndicator = useCallback(async () => {
		if (!editTestForm?.CompetencyId) return setIndicators(null);
		let compId = editTestForm?.CompetencyId;
		let params = '?competencyId=' + compId;
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['indicators', { query: params }], getIndicators);
			setIndicators(response.Data);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}

		// eslint-disable-next-line
	}, [editTestForm?.CompetencyId]);

	useEffect(() => {
		fetchCompetencies();
		fetchLanguages();
		fetchCategories();
		fetchRedirectPages();
		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		fetchIndicator();
		// eslint-disable-next-line
	}, [editTestForm?.CompetencyId]);

	if (loading1 || loading2 || loading3 || loading4) return <Skeleton variant="rectangular" height={500} animation="wave" />;
	if (!competencies || !languages || !categories) return <></>;

	let competencyOptions = competencies
		? competencies.map((comp: Competency) => ({
				value: `${comp.Id}`,
				label: comp.Name
		  }))
		: [];

	let indicatorOptions = indicators
		? indicators?.map((indicator: string) => ({
				value: indicator,
				label: indicator
		  }))
		: [];

	let languageOptions = languages
		? languages.map((language: Language) => ({
				value: `${language.Id}`,
				label: language.Name
		  }))
		: [];

	let categoryOptions = categories
		? categories.map((category: ICategory) => ({
				value: `${category.Id}`,
				label: category.Name
		  }))
		: [];

	let pagesOptions = pages
		? pages.map((page: IPage) => ({
				value: `${page.Id}`,
				label: page.PageTitle
		  }))
		: [];

	let basicNameFields: IFormField[] = [
		{
			name: 'Name',
			type: InputTypes.TEXT,
			isHorizontal: true,
			defaultValue: editTestForm?.Name!
		}
	];
	let basicDescriptionFields: IFormField[] = [
		{
			name: 'Description',
			type: InputTypes.EDITOR,
			isHorizontal: true,
			defaultValue: editTestForm?.Description!
		}
	];

	// let basicDisplayFields: IFormField[] = [
	// 	{
	// 		name: 'DisplayTestName',
	// 		label: 'Test name',
	// 		type: InputTypes.CHECKBOX,
	// 		checkBoxDirection: 'end',
	// 		defaultValue: editTestForm.DisplayTestName || false
	// 	},
	// 	{
	// 		name: 'DisplayPageTitles',
	// 		label: 'Page titles',
	// 		type: InputTypes.CHECKBOX,
	// 		checkBoxDirection: 'end',
	// 		defaultValue: editTestForm?.DisplayPageTitles! || false
	// 	},
	// 	{
	// 		name: 'DisplayFlexiQuizLink',
	// 		label: 'FlexiQuiz link',
	// 		type: InputTypes.CHECKBOX,
	// 		checkBoxDirection: 'end',
	// 		defaultValue: editTestForm?.DisplayFlexiQuizLink! || false
	// 	},
	// 	{
	// 		name: 'DisplayProgressBar',
	// 		label: 'Progress bar',
	// 		type: InputTypes.CHECKBOX,
	// 		checkBoxDirection: 'end',
	// 		defaultValue: editTestForm?.DisplayProgressBar! || false
	// 	},
	// 	{
	// 		name: 'DisplayPageNumberBar',
	// 		label: 'Page number bar',
	// 		type: InputTypes.CHECKBOX,
	// 		checkBoxDirection: 'end',
	// 		defaultValue: editTestForm?.DisplayPageNumberBar! || false
	// 	},
	// 	{
	// 		name: 'DisplayLogo',
	// 		label: 'Logo',
	// 		type: InputTypes.CHECKBOX,
	// 		checkBoxDirection: 'end',
	// 		defaultValue: editTestForm?.DisplayLogo! || false
	// 	}
	// ];

	let basicTimeLimitFields: IFormField[] = [
		{
			name: 'HasTimeLimit',
			type: InputTypes.CHECKBOX,
			checkBoxDirection: 'end',
			defaultValue: editTestForm?.HasTimeLimit!
		},
		{
			name: 'TimeLimit',
			type: InputTypes.NUMBER,
			placeholder: '0',
			defaultValue: editTestForm?.TimeLimit! || 0,
			disabled: !editTestForm?.HasTimeLimit!
		}
	];

	let basicRelatedCompetencyFields: IFormField[] = [
		{
			name: 'CompetencyId',
			type: InputTypes.SELECT,
			options: competencyOptions ? competencyOptions : [],
			placeholder: 'Select Competency',
			defaultValue: editTestForm?.CompetencyId ? competencyOptions?.find((x) => x.value === editTestForm?.CompetencyId!.toString()) : null
		}
	];
	let basicIndicatorFields: IFormField[] = [
		{
			name: 'Indicator',
			type: InputTypes.MULTI_SELECT,
			multiple: true,
			options: indicatorOptions,
			placeholder: 'Select Indicator',
			isHorizontal: true,
			defaultValue: editTestForm?.Indicator ? indicatorOptions?.filter((x) => editTestForm?.Indicator?.includes(x.value)) : null
		}
	];
	let basicLanguageFields: IFormField[] = [
		{
			name: 'LanguageId',
			type: InputTypes.SELECT,
			options: languageOptions ? languageOptions : [],
			placeholder: 'Select Language',
			isHorizontal: true,
			defaultValue: editTestForm?.LanguageId ? languageOptions?.find((x) => x.value === editTestForm?.LanguageId!.toString()) : null
		}
	];
	let basicCategoryFields: IFormField[] = [
		{
			name: 'CategoryId',
			type: InputTypes.SELECT,
			options: categoryOptions ? categoryOptions : [],
			placeholder: 'Select Category',
			isHorizontal: true,
			defaultValue: editTestForm?.CategoryId ? categoryOptions?.find((x) => x.value === editTestForm?.CategoryId!.toString()) : null
		}
	];
	let basicTestRoundFields: IFormField[] = [
		{
			name: 'TestRound',
			type: InputTypes.NUMBER,
			placeholder: '',
			maxInputNumber: 2,
			minInputNumber: 1,
			defaultValue: editTestForm?.TestRound! || 1
		}
	];
	let basicAutoNumberQuestionsFields: IFormField[] = [
		{
			name: 'AutoNumberQuestions',
			type: InputTypes.CHECKBOX,
			defaultValue: editTestForm?.AutoNumberQuestions! || false
		}
	];

	let basicQuestionsPerPageFields: IFormField[] = [
		// {
		//   name: 'AutoNumberQuestions',
		//   type: InputTypes.CHECKBOX,
		//   checkBoxDirection: 'end',
		//   defaultValue: editTestForm?.AutoNumberQuestions! || false,
		// },
		{
			name: 'QuestionsPerPage',
			type: InputTypes.NUMBER,
			placeholder: '0',
			defaultValue: editTestForm?.QuestionsPerPage! || 0
		}
	];

	let basicRedirectionPageFields: IFormField[] = [
		{
			name: 'RedirectionPage',
			type: InputTypes.SELECT,
			options: pagesOptions ? pagesOptions : [],
			defaultValue: editTestForm?.RedirectionPage ? pagesOptions?.find((x) => x.value === editTestForm?.RedirectionPage!.toString()) : null,
			placeholder: 'https://www.example.com'
		}
	];

	// let basicAllowEditOptionsFields: IFormField[] = [
	// 	{
	// 		name: 'AllowCut',
	// 		label: 'Cut',
	// 		type: InputTypes.CHECKBOX,
	// 		checkBoxDirection: 'end',
	// 		defaultValue: editTestForm?.AllowCut! || false
	// 	},
	// 	{
	// 		name: 'AllowCopy',
	// 		label: 'Copy',
	// 		type: InputTypes.CHECKBOX,
	// 		checkBoxDirection: 'end',
	// 		defaultValue: editTestForm?.AllowCopy! || false
	// 	},
	// 	{
	// 		name: 'AllowPaste',
	// 		label: 'Paste',
	// 		type: InputTypes.CHECKBOX,
	// 		checkBoxDirection: 'end',
	// 		defaultValue: editTestForm?.AllowPaste! || false
	// 	},
	// 	{
	// 		name: 'AllowRightClick',
	// 		label: 'Right Click',
	// 		type: InputTypes.CHECKBOX,
	// 		checkBoxDirection: 'end',
	// 		defaultValue: editTestForm?.AllowRightClick! || false
	// 	},
	// 	{
	// 		name: 'AllowPrint',
	// 		label: 'Print',
	// 		type: InputTypes.CHECKBOX,
	// 		checkBoxDirection: 'end',
	// 		defaultValue: editTestForm?.AllowPrint! || false
	// 	}
	// ];

	// let basicAllowPreviousPagePaginationFields: IFormField[] = [
	// 	{
	// 		name: 'AllowPreviousPagePagination',
	// 		type: InputTypes.CHECKBOX,
	// 		checkBoxDirection: 'end',
	// 		defaultValue: editTestForm?.AllowPreviousPagePagination! || false
	// 	}
	// ];

	let basicConfirmOptionsFields: IFormField[] = [
		{
			name: 'ConfirmBeforeSubmit',
			label: 'Submit',
			type: InputTypes.CHECKBOX,
			checkBoxDirection: 'end',
			defaultValue: editTestForm?.ConfirmBeforeSubmit! || false
		},
		{
			name: 'ConfirmBeforeCloseBrowserTab',
			label: 'Close browser tab',
			type: InputTypes.CHECKBOX,
			checkBoxDirection: 'end',
			defaultValue: editTestForm?.ConfirmBeforeCloseBrowserTab! || false
		}
	];

	let basicTestRetryFields: IFormField[] = [
		{
			name: 'AllowRetryTest',
			label: 'Retry test',
			type: InputTypes.CHECKBOX,
			checkBoxDirection: 'end',
			defaultValue: editTestForm?.AllowRetryTest! || false
		},
		{
			name: 'NumberOfAllowedRetries',
			type: InputTypes.NUMBER,
			placeholder: '0',
			defaultValue: editTestForm?.NumberOfAllowedRetries! || 0,
			disabled: !editTestForm?.AllowRetryTest!
		}
	];

	return (
		<Grid container height={1} spacing={2} p={3} bgcolor={'grey.200'} borderRadius={'6px'}>
			{/* Test Name */}
			<Grid item lg={12}>
				<Stack direction={'row'} spacing={2} alignItems={'start'}>
					<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'10%'}>
						Test Name
					</Typography>
					<CustomForm fields={basicNameFields} onChange={onChange} width={'80%'} />
				</Stack>
			</Grid>
			{/* Test Description */}
			<Grid item lg={12}>
				<Stack direction={'row'} spacing={2} alignItems={'start'}>
					<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'10%'}>
						Test Description
					</Typography>
					<CustomForm fields={basicDescriptionFields} onChange={onChange} width={'80%'} />
				</Stack>
			</Grid>

			{/* Display */}
			{/* <Grid item lg={12}>
				<Stack direction={'row'} spacing={2} alignItems={'start'}>
					<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'10%'}>
						Display
					</Typography>
					<CustomForm isHorizontal={true} fields={basicDisplayFields} onChange={onChange} width={'80%'} />
				</Stack>
			</Grid> */}

			{/* Time Limit */}
			<Grid item lg={12}>
				<Stack direction={'row'} spacing={2} alignItems={'start'}>
					<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'10%'}>
						Time Limit
					</Typography>
					<Stack alignItems={'start'} justifyContent={'start'} width={'80%'}>
						<CustomForm isHorizontal={true} fields={basicTimeLimitFields} onChange={onChange} width={'20%'} />
						<Typography variant="body2" fontWeight={300} fontSize={15} color={'gray'}>
							Respondents will only have the set time to complete the whole quiz. The time limit is entered in the format hours:minutes (hh:mm)
						</Typography>
					</Stack>
				</Stack>
			</Grid>

			{/* Related Competency */}
			<Grid item lg={12}>
				<Stack direction={'row'} spacing={2} alignItems={'start'}>
					<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'10%'}>
						Related Competency
					</Typography>
					<Stack alignItems={'start'} justifyContent={'start'} width={'80%'}>
						<Stack direction={'row'} spacing={2} alignItems={'center'} width={'100%'}>
							<CustomForm isHorizontal={true} fields={basicRelatedCompetencyFields} onChange={onChange} width={'80%'} />
							<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'10%'}>
								Indicator
							</Typography>
							<Stack alignItems={'start'} justifyContent={'start'} width={'80%'}>
								<CustomForm isHorizontal={true} fields={basicIndicatorFields} onChange={onChange} width={'80%'} />
							</Stack>
						</Stack>
						<Typography variant="body2" fontWeight={300} fontSize={15} color={'gray'}>
							Set the related competency and the associated indicator if it is available.
						</Typography>
					</Stack>
				</Stack>
			</Grid>

			{/* Language */}
			<Grid item lg={12}>
				<Stack direction={'row'} spacing={2} alignItems={'start'}>
					<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'10%'}>
						Language
					</Typography>
					<Stack alignItems={'start'} justifyContent={'start'} width={'80%'}>
						<CustomForm isHorizontal={true} fields={basicLanguageFields} onChange={onChange} width={'35%'} />
						<Typography variant="body2" fontWeight={300} fontSize={15} color={'gray'}>
							Set the Test language.
						</Typography>
					</Stack>
				</Stack>
			</Grid>

			{/* Category */}
			<Grid item lg={12}>
				<Stack direction={'row'} spacing={2} alignItems={'start'}>
					<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'10%'}>
						Category
					</Typography>
					<Stack alignItems={'start'} justifyContent={'start'} width={'80%'}>
						<CustomForm isHorizontal={true} fields={basicCategoryFields} onChange={onChange} width={'35%'} />
						<Typography variant="body2" fontWeight={300} fontSize={15} color={'gray'}>
							Set the Test Category.
						</Typography>
					</Stack>
				</Stack>
			</Grid>

			{/* Test Round */}
			<Grid item lg={12}>
				<Stack direction={'row'} spacing={2} alignItems={'start'}>
					<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'10%'}>
						Test Round
					</Typography>
					<Stack alignItems={'start'} justifyContent={'start'} width={'80%'}>
						<CustomForm isHorizontal={true} fields={basicTestRoundFields} onChange={onChange} width={'20%'} />
						<Typography variant="body2" fontWeight={300} fontSize={15} color={'gray'}>
							Choose the test round: set one or two.
						</Typography>
					</Stack>
				</Stack>
			</Grid>

			{/* Auto Number Questions */}
			<Grid item lg={12}>
				<Stack direction={'row'} spacing={2} alignItems={'start'}>
					<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'10%'}>
						Auto Number Questions
					</Typography>
					<Stack alignItems={'start'} justifyContent={'start'} width={'80%'}>
						<CustomForm isHorizontal={true} fields={basicAutoNumberQuestionsFields} onChange={onChange} width={'20%'} />
						<Typography variant="body2" fontWeight={300} fontSize={15} color={'gray'}>
							A sequential number will be automatically added to the start of each question
						</Typography>
					</Stack>
				</Stack>
			</Grid>

			{/* Questions Per Page */}
			<Grid item lg={12}>
				<Stack direction={'row'} spacing={2} alignItems={'start'}>
					<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'10%'}>
						Questions Per Page
					</Typography>
					<Stack alignItems={'start'} justifyContent={'start'} width={'80%'}>
						<CustomForm isHorizontal={true} fields={basicQuestionsPerPageFields} onChange={onChange} width={'20%'} />
						<Typography variant="body2" fontWeight={300} fontSize={15} color={'gray'}>
							When the test is taken each page will contain the set number of questions.
						</Typography>
					</Stack>
				</Stack>
			</Grid>

			{/* Redirection Page */}
			<Grid item lg={12}>
				<Stack direction={'row'} spacing={2} alignItems={'start'}>
					<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'10%'}>
						Redirection Page
					</Typography>
					<Stack alignItems={'start'} justifyContent={'start'} width={'80%'}>
						<CustomForm isHorizontal={true} fields={basicRedirectionPageFields} onChange={onChange} width={'40%'} />
						<Typography variant="body2" fontWeight={300} fontSize={15} color={'gray'}>
							After completing the test respondents can be redirected to a specific page.
						</Typography>
					</Stack>
				</Stack>
			</Grid>

			{/* Allow Commands */}
			{/* <Grid item lg={12}>
				<Stack direction={'row'} spacing={2} alignItems={'start'}>
					<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'10%'}>
						Allow commands
					</Typography>
					<Stack alignItems={'start'} justifyContent={'start'} width={'80%'}>
						<CustomForm isHorizontal={true} fields={basicAllowEditOptionsFields} onChange={onChange} width={'20%'} />
						<Typography variant="body2" fontWeight={300} fontSize={15} color={'gray'}>
							Choose which options you want to allow respondents to use.
						</Typography>
					</Stack>
				</Stack>
			</Grid> */}

			{/* Allow Previous Page Pagination */}
			{/* <Grid item lg={12}>
				<Stack direction={'row'} spacing={2} alignItems={'start'}>
					<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'10%'}>
						Allow previous page navigation
					</Typography>
					<Stack alignItems={'start'} justifyContent={'start'} width={'80%'}>
						<CustomForm isHorizontal={true} fields={basicAllowPreviousPagePaginationFields} onChange={onChange} width={'20%'} />
						<Typography variant="body2" fontWeight={300} fontSize={15} color={'gray'}>
							If selected respondents can navigate backwards and forwards between the pages in your Test.
						</Typography>
					</Stack>
				</Stack>
			</Grid> */}

			{/* Confirm Options */}
			<Grid item lg={12}>
				<Stack direction={'row'} spacing={2} alignItems={'start'}>
					<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'10%'}>
						Confirm before
					</Typography>
					<Stack alignItems={'start'} justifyContent={'start'} width={'80%'}>
						<CustomForm isHorizontal={true} fields={basicConfirmOptionsFields} onChange={onChange} width={'20%'} />
						<Typography variant="body2" fontWeight={300} fontSize={15} color={'gray'}>
							If selected respondents will be asked to confirm before submitting the test or closing the browser tab.
						</Typography>
					</Stack>
				</Stack>
			</Grid>

			{/* Test Retry */}
			<Grid item lg={12}>
				<Stack direction={'row'} spacing={2} alignItems={'start'}>
					<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'} width={'10%'}>
						Number of test retries
					</Typography>
					<Stack alignItems={'start'} justifyContent={'start'} width={'80%'}>
						<CustomForm isHorizontal={true} fields={basicTestRetryFields} onChange={onChange} width={'30%'} />
						<Typography variant="body2" fontWeight={300} fontSize={15} color={'gray'}>
							Set the maximum number of times a user can repeat a test.
						</Typography>
					</Stack>
				</Stack>
			</Grid>
		</Grid>
	);
};

export default GeneralTestItems;
