import { AddCircle } from '@mui/icons-material';
import { Skeleton, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { Feature, ResponseResult } from 'models/api';
import MuiButton from 'components/UI/MuiButton';
import { useCallback, useEffect, useState } from 'react';
import { addCategory, addTest, deleteCategory, deleteTest, getTests, updateCategory } from 'framework/testBank';
import { useAlert } from 'contexts/alertContext';
import { ICategoryInput, ICategoryWithTest, ITest, ITestInput } from 'models/testBank';
import { Competency } from 'models/Competency';
import { getIndicators } from 'framework/indicator';
import MuiOutlineButton from 'components/UI/MuiOutlineButton';
import AddCategory from './partials/Category/AddCategory';
import EditCategory from './partials/Category/EditCategory';
import DeleteCategory from './partials/Category/DeleteCategory';
import AddTest from './partials/Test/AddTest';
import ListCategories from './partials/Category/ListCategories';
import DeleteTest from './partials/Test/DeleteTest';
import { getCompetencyList } from 'framework/competency';
import useTestBankStore from 'store/testBank';
import { BackOfficeHeader } from 'helpers/utils';
import { Permission } from 'models/permission';
import useApp from 'hooks/useApp';

interface Props {
	feature: Feature;
}

const TestBank: React.FC<Props> = ({ feature }) => {
	const queryClient = useQueryClient();
	const [showCreateCategory, setShowCreateCategory] = useState<boolean>(false);
	const [showEditCategory, setShowEditCategory] = useState<boolean>(false);
	const [showDeleteCategory, setShowDeleteCategory] = useState<boolean>(false);
	const [showCreateTest, setShowCreateTest] = useState<boolean>(false);
	const [showDeleteTest, setShowDeleteTest] = useState<boolean>(false);
	const [currentCategory, setCurrentCategory] = useState<ICategoryWithTest | null>(null);
	const [competencies, setCompetencies] = useState<Competency[] | null>(null);
	const [indicators, setIndicators] = useState<string[] | null>(null);
	const [addCategoryFormData, setAddCategoryFormData] = useState<ICategoryInput | null>(null);
	const [editCategoryFormData, setEditCategoryFormData] = useState<ICategoryInput | null>(null);
	const [addTestFormData, setAddTestFormData] = useState<ITestInput | null>(null);
	const [selectedTest, setSelectedTest] = useState<ITest | null>(null);
	const [loading, setLoading] = useState(false);
	const { setAlert } = useAlert();
	const listing = useTestBankStore((state) => state.listing);
	const setListing = useTestBankStore((state) => state.setListing);
	const params = useTestBankStore((state) => state.params);

	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 8);

	const { push } = useApp();


 
	useEffect(() => {
		if (!currentPagePermissions?.HasReadPermission) {
			push('/404');
		}
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		, [currentPagePermissions]);

	const addCategoryMutation = useMutation({
		mutationFn: (data: ICategoryInput) => {
			return addCategory(data);
		},
		onSuccess: () => {
			fetchTests();
		}
	});
	const editCategoryMutation = useMutation({
		mutationFn: (data: ICategoryInput) => {
			return updateCategory(data);
		},
		onSuccess: () => {
			fetchTests();
		}
	});
	const deleteCategoryMutation = useMutation({
		mutationFn: (data: ICategoryInput) => {
			return deleteCategory(data);
		},
		onSuccess: () => {
			fetchTests();
		}
	});
	const addTestMutation = useMutation({
		mutationFn: (data: ITestInput) => {
			return addTest(data);
		},
		onSuccess: () => {
			fetchTests();
		}
	});

	const deleteTestMutation = useMutation({
		mutationFn: (id: number) => {
			return deleteTest(id);
		},
		onSuccess: () => {
			fetchTests();
		}
	});

	const fetchTests = useCallback(async () => {
		setLoading(true);

		try {
			let newParams = params ? JSON.parse(params) : {};
			const response: ResponseResult = await queryClient.fetchQuery(['tests', { query: newParams }], getTests);
			setListing(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [params]);

	const fetchCompetencies = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['competencies', { query: '' }], getCompetencyList);
			setCompetencies(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);

	const fetchIndicator = useCallback(async () => {
		if (!addTestFormData?.CompetencyId) return setIndicators(null);
		let params = '?competencyId=' + addTestFormData?.CompetencyId!;
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['indicators', { query: params }], getIndicators);
			setIndicators(response.Data);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}

		// eslint-disable-next-line
	}, [addTestFormData?.CompetencyId]);

	useEffect(() => {
		fetchTests();
		// eslint-disable-next-line
	}, [params]);

	useEffect(() => {
		fetchCompetencies();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchIndicator();
		// eslint-disable-next-line
	}, [addTestFormData?.CompetencyId]);

	if (loading) return <Skeleton variant="rectangular" width={'100%'} height={400} />;

	const handleChangeFormInputAddCategory = (name: string, value: any) => setAddCategoryFormData({ ...addCategoryFormData!, [name]: value });
	const handleChangeFormInputEditCategory = (name: string, value: any) => setEditCategoryFormData({ ...addCategoryFormData!, [name]: value });

	const handleChangeFormInputAddTest = (name: string, value: any) => {
		return setAddTestFormData({ ...addTestFormData!, [name]: value });
	};

	const handelOpenAddCategory = () => {
		setShowCreateCategory(true);
	};
	const handelOpenEditCategory = (category: ICategoryWithTest) => {
		setShowEditCategory(true);
		setCurrentCategory(category);
	};

	const handleOpenDeleteCategory = (category: ICategoryWithTest) => {
		setShowDeleteCategory(true);
		setCurrentCategory(category);
	};
	const handleOpenDeleteTest = (test: ITest) => {
		setShowDeleteTest(true);
		setSelectedTest(test);
	};

	const handelOpenAddTest = () => {
		setShowCreateTest(true);
	};
	const handleClose = () => {
		setShowCreateCategory(false);
		setShowEditCategory(false);
		setShowDeleteCategory(false);
		setShowCreateTest(false);
		setShowDeleteTest(false);
		setAddCategoryFormData(null);
		setEditCategoryFormData(null);
		setAddTestFormData(null);
		setCurrentCategory(null);
		setSelectedTest(null);
		setAddTestFormData(null);
	};

	const handleAddNewCategory = async () => {
		let createInput: ICategoryInput = {
			Name: addCategoryFormData?.Name!
		};
		setLoading(true);
		try {
			const res = await addCategoryMutation.mutateAsync(createInput);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Category added successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};
	const handleEditCategory = async () => {
		let CreateInput: ICategoryInput = {
			Name: editCategoryFormData?.Name!,
			Id: currentCategory?.CategoryId!
		};
		setLoading(true);
		try {
			const res = await editCategoryMutation.mutateAsync(CreateInput);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Category updated successfully',
				type: 'success'
			});

			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};
	const handleDeleteCategory = async () => {
		let createInput: ICategoryInput = {
			Id: currentCategory?.CategoryId!,
			Name: currentCategory?.CategoryName!
		};
		setLoading(true);
		try {
			const res = await deleteCategoryMutation.mutateAsync(createInput);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Category deleted successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleAddTest = async () => {
		const createInput: ITestInput = {
			Name: addTestFormData?.Name!,
			Description: addTestFormData?.Description!,
			CategoryId: addTestFormData?.CategoryId!,
			CompetencyId: addTestFormData?.CompetencyId!,
			Indicator: addTestFormData?.Indicator!
		};
		setLoading(true);
		try {
			const res = await addTestMutation.mutateAsync(createInput);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Test added successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};
	const handleDeleteTest = async () => {
		setLoading(true);
		try {
			const res = await deleteTestMutation.mutateAsync(selectedTest?.Id!);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Test deleted successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
	};

	return (
		<>
			<Stack spacing={4} m={5} useFlexGap>
				{/* Headers */}
				<Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
					<BackOfficeHeader title="Test Bank" />
					{!!currentPagePermissions?.HasModifyPermission && (
						<MuiButton variant="contained" size="large" sx={{ borderRadius: 0, backgroundColor: '#0078B4', '&:hover': { color: '#0078B4', backgroundColor: 'inherit' } }} onClick={handelOpenAddTest}>
							Create Test
						</MuiButton>
					)}
				</Stack>

				{/* Categories */}
				<Stack border={'1px solid'} borderColor={'divider'} borderRadius={1}>
					<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} p={2} bgcolor={'grey.200'}>
						<Typography>Tests</Typography>

						{!!currentPagePermissions?.HasModifyPermission && (
							<MuiOutlineButton startIcon={<AddCircle />} onClick={handelOpenAddCategory}>
								Add Category
							</MuiOutlineButton>
						)}
					</Stack>

					{/* Category list */}
					<ListCategories categories={listing!} onOpenEditCategory={handelOpenEditCategory} onOpenDeleteCategory={handleOpenDeleteCategory} openDeleteTest={handleOpenDeleteTest} feature={feature} />
				</Stack>
			</Stack>

			{/* Create Category */}
			{showCreateCategory && (
				<AddCategory open={showCreateCategory} onClose={handleClose} onChange={handleChangeFormInputAddCategory} onSubmit={handleAddNewCategory} loading={loading} addCategoryFormData={addCategoryFormData!} />
			)}

			{/* Edit Category */}
			{showEditCategory && (
				<EditCategory
					open={showEditCategory}
					onClose={handleClose}
					onChange={handleChangeFormInputEditCategory}
					onSubmit={handleEditCategory}
					loading={loading}
					editCategoryFormData={editCategoryFormData!}
					currentCategory={currentCategory!}
				/>
			)}

			{/* Delete Category */}
			{showDeleteCategory && <DeleteCategory open={showDeleteCategory} onClose={handleClose} onSubmit={handleDeleteCategory} loading={loading} currentCategory={currentCategory!} />}

			{/* Create Test */}
			{showCreateTest && (
				<AddTest
					open={showCreateTest}
					onClose={handleClose}
					onChange={handleChangeFormInputAddTest}
					onSubmit={handleAddTest}
					loading={loading}
					categories={listing!}
					competencies={competencies!}
					indicators={indicators!}
					addTestFormData={addTestFormData!}
				/>
			)}
			{/* Delete Test */}
			{showDeleteTest && <DeleteTest open={showDeleteTest} onClose={handleClose} onSubmit={handleDeleteTest} loading={loading} selectedTest={selectedTest!} />}
		</>
	);
};

export default TestBank;
