import { CircularProgress, Stack } from '@mui/material';
import DialogModal from 'components/UI/DialogModal';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import { InputTypes } from 'constants/enums';
import { IFormField } from 'models/app';
import React from 'react';

interface ReferencesFormData {
	referenceSentence: string;
}

interface Props {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	onChange: (name: string, value: string) => void;
	loading: boolean;
	addReferenceFormData: ReferencesFormData;
}

const AddReference: React.FC<Props> = ({ open, loading, addReferenceFormData, onChange, onClose, onSubmit }) => {
	let addCategoryFields: IFormField[] = [
		{
			name: 'referenceSentence',
			type: InputTypes.TEXT,
			placeholder: 'Enter Reference Sentence'
		}
	];

	return (
		<>
			<DialogModal
				open={open}
				onClose={onClose}
				maxWidth="xl"
				title="New Reference"
				children={
					<Stack sx={{ width: { lg: '30vw', sm: '50vw', xs: '70vw' }, pt: 3 }}>
						<Form fields={addCategoryFields} onChange={onChange} />
					</Stack>
				}
				actions={
					<>
						<MuiButton
							variant="outlined"
							size="medium"
							sx={{
								backgroundColor: '#F0F5FD',
								color: '#8B8BAE',
								fontWeight: 400,
								border: 'none',
								'&:hover': { border: 'none' }
							}}
							onClick={onClose}>
							Cancel
						</MuiButton>
						<MuiButton
							variant="outlined"
							size="medium"
							color="primary"
							sx={{
								fontWeight: 400,
								borderColor: '#fff',
								'&:hover': { color: '#0A94D1' }
							}}
							disabled={loading || !addReferenceFormData?.referenceSentence}
							onClick={onSubmit}>
							Add
							<CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
						</MuiButton>
					</>
				}
			/>
		</>
	);
};

export default AddReference;
