import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import MuiButton from 'components/UI/MuiButton';
import MuiOutlineButton from 'components/UI/MuiOutlineButton';
import { useAlert } from 'contexts/alertContext';
import { AddReplay, editStream } from 'framework/stream';
import { IEditStreamInput, IReplyInput } from 'models/stream';
import { UserActivities } from 'models/user';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import AddReply from './Partials/AddReply/AddReply';
import { getCompetencyList } from 'framework/competency';
import { ResponseResult } from 'models/api';
import { Competency } from 'models/Competency';
import { getIndicators } from 'framework/indicator';
import EditInteraction from './Partials/EditInteraction/EditInteraction';

interface Props {
  userActivities: UserActivities[];
}


const ProgressActivity: React.FC<Props> = ({ userActivities }) => {
  const [openAddReply, setOpenAddReply] = useState<boolean>(false);
  const [openEditInteraction, setOpenEditInteraction] = useState<boolean>(false);
  const [selectedInteraction, setSelectedInteraction] = useState<UserActivities | null>(null);
  const [competencies, setCompetencies] = useState<Competency[] | null>(null);
  const [selectedCompetency, setSelectedCompetency] = useState<Competency | null>(null);
  const [indicators, setIndicators] = useState<string[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { setAlert } = useAlert();
  const queryClient = useQueryClient();

  const addReplayMutation = useMutation({
    mutationFn: (data: IReplyInput) => {
      return AddReplay(data);
    }
  });

  const editInteractionMutation = useMutation({
    mutationFn: (data: IEditStreamInput) => {
      return editStream(data);
    },     
  });

  const fetchCompetencies = useCallback(async () => {

    try {
      setLoading(true);
      const response: ResponseResult = await queryClient.fetchQuery(['competencies', { query: '' }], getCompetencyList);
      setCompetencies(response.Data);
      setLoading(false);
    } catch (err: Error | any) {
      setAlert({
        open: true,
        message: err?.response?.data?.Message || err.message || 'Something went wrong',
        type: 'error'
      });
      setLoading(false);
    }

    // eslint-disable-next-line
  }, []);

  const fetchIndicator = useCallback(async () => {
    if (!selectedCompetency?.Id) return setIndicators(null);
    let params = '?competencyId=' + selectedCompetency?.Id!;
    try {
      const response: ResponseResult = await queryClient.fetchQuery(['indicators', { query: params }], getIndicators);
      setIndicators(response.Data);
    } catch (err: Error | any) {
      setAlert({
        open: true,
        message: err?.response?.data?.Message || err.message || 'Something went wrong',
        type: 'error'
      })
    }

    // eslint-disable-next-line
  }, [selectedCompetency?.Id]);

  
  useEffect(() => {
    fetchCompetencies();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchIndicator();
    // eslint-disable-next-line
  }, [selectedCompetency?.Id]);


  const handleAddAddReply = async (data: IReplyInput) => {
    try {
      let res = await addReplayMutation.mutateAsync(data);
      if (res.Success === 0) throw new Error(res.Message);
      setAlert({
        open: true,
        message: 'Reply added Successfully',
        type: 'success'
      });
    } catch (err: any) {
      setAlert({
        open: true,
        message: err?.response?.data?.Message || err.message || 'Something went wrong',
        type: 'error'
      });
    }
  };

  const handleEditInteraction = async (data: IEditStreamInput) => {
    try {
      let res = await editInteractionMutation.mutateAsync(data);
      if (res.Success === 0) throw new Error(res.Message);
      setAlert({
        open: true,
        message: 'Interaction reviewed Successfully',
        type: 'success'
      });
    } catch (err: any) {
      setAlert({
        open: true,
        message: err?.response?.data?.Message || err.message || 'Something went wrong',
        type: 'error'
      });
    }
  };
  
  if (loading) return <Skeleton animation={'wave'} variant="rectangular" width={'100%'} height={600} />;

  const handleClose = () => {
    setOpenEditInteraction(false);
     setOpenAddReply(false);
    setSelectedInteraction(null);
   }

  const handleSelectCompetency = (competency: Competency | null) => setSelectedCompetency(competency);

  const handleOpenAddReply = (activity: UserActivities) => {
    setSelectedInteraction(activity);
    setOpenAddReply(true);
  }
  const handleOpenEditInteraction = (activity: UserActivities) => {
    setSelectedInteraction(activity);
    setOpenEditInteraction(true);
  }

  return (
    <>
      {userActivities && userActivities.map((activity, index) => (
        <Stack sx={{
          bgcolor: "#F5F6F7",
          p: 2,
          borderRadius: 1,
          my: 2,
          maxHeight: 200,
          overflow: 'auto',
        }} key={index}>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography variant='h6' fontWeight={600} color={'#253746'}>
              #{activity.ShowCaseId}
            </Typography>
            <Typography variant='body2' fontWeight={400} color={'#ACB4BA'}>
              {new Date(activity.CreatedOn).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}
            </Typography>
          </Stack>
          <Grid container alignItems={'center'} justifyContent={'start'} columnGap={2}>
            {/* badge for activity status name not button */}
            <Typography variant='body2' fontWeight={400} sx={{
              bgcolor: '#E8F0F8',
              color: '#253746',
              borderRadius: 1,
              p: 1,
              fontSize: '0.75rem'
            }}>
              Status:  {activity.InteractionStatusName}
            </Typography>


          </Grid>
           <Typography variant="body2" fontWeight={400}
            sx={{
              height: '100%',
              width: { xs: 200, md: "50vh", },
              overflow: 'hidden',
              boxOrient: 'vertical',
              display: activity.EnteredSentence ?'-webkit-box' : 'none',
              textOverflow: 'ellipsis',
              fontSize: 12,
              lineClamp: 1,
              "*": {
                fontSize: 12,
              }
            }}
            component="div" dangerouslySetInnerHTML={{ __html: activity.EnteredSentence.replace(/<[^>]*>?/gm, '') }} />
           <Grid container alignItems={'center'} justifyContent={'end'} columnGap={2}
            sx={{
              display: activity.ShowReviewButton ? 'flex' : 'none'
            }}
          >
            <MuiOutlineButton
              onClick={() => handleOpenEditInteraction(activity)}
              sx={{
                borderRadius: 1,
                color: 'text.secondary',
                backgroundColor: 'white',
                borderColor: '#DADADA',
                p: 2,
              }}
            >Review</MuiOutlineButton>
          </Grid>
          <Grid container alignItems={'center'} justifyContent={'space-between'} columnGap={2} sx={{
            display: activity.ShowReplyButton || activity.ShowCompetency ? 'flex' : 'none'          
         }}>
            <MuiOutlineButton
              onClick={() => handleOpenAddReply(activity)}
              sx={{
                borderRadius: 1,
                color: 'text.secondary',
                backgroundColor: 'white',
                borderColor: '#DADADA',
                p: 2,
                display: activity.ShowReplyButton ? 'flex' : 'none'
              }}
            >Reply</MuiOutlineButton>
            <Stack direction={'row'} spacing={1} sx={{
              alignItems: 'center',
              display: activity.ShowCompetency ? 'flex' : 'none'
            }}>
              <MuiButton variant='text' size='small' sx={{
                color: 'white', fontWeight: 400,
                backgroundColor: "#6FBC00",
                borderRadius: 1
              }}>{activity.CompetencyName} </MuiButton>
              <Box sx={{
                width: 10, height: 10, borderRadius: '50%',
                backgroundColor: '#6FBC00'
              }} />
              <Typography variant='body2' sx={{ fontWeight: 300 }} > {activity.Indicator}</Typography>

            </Stack>
          </Grid>
        </Stack>
      ))}


      {openAddReply && <AddReply
        open={openAddReply}
        onClose={handleClose}
        activity={selectedInteraction!}
        onSubmit={handleAddAddReply}
      />
      }
      {openEditInteraction && <EditInteraction
        open={openEditInteraction}
        onClose={handleClose}
        onSubmit={handleEditInteraction}
        activity={selectedInteraction!}
        onSelectCompetency={handleSelectCompetency}
        competencies={competencies}
        indicators={indicators}
      />
      }
    </>
  );
};

export default ProgressActivity;