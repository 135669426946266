import { Box, CircularProgress, Stack } from '@mui/material';
import DialogModal from 'components/UI/DialogModal';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import { InputTypes } from 'constants/enums';
import { IFormField } from 'models/app';
import { IAddUserProfileFormBuilder } from 'models/settings';

import React from 'react';

interface Props {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	onChange: (name: string, value: string) => void;
	loading: boolean;
	NewFormField: IAddUserProfileFormBuilder;
}

const AddProfileBuilder: React.FC<Props> = ({ open, loading, NewFormField, onChange, onClose, onSubmit }) => {
	let NameField: IFormField[] = [{ name: 'name', type: InputTypes.TEXT, label: 'Name', isHorizontal: false }];

	let LabelField: IFormField[] = [{ name: 'fieldLabel', type: InputTypes.TEXT, label: 'Label', isHorizontal: false }];

	let IsRequiredField: IFormField[] = [{ name: 'isRequired', type: InputTypes.SWITCH, label: 'Required Field', isHorizontal: false }];

	return (
		<DialogModal
			open={open}
			onClose={onClose}
			maxWidth="xl"
			title="Form Builder"
			children={
				<Box sx={{ backgroundColor: 'rgba(235, 237, 238, 1)', p: 4, width: '40vw' }}>
					<Stack direction={'column'} spacing={2}>
						<Form fields={NameField} onChange={onChange} />
						<Form fields={LabelField} onChange={onChange} />
						<Form fields={IsRequiredField} onChange={onChange} />
					</Stack>
				</Box>
			}
			actions={
				<>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#F0F5FD',
							color: '#8B8BAE',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onClose}>
						Cancel
					</MuiButton>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#0A94D1',
							color: '#fff',
							fontWeight: 400,
							border: 'none',
							'&:hover': { color: '#0A94D1', border: 'none' }
						}}
						disabled={loading}
						onClick={onSubmit}>
						Save
						<CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
					</MuiButton>
				</>
			}
		/>
	);
};

export default AddProfileBuilder;
