import { Grid, Stack, Typography } from '@mui/material';
import MuiButton from 'components/UI/MuiButton';
import { Feature, ResponseResult } from 'models/api';
import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { UpdateGroup, addGroup, deleteAdminGroup, getAdminGroubs } from 'framework/users';
import { GroupForm } from 'models/settings';
import ActionLoader from 'components/UI/ActionLoader';
import { useAlert } from 'contexts/alertContext';
import CreateGroup from './partials/CreateGroup';
import ListGroups from './partials/ListAccountGroups';
import { Permission } from 'models/permission';

interface Props {
	feature: Feature;
	onHideHeader?: (status: boolean) => void;
}

const AccountGroups: React.FC<Props> = ({ feature, onHideHeader }) => {
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	const [showCreateGroup, setShowCreateGroup] = useState<boolean>(false);
	const [createGroupFormData, setCreateGroupFormData] = useState<GroupForm | null>(null);

	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let currentPagePermissions: Permission | undefined = permissions?.find((item) => item.PermissionId === 14);

	const { data, isError, isLoading } = useQuery<ResponseResult>(['adminGroubsList', { query: '' }], getAdminGroubs);
	const [loading, setLoading] = useState<boolean>(false);

	React.useEffect(() => {
		if (onHideHeader) onHideHeader(true);
		return () => {
			if (onHideHeader) onHideHeader(false);
		};
	}, [feature, onHideHeader]);

	if (isError)
		return (
			<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
				<Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
					Something Wrong
				</Typography>
			</Grid>
		);

	if (isLoading || loading) return <ActionLoader position={'absolute'} />;

	const handleClose = () => {
		setShowCreateGroup(false);
		setCreateGroupFormData(null);
	};
	const handleChangeFormInviteUser = (name: string, value: any) => setCreateGroupFormData({ ...createGroupFormData!, [name]: value });

	const handleInviteUser = async () => {
		setLoading(true);
		const values: any = createGroupFormData?.Users?.map((item: any) => item.value)?.join(',')!;

		let CreateInput: GroupForm = {
			GroupId: null!,
			Description: createGroupFormData?.Description!,
			Name: createGroupFormData?.Name!,
			Users: values!
		};

		try {
			const res: ResponseResult = await addGroup(CreateInput);

			if (res.Success === 0) throw new Error(res.Message);

			//fetch Users Again
			queryClient.refetchQueries(['adminGroubsList', { query: '' }]);
			setLoading(false);
			setAlert({
				open: true,
				message: 'Group Created Successfully',
				type: 'success'
			});
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
		handleClose();
	};

	const handleEditGroup = async (data: GroupForm) => {
		setLoading(true);

		try {
			const res: ResponseResult = await UpdateGroup(data);

			if (res.Success === 0) throw new Error(res.Message);

			setAlert({
				open: true,
				message: 'User Updated',
				type: 'success'
			});
			queryClient.refetchQueries(['adminGroubsList', { query: '' }]);
			setLoading(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	const handleOnDeleteGroup = async (id: number) => {
		setLoading(true);

		try {
			const res: ResponseResult = await queryClient.fetchQuery(['DeleteAdminGroup', { query: `?groupId=${id}` }], deleteAdminGroup);

			if (res.Success === 0) throw new Error(res.Message);

			//setPermissions(res.Data);
			setAlert({
				open: true,
				message: 'Group Deleted Successfully',
				type: 'success'
			});
			queryClient.refetchQueries(['adminGroubsList', { query: '' }]);
			setLoading(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	return (
		<Stack spacing={4} useFlexGap>
			{/* Headers */}
			<Stack direction={'row'} justifyContent={'end'} alignItems={'center'}>
				{!!currentPagePermissions?.HasModifyPermission && (
					<MuiButton variant="outlined" color="primary" size="large" sx={{ borderRadius: 0, borderColor: 'white', '&:hover': {} }} onClick={() => setShowCreateGroup(true)}>
						Create Group
					</MuiButton>
				)}
			</Stack>

			{/* Groups list */}
			<Stack border={'1px solid'} borderColor={'divider'} borderRadius={1}>
				<ListGroups groups={data?.Data} feature={feature} onEditGroup={handleEditGroup} handleOnDeleteGroup={handleOnDeleteGroup} />
			</Stack>
			{/* Create Group */}
			{showCreateGroup && <CreateGroup open={showCreateGroup} onClose={handleClose} onChange={handleChangeFormInviteUser} onSubmit={handleInviteUser} loading={isLoading} groupForm={createGroupFormData!} />}
		</Stack>
	);
};

export default AccountGroups;
