import { Controller } from 'react-hook-form';
import { Box, IconButton, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { IFormField } from 'models/app';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';

interface Props extends IFormField {
	errors: any;
	control: any;
	defaultValue?: any;
}

interface IState {
	showPassword: boolean;
}

const INITIAL_STATE: IState = { showPassword: false };

const RHTextField: React.FC<Props> = ({ label, name, placeholder, disabled, autoFocus, control, errors, defaultValue }) => {
	const [state, setState] = useState(INITIAL_STATE);
	const { showPassword } = state;
	const handleClickShowPassword = () =>
		setState((prevState) => ({
			...prevState,
			showPassword: !prevState.showPassword
		}));

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	return (
		<>
			<Box sx={{ position: 'relative' }}>
				<Controller
					control={control}
					name={name}
					render={({ field }) => (
						<>
							<Typography variant="body2" fontWeight={600} textAlign={'start'} minWidth={'16ch'}>
								{label}
							</Typography>
							<OutlinedInput
								fullWidth
								size="small"
								{...field}
								disabled={disabled}
								autoFocus={autoFocus}
								placeholder={placeholder}
								error={errors[name]}
								type={showPassword ? 'text' : 'password'}
								defaultValue={defaultValue}
								sx={{
									'& .MuiOutlinedInput-notchedOutline': {
										borderRadius: 1
									}
								}}
								endAdornment={
									<InputAdornment position="end">
										<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end" sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</>
					)}
				/>
				{errors[name] && (
					<Typography variant="body2" fontSize={12} color="error">
						{errors[name].message}
					</Typography>
				)}
			</Box>
		</>
	);
};

export default RHTextField;
