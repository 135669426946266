import Box from "@mui/material/Box";

interface Props {
  content: string;
}

const HtmlContent: React.FC<Props> = ({ content }) => {
  return (
    <Box
      sx={{        
        img: { maxWidth: "100%", height: "auto" },
      }}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default HtmlContent;
