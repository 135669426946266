import { IconButton, Stack, TableCell, Tooltip, Typography } from '@mui/material';
import List from 'components/UI/List';
import { Feature } from 'models/api';
import { ReportUser } from 'models/user';
import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { UserCellsReports } from 'framework/reports';

interface Props {
	users: ReportUser[];
	feature: Feature;
	onSelect?: (user: ReportUser) => void;
}

const ListUsers: React.FC<Props> = ({ users, feature, onSelect }) => {
	const getBodyCells = (row: ReportUser) => {
		let cells = Object.keys(row).map((key: string, i: number) => {
			if (key === 'FirstName')
				return {
					id: 'FirstName',
					element: (
						<TableCell align={'left'} key={i} sx={{
							'&:hover': {
								cursor: 'pointer'
							}						
						}}
						onClick={() => onSelect!(row)}
						>
							<Stack direction={'row'} spacing={1}>
								<PersonIcon />
								<Typography variant="body2" fontWeight={500}>
									{row?.FirstName + ' ' + row.LastName}
								</Typography>
							</Stack>
						</TableCell>
					)
				};
			if (key === 'OrganizationName')
				return {
					id: 'OrganizationName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.OrganizationName}</Typography>
						</TableCell>
					)
				};
		 
		

			if (key === 'EmailAddress')
				return {
					id: 'EmailAddress',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.EmailAddress}</Typography>
						</TableCell>
					)
				};

			if (key === 'UpdatedOn')
				return {
					id: 'UpdatedOn',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">
								{new Date(row?.UpdatedOn).toLocaleString('en-US', {
									year: '2-digit',
									month: '2-digit',
									day: '2-digit',
									hour: '2-digit',
									minute: '2-digit',
									hour12: true
								})}
							</Typography>
						</TableCell>
					)
				};
			if (key === 'Id')
				return {
					id: 'Id',
					element: (
						<TableCell align={'left'} key={i}>
							<Tooltip title="View Progress">
								<IconButton onClick={() => onSelect!(row)}>
									<PersonIcon />
								</IconButton>
							</Tooltip>
						</TableCell>
					)
				};
			else return <></>;
		});
		return cells;
	};
	return <List headCells={UserCellsReports} data={users!} getBodyCells={getBodyCells} feature={feature} canSelect={false} canEdit={false} />;
};

export default ListUsers;
