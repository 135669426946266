import { useCallback, useState } from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';
import Modal from '../../../components/UI/DialogModal';
import { IFormField } from 'models/app';
import { InputTypes } from 'constants/enums';
import MuiButton from 'components/UI/MuiButton';
import Form from 'components/UI/Form';
import { AdminProfile, AdminProfileInput } from 'models/user';
import { useAlert } from 'contexts/alertContext';
import { useMutation, useQueryClient } from 'react-query';
import ActionLoader from 'components/UI/ActionLoader';
import { getAdminProfile, updateAdminProfile } from 'framework/profile';
import { ResponseResult } from 'models/api';
import React from 'react';

interface Props {
	open: boolean;
	onClose: () => void;
}

const UpdateUserInformation: React.FC<Props> = ({ open, onClose }) => {
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState<AdminProfile | null>(null);
	const [userForm, setUserForm] = useState<AdminProfileInput>({} as AdminProfileInput);
	const [saveLoading, setSaveLoading] = React.useState(false);

	const updateProfileMutation = useMutation({
		mutationFn: (data: AdminProfileInput) => {
			return updateAdminProfile(data);
		}
	});

	const fetchData = useCallback(async () => {
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['profile'], getAdminProfile);
			setUser(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		fetchData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) return <ActionLoader position={'absolute'} />;
	if (!user) return null;

	const handleChange = (name: string, value: any) => setUserForm((prevState: AdminProfileInput) => ({ ...prevState, [name]: value }));


	let userFields: IFormField[] = [
		{
			name: 'FirstName',
			type: InputTypes.TEXT,
			placeholder: 'Enter First Name',
			label: 'First Name',
			defaultValue: user.FirstName!
		},
		{
			name: 'LastName',
			type: InputTypes.TEXT,
			placeholder: 'Enter Last Name',
			label: 'Last Name',
			defaultValue: user?.LastName
		},
		{
			name: 'NotificationEmail',
			type: InputTypes.TEXT,
			placeholder: 'Enter Notification Email Address',
			label: 'Notification Email Address',
			defaultValue: user?.NotificationEmailAddress
		},
		{
			name: 'PhoneNumber',
			type: InputTypes.TEXT,
			placeholder: 'Enter Phone Number',
			label: 'Phone Number',
			defaultValue: user?.PhoneNumber
		}
	];


	const handleSave = async () => {
		let createInput: AdminProfileInput = {
			FirstName: userForm.FirstName || user.FirstName,
			LastName: userForm.LastName || user.LastName,
			NotificationEmail: userForm.NotificationEmail || user.NotificationEmailAddress,
			PhoneNumber: userForm.PhoneNumber || user.PhoneNumber
		};
		try {
			setSaveLoading(true);
			const res = await updateProfileMutation.mutateAsync(createInput!);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully updated',
				type: 'success'
			});
			setSaveLoading(false);
			onClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setSaveLoading(false)
		}
	};

	return (
		<Modal
			open={open}
			onClose={onClose}
			fullWidth={true}
			maxWidth={'lg'}
			title="User Profile"
			actions={
				<Stack direction={'row'} spacing={2}>
					<MuiButton onClick={onClose} sx={{ color: '#5C5F66', paddingInline: 6, paddingBlock: 1, border: '1px solid #5C5F66' }}>
						Cancel
					</MuiButton>
					<MuiButton onClick={handleSave} sx={{ backgroundColor: '#1B6EE4', color: '#fff', paddingInline: 6, paddingBlock: 1, '&:hover': { color: '#1B6EE4' } }}>
						Save
						<CircularProgress
							size={10}
							sx={{ mx: 2, display: saveLoading ? "" : "none" }}
						/>
					</MuiButton>
				</Stack>
			}>
			<Box sx={{ paddingBlock: 3, paddingInline: 1 }}>
				<Box sx={{ width: '100%' }}>
					<Form fields={userFields} onChange={handleChange} />
				</Box>
			</Box>
		</Modal>
	);
};

export default UpdateUserInformation;
