import { Box, CircularProgress, Grid, Skeleton, Stack } from '@mui/material';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import { InputTypes } from 'constants/enums';
import { useAlert } from 'contexts/alertContext';
import { getHelp, updateHelp } from 'framework/help';
import { getLanguages } from 'framework/language';
import { BackOfficeHeader } from 'helpers/utils';
import { Language, ResponseResult } from 'models/api';
import { IFormField } from 'models/app';
import { HelpInput } from 'models/help';
import React, { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

interface Props {
	feature: any;
	onHideHeader?: (hide: boolean) => void;
}

const Documentation: React.FC<Props> = ({ feature, onHideHeader }) => {

	const [loading, setLoading] = React.useState(false);
	const [saveLoading, setSaveLoading] = React.useState(false);
	const [loading1, setLoading1] = React.useState(false);
	const [languages, setLanguages] = React.useState<Language[] | null>(null);
	const [selectedLanguage, setSelectedLanguage] = React.useState<Language | null>(null);




	const [currentHelp, setCurrentHelp] = React.useState<string | null>(null);
	const [help, setHelp] = React.useState<string | null>(null);

	const queryClient = useQueryClient();
	const { setAlert } = useAlert();


	const updateHelpMutation = useMutation({
		mutationFn: (data: HelpInput) => {
			return updateHelp(data);
		},
		onSuccess: () => {
			fetchHelp(selectedLanguage!);
		}
	});

	const fetchLanguages = useCallback(async () => {
		setLoading1(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['languages', { query: '' }], getLanguages);
			setLanguages(response.Data);
			setSelectedLanguage(response.Data[0]);
			setLoading1(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading1(false);
		}

		// eslint-disable-next-line
	}, []);

	const fetchHelp = useCallback(async (selectedLanguage: Language) => {
		try {
			setLoading(true);
			const response: any = await queryClient.fetchQuery(['help', { query: `?languageId=${selectedLanguage.Id}` }], getHelp);
			setCurrentHelp(response?.Data?.Description!);
			setLoading(false);
		} catch (err: any) {
			setLoading(false);

		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedLanguage]);

	React.useEffect(() => {
		if (onHideHeader) onHideHeader(true);
		return () => {
			if (onHideHeader) onHideHeader(false);
		};
	}, [feature, onHideHeader]);


	React.useEffect(() => {
		fetchLanguages();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	React.useEffect(() => {
		if (selectedLanguage) fetchHelp(selectedLanguage);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedLanguage]);


	if (loading1) return <Skeleton variant="rectangular" width="100%" height={118} animation="wave" />;
	if (!languages || !currentHelp) return null;

	const handleChange = (name: string, value: any) => {
		setHelp(value);
	};

	let languageOptions = languages
		? languages.map((language: Language) => ({
			value: `${language.Id}`,
			label: language.Name
		}))
		: [];

	let basicLanguageFields: IFormField[] = [
		{
			name: 'LanguageId',
			type: InputTypes.SELECT,
			options: languageOptions ? languageOptions : [],
			placeholder: 'Select Language',
			isHorizontal: true,
			defaultValue: languageOptions[0],
		}
	];
	let basicDescriptionFields: IFormField[] = [{
		name: 'Description',
		type: InputTypes.EDITOR,
		defaultValue: currentHelp,
		editorHeight: 800,
	}];

	const handleLanguageChange = (name: string, value: any) => {
		fetchHelp(languages!.find((language: Language) => `${language!.Id!}` === value!)!);
		setSelectedLanguage(languages!.find((language: Language) => `${language!.Id!}` === value!)!);
	};

	const user = JSON.parse(localStorage.getItem('user')!);


	const handleSave = async () => {
		let createInput: HelpInput = {
			LanguageId: selectedLanguage!.Id,
			Description: help!,
			UserGuideId: user.UserId
		};
		try {
			setSaveLoading(true);
			const res = await updateHelpMutation.mutateAsync(createInput!);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully updated',
				type: 'success'
			});
			setSaveLoading(false)
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setSaveLoading(false)
		}
	};


	return (
		<Stack spacing={2} m={5}>
			<Grid container spacing={2} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
				<BackOfficeHeader title="Documentation" />

				<Box maxWidth={400} width={1}>
					<Form fields={basicLanguageFields} onChange={handleLanguageChange} />

				</Box>
			</Grid>

			{currentHelp && <Stack sx={{
				width: '100%',
				overflowY: 'auto',
			}} spacing={2}>

				<Form fields={basicDescriptionFields} onChange={handleChange} />
				<Grid container justifyContent={'center'}>
					<MuiButton variant="contained" color="primary" size="large"
						onClick={handleSave}
					>Save
						<CircularProgress
							size={10}
							sx={{ mx: 2, display: saveLoading ? "" : "none" }}
						/>
					</MuiButton>
				</Grid>
			</Stack>}
			{loading && <Skeleton variant="rectangular" width="100%" height={118} animation="wave" />}
		</Stack>
	);
};

export default Documentation;


