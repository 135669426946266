import { Box, Button, Grid, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import LabelsAndDescriptions from './partials/LabelsAndDescriptions';
import { ResponseResult } from 'models/api';
import { useQueryClient } from 'react-query';
import { getFeelings } from 'framework/responseForm';
import { useAlert } from 'contexts/alertContext';
import ActionLoader from 'components/UI/ActionLoader';
import Feeling from './partials/Feeling';
type Props = {};

interface IFeeling {
	Id: number;
	Name: string;
}

const StanderedField: React.FC<Props> = () => {
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	const [index, setIndex] = useState<number>(1);
	const [actualIndex, setActualIndex] = useState<number>(1);
	const [feelings, setFeelings] = useState<IFeeling[] | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedFeeling, setSelectedFeeling] = useState<IFeeling | null>(null);

	const fetchFeelings = useCallback(async () => {
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['feelings'], getFeelings);
			setFeelings(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchFeelings();
		// eslint-disable-next-line
	}, []);

	if (loading) return <ActionLoader position={'absolute'} />;

	const tabs = [<LabelsAndDescriptions />, <Feeling selectedFeeling={selectedFeeling} />];
	const TABS = [{ id: 1, title: 'Labels & Descriptions' }, ...(feelings?.map((feeling, index) => ({ id: index + 2, title: feeling.Name })) || [])];

	const handleTabClick = (tabIndex: number) => {
		setIndex(tabIndex);
		setSelectedFeeling(feelings![tabIndex - 2]);
		setActualIndex(tabIndex > 2 ? 2 : tabIndex);
	};

	return (
		<Stack p={4} spacing={3} useFlexGap>
			<Grid container spacing={2}>
				<Grid item xs={12} md={5}>
					<Stack spacing={4} useFlexGap my={1} direction={'row'} justifyContent={{ md: 'flex-start', xs: 'center' }} flexWrap={'wrap'}>
						{TABS.map((tab) => (
							<Button
								size="large"
								color="inherit"
								key={tab.id}
								onClick={() => handleTabClick(tab.id)}
								sx={{
									justifyContent: 'flex-start',
									boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
									p: 4,
									border: tab.id === index ? '2px solid rgba(132, 204, 30, 1)' : 'none',
									borderRadius: 0,
									':hover': { bgcolor: tab.id === index ? 'grey.200' : 'transparent' },
									fontWeight: tab.id === index ? 600 : 400
								}}
								disableRipple>
								{tab.title}
							</Button>
						))}
					</Stack>
				</Grid>

				<Grid item xs={12} md={7}>
					<Box sx={{ overflowY: 'auto' }}>
						<Grid container height={1}>
							<Grid item xs={12} md={10}>
								{tabs[actualIndex - 1]}
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</Grid>
		</Stack>
	);
};

export default StanderedField;
