import React from 'react';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material';

interface SectionProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

const Section: React.FC<SectionProps> = ({ children, sx }) => {
  return (
    <Box py={3} sx={sx}>
      {children}
    </Box>
  );
};

export default Section;
