import { Box, IconButton, Stack } from '@mui/material';
import Search from 'components/Search/Search';
import { Feature } from 'models/api';
import { useState } from 'react';
import HeaderDrawer from './partials/Drawer';
import { Menu } from '@mui/icons-material';
import { IMediaQuery } from 'types/mediaQuery';
import useResponsive from 'hooks/useResponsive';
import logo from 'assets/images/Logo.jpeg';
import FilterByDate from 'components/SearchFilters/partials/FilterByDate';
import { Resources } from 'constants/enums';

type Props = {
	feature: Feature;
};

const Header: React.FC<Props> = ({ feature }) => {
	const [openDrawer, setOpenDrawer] = useState<boolean>(false);

	const params: IMediaQuery = { query: 'up', key: 'md' };
	const mdUp = useResponsive(params);
	let pathname = window.location.pathname;

	const excludedResourcesFilter = [
		Resources.EMAILS,
		Resources.USER_MANAGEMENT,
		Resources.TEST_BANK,
		Resources.USER_PROFILE,
		Resources.USERS_PERMISSIONS,
		Resources.KEY_MANAGEMENT,
		Resources.TEST_BANK,
		Resources.USER_MANAGEMENT,
		Resources.RESPONSE_FORM,
		Resources.COMPETENCIES,
		Resources.DOCUMENTATION,
	];
 	let testConfigurationPage = pathname.split('/')[1] === Resources.TEST_BANK && pathname.split('/').length > 2;
	let editShowcasePage = pathname.split('/')[1] === Resources.SHOW_CASES && pathname.split('/').length > 2;
	let singleSupportPage = pathname.split('/')[1] === Resources.SUPPORT && pathname.split('/').length > 2;

	const isResourceExcludedFilter = (slug: any) => excludedResourcesFilter.includes(slug.toLowerCase());
 
	return (
		<>
			{!testConfigurationPage && <Stack
				component="header"
				direction={'row'}
				sx={{
					backgroundColor: '#1B6EE4',
					width: '100%',
					height: 80,
					p: 2,
					flexShrink: 0,
					position: 'sticky',
					top: 0,
					zIndex: 1000
				}}
				spacing={4}>
				<IconButton sx={{ display: { xs: 'block', lg: 'none' } }} size="small" onClick={() => setOpenDrawer(true)}>
					<Menu />
				</IconButton>
				<HeaderDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} feature={feature} />
				{mdUp && (
					<>
						{!isResourceExcludedFilter(feature.slug) &&
							!editShowcasePage &&
							!singleSupportPage &&
							<FilterByDate feature={feature}
							/>}
						<Search feature={feature} isEditPage={editShowcasePage || singleSupportPage}
						/>
					</>
				)}
				{!mdUp && (
					<Stack width={1} height={1} justifyContent={'center'}>
						<Box width={200} alignSelf={'center'}>
							<Box component={'img'} src={logo} alt="scofolio" sx={{ display: 'block', maxWidth: '100%', height: 'auto' }} />
						</Box>
					</Stack>
				)}
			</Stack>}
		</>
	);
};

export default Header;
