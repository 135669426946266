import { Close } from "@mui/icons-material";
import { Drawer, IconButton, Stack, Typography } from "@mui/material";
import { getDayTime } from "helpers/utils";
import DrawerMenu from "./DrawerMenu";
import { Feature } from "models/api";
import Search from "components/Search/Search";

type Props = {
	feature: Feature;
	openDrawer: boolean;
	setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
};

function HeaderDrawer({ openDrawer, setOpenDrawer, feature }: Props) {
 
	return (
		<Drawer anchor={"right"} open={openDrawer} onClose={() => setOpenDrawer(false)}>
			<Stack m={2} borderRadius={4}>
				<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} spacing={2} mb={2}>
					<Typography variant="body2" fontWeight={600} color={"text.primary"}>{` Good ${getDayTime()}`}</Typography>
					<IconButton size="small" onClick={() => setOpenDrawer(false)}><Close color="inherit" /></IconButton>
				</Stack>
				<Search feature={feature} />
				<DrawerMenu setOpenDrawer={setOpenDrawer} />
			</Stack>
		</Drawer>
	);
}

export default HeaderDrawer;