import { Stack, TableCell, Typography } from '@mui/material';
import List from 'components/UI/List';
import { Feature } from 'models/api';
import React from 'react';
import { CompetencyCellsReports, IReportCompetency } from 'framework/reports';

interface Props {
	competencies: IReportCompetency[];
	feature: Feature;
}

const ListCompetencies: React.FC<Props> = ({ competencies, feature }) => {
	const getBodyCells = (row: IReportCompetency) => {
		let cells = Object.keys(row).map((key: string, i: number) => {
			if (key === 'CompetencyName')
				return {
					id: 'CompetencyName',
					element: (
						<TableCell align={'left'} key={i}>
							<Stack direction={'row'} spacing={1}>
								<Typography variant="body2" fontWeight={500}>
									{row?.CompetencyName}
								</Typography>
							</Stack>
						</TableCell>
					)
				};
			// if (key === 'CompetencyDescription')
			// 	return {
			// 		id: 'CompetencyDescription',
			// 		element: (
			// 			<TableCell align={'left'} key={i}>
			// 				<Typography variant="body2">{row?.CompetencyDescription}</Typography>
			// 			</TableCell>
			// 		)
			// 	};
			if (key === 'NumIndicators')
				return {
					id: 'NumIndicators',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.NumIndicators}</Typography>
						</TableCell>
					)
				};
			if (key === 'PillarName')
				return {
					id: 'PillarName',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.PillarName}</Typography>
						</TableCell>
					)
				};

			// if (key === 'PillarDescription')
			// 	return {
			// 		id: 'PillarDescription',
			// 		element: (
			// 			<TableCell align={'left'} key={i}>
			// 				<Typography variant="body2">{row?.PillarDescription}</Typography>
			// 			</TableCell>
			// 		)
			// 	};

			if (key === 'HighsetScore')
				return {
					id: 'HighsetScore',
					element: (
						<TableCell align={'left'} key={i}>
							<Typography variant="body2">{row?.HighsetScore}</Typography>
						</TableCell>
					)
				};
			else return <></>;
		});
		return cells;
	};

	return <List headCells={CompetencyCellsReports} data={competencies!} getBodyCells={getBodyCells} feature={feature} canSelect={false} canEdit={false} />;
};

export default ListCompetencies;
