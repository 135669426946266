import {
	GET_COMPETENCY_INDICATOR,
	GET_NUMBER_OF_INTERACTION_SET_AS_NONE,
	GET_REPORTS_ASSESMENTS_WITHOUT_TESTS,
	GET_REPORTS_ASSESMENTS_WITH_TESTS,
	GET_REPORTS_COMPETENCIES,
	GET_REPORTS_FIRST_LEVEL_INTERACTION,
	GET_REPORTS_ORGANIZATIONS,
	GET_REPORTS_SECOND_LEVEL_INTERACTION,
	GET_REPORTS_TESTS,
	GET_REPORTS_TOP_PERFORMANCE,
	GET_REPORTS_USERS,
	GET_REPORTS_USER_ACTIVITIES,
	GET_REPORTS_USER_TESTS
} from 'constants/api';
import axios from '../helpers/axiosInstance';
import { HeadCell } from 'models/app';

export async function getReportsCompetencyList({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_REPORTS_COMPETENCIES + query);
	return response.data;
}

export async function getReportsTests({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_REPORTS_TESTS + query);
	return response.data;
}
export async function getReportsUsers({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_REPORTS_USERS + query);
	return response.data;
}
export async function getReportsUserTests({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_REPORTS_USER_TESTS + query);
	return response.data;
}
export async function getReportsUserActivities({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_REPORTS_USER_ACTIVITIES + query);
	return response.data;
}

export async function getReportsOrganizations({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_REPORTS_ORGANIZATIONS + query);
	return response.data;
}

export async function getReportsFirstLevelInteraction({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_REPORTS_FIRST_LEVEL_INTERACTION + query);
	return response.data;
}

export async function getReportsSecondLevelInteraction({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_REPORTS_SECOND_LEVEL_INTERACTION + query);
	return response.data;
}
export async function getReportsAssesmentsWithTests({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_REPORTS_ASSESMENTS_WITH_TESTS + query);
	return response.data;
}
export async function GetNumberOfInteractionsSetAsNone({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_NUMBER_OF_INTERACTION_SET_AS_NONE + query);
	return response.data;
}
export async function getReportsAssesmentsWithoutTests({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_REPORTS_ASSESMENTS_WITHOUT_TESTS + query);
	return response.data;
}
export async function getReportsTopPerformance({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_REPORTS_TOP_PERFORMANCE + query);
	return response.data;
}

export async function getReportCompetencyIndicator({ queryKey }: any) {
	const { query } = queryKey[1];
	const response = await axios.get(GET_COMPETENCY_INDICATOR + query);
	return response.data;
}

export const UserCellsReports: HeadCell[] = [
	{
		id: 'FirstName',
		numeric: false,
		disablePadding: false,
		label: 'User info'
	},
	{
		id: 'EmailAddress',
		numeric: false,
		disablePadding: false,
		label: 'Email'
	},
 

	 
	{
		id: 'UpdatedOn',
		numeric: false,
		disablePadding: false,
		label: 'Updated On',
		isDate: true,
	},
	{
		id: 'Id',
		numeric: false,
		disablePadding: false,
		label: '',
 	}
];

export const OrganizationsCellsReports: HeadCell[] = [
	{
		id: 'Name',
		numeric: false,
		disablePadding: false,
		label: 'Name'
	},
	{
		id: 'CompetencyName',
		numeric: false,
		disablePadding: false,
		label: 'Competency Name'
	},

	{
		id: 'NumberOfUsers',
		numeric: false,
		disablePadding: false,
		label: 'Number Of Users'
	},
	{
		id: 'NumberofInteractions',
		numeric: false,
		disablePadding: false,
		label: 'Number of Interactions'
	}
];

export const TestsCellsReports: HeadCell[] = [
	{
		id: 'TestName',
		numeric: false,
		disablePadding: false,
		label: 'Test Name'
	},
	{
		id: 'NumberOfparticipants',
		numeric: false,
		disablePadding: false,
		label: 'Number Of participants'
	},
	{
		id: 'PercentageOfPassedUsers',
		numeric: false,
		disablePadding: false,
		label: 'Percentage Of PassedUsers'
	},

	{
		id: 'NumberOfRetries',
		numeric: false,
		disablePadding: false,
		label: 'Number Of Retries'
	}
];

export const CompetencyCellsReports: HeadCell[] = [
	{
		id: 'CompetencyName',
		numeric: false,
		disablePadding: false,
		label: 'Competency Name'
	},
	// {
	// 	id: 'CompetencyDescription',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Competency Description'
	// },
	{
		id: 'NumIndicators',
		numeric: false,
		disablePadding: false,
		label: 'Number Indicators'
	},

	{
		id: 'PillarName',
		numeric: false,
		disablePadding: false,
		label: 'Pillar Name'
	},
	// {
	// 	id: 'PillarDescription',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Pillar Description'
	// },
	{
		id: 'HighsetScore',
		numeric: false,
		disablePadding: false,
		label: 'Highest Score'
	}
];
export const UserTestsHeads: HeadCell[] = [
	{
		id: 'CompetencyName',
		numeric: false,
		disablePadding: false,
		label: 'Competency Name'
	},
	{
		id: 'TestName',
		numeric: false,
		disablePadding: false,
		label: 'Test Name'
	},
	{
		id: 'TestRound',
		numeric: false,
		disablePadding: false,
		label: 'Retries'
	},
	{
		id: 'Score',
		numeric: false,
		disablePadding: false,
		label: 'Score',
	},

	{
		id: 'DateTaken',
		numeric: false,
		disablePadding: false,
		label: 'Date Taken',
		isDate: true
	},	
];

export interface IReportCompetency {
	CompetencyId: number;
	CompetencyName: string;
	CompetencyDescription: string;
	NumIndicators: number;
	PillarId: number;
	PillarName: string;
	PillarDescription: string;
	HighsetScore: number;
}

export interface IReportTest {
	TestId: number;
	TestName: string;
	PercentageOfPassedUsers: number;
	NumberOfparticipants: number;
	NumberOfRetries: number;
}

export interface IReportOrganizations {
	Id: number;
	Name: string;
	UpdatedCompetencyId: number;
	CompetencyName: string;
	NumberOfUsers: number;
	NumberofInteractions: number;
}
