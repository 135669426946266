import { CircularProgress, Grid, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import DialogModal from 'components/UI/DialogModal';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import { FormMode, InputTypes } from 'constants/enums';
import { useAlert } from 'contexts/alertContext';
import { getCountries } from 'framework/organizations';
import { IRSSFormData } from 'models/ResponseForm';
import { ResponseResult } from 'models/api';
import { IFormField, IOption } from 'models/app';
import { Country } from 'models/organization';

import React, { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

interface Props {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	onChange: (name: string, value: string) => void;
	loading: boolean;
	formData: IRSSFormData;
	mode: FormMode;
}

const RefrenceSentenceSetForm: React.FC<Props> = ({ open, formData, onChange, onClose, onSubmit, mode }) => {

	const [countries, setCountries] = useState<Country[]|null>(null);
	const [loading, setLoading] = useState(false);
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	

	const fetchCountries = useCallback(async () => {
		setLoading(true);

		try {
			const response: ResponseResult = await queryClient.fetchQuery(['countries', { query: '' }], getCountries);
			setCountries(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchCountries();
		 
		// eslint-disable-next-line
	}, []);

	if (loading) return <ActionLoader position={'absolute'} />;
	if (!countries) return null;

 
	const countriesOption: IOption[] = countries?.map((country) => ({
		label: country.Name,
		value: country.Id.toString()
	}));

	let nameField: IFormField[] = [
		{
			name: 'Name',
			type: InputTypes.TEXT,
			placeholder: 'Enter New RF Set',
			defaultValue: mode === FormMode.EDIT ? formData.Name : undefined
		}
	];

	let directField: IFormField[] = [
		{
			name: 'CountryId',
			type: InputTypes.SELECT,
			placeholder: 'Select Country',
			options: countriesOption || [],
			defaultValue: mode === FormMode.EDIT ? countriesOption?.find((option) => option.value === formData.CountryId!?.toString()) : undefined
		}
	];

	return (
		<DialogModal
			open={open}
			onClose={onClose}
			maxWidth="md"
			title={`${mode === FormMode.CREATE ? 'Create' : 'Edit'} New Reference Sentence Set`}
			children={
				<Grid container sx={{ width: '100%' }} my={2} spacing={2}>
					<Grid item xs={12} sm={3}>
						<Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Name</Typography>
					</Grid>
					<Grid item xs={12} sm={9}>
						<Form fields={nameField} onChange={onChange} />
					</Grid>

					<Grid item xs={12} sm={3}>
						<Typography sx={{ fontSize: '16px', fontWeight: 500 }}>Country</Typography>
					</Grid>
					<Grid item xs={12} sm={9}>
						<Form fields={directField} onChange={onChange} />
					</Grid>
				</Grid>
			}
			actions={
				<>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#F0F5FD',
							color: '#8B8BAE',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onClose}>
						Cancel
					</MuiButton>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#0A94D1',
							color: '#fff',
							fontWeight: 400,
							border: 'none',
							'&:hover': { color: '#0A94D1', border: 'none' }
						}}
						disabled={loading || !formData?.Name || !formData?.CountryId}
						onClick={onSubmit}>
						{mode === FormMode.CREATE ? 'Create' : 'Update'}
						<CircularProgress size={10} sx={{ mx: 2, display: loading ? '' : 'none' }} />
					</MuiButton>
				</>
			}
		/>
	);
};

export default RefrenceSentenceSetForm;
