import { Alert, Box } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { Resources } from 'constants/enums';
import { Feature, User } from 'models/api';

import { useQuery } from 'react-query';
import { Route, Routes, useParams } from 'react-router-dom';
import { fetchServices } from 'framework/services';
import Stream from 'Pages/Stream';
import ShowCases from 'Pages/ShowCases';
import Insights from 'Pages/Insights';
import Header from 'components/Header';
import UserManagement from 'Pages/UserManagement';
import DataCenter from 'Pages/DataCenter';
import Backlog from 'Pages/Backlog';
import EditShowcase from 'Pages/ShowCases/partials/EditShowcase';
import React from 'react';
import TestBank from 'Pages/TestBank';
import EditTest from 'Pages/TestBank/partials/Test/EditTest';
import Competencies from 'Pages/Competencies/Competencies';
import AccessPermissions from 'Pages/Settings/AccessPermissions/AccessPermissions';
import KeyManagement from 'Pages/Settings/KeyManagement/KeyManagement';
import UserProfile from 'Pages/Settings/UserProfile/UserProfile';
import Documentation from 'Pages/Settings/Documentation';
import Emails from 'Pages/Settings/Emails/Emails';
import ResponseFormEdit from 'Pages/ResponseForm/Edit/ResponseFormEdit';
import ResponseForm from 'Pages/ResponseForm';
import Reports from 'Pages/Reports/Reports';
import Support from 'Pages/Support';
import Ticket from 'Pages/Support/Ticket';
import Error404 from 'Pages/404';
import { Permission } from 'models/permission';
import useApp from 'hooks/useApp';

interface Props { }

const Listing: React.FC<Props> = () => {
	let { slug } = useParams<{ slug: string }>();

	const { push } = useApp();

	const [hideHeader, setHideHeader] = React.useState<boolean>(false);
	const { data, isError, isLoading } = useQuery<Feature[]>('features', fetchServices);
	if (isLoading) return <ActionLoader position="fixed" />;
	if (isError) return <Alert severity="error"> Server Error</Alert>;
	let feature: Feature | undefined = data?.find((feature) => feature.slug === slug || feature.children.some((child) => child.slug === slug));
	if (feature?.children?.length! > 0) feature = feature?.children.find((child) => child.slug === slug);
	if (!feature) return <Alert severity="error"> Server Error</Alert>;

	let user: User | null = JSON.parse(localStorage.getItem('user')!) || null;
	if (!user) push('/login');
	let permissions: Permission[] = JSON.parse(localStorage.getItem('permissions')!) || [];
	let features = data;
	if (data && data.length > 0) {
		features = data.filter(
			(feature: Feature) => permissions.some((permission: Permission) => feature.PermissionId === `${permission.PermissionId}` && permission.HasReadPermission === 1) || feature.PermissionId === '-1'
		);

		features.forEach((feature: Feature) => {
			feature.children = feature.children.filter(
				(child: Feature) => permissions.some((permission: Permission) => child.PermissionId === `${permission.PermissionId}` && permission.HasReadPermission === 1) || child.PermissionId === '-1'
			);
		});
	}

	features = features?.filter((feature: Feature) => feature.PermissionId === '-1' ? feature.children.length > 0 : feature);

	 

	const renderList = () => {
		if (!feature || !permissions.some(p => `${p.PermissionId}` === feature?.PermissionId)) return <Error404 />;
		 
		if (feature.slug.toLowerCase() === Resources.STREAM) return <Stream />;
		if (feature.slug.toLowerCase() === Resources.INSIGHTS) return <Insights feature={feature} />;
		if (feature.slug.toLowerCase() === Resources.SHOW_CASES) return <ShowCases feature={feature} />;
		if (feature.slug.toLowerCase() === Resources.USER_MANAGEMENT) return <UserManagement feature={feature} />;
		if (feature.slug.toLowerCase() === Resources.DATA_CENTER) return <DataCenter feature={feature} />;
		if (feature.slug.toLowerCase() === Resources.BACKLOG) return <Backlog onHideHeader={handleHideHeader} />;
		if (feature.slug.toLowerCase() === Resources.TEST_BANK) return <TestBank feature={feature} />;
		if (feature.slug.toLowerCase() === Resources.REPORTS) return <Reports feature={feature} />;
		if (feature.slug.toLowerCase() === Resources.RESPONSE_FORM) return <ResponseForm feature={feature} onHideHeader={handleHideHeader} />;
		if (feature.slug.toLowerCase() === Resources.SUPPORT) return <Support feature={feature} />;
		if (feature.slug.toLowerCase() === Resources.COMPETENCIES) return <Competencies feature={feature} onHideHeader={handleHideHeader} />;
		if (feature.slug.toLowerCase() === Resources.USERS_PERMISSIONS) return <AccessPermissions feature={feature} onHideHeader={handleHideHeader} />;
		if (feature.slug.toLowerCase() === Resources.KEY_MANAGEMENT) return <KeyManagement feature={feature} onHideHeader={handleHideHeader} />;
		if (feature.slug.toLowerCase() === Resources.USER_PROFILE) return <UserProfile feature={feature} onHideHeader={handleHideHeader} />;
		if (feature.slug.toLowerCase() === Resources.EMAILS) return <Emails feature={feature} onHideHeader={handleHideHeader} />;
		if (feature.slug.toLowerCase() === Resources.DOCUMENTATION) return <Documentation feature={feature} onHideHeader={handleHideHeader} />;
		if (feature.slug.toLowerCase() === Resources._404)
			return <Error404 />;

		else return <ShowCases feature={feature} />;
	};

	const renderEditForm = () => {
		if (!feature || !features?.some((_feature: Feature) => _feature?.slug! === slug
			|| _feature?.children?.some((child: Feature) => child.slug === slug))) return null;
		if (feature.slug.toLowerCase() === Resources.SHOW_CASES) return <EditShowcase feature={feature} onHideHeader={handleHideHeader} />;
		if (feature.slug.toLowerCase() === Resources.TEST_BANK) return <EditTest feature={feature} onHideHeader={handleHideHeader} />;
		if (feature.slug.toLowerCase() === Resources.RESPONSE_FORM) return <ResponseFormEdit feature={feature} onHideHeader={handleHideHeader} />;
		if (feature.slug.toLowerCase() === Resources.SUPPORT) return <Ticket feature={feature} onHideHeader={handleHideHeader} />;
		else return <ShowCases feature={feature} />;
	};

	const handleHideHeader = (status: boolean) => setHideHeader(status);
	return (
		<>
			{<Header feature={feature} />}
			<Box>
				<Routes>
					<Route path="/*" element={renderList()} />
					<Route path={`:${feature.singleName}Id/*`} element={renderEditForm()} />
				</Routes>
			</Box>
		</>
	);
};

export default Listing;
