import React, { useState } from 'react';
import { ActivityHistory } from 'models/activityHistory';
import { Box, Pagination, Stack, Typography } from '@mui/material';

interface Props {
  activityHistory: ActivityHistory[];
 };

const ActivityHistoryView: React.FC<Props> = ({ activityHistory }) => {
  const [page, setPage] = useState(1);
 
  const itemsPerPage = 10;
  const numPages = Math.ceil(activityHistory.length / itemsPerPage);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const activityHistoryToShow = activityHistory.slice(startIndex, startIndex + itemsPerPage);
  

  return (
    <Stack>
      <Typography variant='h5' paragraph sx={{ fontWeight: 400 }} color={'gray'}> Activity History</Typography>
      <Stack spacing={2}>
        {activityHistoryToShow.map((activity, index) => (
          <Box key={index} sx={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
            bgcolor: (theme) => theme.palette.background.default,
          }}>
            {activity.ActionTypeDescription && <Typography variant="body2" fontWeight={400}
              sx={{
                height: 70,
                width: 250,
                overflow: 'hidden',
                boxOrient: 'vertical',
                display: '-webkit-box',
                textOverflow: 'ellipsis',
                fontSize: 12,
                lineClamp: 1,
                "*": {
                  fontSize: 12,
                }
              }}
              component="div" dangerouslySetInnerHTML={{ __html: activity.ActionTypeDescription }}  />}
           </Box>
        ))}
      </Stack>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination count={numPages}
          page={page}
          onChange={handleChangePage}
          variant="outlined"
          color="primary"
        />
      </Box>
    </Stack>
  );
};

export default ActivityHistoryView;