import { Container } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { IFormField, IOption } from 'models/app';
import { InputTypes } from 'constants/enums';
import Form from 'components/UI/Form';
import MuiButton from 'components/UI/MuiButton';
import PrimeReactModal from 'components/UI/PrimeReactModal';
import { Language, ResponseResult } from 'models/api';
import { getTranslationLanguages } from 'framework/language';
import { useQueryClient } from 'react-query';
import { useAlert } from 'contexts/alertContext';
import ActionLoader from 'components/UI/ActionLoader';
import { TranslateFieldFormKeyManagment } from 'models/Competency';
import { GetTranslateKeyManamgment } from 'models/settings';
import { getTranslationKeyManagment } from 'framework/settings';

interface Props {
	open: boolean;
	onClose: () => void;
	onSubmit: () => void;
	onChange: (name: string, value: any) => void;
	translateForm: TranslateFieldFormKeyManagment;
}

const TranslateField: React.FC<Props> = ({ open, onChange, onClose, onSubmit, translateForm }) => {
	const queryClient = useQueryClient();
	const { setAlert } = useAlert();
	const [loading, setLoading] = useState<boolean>(false);
	const [languages, setLanguages] = useState<Language[]>([]);
	const [currentTranslation, setCurrentTranslation] = useState<GetTranslateKeyManamgment | null>(null);

	const fetchTranslation = useCallback(async () => {
		if (!translateForm?.languageId) {
			setCurrentTranslation(null);
			return;
		}
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['getTranslation', { query: `?languageId=${translateForm?.languageId}` }], getTranslationKeyManagment);

			setCurrentTranslation(response.Data);
			//onChange('',response.Data)
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [translateForm?.languageId]);

	const fetchLanguages = useCallback(async () => {
		setLoading(true);
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['Translationlanguages', { query: '' }], getTranslationLanguages);

			setLanguages(response.Data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchLanguages();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		fetchTranslation();
		// eslint-disable-next-line
	}, [translateForm?.languageId]);

	if (loading) return <ActionLoader position={'absolute'} />;

	const basicFields: IFormField[] = [
		{
			name: 'languageId',
			label: 'Language',
			type: InputTypes.SELECT,
			placeholder: 'Select Language',
			defaultValue: languages.map((language) => ({ label: language.Name, value: `${language.Id}` })).find((item: IOption) => item.value === currentTranslation?.LanguageId.toString()),
			options: languages.map((language) => ({ label: language.Name, value: `${language.Id}` })) || []
		},
		{
			name: 'Description',
			label: 'Description',
			type: InputTypes.EDITOR,
			placeholder: 'Enter Description',
			defaultValue: currentTranslation?.[translateForm?.fieldName],
			disabled: !(currentTranslation?.LanguageId || translateForm?.languageId)
		}
	];


	return (
		<PrimeReactModal
			open={open}
			onClose={onClose}
			title={`Translate label ${translateForm?.fieldName}`}
			children={
				<Container maxWidth={'md'} sx={{ py: 2, pb: 4 }}>
					<Form fields={basicFields} onChange={onChange} />
				</Container>
			}
			actions={
				<>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#F0F5FD',
							color: '#8B8BAE',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onClose}>
						Cancel
					</MuiButton>
					<MuiButton
						variant="outlined"
						size="medium"
						sx={{
							backgroundColor: '#0A94D1',
							color: '#fff',
							fontWeight: 400,
							border: 'none',
							'&:hover': { border: 'none' }
						}}
						onClick={onSubmit}
						disabled={!translateForm?.Description || !translateForm?.languageId || !translateForm?.fieldName}>
						Update
					</MuiButton>
				</>
			}
		/>
	);
};

export default TranslateField;
