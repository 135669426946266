
import { GET_TAGS, ADD_TAG } from "constants/api";
import axios from "../helpers/axiosInstance";


export async function getTags({ queryKey }: any) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_key, _params] = queryKey;
  const response = await axios.get(GET_TAGS + _params.query);
  return response.data;
}


export async function addTag(tagName: string) {
  const response = await axios.post(ADD_TAG + "?Name=" + tagName);
  return response.data;
}