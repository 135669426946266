import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { IFormField } from 'models/app';

interface Props extends IFormField {
	errors: any;
	control: any;
	defaultValue?: any;
	setValue?: any;
}

const RHCheckbox: React.FC<Props> = ({ label, name, disabled, control, defaultValue, labelColor, checkBoxDirection, customItem = null, onChange, setValue }) => {
	return (
		<>
			<Controller
				control={control}
				name={name}
				render={({ field }) => (
					<Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={checkBoxDirection || 'end'}>
						<FormControlLabel
							control={
								<Checkbox
									{...field}
									id={`${name}`}
									onChange={(e) => {
										onChange && onChange(e.target.checked);
										setValue && setValue(name, e.target.checked);
									}}
									disabled={disabled}
									defaultChecked={defaultValue}
								/>
							}
							label={label}
							labelPlacement="end"
							sx={{
								'& .MuiFormControlLabel-label': {
									color: labelColor ? labelColor : 'text.primary',
									minWidth: '8ch'
								}
							}}
						/>
						{customItem}
					</Stack>
				)}
			/>
		</>
	);
};

export default RHCheckbox;
