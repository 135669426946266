import { Stack } from '@mui/material';
import { useState } from 'react';
import { List, ListItem, ListItemText, Paper, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles'; // Updated import statement

type Props = {
 	onNavItemSelect: (selectedItem: Number) => void;
	navItems: any[];
};

const useStyles: any = makeStyles((theme: Theme) => ({
	selected: {
		backgroundColor: 'white',
		color: 'black',
		'&:hover': {
			backgroundColor: 'white'
		}
	}
}));

const HeaderTest: React.FC<Props> = ({ onNavItemSelect, navItems }) => {
	const classes = useStyles();
	const [selectedItem, setSelectedItem] = useState<Number>(0);
 
	const handleItemClick = (item: Number) => {
		setSelectedItem(item);
		onNavItemSelect(item);
	};

	return (
		<Stack
			component="header"
			direction={'row'}
			sx={{
				backgroundColor: '#1B6EE4',
				width: '100%',
				p: 2,
				pb: 0,
				pl: '15%',
				pr: '10%',

				flexShrink: 0,
				position: 'sticky',
				top: 0,
				zIndex: 1000
			}}
			spacing={4}>
			{true && (
				<>
					<Paper sx={{ width: '100%', boxShadow: 'none', borderRadius: 1, backgroundColor: 'transparent', color: 'white' }}>
						<List
							sx={{
								display: 'flex',
								p: 0,
								height: '100%'
							}}>
							{navItems.map((item, index) => (
								<ListItem button sx={{ width: 'auto', p: 1.5, borderRadius: '5px 5px 0 0' }} key={index} onClick={() => handleItemClick(index)} className={selectedItem === index ? classes.selected : ''}>
									<ListItemText primary={item.Name} />
								</ListItem>
							))}
							{/* <ListItem button sx={{ width: 'auto', ml: 'auto' }} onClick={() => null}>
								<ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
									<VisibilityIcon sx={{ color: 'white' }} />
								</ListItemIcon>
								<ListItemText primary={'Preview'} />
							</ListItem> */}
						</List>
					</Paper>			 
				</>
			)}
		 
		</Stack>
	);
};

export default HeaderTest;
