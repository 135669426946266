import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { IProgressScoreForChart } from 'models/progress';

import React from 'react';
 
interface Props {
	ScoreForChart: IProgressScoreForChart[];
	selectedScore: string;
}




const ProgressChart: React.FC<Props> = ({ ScoreForChart, selectedScore, }) => {
 

	const series1 = [
		{
			name: "Score",
			data: ScoreForChart.map((sort: any) =>  sort[selectedScore]),
		}
	];
	const series2 = [
		{
			name: "Average Score",
			data: ScoreForChart.map((sort: any) => sort[`Average${selectedScore}`]),

		}
	];

	const options: ApexOptions = {
		chart: {
			height: 400, type: 'radar',
			dropShadow: { enabled: true, blur: 1, left: 1, top: 1 },
			toolbar: { show: false }
		},
		legend: {
			position: 'bottom',
			horizontalAlign: 'center',
		},
		title: { text: '' },
		stroke: { width: 2 },
		fill: { opacity: 0.1 },
		markers: { size: 0 },
		xaxis: { categories: ScoreForChart.map((sort) => sort.PillarName) },
		yaxis: {
			min: 0,
			max: 20,
			tickAmount: 4,
		},
		responsive: [{
			breakpoint: 480,

			options: {
				plotOptions: {
					bar: {
						horizontal: false
					}
				},
				xaxis: {
					labels: {
						show: false
					},
				},
			}
		}]

	};

	return <ReactApexChart options={options} series={[...series1, ...series2]} type="radar" height={450} />

};

export default ProgressChart;
