import { Autocomplete, TextField, Typography, Stack, Box, Grid } from '@mui/material';
import { IFormField, IOption } from 'models/app';
import React from 'react';
import { Controller } from 'react-hook-form';
import MuiButton from '../MuiButton';

// ... other imports ...

interface Props extends IFormField {
	control: any;
	watch: any;
	defaultValue?: IOption;
	hidden?: boolean;
	icon?: any;
	setValue?: any;
}

const RHAutoComplete: React.FC<Props> = ({ label, name, clearButton, control, watch, multiple, options, onChange, defaultValue, hidden, placeholder, isHorizontal, bulkAction, bulkActionLabel, bulkActionFn, disabled, errorMessage, setValue }) => {
	const value = watch(name) || [];
	const handleChange = (e: any) => {
		onChange && onChange(e);
	};

	const getOptionDisabled = (option: IOption) => {
		if (!multiple) {
			return option?.value! === value?.value!;
		}
		if (!value) return false;
		return value.some((selectedOption: IOption) => selectedOption.label + selectedOption?.value! === option.label + option?.value!);
	};

	return (
		<Stack direction={isHorizontal ? 'row' : 'column'} hidden={hidden} width={'100%'}>
			{(label || bulkAction) && (
				<Grid container justifyContent={'space-between'} alignItems={'center'} >
					{label && (
						<Typography variant="body2" fontWeight={600} textAlign={'start'} mr={3}>
							{label}
						</Typography>
					)}
					{bulkAction && bulkActionLabel && bulkActionFn && (
						<>
							<MuiButton variant="text" color="primary" size="small" sx={{ textTransform: 'none' }} onClick={bulkActionFn}>
								{bulkActionLabel}
							</MuiButton>
						</>
					)}
				</Grid>
			)}
			<Controller
				control={control}
				name={name}
				disabled={disabled}
				render={({ field: { ref, onChange, ...field } }) => (
					<Box sx={{ width: '100%' }}>
						<Autocomplete
							multiple={multiple ? true : false}
							limitTags={3}
							disabled={disabled}
							options={options!}
							clearIcon={clearButton ? null : undefined}
							defaultValue={defaultValue}
							// value={value || null}
							getOptionDisabled={getOptionDisabled}
							isOptionEqualToValue={(option: any, value) => option.value === value?.value!}
							filterOptions={(options, state) => {
								if (state.inputValue?.length! === 0) return options;
								return options.filter((option: IOption) => (option?.label!.toLowerCase() + option.value).includes(state.inputValue.toLowerCase()));
							}}
							onChange={(_, data) => {
								onChange(data);
								handleChange(data);
								setValue && setValue(name, data);
							}}
							getOptionLabel={(option: IOption) => option.label || ''}
							renderOption={(props, option: IOption) => (
								<li {...props}>
									<Stack direction="row" spacing={2} alignItems="end">
										{option.icon}
										<Typography variant="body2" fontWeight={300} textAlign="start">
											{option.label}
										</Typography>
									</Stack>
								</li>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									{...field}
									inputRef={ref}
									InputLabelProps={{ children: '' }}
									fullWidth
									placeholder={placeholder}
									size="small"
									sx={{
										'& .MuiOutlinedInput-root': {
											bgcolor: 'common.white'
										}
									}}
								/>
							)}
						/>
						{errorMessage && (
							<Typography variant="caption" color="error.main" textAlign="start">
								{errorMessage}
							</Typography>
						)}
					</Box>
				)}
			/>
		</Stack>
	);
};

export default RHAutoComplete;
