import { Stack, Typography } from '@mui/material';
import DraggableFormCharacters from 'Pages/ResponseForm/partials/DragableCharachters';
import ActionLoader from 'components/UI/ActionLoader';
import MuiButton from 'components/UI/MuiButton';
import { useAlert } from 'contexts/alertContext';
import {
	addCharacter,
	addPurpose,
	deleteCharacter,
	deletePurpose,
	getCharcterByFeelingId,
	getPurposesByCharacterId,
	translateCharacter,
	translatePurpose,
	updateCharacter,
	updateCharactersIndex,
	updatePurpose,
	updatePurposeIndex
} from 'framework/responseForm';
import { ResponseResult } from 'models/api';
import { useCallback, useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import DraggableFormPurposes from 'Pages/ResponseForm/partials/DragablePurposes';
import AddCharacter from './AddCharacter';
import AddPurpose from './AddPurpose';
import { ICharchter, IPurpose } from 'models/ResponseForm';
import TranslateCharacter from './TranslateCharacter';
import { TranslateCharacterForm, TranslatePurposeForm } from 'models/Competency';
import TranslatePurpose from './TranslatePurpose';
interface IFeeling {
	Id: number;
	Name: string;
}

interface IndexField {
	Id: number;
	Index: number;
}

interface Purpose {
	Id: number;
	CharacterId: number;
	FeelingId: number;
	SortOrder: number;
	Label: string;
}

interface FormField {
	Id: number;
	FeelingId: number;
	IconPath?: File;
	SortOrder: number;
	Label: string;
	IsDeleted: boolean;
	ResponseId: number;
}

function modifyFieldsOrder(firstNumber: number, secondNumber: number, fields: ICharchter[] | IPurpose[]): IndexField[] {
	const modifiedFields: IndexField[] = [];

	fields.forEach((field) => {
		const index = field.SortOrder === firstNumber ? secondNumber : field.SortOrder === secondNumber ? firstNumber : field.SortOrder;

		modifiedFields.push({
			Id: field.Id,
			Index: index
		});
	});

	return modifiedFields.sort((a, b) => a.Index - b.Index);
}

type Props = {
	selectedFeeling: IFeeling | null;
};

const Feeling: React.FC<Props> = ({ selectedFeeling }) => {
	const queryClient = useQueryClient();

	const [charcters, setCharcters] = useState<ICharchter[] | null>(null);
	const [purposes, setPurposes] = useState<IPurpose[] | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [characterId, setCharacterId] = useState<number | null>(null);
	const [showAddCharacter, setShowAddCharacter] = useState<boolean>(false);
	const [showTranslateCharacter, setShowTranslateCharacter] = useState<boolean>(false);
	const [showTranslatePurpose, setShowTranslatePurpose] = useState<boolean>(false);
	const [addCharacterFormData, setAddCharacterFormData] = useState<ICharchter | null>(null);
	const [showAddPurpose, setShowAddPurpose] = useState<boolean>(false);
	const [addPurposeFormData, setAddPurposeFormData] = useState<IPurpose | null>(null);
	const [translateCharacterForm, setTranslateCharacterForm] = useState<TranslateCharacterForm | null>(null);
	const [translatePurposeForm, setTranslatePurposeForm] = useState<TranslatePurposeForm | null>(null);

	const { setAlert } = useAlert();

	const updateCharacterMutation = useMutation({
		mutationFn: (data: FormData) => {
			return updateCharacter(data);
		},
		onSuccess: () => {
			fetchCharcter();
		}
	});

	const addCharacterMutation = useMutation({
		mutationFn: (data: FormData) => {
			return addCharacter(data);
		},
		onSuccess: () => {
			fetchCharcter();
		}
	});

	const translateCharacterMutation = useMutation({
		mutationFn: (data: TranslateCharacterForm) => {
			return translateCharacter(data);
		},
		onSuccess: () => {
			fetchCharcter();
		}
	});

	const translatePurposeMutation = useMutation({
		mutationFn: (data: TranslatePurposeForm) => {
			return translatePurpose(data);
		},
		onSuccess: () => {
			fetchCharcter();
		}
	});

	const fetchCharcter = useCallback(async () => {
		setPurposes(null);
		setCharacterId(null);
		setLoading(true);
		let params = `?feelingId=${selectedFeeling?.Id}&languageId=1`;
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['characters', { query: params }], getCharcterByFeelingId);
			const data = response.Data;
			data.forEach((item: ICharchter, index: number) => {
				item.SortOrder = index + 1;
			});
			setCharcters(data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
		}
		setLoading(false);
		// eslint-disable-next-line
	}, [selectedFeeling?.Id]);

	const fetchPurpose = useCallback(async () => {
		if (!characterId) {
			setPurposes(null);

			return;
		}
		setLoading(true);

		let params = `?feelingId=${characterId!}&languageId=1`;
		try {
			const response: ResponseResult = await queryClient.fetchQuery(['purposes', { query: params }], getPurposesByCharacterId);

			const data = response.Data;
			data.forEach((item: IPurpose, index: number) => {
				item.SortOrder = index + 1;
			});
			setPurposes(data);
			setLoading(false);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}

		// eslint-disable-next-line
	}, [characterId]);

	useEffect(() => {
		fetchCharcter();
		// eslint-disable-next-line
	}, [selectedFeeling?.Id]);

	useEffect(() => {
		fetchPurpose();
		// eslint-disable-next-line
	}, [characterId]);

	if (loading) return <ActionLoader position={'absolute'} />;

	const onDeleteCharacter = async (id: number) => {
		setLoading(true);

		try {
			const res = await queryClient.fetchQuery(['deleteCharacter', { query: `?Id=${id}` }], deleteCharacter);

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Deleted',
				type: 'success'
			});
			setLoading(false);
			fetchCharcter();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	const onDeletePurpose = async (id: number) => {
		setLoading(true);

		try {
			const res = await queryClient.fetchQuery(['deletePurpose', { query: `?Id=${id}` }], deletePurpose);

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Deleted',
				type: 'success'
			});
			setLoading(false);
			fetchCharcter();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	const handleUpdateCharacter = async (data: Partial<ICharchter>) => {
		const formData = new FormData();
		formData.append('Id', data?.Id?.toString() || '');
		formData.append('ResponseId', '1');
		formData.append('Label', data?.Label || '');
		formData.append('iconPath', data?.iconPath || data?.IconPath || '');

		setLoading(true);

		try {
			const res = await updateCharacterMutation.mutateAsync(formData);

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Updated',
				type: 'success'
			});
			setLoading(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
		//handleClose();
	};

	const handleUpdatePurpose = async (data: Partial<IPurpose>) => {
		setLoading(true);

		try {
			const res = await queryClient.fetchQuery(['updatePurpose', { query: `?Id=${data?.Id}&label=${data?.Label}&characterId=${characterId}` }], updatePurpose);

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Updated',
				type: 'success'
			});
			setLoading(false);
			fetchCharcter();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
		//handleClose();
	};

	const handleAddNewCharacter = async () => {
		const formData = new FormData();
		formData.append('responseId', '1');
		formData.append('label', addCharacterFormData?.Label! || addCharacterFormData?.label! || '');
		formData.append('iconPath', addCharacterFormData?.iconPath || addCharacterFormData?.IconPath || '');
		formData.append('feelingId', selectedFeeling?.Id!?.toString() || '');

		setLoading(true);

		try {
			const res = await addCharacterMutation.mutateAsync(formData);

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Added',
				type: 'success'
			});
			setLoading(false);
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);

		handleClose();
	};

	const handleAddNewPurpose = async () => {
		setLoading(true);

		try {
			const res = await queryClient.fetchQuery(['addPurpose', { query: `?label=${addPurposeFormData?.Label}&characterId=${characterId}` }], addPurpose);

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Added',
				type: 'success'
			});
			setLoading(false);
			fetchCharcter();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);

		handleClose();
	};

	const handleMarkClick = (characterId: number | null) => {
		// Handle the mark click event in the Feeling component
		setCharacterId(characterId);
		// Add your logic here
	};

	const handleSelectCharacterTranslation = (item: FormField) => {
		setShowTranslateCharacter(true);
		setTranslateCharacterForm({ CharacterId: item.Id!, Label: '', LanguageId: null!, ResponseId: item.ResponseId! });
	};

	const handleSelectPurposeTranslation = (item: Purpose) => {
		setShowTranslatePurpose(true);
		setTranslatePurposeForm({ purposeId: item.Id!, label: '', languageId: null!, responseId: 1 });
	};

	const handleClose = () => {
		setShowAddCharacter(false);
		setShowAddPurpose(false);
		setShowTranslateCharacter(false);
		setShowTranslateCharacter(false);
		setAddCharacterFormData(null);
		setAddPurposeFormData(null);
		setShowAddPurpose(false);
		setShowTranslatePurpose(false);
		setTranslateCharacterForm(null);
		setTranslatePurposeForm(null);
	};

	const handleCharacterSort = async (drag: number, drop: number) => {
		setLoading(true);
		try {
			const res = await updateCharactersIndex(modifyFieldsOrder(drag + 1, drop + 1, charcters!)); //provide data

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Updated',
				type: 'success'
			});
			setLoading(false);
			fetchCharcter();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	const handlePurposeSort = async (drag: number, drop: number) => {
		setLoading(true);

		try {
			const res = await updatePurposeIndex(modifyFieldsOrder(drag + 1, drop + 1, purposes!)); //provide data

			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'successfully Updated',
				type: 'success'
			});
			setLoading(false);
			fetchCharcter();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
		setLoading(false);
	};

	const handleTranslateCharacter = async () => {
		setLoading(true);
		const createInput: TranslateCharacterForm = {
			CharacterId: translateCharacterForm?.CharacterId!,
			Label: translateCharacterForm?.Label!,
			ResponseId: 1,
			LanguageId: translateCharacterForm?.LanguageId!
		};
		try {
			const res = await translateCharacterMutation.mutateAsync(createInput!);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Character Translated successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleTranslatePurpose = async () => {
		setLoading(true);
		const createInput: TranslatePurposeForm = {
			purposeId: translatePurposeForm?.purposeId!,
			label: translatePurposeForm?.label!,
			responseId: 1,
			languageId: translatePurposeForm?.languageId!
		};
		try {
			const res = await translatePurposeMutation.mutateAsync(createInput!);
			if (res.Success === 0) throw new Error(res.Message);
			setAlert({
				open: true,
				message: 'Purpose Translated successfully',
				type: 'success'
			});
			setLoading(false);
			handleClose();
		} catch (err: any) {
			setAlert({
				open: true,
				message: err?.response?.data?.Message || err.message || 'Something went wrong',
				type: 'error'
			});
			setLoading(false);
		}
	};

	const handleChangeFormInputAddCharacter = (name: string, value: any) => setAddCharacterFormData({ ...addCharacterFormData!, [name]: value });
	const handleChangeFormInputAddPurpose = (name: string, value: any) => setAddPurposeFormData({ ...addPurposeFormData!, [name]: value });
	const handleChangeTranslateCharacterForm = (name: string, value: any) => setTranslateCharacterForm({ ...translateCharacterForm!, [name]: value });
	const handleChangeTranslateCharacterMultipleForm = (data: { name: string, value: any }[]) => {
		let temp = { ...translateCharacterForm! };
		data.forEach((item) => {
			temp[item.name] = item.value;
		});
		setTranslateCharacterForm(temp);
	}  
	const handleChangeTranslatePurposeForm = (name: string, value: any) => setTranslatePurposeForm({ ...translatePurposeForm!, [name]: value });
	return (
		<Stack direction={'column'} sx={{ backgroundColor: 'rgba(241, 241, 241, 1)' }} p={4}>
			<Typography variant="h5">{selectedFeeling?.Name}</Typography>

			<Stack direction={'row'} justifyContent={'space-between'}>
				<Typography py={3}>Characters</Typography>
				<Stack direction={'row'} spacing={2} justifyContent={'end'} alignItems={'center'} my={3}>
					<MuiButton
						startIcon={<AddCircleIcon sx={{ color: 'rgba(30, 169, 234, 1)' }} />}
						variant="outlined"
						color="primary"
						size="large"
						sx={{ width: 'fit-content', borderRadius: 0, borderColor: 'rgba(241, 241, 241, 1)', p: 2, px: 3, color: 'rgba(30, 169, 234, 1)', ml: 'auto' }}
						onClick={() => setShowAddCharacter(true)}>
						Add New
					</MuiButton>
				</Stack>
			</Stack>

			<DraggableFormCharacters
				fields={charcters!}
				onDelete={onDeleteCharacter}
				onSortUpdate={handleCharacterSort}
				onFieldSubmit={handleUpdateCharacter}
				onMarkClick={handleMarkClick}
				currentCharacter={characterId}
				onSelectioTranslation={handleSelectCharacterTranslation}
			/>

			{/* Create Character */}
			{showAddCharacter && <AddCharacter open={showAddCharacter} onClose={handleClose} onChange={handleChangeFormInputAddCharacter} onSubmit={handleAddNewCharacter} loading={false} />}

			{showTranslateCharacter && (
				<TranslateCharacter open={showTranslateCharacter} onClose={handleClose} translateForm={translateCharacterForm!} onChange={handleChangeTranslateCharacterForm} onSubmit={handleTranslateCharacter} onChangeMultiple={handleChangeTranslateCharacterMultipleForm} />
			)}
			{purposes && (
				<>
					<Stack direction={'row'} justifyContent={'space-between'} my={2}>
						<Typography py={3}>Purposes</Typography>
						<Stack direction={'row'} spacing={2} justifyContent={'end'} alignItems={'center'} my={3}>
							<MuiButton
								startIcon={<AddCircleIcon sx={{ color: 'rgba(30, 169, 234, 1)' }} />}
								variant="outlined"
								color="primary"
								size="large"
								sx={{ width: 'fit-content', borderRadius: 0, borderColor: 'rgba(241, 241, 241, 1)', p: 2, px: 3, color: 'rgba(30, 169, 234, 1)', ml: 'auto' }}
								onClick={() => setShowAddPurpose(true)}>
								Add New
							</MuiButton>
						</Stack>
					</Stack>

					<DraggableFormPurposes fields={purposes!} onDelete={onDeletePurpose} onFieldSubmit={handleUpdatePurpose} onSortUpdate={handlePurposeSort} onSelectioTranslation={handleSelectPurposeTranslation} />

					{/* Create Purpose */}
					{showAddPurpose && <AddPurpose open={showAddPurpose} onClose={handleClose} onChange={handleChangeFormInputAddPurpose} onSubmit={handleAddNewPurpose} loading={false} purposeFields={addPurposeFormData!} />}
					{showTranslatePurpose && (
						<TranslatePurpose open={showTranslatePurpose} onClose={handleClose} translateForm={translatePurposeForm!} onChange={handleChangeTranslatePurposeForm} onSubmit={handleTranslatePurpose} />
					)}
				</>
			)}
		</Stack>
	);
};

export default Feeling;
