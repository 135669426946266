
import React, { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import useLoading from 'hooks/loading';
import useStreamStore from 'store/stream';
import { ResponseResult } from 'models/api';
import { getRelevanceVotes, getStreams } from 'framework/stream';
import { Box, Container, Grid, Pagination, Stack, Typography } from '@mui/material';
import ActionLoader from 'components/UI/ActionLoader';
import { IBacklogInput, IEditStreamInput, IRelevanceVoteChangeInput, IReplyInput, RelevanceVote, StreamListItem } from 'models/stream';
import StreamCard from './partials/StreamCard';
import MuiOutlineButton from 'components/UI/MuiOutlineButton';
import { IMediaQuery } from 'types/mediaQuery';
import useResponsive from 'hooks/useResponsive';
import { Competency } from 'models/Competency';
import AddBacklog from '../AddBacklog/AddBacklog';
import AddReply from '../AddReply/AddReply';
import { ActivityHistory } from 'models/activityHistory';
import EditStream from '../EditStream/EditStream';
import { Permission } from 'models/permission';


interface Props {
  onApproveInteraction: (id: number) => void;
  onAddAsNone: (id: number) => void;
  onChangeRelevanceVote: (data: IRelevanceVoteChangeInput) => void;
  onAddBacklog: (data: IBacklogInput) => void;
  onSelectCompetency: (competency: Competency |null) => void;
  onAddReply: (data: IReplyInput) => void;
  onEditStream: (data: IEditStreamInput) => void;
  onConfirmStream: (stream: StreamListItem) => void;
  competencies: Competency[] | null;
  selectedCompetency: Competency | null;
  indicators: string[] | null;
  activityHistory: ActivityHistory[] | null;
  permissions: Permission;
}

const enum Statuses {
  ITEMS_FOR_REVIEW = "ITEMS FOR REVIEW",
  APPROVED = "APPROVED",
  EDITED = "EDITED",
  PENDING = "PENDING",
  NONE = "NONE"
}

const filters = [Statuses.ITEMS_FOR_REVIEW, Statuses.APPROVED, Statuses.EDITED, Statuses.PENDING, Statuses.NONE];

const StreamList: React.FC<Props> = ({ onApproveInteraction, onChangeRelevanceVote, onAddAsNone, onAddBacklog,
  onSelectCompetency, onAddReply, onEditStream, onConfirmStream, competencies, selectedCompetency, indicators, permissions, activityHistory
}) => {
  const mediaParams: IMediaQuery = { query: 'up', key: 'md' };
  const mdUp = useResponsive(mediaParams);
  const queryClient = useQueryClient();
  const { loading, setLoading } = useLoading();
  const [error, setError] = useState<boolean>(false);
  const setListing = useStreamStore((state) => state.setListing);
  const listing = useStreamStore((state) => state.listing);
  const params = useStreamStore((state) => state.params);
  // const setParams = useStreamStore((state) => state.setParams);
  const [selectedFilter, setSelectedFilter] = React.useState<string>(Statuses.ITEMS_FOR_REVIEW);
  const [page, setPage] = useState(1);
  const [filteredListing, setFilteredListing] = useState<StreamListItem[]>([]);
  const [openAddBacklog, setOpenAddBacklog] = useState<boolean>(false);
  const [openAddReply, setOpenAddReply] = useState<boolean>(false);
  const [openEditStream, setOpenEditStream] = useState<boolean>(false);
  const [selectedStream, setSelectedStream] = useState<StreamListItem | null>(null);

  const { data: relevanceVotesResult, isError: isErrorRelevanceVotes, isLoading: isLoadingRelevanceVotes } = useQuery<ResponseResult>(['relevanceVotes', { query: '' }], getRelevanceVotes);


  const fetchStreams = useCallback(async () => {
    setLoading(true);
    try {
      const response: ResponseResult = await queryClient.fetchQuery(['streams', { query: params }], getStreams);
      setListing(response.Data);
      setFilteredListing(response.Data.filter((item: StreamListItem) => {
        // return filter by selected filter
        switch (selectedFilter) {
          case Statuses.ITEMS_FOR_REVIEW:
            return item.StatusId === 2;
          case Statuses.APPROVED:
            return item.StatusId === 4;
          case Statuses.EDITED:
            return item.StatusId === 5;
          case Statuses.PENDING:
            return item.StatusId === 3;
          case Statuses.NONE:
            return item.StatusId === 1;
          default: return item.StatusId === 2;
        }
      }));
      setLoading(false);
    } catch (err: Error | any) {
      setError(true);
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    fetchStreams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
 
  if (error || isErrorRelevanceVotes)
    return (
      <Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <Typography variant="body2" fontWeight={500} fontSize={15} color={'#000'}>
          Something Wrong
        </Typography>
      </Grid>
    );
  if (loading || isLoadingRelevanceVotes) return <ActionLoader position={'absolute'} />;

  let relevanceVotes: RelevanceVote[] = relevanceVotesResult?.Data || ([] as RelevanceVote[]);

  const itemsPerPage = 5;
  const numPages = Math.ceil(filteredListing.length / itemsPerPage);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const listingToShow = filteredListing.slice(startIndex, startIndex + itemsPerPage);



  const handleFilter = (filter: string) => {
    setSelectedFilter(filter);
    setPage(1);
    switch (filter) {
      case Statuses.ITEMS_FOR_REVIEW:
        setFilteredListing(listing.filter((item) => item.StatusId === 2));
        break;
      case Statuses.APPROVED:
        setFilteredListing(listing.filter((item) => item.StatusId === 4));
        break;
      case Statuses.EDITED:
        setFilteredListing(listing.filter((item) => item.StatusId === 5));
        break;
      case Statuses.PENDING:
        setFilteredListing(listing.filter((item) => item.StatusId === 3));
        break;
      case Statuses.NONE:
        setFilteredListing(listing.filter((item) => item.StatusId === 1));
        break;
      default: setFilteredListing(listing.filter((item) => item.StatusId === 2));
        break;
    }
  }

  const handleClose = () => {
    setOpenEditStream(false);
    setOpenAddBacklog(false);
    setOpenAddReply(false);
    setSelectedStream(null);
    onSelectCompetency(null);
  }

  const handleOpenAddBacklog = (stream: StreamListItem) => { 
    setSelectedStream(stream);
    setOpenAddBacklog(true);
  }
  const handleOpenAddReply = (stream: StreamListItem) => { 
    setSelectedStream(stream);
    setOpenAddReply(true);
  }
  const handleOpenEditStream = (stream: StreamListItem) => { 
    setSelectedStream(stream);
    setOpenEditStream(true);
  }

  return (
    <Grid container spacing={2} py={5} px={mdUp ? 5 : 0} >
      <Stack spacing={4}>
      
        <Container sx={{ display: 'block' }}>
          <Box
            sx={{
              display: { xs: "block", md: "flex" },
              whiteSpace: "nowrap",
              overflowX: "auto",
              '&::-webkit-scrollbar': {
                display: 'none'
              },
              maxWidth: { xs: '80vw', md: '100vw' }
            }}
          >

            {filters.map((filter, index) => (
              <Box
                key={index}
                className="navMenuItem"
                sx={{
                  alignItems: "center",
                  textAlign: 'center',
                  justifyContent: "center",
                  "&:first-of-type": {
                    marginLeft: 0,
                  },
                  overflow: "hidden",
                  display: { xs: "inline-block", md: "flex" },
                  transition: ".3s ease",
                  color: filter === selectedFilter ? 'white' : 'black',
                  backgroundColor: filter === selectedFilter ? '#0078B4' : 'white',
                  borderColor: filter === selectedFilter ? '#0078B4' : '#DADADA',
                }}
                onClick={() => handleFilter(filter)}
              >
                <MuiOutlineButton
                  key={index}
                  onClick={() => handleFilter(filter)} variant={filter === selectedFilter ? 'contained' : 'outlined'}
                  sx={{
                    borderRadius: 0,
                    color: filter === selectedFilter ? 'white' : 'black',
                    backgroundColor: filter === selectedFilter ? '#0078B4' : 'white',
                    borderColor: filter === selectedFilter ? '#0078B4' : '#DADADA',
                    p: 1,
                    height: '3rem',
                    minWidth: '7rem',
                    mx: 1,

                  }}
                >
                  {filter}
                  {filter === Statuses.ITEMS_FOR_REVIEW &&
                    <Box component={'span'} ml={1} color={'#000'}
                      sx={{
                        // make it circle
                        height: 30,
                        width: 30,
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        border: '1px solid #0078B4',
                        fontSize: 12,
                        fontWeight: 600,

                      }}
                      fontWeight={600}>
                      {listing.filter((item) => item.StatusId === 2).length}
                    </Box>}
                  {filter === Statuses.PENDING &&
                    <Box component={'span'} ml={1} color={'#000'}
                      sx={{
                        // make it circle
                        height: 30,
                        width: 30,
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        border: '1px solid #0078B4',
                        fontSize: 12,
                        fontWeight: 600,

                      }}
                      fontWeight={600}>
                      {listing.filter((item) => item.StatusId === 3).length}
                    </Box>}
                </MuiOutlineButton>
              </Box>
            )
            )}
          </Box>
        </Container>
        
       
        <Stack spacing={5} >
          {listingToShow.length > 0 &&
            listingToShow.map((stream: StreamListItem, index) => (
              <StreamCard stream={stream} selectedFilter={selectedFilter} key={index} relevanceVotes={relevanceVotes} onApproveInteraction={onApproveInteraction} onChangeRelevanceVote={onChangeRelevanceVote}
                onAddAsNone={onAddAsNone} onAddBacklog={handleOpenAddBacklog} onAddReply={handleOpenAddReply}
                onEditStream={handleOpenEditStream} onConfirmStream={onConfirmStream} permissions={permissions}
              />
            ))}
          {listingToShow.length === 0 && <Grid container justifyContent={'center'} alignItems={'center'} height={'10vh'}>
            <Typography variant="body2" fontWeight={500} fontSize={15} color={'warning.main'}>
              There are no streams has status <Box component={'span'} fontWeight={600} color={'#000'}>
                {selectedFilter.charAt(0).toUpperCase() + selectedFilter.slice(1).toLowerCase()}
              </Box>
            </Typography>
          </Grid>}
        </Stack>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Pagination count={numPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            color="primary"
          />
        </Box>
      </Stack>

      {openAddBacklog && <AddBacklog
        open={openAddBacklog}
        onClose={handleClose}   
        onSelectCompetency={onSelectCompetency}
        competencies={competencies}
        selectedCompetency={selectedCompetency}
        indicators={indicators}
        stream={selectedStream!}
        onSubmit={onAddBacklog}
      />
       }
      {openAddReply && <AddReply
        open={openAddReply}
        onClose={handleClose}       
        stream={selectedStream!}
        onSubmit={onAddReply}
       />
       }
      {openEditStream && <EditStream
        open={openEditStream}
        onClose={handleClose}        
        onSubmit={onEditStream}
        stream={selectedStream!}
        onSelectCompetency={onSelectCompetency}
        competencies={competencies}
        selectedCompetency={selectedCompetency}
        indicators={indicators}
       />
       }
    </Grid>
  );
};

export default StreamList;