import { HeadCell } from 'models/app';
import axios from '../helpers/axiosInstance';
import {
	ADD_CHARACTER,
	ADD_PURPOSE,
	ADD_REFERENCE_QUICK_REPLY,
	ADD_REFERENCE_SENTENCE_SET,
	ADD_REFRENCE_BY_PURPOSE_ID,
	DELETE_CHARACTER,
	DELETE_PURPOSE,
	DELETE_REFERENCE_QUICK_REPLY,
	DELETE_REFERENCE_SENTENCE_SET,
	DELETE_REFRENCE_SET_DATA,
	GET_ADVANCED_SETTINGS,
	GET_CHARCTER_BY_FEELING_ID,
	GET_DIRECTS,
	GET_FEELINGS,
	GET_LABELS,
	GET_PURPOSE_BY_CHARCTER_ID,
	GET_REFERENCE_QUICK_REPLY,
	GET_REFERENCE_SENTENCE_SETS,
	GET_REFERENCE_SENTENCE_SETS_BY_ID,
	GET_REFRENCE_BY_PURPOSE_ID,
	SORT_CHARACTER,
	SORT_PURPOSE,
	SORT_REFERENCE_QUICK_REPLY,
	SORT_REFERENCE_SENTENCE_DATA,
	TRANSLATE_CHARACTER,
	TRANSLATE_PURPOSE,
	UPDATE_ADVANCED_SETTINGS,
	UPDATE_CHARACTER,
	UPDATE_LABELS,
	UPDATE_PURPOSE,
	UPDATE_REFERENCE_QUICK_REPLY,
	UPDATE_REFERENCE_SENTENCE_DATA,
	UPDATE_REFERENCE_SENTENCE_SET
} from 'constants/api';
import { IAdvancedSettings, IFieldIndex, ILabels } from 'models/ResponseForm';
import { TranslateCharacterForm, TranslatePurposeForm } from 'models/Competency';

export async function getRefrenceSentenceSets() {
	const response = await axios.get(GET_REFERENCE_SENTENCE_SETS);
	return response.data;
}

export async function getFeelings() {
	const response = await axios.get(GET_FEELINGS);
	return response.data;
}

export async function getLabels({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_LABELS + _params.query);
	return response.data;
}

export async function updateLabels(data: ILabels) {
	const response = await axios.post(`${UPDATE_LABELS}`, data);
	return response.data;
}

export async function getCharcterByFeelingId({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_CHARCTER_BY_FEELING_ID + _params.query);
	return response.data;
}

export async function getAdvancedSettings({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_ADVANCED_SETTINGS + _params.query);
	return response.data;
}

export async function updateAdvancedSettings(data: IAdvancedSettings) {
	const response = await axios.post(`${UPDATE_ADVANCED_SETTINGS}`, data);
	return response.data;
}

export async function getPurposesByCharacterId({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_PURPOSE_BY_CHARCTER_ID + _params.query);
	return response.data;
}

export async function getRefrenceSentenceSetsByPurposeId({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_REFRENCE_BY_PURPOSE_ID + _params.query);
	return response.data;
}

export async function getRefrenceSentenceSetsById({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_REFERENCE_SENTENCE_SETS_BY_ID + _params.query);
	return response.data;
}

export async function getRefrenceQuickReplyById({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.get(GET_REFERENCE_QUICK_REPLY + _params.query);
	return response.data;
}

export async function addRefrenceQuickReply({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.post(ADD_REFERENCE_QUICK_REPLY + _params.query);
	return response.data;
}

export async function deleteRefrenceQuickReplyById({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.post(DELETE_REFERENCE_QUICK_REPLY + _params.query);
	return response.data;
}
export async function deleteRefrenceSetData({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.post(DELETE_REFRENCE_SET_DATA + _params.query);
	return response.data;
}

export async function deleteCharacter({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.post(DELETE_CHARACTER + _params.query);

	return response.data;
}

export async function addPurpose({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.post(ADD_PURPOSE + _params.query);

	return response.data;
}

export async function addCharacter(data: FormData) {
	const response = await axios.post(ADD_CHARACTER, data);

	return response.data;
}

export async function translateCharacter(data: TranslateCharacterForm) {
	const response = await axios.post(TRANSLATE_CHARACTER, data);

	return response.data;
}

export async function translatePurpose(data: TranslatePurposeForm) {
	const response = await axios.post(TRANSLATE_PURPOSE + `?purposeId=${data?.purposeId}&responseId=${data?.responseId}&languageId=${data?.languageId}&label=${data?.label}`);

	return response.data;
}

export async function deletePurpose({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.post(DELETE_PURPOSE + _params.query);

	return response.data;
}

export async function updateCharacter(data: FormData) {
	const response = await axios.post(UPDATE_CHARACTER, data);

	return response.data;
}

export async function updatePurpose({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.post(UPDATE_PURPOSE + _params.query);

	return response.data;
}

export async function updateRefrenceSetData({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.post(UPDATE_REFERENCE_SENTENCE_DATA + _params.query);
	return response.data;
}

export async function updateRefrenceQuickReplyById({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.post(UPDATE_REFERENCE_QUICK_REPLY + queryKey);
	return response.data;
}

export async function getDirects() {
	const response = await axios.get(GET_DIRECTS);
	return response.data;
}

export async function addRefrenceSentenceSet({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.post(ADD_REFERENCE_SENTENCE_SET + queryKey);
	return response.data;
}

export async function updateRefrenceSentenceSet({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.post(UPDATE_REFERENCE_SENTENCE_SET + queryKey);
	return response.data;
}

export async function updatePurposeIndex(data: IFieldIndex[]) {
	const response = await axios.post(SORT_PURPOSE, data);
	return response.data;
}

export async function updateCharactersIndex(data: IFieldIndex[]) {
	const response = await axios.post(SORT_CHARACTER, data);
	return response.data;
}

export async function updateRefrenceSentenceSetIndex(data: IFieldIndex[]) {
	const response = await axios.post(SORT_REFERENCE_SENTENCE_DATA, data);
	return response.data;
}

export async function updateRefrenceQuickReplyIndex(data: IFieldIndex[]) {
	const response = await axios.post(SORT_REFERENCE_QUICK_REPLY, data);
	return response.data;
}

export async function addRefrenceSentenceSetData({ queryKey }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_key, _params] = queryKey;
	const response = await axios.post(ADD_REFRENCE_BY_PURPOSE_ID + _params.query);
	return response.data;
}

export async function deleteRefrenceSentenceSet(id: number) {
	const response = await axios.post(DELETE_REFERENCE_SENTENCE_SET + `?Id=${id}`);
	return response.data;
}

export const ReferenceSentenceSetsCells: HeadCell[] = [
	{
		id: 'Name',
		numeric: false,
		disablePadding: false,
		label: 'Reference Sentence Set'
	},
	{
		id: 'countryName',
		numeric: false,
		disablePadding: false,
		label: 'Country'
	},
	{
		id: 'UpdatedOn',
		numeric: false,
		disablePadding: false,
		label: 'Updated On',
		isDate: true
	},
	{
		id: 'Id',
		numeric: false,
		disablePadding: false,
		label: '',
		isNotSortable: true
	}
];
