import { Download } from '@mui/icons-material';
import { Box, Divider, Stack, Typography } from '@mui/material';
import CustomUploadFile from 'components/UI/CustomUploadFile';
import MuiButton from 'components/UI/MuiButton';
import { AddIcon1 } from 'components/UI/SvgIcons';
import useResponsive from 'hooks/useResponsive';
import React from 'react';
import { IMediaQuery } from 'types/mediaQuery';

interface Props {
  OpenAddShowCase: () => void;
  GenerateTemplate: () => void;
  ImportTemplate: (file: File) => void;
}

const ShowcaseBulkActions: React.FC<Props> = ({ OpenAddShowCase, GenerateTemplate, ImportTemplate }) => { 
  const params: IMediaQuery = { query: "up", key: "md" };
  const mdUp = useResponsive(params);
  
  return (
    <Stack direction={'column'} spacing={mdUp ? 10 : 5} px={mdUp ? 5 : 2} >
      <Stack spacing={2}>
        <MuiButton variant="contained"
          size="large" sx={{
            backgroundColor: "#E9230B",
            color: "#fff",
            fontWeight: 500,
            border: 'none',
            maxWidth: 200,
          }}
          startIcon={<AddIcon1 />}
          onClick={OpenAddShowCase}>
          Add Showcase
        </MuiButton>
        <MuiButton variant="contained"
          size="large" sx={{
            backgroundColor: "#0A94D1",
            color: "#fff",
            fontWeight: 500,
            border: 'none',
            maxWidth: 200,
            fontSize: 12
          }}
          startIcon={<Download />}
          onClick={GenerateTemplate}>
          Download Template
        </MuiButton>
      </Stack>
      <Box>
        <Typography variant="body2" fontWeight={500} fontSize={13} color={"#000"}>Bulk Uploads</Typography>
        <Divider sx={{ mb: 2 }} />
        <CustomUploadFile onChange={ImportTemplate} />
      </Box>
    </Stack>
    )
}

export default ShowcaseBulkActions;